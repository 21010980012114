import * as React from 'react'
import { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

/* Import components here */
import { DumbPage, Grid, Heading, Module, Paragraph } from 'Components'
import { LoadingPage } from 'Pages'

/* Import interfaces here */
import { IKlarnaFailedPageParams } from './KlarnaFailed.interfaces'

/* Import utilities here */
import { API_DEPRECATED, IBookingDto } from 'Api_DEPRECATED'
import { PossibleRootPathValue } from 'Config'
import { UIJump } from 'Utils/ui'

const KlarnaFailedPage = ({
  match: {
    params: { bookingNumber, klarnaOrderId },
  },
}: RouteComponentProps<IKlarnaFailedPageParams>): JSX.Element => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [success, setSuccess] = useState<boolean>(false)

  useEffect((): void => {
    const postPaymentFailed = async (): Promise<void> => {
      try {
        const booking: IBookingDto = await API_DEPRECATED.Bookings.postPaymentFailed(bookingNumber, {
          orderId: klarnaOrderId,
        })
        setIsLoading(false)
        if (booking) {
          setSuccess(true)
        }
      } catch (err) {
        setIsLoading(false)
      }
    }

    postPaymentFailed()
  }, [bookingNumber, klarnaOrderId])

  return isLoading ? (
    <LoadingPage />
  ) : success ? (
    <UIJump
      params={klarnaOrderId ? `${bookingNumber}/${klarnaOrderId}` : bookingNumber}
      state={{ shouldOverridePreviousPageComplete: true }}
      to={PossibleRootPathValue.summary}
    />
  ) : (
    <DumbPage hideFooter={true} hideHeader={true} hideNavigation={true}>
      <Grid>
        <Module title={t('errors:oopsSomethingWrong')}>
          <Heading level={1}>{t('errors:klarna.couldNotComplete')}</Heading>
          <Paragraph alignSelf="center">{t('errors:pleaseContactSupport')}</Paragraph>
        </Module>
      </Grid>
    </DumbPage>
  )
}

export default KlarnaFailedPage
