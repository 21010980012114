import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import { getMinWidth } from 'Theme'
import { IVisibilityProps } from './Visibility.interfaces'

export const StyledVisibility = styled.div<IVisibilityProps>`
  display: none;

  @media ${({ minWidth }): string => getMinWidth(minWidth)} {
    display: inherit;
  }

  ${({ maxWidth }): FlattenSimpleInterpolation | undefined =>
    maxWidth &&
    css`
      @media ${getMinWidth(maxWidth)} {
        display: none;
      }
    `}
`
