import * as React from 'react'

import classNames from 'classnames'
import { format } from 'date-fns'

/* Import components here */
import { Date, Day, StyledWeekday } from './Weekday.styles'

/* Import interfaces here */
import { IWeekdayProps } from './Weekday.interfaces'

/* Import utilities here */
import { capitalize, getLocaleFile } from 'Utils'

export class Weekday extends React.Component<IWeekdayProps> {
  public render(): JSX.Element {
    const { selected, weekday, isPast } = this.props

    return (
      <StyledWeekday className={classNames(isPast && 'isPast', selected && 'selected')} onClick={this.onClickHandler}>
        <Day>{capitalize(format(weekday, 'ddd', { locale: getLocaleFile() }))}</Day>
        <Date>{weekday.getDate()}</Date>
      </StyledWeekday>
    )
  }

  private onClickHandler = (): void => {
    const { weekday } = this.props

    this.props.onSelectDay(weekday)
  }
}

export default Weekday
