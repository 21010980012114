import * as React from 'react'
import * as Sentry from '@sentry/react'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'mobx-react'
import { BrowserRouter, Route, RouteProps, Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.min.css'

/* Components */
import { AppRouter, ErrorPage, LoadingPage } from 'Pages'
import { BookingSessionTimer } from 'Components/Opus/BookingSessionTimer'

/* Utilities */
import { GlobalStyle, theme } from 'Theme'
import { IRootStore } from 'Models'
import { setupRootStore } from 'App'
import i18nConfig from 'Config/i18n'
import { Tracker } from 'Utils'
import { history } from 'Config/sentry'

export interface IAppState {
  loadingRootStore: boolean
  rootStore?: IRootStore
}

export class App extends React.Component<RouteProps, IAppState> {
  public state: Readonly<IAppState> = {
    loadingRootStore: true,
    rootStore: undefined,
  }

  public async componentDidMount(): Promise<void> {
    Tracker.init()
    Tracker.trackPage('/')

    const rootStore = await setupRootStore()
    try {
      await rootStore.stationsStore.FetchPriceRules()
    } catch (e) {
      Sentry.captureException(e)
    }
    this.setState({
      loadingRootStore: false,
      rootStore,
    })
  }

  public render(): JSX.Element {
    const { loadingRootStore, rootStore } = this.state
    if (loadingRootStore) {
      // If we are still loading the rootStore, show loading
      return (
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle />
            <BrowserRouter>
              <Route component={LoadingPage} />
            </BrowserRouter>
          </>
        </ThemeProvider>
      )
    }

    if (!rootStore) {
      // If there is an error with setting up the rootStore, show the error-page
      return (
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle />
            <BrowserRouter>
              <Route component={ErrorPage} />
            </BrowserRouter>
          </>
        </ThemeProvider>
      )
    }

    const { bookingStore, productsStore, stationsStore, uiStore, sessionStore } = rootStore

    const otherStores = {
      bookingStore,
      productsStore,
      stationsStore,
      uiStore,
      sessionStore,
    }

    return (
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18nConfig}>
            <Provider rootStore={rootStore} {...otherStores}>
              <Sentry.ErrorBoundary showDialog={true}>
                <GlobalStyle />
                <AppRouter />
                <ToastContainer
                  autoClose={10000}
                  closeButton={false}
                  hideProgressBar={true}
                  pauseOnFocusLoss={false}
                  pauseOnHover={false}
                  position="top-right"
                />
                <BookingSessionTimer />
              </Sentry.ErrorBoundary>
            </Provider>
          </I18nextProvider>
        </ThemeProvider>
      </Router>
    )
  }
}
