import i18n from 'Config/i18n'
import { differenceInCalendarDays, format, formatDistanceToNow, isToday, isTomorrow, parseISO } from 'date-fns'
import { getLocaleFile } from 'Utils'

/**
 *
 * @param dateISOString
 */
export const isOtherYear = (dateISOString: string): boolean => {
  const currentYear = new Date().getUTCFullYear()
  const dateYear = parseISO(dateISOString).getUTCFullYear()

  return currentYear !== dateYear
}

/**
 * Format a date to string conforming to yyyy-MM-dd
 * @param date - date to format
 * @returns a string formated as yyyy-MM-dd
 */
export const formatYMD = (date: Date): string => format(date, 'yyyy-MM-dd')

/**
 * Returns a date in the date format of the given locale with numeric year, month and day
 * @param dateISOString - date to format. String or Date.
 * @param locale Ex. 'sv-SE' or 'en-US'. Defaults to current langauge.
 * @returns a string formatted like yyyy-MM-dd, depending on locale
 */
export const formatToLocaleNumericYMD = (date: Date | string, locale: string = i18n.language): string | null => {
  if (!date) {
    return null
  }

  const convertedDate: Date = typeof date === 'string' ? new Date(date) : date

  return convertedDate.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  })
}

export const durationInWordsFromToday = (date: Date): string =>
  isToday(date)
    ? i18n.t('vehiclesAndProducts:today')
    : isTomorrow(date)
    ? i18n.t('vehiclesAndProducts:tomorrow')
    : differenceInCalendarDays(date, new Date().setHours(0, 0, 0, 0)) === 2
    ? i18n.t('vehiclesAndProducts:dayAfterTomorrow')
    : formatDistanceToNow(date, { addSuffix: true, locale: getLocaleFile() })

/**
 * Returns the month name for given date
 *
 * @param date The date to get the month name for
 * @param short Get the month name like `jan` instead of `january`. Defaults to `false`.
 */
export const getMonthName = (date: Date, short = false): string => {
  const monthFormat = short ? 'MMM' : 'MMMM'
  const month = format(date, monthFormat, {
    locale: getLocaleFile(),
  })

  return month
}
