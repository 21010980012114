import { Button, List } from 'Components'
import { StyledListContent, StyledListItem } from 'Components/Generic/List/ListItem/ListItem.styles'
import { StyledListExtraLeft } from 'Components/Generic/List/ListExtraLeft/ListExtraLeft.styles'
import { StyledListExtraRight } from 'Components/Generic/List/ListExtraRight/ListExtraRight.styles'
import { styled } from 'Theme'

export const StyledProductList = styled(List)`
  ${StyledListItem} {
    margin-top: 0.25rem;
    align-items: center;
    height: 3rem;

    ${StyledListExtraLeft} {
      margin: 0;
      padding-left: 1rem;
    }

    ${StyledListContent} {
      margin-top: 0;
      margin-right: 0;
    }

    ${StyledListExtraRight} {
      display: flex;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`

export const ShowAllProductsButton = styled(Button)`
  background: transparent;
  color: ${({ theme }): string => theme.colors.primary};

  padding-left: 0;
  & > button {
    padding-left: 0;
  }

  &:hover {
    background: inherit;
  }
`

export const ProductInfo = styled.div``

export const IndentedListItem = styled(List.Item)<{ indented: boolean }>`
  ${({ indented }) => indented && `padding-left: 3rem`}
`
