import { API_DEPRECATED } from '.'

import { ICoordinateDto, INamedResponse, IStationDto, IStationOpenHours } from './interfaces'

/* INTERFACES */

export interface IStationsEndpoints {
  /**
   * Retrieves all active stations
   *
   * GET /api/stations
   */
  get(): Promise<IStationDto[]>

  /**
   * Gets a specific station
   *
   * GET /api/stations/{id}
   */
  getStation(id: number): Promise<IStationDto[]>

  /**
   * Gets a specific station open hours info
   *
   * GET /api/stations/{id}/openHours
   */
  getStationOpenHoursAndInfo(
    id: number,
    queryParams: {
      date?: string
    },
  ): Promise<IStationOpenHours>

  /**
   * Gets the closest stations based on the clients position. This endpoint should not be used when getting available stations for a booking
   *
   * GET /api/stations/closest
   */
  getClosest(queryParams: { Lat?: number; Lon?: number; Radius: number }): Promise<IStationDto[]>

  /**
   * This endpoint retrieves all the stations that match the given search criteria
   *
   * GET /api/stations/search
   */
  getSearch(queryParams: { search?: string }): Promise<INamedResponse[]>

  /**
   * This endpoint retrieves the coordinate that match the given search criteria
   *
   * GET /api/stations/coordinate
   */
  getSearchLocation(queryParams: { search?: string }): Promise<ICoordinateDto>
}

/* IMPLEMENTATION */

export const StationsEndpoints: IStationsEndpoints = {
  get: async function () {
    const response = await API_DEPRECATED.http.get<IStationDto[]>(`/api/stations`)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getStation: async function (id) {
    const response = await API_DEPRECATED.http.get<IStationDto[]>(`/api/stations/${id}`)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getStationOpenHoursAndInfo: async function (id, queryParams) {
    const response = await API_DEPRECATED.http.get<IStationOpenHours>(`/api/stations/${id}/openHours`, queryParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getClosest: async function (queryParams) {
    const response = await API_DEPRECATED.http.get<IStationDto[]>(`/api/stations/closest`, queryParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getSearch: async function (queryParams) {
    const response = await API_DEPRECATED.http.get<INamedResponse[]>(`/api/stations/search`, queryParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getSearchLocation: async function (queryParams) {
    const response = await API_DEPRECATED.http.get<ICoordinateDto>(`/api/stations/coordinate`, queryParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },
}
