import { styled } from 'Theme'

export const InputWrapper = styled.div`
  margin-top: 0rem;
  width: 100%;

  /* NOTE: This is how to reference siblings of the same component */
  & + & {
    margin-top: 1rem;
  }
`

export const StyledInput = styled.input`
  display: block;

  padding: ${({ theme }): string => theme.input.padding};

  width: 100%;

  background: ${(props): string =>
    props.disabled ? props.theme.input.backgroundColor.disabled : props.theme.input.backgroundColor.default};

  border-radius: ${({ theme }): string => theme.input.borderRadius};
  border-width: ${({ theme }): string => theme.input.borderWidth};
  border-style: ${({ theme }): string => theme.input.borderStyle};
  border-color: ${({ theme }): string => theme.input.borderColor};

  font-weight: ${({ theme }): string => theme.input.fontWeight};
  font: inherit;

  line-height: 1;

  /* Variants */
  .error & {
    background-color: ${({ theme }): string => theme.input.variant.error.background};
    border-color: ${({ theme }): string => theme.input.variant.error.borderColor};
    border-style: ${({ theme }): string => theme.input.variant.error.borderStyle};
    border-width: ${({ theme }): string => theme.input.variant.error.borderWidth};
  }
`

export const Label = styled.label`
  color: ${({ theme }): string => theme.input.label.color};

  display: block;

  font-size: ${({ theme }): string => theme.input.label.fontSize};
  font-weight: ${({ theme }): string => theme.input.label.fontWeight};

  margin-bottom: ${({ theme }): string => theme.input.label.marginBottom};
`

export const Message = styled.span`
  display: inline-block;

  margin-top: ${({ theme }): string => theme.input.message.marginTop};

  /* Variants */
  .error & {
    color: ${({ theme }): string => theme.input.message.error.color};
  }
`
