import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  /* Fonts */

  @font-face {
    font-family: 'Brokman';
    font-style: normal;
    font-weight: 300;
    src: local('Brokman Std Light'), local('Brokman Std Light'),
      url('/fonts/brokman/brokman-std-light.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/brokman/brokman-std-light.otf') /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Brokman';
    font-style: normal;
    font-weight: 400;
    src: local('Brokman Std Regular'), local('Brokman Std Regular'),
      url('/fonts/brokman/brokman-std-regular.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/brokman/brokman-std-regular.otf') /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Brokman';
    font-style: normal;
    font-weight: 500;
    src: local('Brokman Std Medium'), local('Brokman Std Medium'),
      url('/fonts/brokman/brokman-std-medium.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/brokman/brokman-std-medium.otf') /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Brokman';
    font-style: normal;
    font-weight: 600;
    src: local('Brokman Std Bold'), local('Brokman Std Bold'),
      url('/fonts/brokman/brokman-std-bold.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/brokman/brokman-std-bold.otf') /* Safari, Android, iOS */
  }

  /* Reset */

  * {
    margin: 0;
    padding: 0;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  html, body {
    font-family: 'Brokman', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-size: 16px;

    color: white;
    background: ${({ theme }): string => theme.colors.primary};
  }

  a {
    color: inherit;
    font-weight: 500;
    text-decoration: none;
    border-bottom: 1px solid currentColor;
  }

  input:focus {
    outline: none;
  }

  /* Layout */

  .right {
      text-align: right;
    }

  .container {
    width: ${({ theme }): string => theme.sizes.container};

    margin: 0 auto;
    padding: 0 1rem;

    @media screen and (min-width: ${({ theme }): string => theme.sizes.mdContainer}) {
      width: ${({ theme }): string => theme.sizes.mdContainer};
    }

    @media screen and (min-width: ${({ theme }): string => theme.sizes.xlContainer}) {
      width: ${({ theme }): string => theme.sizes.xlContainer};
    }
  }

  #root {
    margin-left: calc(100vw - 100%);
    margin-right: 0;
    overflow: auto;
  }
`
