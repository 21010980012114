import { Instance, types } from 'mobx-state-tree'

import { IVehicleBasicInfo } from 'Api_DEPRECATED'
import { StatusCode } from 'DataTypes'

export const Vehicle = types.model('Vehicle', {
  color: types.string,
  inspectionDeadline: types.maybeNull(types.string),
  hasOnlyDbInfo: types.boolean,
  regNo: types.string,
  suggestedInspectionCode: types.maybeNull(types.string),
  vehicleDescription: types.maybeNull(types.string),
  vehicleTypeId: types.number,
  isOriginalVehicle: types.boolean,
  statusCode: types.union(
    types.literal(StatusCode.Deregistered),
    types.literal(StatusCode.InTraffic),
    types.literal(StatusCode.OffRoad),
    types.literal(StatusCode.Unknown),
  ),
})

// FIXME: Justera så vi eventuellt inte behöver inaktivera den här regeln
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IVehicle extends Instance<typeof Vehicle> {}

export const VehicleModelFromVehicleBasicInfo = (vehicle: IVehicleBasicInfo): IVehicle => {
  return Vehicle.create({
    color: vehicle.Color || 'UNAVAILABLE', // FIXME: Ha inte någon fallback till 'unavailable'. Byt till `types.maybeNull()` i Vehicle. Hantera det där man använder det.
    inspectionDeadline: vehicle.InspectionDeadline,
    regNo: vehicle.RegNo,
    suggestedInspectionCode: vehicle.SuggestedInspectionCode,
    vehicleDescription: vehicle.VehicleDescription + (vehicle.HasOnlyDbInfo ? ' (DB)' : ''),
    vehicleTypeId: vehicle.VehicleTypeId,
    statusCode: vehicle.StatusCode,
    isOriginalVehicle: vehicle.IsOriginalVehicle,
    hasOnlyDbInfo: vehicle.HasOnlyDbInfo,
  })
}
