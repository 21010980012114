import { styled } from 'Theme'
import { IconWrapper, StyledCheckbox } from 'Components/Generic/Checkbox/Checkbox.styles'
import { StyledButton } from 'Components/Generic/Button/Button/Button.styles'

export const StyledPayAtStation = styled.div`
  margin: 2rem auto 0;

  @media (min-width: ${({ theme }): string => theme.sizes.mdContainer}) {
    max-width: 24rem;
  }

  ${StyledCheckbox} {
    margin-top: 2rem;
  }

  ${IconWrapper} {
    min-width: 1.5rem;
  }

  ${StyledButton} {
    float: right;
  }
`
