import * as React from 'react'

import classNames from 'classnames'

/* Import components here */
import { StyledCircleButton, Title, TitleAndIcon } from './CircleButton.styles'

/* Import interfaces here */
import { ICircleButtonProps } from './CircleButton.interfaces'

export const CircleButton: React.FC<ICircleButtonProps> = ({
  children,
  className,
  onClick,
  icon,
  size,
  style,
  title,
  type,
  variant,
  disabled,
}) => {
  return (
    <StyledCircleButton
      className={classNames(className, size, variant)}
      disabled={disabled}
      role="button"
      style={style}
      type={type}
      onClick={onClick}
    >
      {!!title || !!icon ? (
        <TitleAndIcon>
          {icon}
          {title && <Title>{title}</Title>}
        </TitleAndIcon>
      ) : null}
      {children}
    </StyledCircleButton>
  )
}

export default CircleButton
