import { styled } from 'Theme'

import { Button } from 'Components/Generic/Button'

export const StyledDatePicker = styled.div`
  background-color: white;
  border-radius: 0.25rem;
  color: ${({ theme }): string => theme.colors.primary};
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto 0.5rem;
`

export const Day = styled.h3`
  align-self: center;
  cursor: default;
  flex: 1;
  font-size: 1rem;
  margin-bottom: 0;
  order: 1;
  padding: 0 1rem;
  text-align: center;
`

export const Message = styled.h4`
  align-self: center;
  flex: 1;
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0.25rem;
  order: 2;
  text-align: center;
`

export const Month = styled.p`
  flex: 1;
  align-self: center;

  margin-bottom: 0;
  order: 1;

  font-size: 1rem;
  text-align: center;
`

export const Week = styled.p`
  flex: 1;
  align-self: center;

  order: 0;
  margin-bottom: 0;

  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`

export const WeekButton = styled(Button)`
  background: ${({ theme }): string => theme.colors.lightGreen};
  color: ${({ theme }): string => theme.colors.secondary};
  font-size: 1.25rem;
  height: 2.5rem;
  margin: 0;
  padding: 0.5rem;
  width: 2.5rem;

  :disabled {
    background: ${({ theme }): string => theme.colors.lightGray};
    color: ${({ theme }): string => theme.colors.gray};
  }
`

export const Weekdays = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const WeekNavigation = styled.div`
  align-items: center;
  border-bottom: 0.1rem solid ${({ theme }): string => theme.colors.lightGray};
  display: flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: space-between;
  padding: 1rem;
`
