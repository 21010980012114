import { DefaultTheme } from 'styled-components' // Declared in ./styled.d.ts

import { alert } from './alert'
import { button } from './button'
import { chip } from './chip'
import { colors } from './colors'
import { emptyState } from './emptyState'
import { header } from './header'
import { heading } from './heading'
import { input } from './input'
import { loadingIndicator } from './loadingIndicator'
import { modal } from './modal'
import { notification } from './notification'
import { paragraph } from './paragraph'
import { sizes } from './sizes'
import { tabs } from './tabs'
import { text } from './text'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITheme extends DefaultTheme {}

export const theme: ITheme = {
  alert,
  borderRadius: '.25rem',
  button,
  chip,
  colors,
  emptyState,
  header,
  heading,
  input,
  list: {
    item: {
      description: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
      left: {
        padding: '0.5rem',
      },
      right: {
        padding: '1rem',
      },
      title: {
        fontSize: '1rem',
        fontWeight: '600',
      },
    },
  },
  loadingIndicator,
  modal,
  notification,
  paragraph,
  sizes,
  spacing: {
    narrow: '0.5rem',
    normal: '1rem',
    wide: '2rem',
  },
  tabs,
  text,
}
