/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { styled } from 'Theme'
import { CircleButton } from 'Components/Generic/Button'
import { Grid } from 'Components/Generic/Grid'

export const StyledCalendar = styled.div`
  background-color: white;
  color: ${({ theme }) => theme.colors.primary};
  padding: 1rem;
  border-radius: 0.25em;
`

export const CalendarGrid = styled(Grid)`
  gap: 0.25rem;
  padding-top: 0.25rem;
`

export const StyledDay = styled.div<{ isSelected: boolean; isToday: boolean; isCurrentMonth: boolean }>`
  padding: 3px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: ${(props): string => (props.isSelected || props.isToday ? '#ccc' : 'transparent')};
  opacity: ${(props): string => (!props.isCurrentMonth ? '0.3' : '1')};
  cursor: pointer;
`

export const DayName = styled.div`
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
`

export const Navigation = styled.div`
  display: flex;
  padding-bottom: 0.5rem;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.lightGray};
`

export const MonthButton = styled(CircleButton)`
  background: ${({ theme }) => theme.colors.lightGreen};
  color: ${({ theme }) => theme.colors.secondary};

  &:hover {
    color: ${({ theme, disabled }) => !disabled && theme.colors.white};
  }

  &[disabled] {
    background: ${({ theme }) => theme.colors.lightGray};
  }
`

export const Month = styled.p`
  flex: 1;
  margin: 0;

  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
`

export const Year = styled.p`
  margin: 0;

  font-size: 1rem;
  font-weight: 500;

  text-align: center;
`

export const DayCount = styled.div`
  text-align: center;
  flex: 1;
  font-size: 0.75rem;
`
