import { Instance, flow, types } from 'mobx-state-tree'

import { API_DEPRECATED, ICoordinateDto, IStationDto, IStationOpenHours } from 'Api_DEPRECATED'
import { bookingsApi } from 'Api'
import { FlowReturn, IGetStationOpenHoursAndInfoParams, formatYMD } from 'Utils'
import { IAvailableTimesOverview } from 'Utils/api'
import { IStation, Station, StationModelFromStationDto } from './Stations'
import { ITime, Time, TimeModelFromTimeDto } from './Times'
import { AxiosResponse } from 'axios'
import { ACPMPriceRuleTextDto, BookingTime } from '@cdab/opus-api-client'
import _ from 'lodash'
import { addDays, max, min, startOfDay } from 'date-fns'

const StationTimes = types.model({
  stationId: types.identifier,
  start: types.Date,
  end: types.Date,
  times: types.map(Time),
})

const AcpmRule = types.model({
  id: types.identifier,
  message: types.maybe(types.string),
})

export const StationsStore = types
  .model('StationsStore', {
    stations: types.array(Station),
    stationTimes: types.map(StationTimes),
    priceRules: types.map(AcpmRule),
  })
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  .views(self => {
    return {
      /**
       * timesAtStations
       * @param stationIds - get overview from these stations
       * @param start - get overview from this date
       * @param end - get overview to this date
       */
      timesAtStations(stationIds: number[], start: Date, end?: Date): ITime[] {
        const endWithDefault = end ? end : addDays(start, 1)

        // Extract an array of times from all stations with given ids
        const times: ITime[] = _.reduce(
          Array.from(self.stationTimes.entries()),
          (acc: ITime[], [stationId, stationTimes]): ITime[] =>
            stationIds.includes(parseInt(stationId)) ? acc.concat(Array.from(stationTimes.times.values())) : acc,
          [],
        )

        return times
          .filter((t): boolean => t.date.getTime() >= start.getTime() && t.date.getTime() <= endWithDefault.getTime())
          .sort((a, b): number => a.date.getTime() - b.date.getTime())
      },

      /**
       * timesOverview
       * @param stationIds - get overview from these stations
       * @param start - get overview from this date
       * @param end - get overview to this date
       */
      timesOverview(stationIds: number[], start: Date, end: Date): IAvailableTimesOverview {
        const times = this.timesAtStations(stationIds, start, end)

        // Iterate the time entries, and create the overview
        return times.reduce((overview: IAvailableTimesOverview, time: ITime): IAvailableTimesOverview => {
          const key = formatYMD(time.date)
          const oldItem = overview[key] || { available: 0, bestPrice: time.price }
          return {
            ...overview,
            [key]: {
              available: oldItem.available + 1,
              bestPrice: Math.min(oldItem.bestPrice, time.price),
            },
          }
        }, {})
      },
      bestPrice(stationIds: number[], start: Date, end?: Date): number {
        return this.timesAtStations(stationIds, start, end).reduce(
          (bestPrice: number, currentTime: ITime): number =>
            currentTime.price < bestPrice ? currentTime.price : bestPrice,
          Number.MAX_VALUE,
        )
      },
      hasManyDifferentPrices(stationIds: number[], start: Date, end?: Date): boolean {
        return _.uniq(this.timesAtStations(stationIds, start, end).map((t: ITime): number => t.price)).length > 1
      },
      hasDiscounts(stationIds: number[], start: Date, end?: Date): boolean {
        return this.timesAtStations(stationIds, start, end).reduce(
          (hasDiscounts: boolean, time: ITime): boolean => hasDiscounts || time.discount > 0,
          false,
        )
      },
      hasAvailableTimes(stationIds: number[], start: Date, end?: Date): boolean {
        return this.timesAtStations(stationIds, start, end).length > 0
      },
      firstAvailableTime(stationIds: number[], start: Date, end?: Date): ITime | undefined {
        return this.timesAtStations(stationIds, start, end)[0]
      },
      stationById(stationId: number): IStation | undefined {
        return self.stations.find(s => s.id === stationId)
      },
      acpmMessages(stationIds: number[]) {
        const allTimes = _.flatten(Array.from(self.stationTimes.values()).map(st => Array.from(st.times.values())))
        const ruleIds = allTimes.filter(t => stationIds.includes(t.stationId)).map(t => t.acpmRule)
        const messages = _.uniq(ruleIds).map(id => id && self.priceRules.get(id)?.message)
        return messages.filter(t => !!t) as string[]
      },
    }
  })
  // FIXME: Justera så vi eventuellt inte behöver inaktivera den här regeln. Försök använda `.actions((self): { [keys: string]: Function } => {` för return type
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  .actions(self => {
    return {
      /**
       * UpdateAvailableTimes
       * Fetch available times from API, and update the times in store.
       * @param stationIds - the stations to fetch times for
       * @param start - fetch times from this date (from start of date)
       * @param end - fetch times until this date (not including)
       */
      UpdateAvailableTimes: flow(function* (
        bookingNumber: string,
        params: { stationIds: number[]; start: Date; end: Date },
      ): FlowReturn<AxiosResponse<BookingTime[]>> {
        // Do we need to update?
        const stationsToUpdate: number[] = []
        params.stationIds.forEach((id: number): void => {
          const timesForStation = self.stationTimes.get(String(id))

          // No times for this station
          if (timesForStation === undefined) {
            stationsToUpdate.push(id)
            return
          }

          if (
            startOfDay(params.start).getTime() < timesForStation.start.getTime() ||
            startOfDay(params.end).getTime() > timesForStation.end.getTime()
          ) {
            stationsToUpdate.push(id)
          }
        })

        if (!stationsToUpdate.length) {
          return
        }

        const response: AxiosResponse<BookingTime[]> = yield bookingsApi.getAvailableTimesForBooking(
          bookingNumber,
          stationsToUpdate,
          formatYMD(params.start),
          formatYMD(params.end),
        )

        // Update ranges for each station queried
        stationsToUpdate.forEach((sid: number): void => {
          const stationTimes = self.stationTimes.get(String(sid))
          if (stationTimes === undefined) {
            self.stationTimes.put({
              stationId: String(sid),
              start: startOfDay(params.start),
              end: startOfDay(params.end),
              times: {},
            })
            return // Continue with next
          }

          stationTimes.start = min([stationTimes.start, params.start])
          stationTimes.end = max([stationTimes.end, params.end])
        })

        const availableTimes = response.data
        availableTimes.forEach((dto): void => {
          const stationId = String(dto.StationId)
          const time = TimeModelFromTimeDto(dto)

          const stationTimes = self.stationTimes.get(stationId)
          if (stationTimes === undefined) throw new Error("stationTimes is undefined even though it shouldn't be")
          stationTimes.times.set(time.hash, time)
        })
      }),
      GetStations: flow(function* (
        bookingNumber: string,
        lat: number | undefined,
        lon: number | undefined,
      ): FlowReturn<IStationDto[]> {
        const stationsFromAPI: IStationDto[] = yield API_DEPRECATED.Bookings.getSuggestedStationsForBooking(
          bookingNumber,
          {
            lat,
            lon,
            radius: 100000000, // TODO: Ska denna verkligen vara konstant?
          },
        )
        if (stationsFromAPI) {
          // Transform Distance to km
          stationsFromAPI.forEach((o, i, a): void => {
            a[i].Distance = Math.round(a[i].Distance / 1000)
          })

          // Remove all cached stations
          self.stations.replace([])

          stationsFromAPI.forEach((station): void => {
            self.stations.push(StationModelFromStationDto(station))
          })
        }
      }),
      GetSearchLocation: flow(function* (searchString: string): FlowReturn<ICoordinateDto, ICoordinateDto> {
        return yield API_DEPRECATED.Stations.getSearchLocation({ search: searchString })
      }),
      GetStationOpenHoursAndInfo: flow(function* (
        stationId: number,
        queryParams?: IGetStationOpenHoursAndInfoParams,
      ): FlowReturn<IStationOpenHours, IStationOpenHours | null> {
        const params = new URLSearchParams()
        if (!!queryParams && !!queryParams.date) {
          params.append('date', queryParams.date.toJSON())
        }

        const stationOpenHoursAndInfo = yield API_DEPRECATED.Stations.getStationOpenHoursAndInfo(
          stationId,
          (params as unknown) as { date: string }, // TODO: Justera så vi har rätt interface här. IGetSTationOpenHoursAndInfoParams fungerar inte.
        )

        if (stationOpenHoursAndInfo) {
          return stationOpenHoursAndInfo
        } else {
          return null
        }
      }),
      FetchPriceRules: flow(function* () {
        const response: AxiosResponse<ACPMPriceRuleTextDto[]> = yield bookingsApi.getACPMPriceRuleTexts()

        self.priceRules.clear()
        response.data.forEach(r => {
          if (!r.Id || !r.InfoInBookingApp) return
          self.priceRules.put({ id: r.Id.toString(), message: r.InfoInBookingApp })
        })
      }),
      RemoveTime: (stationId: number, hash: string): void => {
        const stationTime = self.stationTimes.get(String(stationId))
        if (stationTime) {
          stationTime.times.delete(hash)
        }
      },
      clearTimes: () => {
        self.stationTimes.clear()
      },
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IStationsStore extends Instance<typeof StationsStore> {}
