import * as React from 'react'

export const parseTemplateString = (
  stringWhichMightContainTemplateCode: string,
  TextTag?: React.ComponentType,
  LinkTag?: React.ComponentType<{ href: string }>,
): React.ReactNode[] => {
  if (stringWhichMightContainTemplateCode.length === 0) {
    return []
  }
  // const regex = new RegExp(/\[\[(.+?);(.+?)\]\]/gm) // FIXME: This is for ; <-- This is the more correct one!!!
  const regex = new RegExp(/\[\[(.+):(.+?)\]\]/gm) // This is for use with : <-- This is what the server is sending at the moment, fix in netsuite!

  const children: React.ReactNode[] = []
  let previousIndex = 0

  let match: RegExpMatchArray | null = null
  while ((match = regex.exec(stringWhichMightContainTemplateCode))) {
    if (match.length !== 3 || typeof match.index === 'undefined') {
      throw new Error('Incorrect formatted template code')
    }
    const text = stringWhichMightContainTemplateCode.substring(previousIndex, match.index)
    previousIndex = match.index + match[0].length

    if (text.length > 0) {
      let content: React.ReactNode
      if (TextTag) {
        content = <TextTag key={`TextTag_${match.index}`}>{text}</TextTag>
      } else {
        content = <p key={`p_${match.index}`}>{text}</p>
      }

      children.push(content)
    }

    let content: React.ReactNode
    if (LinkTag) {
      content = (
        <LinkTag key={`LinkTag_${match.index}`} href={match[1]}>
          {match[2]}
        </LinkTag>
      )
    } else {
      content = (
        <a key={`a_${match.index}`} href={match[1]} rel="noopener noreferrer" target="_blank">
          {match[2]}
        </a>
      )
    }

    children.push(content)
  }

  // If there are no template-code in this string, just return the string
  if (children.length === 0) {
    children.push(<p key={`0`}>{stringWhichMightContainTemplateCode}</p>)
  }

  return children
}
