import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { faCar, faMapMarkerAlt, faShoppingCart } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { INavigationProps } from './Navigation.interfaces'

import {
  NavigationDescription,
  StepCounter,
  StyledNavigation,
  StyledNavigationDistance,
  StyledNavigationIcon,
} from './Navigation.styles'

export const Navigation: React.FC<INavigationProps> = ({ steps, currentStep }) => {
  const { t } = useTranslation()
  if (steps === undefined) {
    steps = ['step1', 'step2', 'step3']
  }
  const stepItems = {
    step1: { icon: faCar, text: t('common:navigation.step1') },
    step2: { icon: faMapMarkerAlt, text: t('common:navigation.step2') },
    step3: { icon: faShoppingCart, text: t('common:navigation.step3') },
  }

  return (
    <StyledNavigation role="navigation">
      <StyledNavigationDistance>
        {steps.map(
          (stepKey, index): JSX.Element => {
            const { icon, text } = stepItems[stepKey]
            return (
              <StyledNavigationIcon key={stepKey} active={currentStep === stepKey}>
                <FontAwesomeIcon icon={icon} />
                <StepCounter>{index + 1}.</StepCounter>
                <NavigationDescription>&nbsp;{text}</NavigationDescription>
              </StyledNavigationIcon>
            )
          },
        )}
      </StyledNavigationDistance>
    </StyledNavigation>
  )
}

export default Navigation
