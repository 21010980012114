import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'

import { getBooleanFromEnv, getStringFromEnv } from 'Config/environment'

import packageJson from '../../package.json'

const debug = getBooleanFromEnv('SENTRY_DEBUG', false)
const dsn = getStringFromEnv('SENTRY_DSN', '')
const enabled = getBooleanFromEnv('SENTRY_ENABLED', false)
const environment = getStringFromEnv('SENTRY_ENVIRONMENT', 'local.dev')
const release = `${packageJson.name}@${packageJson.version}`

export const history = createBrowserHistory()

export const sentryInitSettings: Sentry.BrowserOptions = {
  attachStacktrace: true,
  debug,
  dsn,
  enabled,
  environment,
  // TODO: Activate this to get better tracking. This was disabled because the transactions filled up our monthly quota
  // integrations: [
  //   new Integrations.BrowserTracing({
  //     routingInstrumentation: Sentry.reactRouterV5Instrumentation(history), // FIXME: Add https://docs.sentry.io/platforms/javascript/guides/react/integrations/react-router/#parameterized-transaction-names
  //   }),
  // ],
  release,
  tracesSampleRate: 0.5,
  beforeSend(event, hint) {
    const statusCode: number | undefined =
      (hint && hint.originalException && hint.originalException['response']['status']) || undefined

    const message: string | undefined = hint && hint.originalException && hint.originalException['message']

    const url: string | undefined = hint && hint.originalException && hint.originalException['config']['url']

    if (message?.match(/document\.getElementById\('txtRegNo'\)/i)) {
      return null
    }

    /** Check which page where the error occured is */
    if (url?.match(/\/availableTimes\?/) && statusCode === 404) {
      return null
    }

    /** Check which page where the error occured is */
    if (url?.match(/\/stations\?/) && statusCode === 404) {
      return null
    }

    return event
  },
  denyUrls: [
    // Cookiebot
    /cookiebot\.com/i,
    // Ignore Google flakiness
    /\/(gtm|ga|analytics)\.js/i,
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
}
