/**
 * Check if a number belongs to a mobile phone
 * TODO: Add prop for deciding country or change name
 */
export const isValidPhoneNumber = (phoneNumber: string): boolean =>
  /^(?:(?:\+46|0046)|0)(?:10|70|72|73|76|79)[0-9]{7,13}$/.test(phoneNumber.replace(/\s+|-+/g, ''))

export const isValidEmail = (email: string): boolean =>
  /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9][-a-z0-9]*(\.[-a-z0-9]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|email|[a-zåäö]{2,20})|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i.test(
    email,
  )
