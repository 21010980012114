import * as React from 'react'

/* Import components here */
import { StyledListExtraLeft } from './ListExtraLeft.styles'

/* Import interfaces here */
import { IListExtraLeftProps } from './ListExtraLeft.interfaces'

/* Import utilities here */

export const ListExtraLeft: React.FC<React.HTMLAttributes<IListExtraLeftProps>> = ({ children, ...props }) => {
  return <StyledListExtraLeft {...props}>{children}</StyledListExtraLeft>
}

export default ListExtraLeft
