/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { styled } from 'Theme'

import { StyledListContent, StyledListItem } from 'Components/Generic/List/ListItem/ListItem.styles'
import { StyledListExtraLeft } from 'Components/Generic/List/ListExtraLeft/ListExtraLeft.styles'
import { StyledListExtraRight } from 'Components/Generic/List/ListExtraRight/ListExtraRight.styles'

export const StyledStation = styled.div`
  background-color: white;

  border-radius: 0.25rem;
  border: 1px solid transparent;

  color: ${({ theme }) => theme.colors.primary};

  outline-style: none;

  ${StyledListItem} {
    cursor: pointer;

    align-items: center;

    height: auto;
    min-height: 3rem;

    padding-top: 0.2rem;
    padding-bottom: 0.2rem;

    border-top: 1px solid #efefef;

    &:hover {
      background: ${({ theme }) => theme.colors.lightGray};
    }
  }

  ${StyledListExtraLeft} {
    align-self: flex-start;

    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 0.4rem;

    padding-top: 0;
    padding-bottom: 0;
  }

  ${StyledListContent} {
    margin-top: 0;

    line-height: 1rem;
    word-break: break-word;
  }

  ${StyledListExtraRight} {
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const Distance = styled.div`
  min-width: 4.5rem;

  text-align: end;
`

export const StationInfo = styled.div`
  margin-left: 0.5rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primarySemiLight};
  }
`
