import { styled } from 'Theme'

import { Page } from 'Components'

import { PageContent } from 'Components/Opus/Page/Page.styles'

/* NOTE: This is how to override the page content styles for a specific page */
export const StyledPage = styled(Page)`
  ${PageContent} {
    padding-top: 7rem; /* Overrides default top padding since the navigation is hidden */
  }
`

export const StyledButtonHolder = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
`
