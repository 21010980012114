import { EnvironmentVariables } from 'Config/environment'

interface IBookingSessionConfig {
  warnTimeout: number
  endTimeout: number
}

export const config: IBookingSessionConfig = {
  warnTimeout: EnvironmentVariables.BOOKING_SESSION_WARN,
  endTimeout: EnvironmentVariables.BOOKING_SESSION_END,
}

const AVAILABLE_BOOKING_ACTIONS_MAP = {
  canAddVehicle: { default: true, bit: 1 },
  canRemoveVehicle: { default: false, bit: 2 },
  canAddProduct: { default: false, bit: 3 },
  canRemoveProduct: { default: false, bit: 4 },
  canSelectStation: { default: false, bit: 5 },
  canSelectTime: { default: false, bit: 6 },
  canAddCampaignCode: { default: false, bit: 7 },
  canRemoveCampaignCode: { default: false, bit: 8 },
  canPay: { default: false, bit: 9 },
  canRebook: { default: false, bit: 10 },
  canCancelBooking: { default: false, bit: 11 },
  canCredit: { default: false, bit: 12 },
  canBankCredit: { default: false, bit: 13 },
  canExitRebooking: { default: false, bit: 14 },
  canRebookWithoutAuthentication: { default: false, bit: 15 },
  canSendConfirmation: { default: false, bit: 16 },
}

type TBookingActionKeys = keyof typeof AVAILABLE_BOOKING_ACTIONS_MAP

/**
 * convert DEFAULT_AVAILABLE_BOOKING_ACTIONS_MAP to a map resembling all
 * default values, such as
 *  { canAddVehicle: true, canRemoiveVehicle: false, ... }
 */
export const DEFAULT_AVAILABLE_BOOKING_ACTIONS = Object.keys(AVAILABLE_BOOKING_ACTIONS_MAP).reduce(
  (acc, current): Partial<{ [key in TBookingActionKeys]: boolean }> => ({
    ...acc,
    [current]: AVAILABLE_BOOKING_ACTIONS_MAP[current].default,
  }),
  {},
) as { [key in TBookingActionKeys]: boolean }

/**
 * convert DEFAULT_AVAILABLE_BOOKING_ACTIONS_MAP to a map resemlbing all
 * bit values, such as
 * { canAddVehicle: 1, canRemoveVehicle: 2, ... }
 */
export const AVAILABLE_BOOKING_ACTIONS_BITS = Object.keys(AVAILABLE_BOOKING_ACTIONS_MAP).reduce(
  (acc, current): Partial<{ [key in TBookingActionKeys]: number }> => ({
    ...acc,
    [current]: AVAILABLE_BOOKING_ACTIONS_MAP[current].bit,
  }),
  {},
) as { [key in TBookingActionKeys]: number }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getBitmaskFromObject = (obj: { [key in TBookingActionKeys]: boolean }): number =>
  Object.keys(obj).reduce((acc: number, key: string): number => {
    const bitValue = Number(obj[key])
    const position = AVAILABLE_BOOKING_ACTIONS_BITS[key]
    return acc | (bitValue * (1 << (position - 1)))
  }, 0)
