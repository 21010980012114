import { ITheme, styled } from 'Theme'
import { FlattenSimpleInterpolation, css } from 'styled-components'

import { ICustomMargin, IHorizontalVerticalMargin, IStyledParagraphProps } from './Paragraph.interfaces'

import _ from 'lodash'

type TConditionalStyle<T> = FlattenSimpleInterpolation | string | undefined | false | T

const truncateStyle = (): FlattenSimpleInterpolation => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

// FIXME: These typeguards are duplicated in Heading.styles
const isComplexMargin = (margin: string | ICustomMargin | IHorizontalVerticalMargin): margin is ICustomMargin => {
  // Check if margin contains any of the properties from the ICustomMargin interface
  return _.intersection(_.keys(margin), ['top', 'bottom', 'left', 'right']).length > 0
}

const isHorizontalOrVertical = (
  margin: string | ICustomMargin | IHorizontalVerticalMargin,
): margin is IHorizontalVerticalMargin => {
  const result =
    Object.prototype.hasOwnProperty.call(margin, 'horizontal') ||
    Object.prototype.hasOwnProperty.call(margin, 'vertical')
  return result
}

const calculateMargin = (
  margin: string | IHorizontalVerticalMargin | ICustomMargin,
  theme: ITheme,
): FlattenSimpleInterpolation => {
  if (isHorizontalOrVertical(margin)) {
    return css`
      margin-top: ${margin.vertical && theme.heading.margin[margin.vertical]};
      margin-bottom: ${margin.vertical && theme.heading.margin[margin.vertical]};
      margin-right: ${margin.horizontal && theme.heading.margin[margin.horizontal]};
      margin-left: ${margin.horizontal && theme.heading.margin[margin.horizontal]};
    `
  } else if (isComplexMargin(margin)) {
    return css`
      margin-top: ${margin.top && theme.heading.margin[margin.top]};
      margin-right: ${margin.right && theme.heading.margin[margin.right]};
      margin-bottom: ${margin.bottom && theme.heading.margin[margin.bottom]};
      margin-left: ${margin.left && theme.heading.margin[margin.left]};
    `
  } else {
    return css`
      margin: ${theme.heading.margin[margin]};
    `
  }
}

export const StyledParagraph = styled.p<IStyledParagraphProps>`
  align-self: ${({ alignSelf }): string => alignSelf};

  color: ${({ color, theme }): string => color || theme.heading.color};

  font-size: ${({ size, theme }): string => theme.paragraph.size[size]};

  text-align: ${({ textAlign }): string => textAlign};

  font-weight: ${({ fontWeight }): string => fontWeight};

  font-style: ${({ fontStyle }): string => fontStyle};

  /** Margin */
  ${({ margin, theme }): TConditionalStyle<string> => margin && calculateMargin(margin, theme)}

  /** Truncation */
  ${({ truncate }): TConditionalStyle<typeof truncateStyle> => truncate && truncateStyle()}
`
