import * as React from 'react'

/* Import components here */
import { ListItem } from '../'
import { StyledList } from './List.styles'

/* Import interfaces here */
import { IListProps } from './List.interfaces'

export class List extends React.Component<IListProps> {
  public static Item = ListItem

  public render(): JSX.Element {
    const { ...props } = this.props

    const toRender = props.children
    return <StyledList {...props}>{props.ordered ? <ol>{toRender}</ol> : <ul>{toRender}</ul>}</StyledList>
  }
}

export default List
