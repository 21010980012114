import { Form } from 'formik'
import { FlattenSimpleInterpolation, css } from 'styled-components'

import { ITheme, styled } from 'Theme'

import { Button } from 'Components'

type TConditionalStyle<T> = FlattenSimpleInterpolation | string | undefined | false | T

export const SearchRegNoWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-bottom: 1rem;

  border: 10px;
`

const disabledStyle = (theme: ITheme): FlattenSimpleInterpolation => css`
  background-color: ${theme.colors.primaryDark} !important;
  color: ${theme.button.disabled.color} !important;
`

export const StyledForm = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-bottom: 1rem;

  border: 10px;

  @media (min-width: ${({ theme }): string => theme.sizes.smContainer}) {
    flex-wrap: nowrap;
  }
`

interface IStyledSearchButtonProps {
  disabled: boolean
}

export const SearchButton = styled(Button)<IStyledSearchButtonProps>`
  margin-top: 1rem;
  margin-left: none;

  @media (min-width: ${({ theme }): string => theme.sizes.smContainer}) {
    margin-top: 0;
    margin-left: 1rem;
  }

  ${({ disabled, theme }): TConditionalStyle<string> => disabled && disabledStyle(theme)}
`
