import * as React from 'react'

import { observer } from 'mobx-react'
import { Redirect, withRouter } from 'react-router-dom'

/* Import components here */
import { Header, Navigation, OpusFooter, OpusHeader } from 'Components'
import { PageContent, StyledPage } from './Page.styles'

/* Import interfaces here */
import { IPageProps } from './Page.interfaces'

import { STARTPAGE } from 'Config'
import { useStores } from 'Utils/hooks'
import { ILocationState, TStep } from 'Models/UIStore'

export const Page: React.FunctionComponent<IPageProps> = observer(
  ({
    history,
    location,
    children,
    isPageCompleteCallback,
    footerProps,
    hideHeader,
    hideNavigation,
    hideFooter,
    headerProps,
    ...props
  }): JSX.Element => {
    const { uiStore } = useStores()
    const isPreviousPageComplete = (): boolean => {
      if (!uiStore) return true

      uiStore.updateCurrentPage(history)

      if (!uiStore.currentPage || !uiStore.currentPage.previousPath) return true

      const previousPage = uiStore.pages.get(uiStore.currentPage.previousPath)

      if (!previousPage) return true

      return previousPage.isComplete
    }

    const state = history.location.state as ILocationState

    const shouldRender = (state && state.shouldOverridePreviousPageComplete) || isPreviousPageComplete()

    if (!shouldRender) {
      const previousPage = !!uiStore && !!uiStore.previousPage ? uiStore.previousPage.rootPath : STARTPAGE

      return <Redirect to={previousPage} />
    }

    const isPageComplete = isPageCompleteCallback()
    if (uiStore) {
      const currentRootPath: string = uiStore.getRootPathFromLocationPathname(location.pathname) || ''
      const thisPage = uiStore.pages.get(currentRootPath)
      if (thisPage) {
        thisPage.setIsComplete(isPageComplete)
      }
    }

    const currentStep = uiStore && uiStore.currentStep

    return (
      <StyledPage {...props}>
        {!hideHeader && (
          <Header>
            <OpusHeader {...headerProps} />
          </Header>
        )}

        {!hideNavigation && <Navigation currentStep={currentStep} steps={uiStore.availableSteps as TStep[]} />}

        <PageContent>{children}</PageContent>

        {!hideFooter && <OpusFooter {...footerProps} />}
      </StyledPage>
    )
  },
)

export default withRouter(Page)
