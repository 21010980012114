/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { css } from 'styled-components'
import { styled } from 'Theme'
import { Button } from 'Components'

export const StyledAvailableTimesCalendar = styled.div`
  margin-bottom: 0.75rem;
`

export const StyledDay = styled(Button)<{
  isSelected: boolean
  isToday: boolean
  isMonth: boolean
  isCurrentMonth: boolean
}>`
  font-weight: 500;
  border-radius: 0.2rem;
  padding: 0.2rem;
  cursor: pointer;

  background: ${({ theme }) => theme.colors.lightGreen};
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }

  &[disabled] {
    background: ${({ theme }) => theme.colors.lightGray};

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background: ${theme.colors.secondary};
      color: white;
    `}

  ${({ isToday }) =>
    isToday &&
    css`
      border: 1px solid #b3d4d4;
    `}

  ${({ isCurrentMonth, isMonth }) =>
    !isCurrentMonth &&
    !!isMonth &&
    css`
      opacity: 0.65;
    `}
`

export const MonthDisplay = styled.span`
  color: ${({ theme }) => theme.colors.grayDark};

  margin-bottom: 0.5rem;

  font-size: 0.875rem;
  font-weight: 500;

  text-align: center;
  text-transform: capitalize;
`

export const DateDisplay = styled.div`
  text-align: center;
`

export const CountDisplay = styled.div<{ showPriceTag: boolean }>`
  font-size: 0.7em;
  text-align: center;
  font-weight: bold;
  margin-top: 0.2rem;
  position: relative;
`

export const DayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
`
