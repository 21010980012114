import * as React from 'react'

import _ from 'lodash'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
import { Trans, withTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'url-search-params-polyfill'

/* Import components here */
import {
  Alert,
  Card,
  CoronaMessage,
  Grid,
  Heading,
  LoadingIndicator,
  Module,
  Page,
  PageTitle,
  ProductList,
  Section,
  VehicleDataCard,
  VehicleSearchForm,
} from 'Components'
import { AdditionalVehicleButton, BorderedBox, LinkBlock, PolicyLink } from './VehicleProductsPage.styles'

/* Import interfaces here */
import { IVehicleProducts } from 'Api_DEPRECATED'
import {
  IVehicleProductsPageProps,
  IVehicleProductsPageState,
  IVehicleProductsPageStores,
} from './VehicleProductsPage.interfaces'
import { IParentOpusHeaderProps } from 'Components/Opus/OpusHeader/OpusHeader.interfaces'

import { EBookingStep, ETrackingEvent } from '@cdab/opus-api-client'

/* Import utilities here */
import { Paths } from 'Config'
import { EnvironmentVariables } from 'Config/environment'
import { BookingStatus } from 'DataTypes'
import { IProductsPerVehicle } from 'Models'
import { IVehicle } from 'Models/Vehicle'
import { Tracker, fbTrackEvent, getPosition, notifyError } from 'Utils'

const requireCustomerSupport = (vehicle: IVehicle): boolean =>
  !vehicle.suggestedInspectionCode || vehicle.suggestedInspectionCode.startsWith('A')

/**
 * VehicleProductsPage jobbar alltid med datat i productStore.
 * Man kan komma till sidan på tre olika sätt
 *
 * 1. Söker på ett regno, productStore är tomt och fylls på vid sökningen
 * 2. Kommer tillbaka från steg 2, all data finns redan i productStore
 * 3. Kommer från steg 4 vid ombokning, productStore är tomt och fylls på
 *
 * this.state.selectedProducts är en Map() med <regNo, selectedProductId[]>
 */
@inject('bookingStore', 'productsStore', 'uiStore', 'rootStore')
@observer
class VehicleProductsPage extends React.Component<
  IVehicleProductsPageProps & Partial<IVehicleProductsPageStores>,
  IVehicleProductsPageState
> {
  public state: Readonly<IVehicleProductsPageState> = {
    isDeletingVehicle: false,
    leaving: false,
    loading: false,
    showAdditionalVehicleInput: false,
  }

  private campaignCodeInputRef = React.createRef<HTMLInputElement>()

  public async componentDidMount(): Promise<void> {
    const { regNo } = this.props.match.params
    const params = new URLSearchParams(this.props.history.location.search)

    if (regNo) {
      this.onVehicleSearchFormSubmit(regNo, params.get('testcenterid') || undefined)
      return
    }

    // Ask for permission to get location
    getPosition()

    // Säkerställ att alla fordon i bokningen finns med sina produkter i productStore
    this.setState({ loading: true })
    await this.GetVehicleAndProductsForAllVehiclesInTheBooking()
    this.setPreSelectedProducts()
    this.setState({ loading: false })

    // Send event to facebook
    await fbTrackEvent({
      EventName: ETrackingEvent.ViewContent,
    })
  }

  private setProductsOnBooking = async (): Promise<void> => {
    // Update the booking
    const {
      bookingStore: { bookingNumber, getVehicleInBooking, SetProductsOnBooking },
      productsStore: { selectedProducts, productsDataForVehicle, driveInMyCar },
    } = this.props as IVehicleProductsPageStores

    if (bookingNumber) {
      const vehicles: IVehicleProducts[] = []
      // Lägg till valda produkter för alla fordon in productStore
      const regNos = Array.from(productsDataForVehicle.keys()) // NOTE: Kan detta någonsin bli osynkat med vehicles?. Förslag är att flytta allt som har med fordon att göra till en ny VehicleStore

      for (const regNo of regNos) {
        const vehicleInfo = getVehicleInBooking(regNo)
        const HasOnlyDbInfo = vehicleInfo && vehicleInfo.hasOnlyDbInfo

        vehicles.push({
          Products: selectedProducts.get(regNo),
          Regno: regNo,
          HasOnlyDbInfo,
        })
      }

      // Anropa api:et och sätt valda produkter på bokningen
      await SetProductsOnBooking(bookingNumber, driveInMyCar, vehicles)
    }
  }

  public render(): JSX.Element {
    const { i18n } = this.props
    const locationState = this.props.history.location.state

    const {
      bookingStore: { bookingNumber, campaignCode, userMessage },
      productsStore: { hasAddedVehicle, hasCampaignCodeMessageArray, productsDataForVehicle },
      uiStore: { bookingActions },
    } = this.props as IVehicleProductsPageStores
    const { leaving, loading, showAdditionalVehicleInput } = this.state

    // Count how many columns to use for current number of vehicles
    const numberOfVehicles = productsDataForVehicle.size
    const mdColumns = (numberOfVehicles === 1 && 6) || (numberOfVehicles >= 2 && 6) || 4
    const mdStartColumn = (numberOfVehicles === 1 && 1) || (numberOfVehicles === 2 && 1) || 0
    const xlColumns = (numberOfVehicles === 1 && 4) || (numberOfVehicles >= 2 && 4) || 2
    const xlStartColumn = (numberOfVehicles === 1 && 2) || (numberOfVehicles === 2 && 2) || 0

    /** Hide campaign code chip if there is no started booking, or no campaign code addded */
    const hideCampaignCodeChip =
      !bookingNumber ||
      (!bookingActions.canAddCampaignCode && !campaignCode) ||
      !!userMessage ||
      hasCampaignCodeMessageArray.length > 0

    return (
      <Page
        className="vehicle-products" /* used for styling in IE */
        isPageCompleteCallback={this.IsPageComplete}
        {...this.props}
        footerProps={{
          hideCampaignCodeChip,
          campaignCodeInputRef: this.campaignCodeInputRef,
          hideNextButton: true,
        }}
        headerProps={this.headerProps()}
        hideFooter={locationState && locationState.hideFooter}
        hideNavigation={true}
      >
        {leaving && <LoadingIndicator message={i18n.t('leaving')} />}
        {loading && <LoadingIndicator message={i18n.t('vehiclesAndProducts:loading-vehicle-products')} />}
        <Section>
          {hasAddedVehicle ? (
            <>
              <PageTitle
                style={{ marginBottom: '1.5rem' }}
                title={i18n.t('vehiclesAndProducts:titles.mainAlternative')}
              />
              {EnvironmentVariables.FEATURE_SHOW_COVID19_INFO && (
                <Grid columns={6} mdColumns={7}>
                  <Module columns={6} mdColumns={3} mdStartColumn={3}>
                    <CoronaMessage variant="short" />
                  </Module>
                </Grid>
              )}
              <Grid columns={6}>
                <Module
                  columns={6}
                  mdColumns={mdColumns}
                  mdStartColumn={mdStartColumn}
                  xlColumns={xlColumns}
                  xlStartColumn={xlStartColumn}
                >
                  {hasCampaignCodeMessageArray.map(
                    (productData): JSX.Element => {
                      return (
                        <Alert key={productData[0]} message={productData[1].campaignCodeMessage || ''} type="info" />
                      )
                    },
                  )}
                </Module>
              </Grid>
              {this.renderVehicleAndProducts()}
              <Grid columns={6}>
                {bookingActions.canAddVehicle && (
                  <BorderedBox
                    columns={6}
                    mdColumns={6}
                    mdStartColumn={1}
                    startColumn={1}
                    xlColumns={4}
                    xlStartColumn={2}
                  >
                    <Heading level={2} margin={{ vertical: 'xlarge' }} textAlign="center">
                      {i18n.t('vehiclesAndProducts:titles.additionalInspection')}
                    </Heading>
                    {showAdditionalVehicleInput ? (
                      <VehicleSearchForm buttonText={i18n.t('common:add')} onSubmit={this.onVehicleSearchFormSubmit} />
                    ) : (
                      <AdditionalVehicleButton
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        title={i18n.t('vehiclesAndProducts:titles.add')}
                        onClick={this.toggleAdditionalVehicleInput}
                      />
                    )}
                  </BorderedBox>
                )}
              </Grid>
            </>
          ) : (
            <>
              <PageTitle title={i18n.t('vehiclesAndProducts:titles.main')} />
              {EnvironmentVariables.FEATURE_SHOW_COVID19_INFO && (
                <Grid columns={6} mdColumns={7}>
                  <Module columns={6} mdColumns={3} mdStartColumn={3}>
                    <CoronaMessage variant="short" />
                  </Module>
                </Grid>
              )}
              <span onClick={this.openCustomerSupport}>
                <Heading level={2} margin={{ vertical: 'xlarge' }} textAlign="center">
                  {i18n.t('vehiclesAndProducts:titles.enterRegNo')}
                </Heading>
              </span>
              <Grid columns={1}>
                <Module>
                  {bookingActions.canAddVehicle && (
                    <VehicleSearchForm
                      buttonText={i18n.t('vehiclesAndProducts:getVehicleInfo')}
                      onSubmit={this.onVehicleSearchFormSubmit}
                    />
                  )}
                  {this.renderVehicleAndProducts()}
                  <LinkBlock>
                    <PolicyLink href={EnvironmentVariables.GDPR_PAGE_URL} rel="noopener noreferrer" target="_blank">
                      {i18n.t('vehiclesAndProducts:gdprText')}
                    </PolicyLink>
                    <PolicyLink href={EnvironmentVariables.COOKIE_POLICY_URL} rel="noopener noreferrer" target="_blank">
                      {i18n.t('vehiclesAndProducts:cookiePolicy')}
                    </PolicyLink>
                  </LinkBlock>
                </Module>
              </Grid>
            </>
          )}
        </Section>
      </Page>
    )
  }

  private toggleAdditionalVehicleInput = () => {
    this.setState({ showAdditionalVehicleInput: !this.state.showAdditionalVehicleInput })
  }

  /**
   * Redirect user to customer support login if the user is pressing the shift key when clicking
   */
  private openCustomerSupport = (event: React.MouseEvent): void => {
    const redirectLink = EnvironmentVariables.CLIENT_SUPPORT_LINK

    if (event.shiftKey) {
      window.location.href = redirectLink
    }
  }

  public IsPageComplete = (): boolean => {
    const { productsStore, uiStore } = this.props
    if (!productsStore) return false

    const { selectedProducts } = productsStore

    if (!uiStore || !productsStore) return false

    let ok = true

    // Om det inte finns några fordon tillagda (size === 0)
    if (!productsStore.productsDataForVehicle.size) {
      ok = false
    }

    // Alla fordon i productStore måste ha en selectedProduct
    for (const regNo of Array.from(productsStore.productsDataForVehicle.keys())) {
      // Finns det några val i selectedProducts för detta regNo?
      const selectedProductsForRegNo = selectedProducts.get(regNo)

      if (!selectedProductsForRegNo || selectedProductsForRegNo.length === 0) {
        ok = false
      }
    }

    return ok
  }

  public renderVehicleAndProducts = (): JSX.Element => {
    // Render vehciles and products that are in the products store
    const { productsStore, bookingStore, uiStore } = this.props as IVehicleProductsPageStores

    // Filter and index all original vehicles into an object such as { [regno]: <isOriginalVehicle> }
    // Only original vehicles are included in the object
    const originalVehicleHash = _.reduce(
      bookingStore.vehicles,
      (acc: { [keys: string]: boolean }, cur: IVehicle): { [keys: string]: boolean } =>
        cur.isOriginalVehicle ? { ...acc, [cur.regNo]: true } : acc,
      {},
    )

    const moreThanOneOriginalVehicle = _.keys(originalVehicleHash).length > 1
    const isOriginalVehicle = (regno: string): boolean => originalVehicleHash[regno]

    return (
      <Grid columns={6}>
        {Array.from(productsStore.productsDataForVehicle.entries()).map(
          ([key, productsData], index): JSX.Element => {
            // The user should be able to remove a vehicle, if it's not the last original vehicle
            // So if it's not an original vehicle, user should always be able to remove it
            const canRemoveThisVehicle =
              uiStore.bookingActions.canRemoveVehicle &&
              (moreThanOneOriginalVehicle || !isOriginalVehicle(productsData.vehicle.regNo))

            const showCustomerSupportAlert = requireCustomerSupport(productsData.vehicle)

            return (
              <Module key={'C_' + key} columns={6} mdColumns={6} mdStartColumn={1} xlColumns={4} xlStartColumn={2}>
                <Card key={index} spacing="none">
                  <VehicleDataCard
                    key={'VDC_' + key}
                    buttonCallback={index === 0 ? this.onNextClick : undefined}
                    buttonDisabled={!this.IsPageComplete()}
                    deleteVehicle={this.deleteVehicle}
                    hideTrashcan={index === 0 || !canRemoveThisVehicle}
                    isDeletingVehicle={this.state.isDeletingVehicle}
                    showRequireSupportAlert={showCustomerSupportAlert}
                    vehicle={productsData.vehicle}
                  />
                  {!showCustomerSupportAlert && (
                    <ProductList key={'PL_' + key} regNo={productsData.vehicle.regNo} showDriveInMyCar={index === 0} />
                  )}
                </Card>
              </Module>
            )
          },
        )}
      </Grid>
    )
  }

  private headerProps = (): IParentOpusHeaderProps => {
    const { bookingNumber, bookingStatus } = (this.props as IVehicleProductsPageStores).bookingStore
    const { canExitRebooking } = (this.props as IVehicleProductsPageStores).uiStore.bookingActions

    return {
      bookingNumber,
      bookingStatus,
      canExitRebooking,
    }
  }

  private onNextClick = async (): Promise<boolean> => {
    const { history, i18n } = this.props
    const { bookingNumber, SetCampaignCode, productsForVehicle } = (this
      .props as IVehicleProductsPageStores).bookingStore
    const { productsStore } = this.props as IVehicleProductsPageStores
    const { uiStore } = this.props as IVehicleProductsPageStores

    this.setState({ leaving: true })
    await fbTrackEvent({
      EventName: ETrackingEvent.CustomizeProduct,
      BookingId: bookingNumber,
      CustomData: {
        content_ids: Array.from(productsStore.selectedProducts.values())
          .reduce((acc: number[], cur: number[]): number[] => [...acc, ...cur], [] as number[])
          .map(id => id.toString()),
        content_type: 'product',
      },
    })

    try {
      await this.setProductsOnBooking()

      // If we have an unsubmitted campaign code, try to add it
      if (bookingNumber && this.campaignCodeInputRef.current && this.campaignCodeInputRef.current.value.length > 0) {
        const didAddCampaignCode = await SetCampaignCode(bookingNumber, this.campaignCodeInputRef.current.value)

        // If we failed, don't go to the next step
        if (!didAddCampaignCode) {
          notifyError(i18n.t('common:campaignCodeDoesntExist'), undefined, 'info')
          return false
        }
      }

      toast.dismiss()

      uiStore.next(history)
    } catch (e) {
      if (e.response && e.response.status === 404) {
        notifyError(i18n.t('common:campaignCodeDoesntExist'), undefined, 'info')
      } else {
        notifyError(e)
      }

      this.setState({ leaving: false })
      return false
    }
    return true
  }

  private deleteVehicle = async (regNo: string): Promise<void> => {
    const { i18n, history } = this.props
    const { bookingStore, productsStore, uiStore } = this.props as IVehicleProductsPageStores

    if (bookingStore.bookingNumber) {
      this.setState({ isDeletingVehicle: true })

      if (productsStore.productsDataForVehicle.size === 1) {
        // We are removing the last vehicle, exit booking instead
        await bookingStore.ExitBooking(bookingStore.bookingNumber)

        if (uiStore) {
          uiStore.restart(history)
          return
        }
      }

      // We can't send delta, send what we want the data to look like
      await productsStore.DeleteVehicle(bookingStore.bookingNumber, regNo, bookingStore)
    } else {
      notifyError(i18n.t('noVehicleToDelete'))
    }
  }

  private getOrCreateBookingInBookingStore = async (regNo: string, testcenterid?: string): Promise<void> => {
    const { bookingStore } = this.props as IVehicleProductsPageStores
    if (!bookingStore) return

    // Hämta bokningar för det här fordonet
    await bookingStore.GetVehicleBookings(regNo, {
      ignorePassedBookings: true,
      includeUncompleted: true,
    })

    // Om det inte finns en bokning, skapa en
    if (!bookingStore.bookingNumber) {
      await bookingStore.CreateBooking(regNo, testcenterid)
    }

    Tracker.trackExternalId(bookingStore.bookingNumberHash || '')

    await fbTrackEvent({
      EventName: ETrackingEvent.SubmitApplication,
      BookingId: bookingStore.bookingNumber,
      CustomData: {
        booking_step: EBookingStep.VehiclesAndProducts,
      },
    })
  }

  private didGetProductsForVehicleInBooking = async (regNo: string): Promise<boolean> => {
    const { bookingStore, productsStore } = this.props as IVehicleProductsPageStores

    let didAddVehicle = false
    if (bookingStore.bookingNumber && productsStore.productsDataForVehicle.has(regNo.toUpperCase()) === false) {
      didAddVehicle = await productsStore.GetAvailableProductsForBookingWithRegno(bookingStore.bookingNumber, regNo)
    }

    return didAddVehicle
  }

  private onVehicleSearchFormSubmit = async (regNo: string, testcenterid?: string): Promise<void> => {
    const { history, i18n } = this.props
    const { bookingStore, productsStore, uiStore } = this.props as IVehicleProductsPageStores

    this.setState({ loading: true })

    try {
      // Lite kontroller
      const errorMessage = this.ValidateInput(regNo)
      if (errorMessage !== '') {
        notifyError(regNo.toUpperCase(), errorMessage, 'warning')
        this.setState({ loading: false })
        return
      }

      const regNosToGetProductsFor: string[] = [regNo.toUpperCase()]

      // Om vi inte har en bokning, hämta eller skapa en
      if (!bookingStore.bookingNumber) {
        await this.getOrCreateBookingInBookingStore(regNo, testcenterid)

        // Om vi har en bokning med status 1 eller 5, gå till /summary
        if (
          (bookingStore.bookingStatus === BookingStatus.NotConfirmed ||
            bookingStore.bookingStatus === BookingStatus.ConfirmedButNotStarted) &&
          uiStore
        ) {
          uiStore.jump(Paths.summary, history, bookingStore.bookingNumber, {
            hideFooter: true,
            hideNavigation: true,
            shouldOverridePreviousPageComplete: true,
            showCoronaMessage: true,
          })
          return // Vi har gått till /summary och är klara med den här metoden
        }

        bookingStore.vehicles.forEach((vehicle): void => {
          if (!regNosToGetProductsFor.find((_regNo): boolean => _regNo === vehicle.regNo.toUpperCase())) {
            regNosToGetProductsFor.push(vehicle.regNo.toUpperCase())
          }
        })
      }

      const vehicleResponses: { regNo: string; ok: boolean }[] = []

      if (productsStore.productsDataForVehicle.size > 0) {
        await this.setProductsOnBooking()
      }

      await Promise.all(
        regNosToGetProductsFor.map(
          async (regNo): Promise<void> => {
            const ok = await this.didGetProductsForVehicleInBooking(regNo)
            vehicleResponses.push({ regNo, ok })
          },
        ),
      )

      vehicleResponses
        .filter((res): boolean => !res.ok)
        .forEach((res): void => {
          notifyError(res.regNo.toUpperCase(), i18n.t('vehiclesAndProducts:vehicleNotAllowed'))
        })

      this.setPreSelectedProducts()

      if (productsStore.productsDataForVehicle.size > 1 && regNosToGetProductsFor.length === 1) {
        // NOTE: Om det behövs kan man optimera detta genom att ta bort `await` och använda `.then` för att välja förvalda produkter
        // För att det ska fungera måste en funktion skrivas för att sätta förvalda produkter för endast ett fordon
        await this.setProductsOnBooking()
      }

      this.setState({ showAdditionalVehicleInput: false })
    } catch (e) {
      notifyError(e)
    }

    this.setState({ loading: false })
  }

  private ValidateInput = (regNo: string): string => {
    const { i18n } = this.props
    const { bookingStore } = this.props as IVehicleProductsPageStores

    // Är det en kökod, KUNDFORDON eller BLOCKERA?
    if (/^[0-9]{4}$/.test(regNo) || /^(KUNDFORDON|BLOCKERA)/.test(regNo.toUpperCase())) {
      return i18n.t('vehiclesAndProducts:vehicle-search-form-validation:regNo.matchesKokod')
    }

    // Är det ett ärendenummer?
    if (/^[äÄ][0-9]{8}-[0-9]{5}$/.test(regNo)) {
      return i18n.t('vehiclesAndProducts:vehicle-search-form-validation:regNo.matchesÄrendenummer')
    }

    // Är det inget ärendenummer men har ett bidestreck med?
    if (regNo.indexOf('-') > -1) {
      return i18n.t('vehiclesAndProducts:vehicle-search-form-validation:regNo.matchesCharacters')
    }

    // Är det ett anonymt nummer?
    if (/^ANON/.test(regNo.toUpperCase())) {
      return i18n.t('vehiclesAndProducts:vehicle-search-form-validation:regNo.matchesAnonym')
    }

    // Finns regno redan?
    if (bookingStore.vehicles.some((vehicle): boolean => vehicle.regNo.toUpperCase() === regNo.toUpperCase())) {
      return i18n.t('vehiclesAndProducts:vehicle-search-form-validation:regNo.regNoAlreadyAdded')
    }

    return ''
  }

  private GetVehicleAndProductsForAllVehiclesInTheBooking = async (): Promise<void> => {
    const { bookingStore, productsStore } = this.props as IVehicleProductsPageStores

    if (bookingStore.bookingNumber) {
      try {
        await Promise.all(
          bookingStore.vehicles.map(
            async (vehicle): Promise<void> => {
              if (
                bookingStore.bookingNumber &&
                !productsStore.productsDataForVehicle.has(vehicle.regNo.toUpperCase())
              ) {
                await productsStore.GetAvailableProductsForBookingWithRegno(bookingStore.bookingNumber, vehicle.regNo)
              }
            },
          ),
        )
      } catch (e) {
        notifyError(e)
      }
    }
  }

  /**
   * Pre-select one of the available products for a vehicle
   *
   * Description: *Update `this.state.selectedProducts` with one of the products if a vehicle has products*
   */
  private setPreSelectedProducts(): void {
    const { productsStore, bookingStore } = this.props as IVehicleProductsPageStores

    if (productsStore.productsDataForVehicle.size) {
      const { selectedProducts } = productsStore

      /** Map of product of pre-selected products for each vehicle */
      const productsToSelectMap: Map<string, number[]> = new Map()

      // Loop all vehicle and products in productStore and collect which product that will be selected by default
      for (const productsPerVehicle of Array.from(productsStore.productsDataForVehicle.values())) {
        const { regNo } = productsPerVehicle.vehicle
        productsToSelectMap.set(regNo, selectedProducts.get(regNo) || this.getPreSelectedProducts(productsPerVehicle))
      }

      // Only update the state if there are any new products to  pre-select for the vehicle.
      // Withouth this check the app will crash because productsToSelectMap defaults to an empty Map (see definition for `productsToSelectMap`)
      if (productsToSelectMap.size) {
        productsStore.SetSelectedProducts(productsToSelectMap, bookingStore.driveInMyCar)
      }
    }
  }

  /**
   *  Are there any bookingRows, collect productIds and preselect them
   *  If no bookingRows, preselect suggestedInspectionCode
   */
  private getPreSelectedProducts(productsPerVehicle: IProductsPerVehicle): number[] {
    const { bookingStore } = this.props as IVehicleProductsPageStores
    /** Products to preselect */
    let preSelectedProducts: number[] = []

    /** Selected products for a vehicle */
    const selectedProducts = bookingStore.bookingRows.filter(
      (row): boolean => row.regNo === productsPerVehicle.vehicle.regNo,
    )
    // If we have selected products
    if (selectedProducts.length > 0) {
      preSelectedProducts = selectedProducts.map((product): number => product.productId)
    } else if (
      // Do we have a suggested product?
      productsPerVehicle.vehicle.suggestedInspectionCode !== null &&
      productsPerVehicle.products.length > 0
    ) {
      /** Finds the suggested product */
      const productWithSuggestedInspectionCode = productsPerVehicle.products.find(
        (product): boolean => product.controlCode === productsPerVehicle.vehicle.suggestedInspectionCode,
      )
      preSelectedProducts = productWithSuggestedInspectionCode ? [productWithSuggestedInspectionCode.id] : []
    }

    return preSelectedProducts
  }
}

export default withTranslation()(withRouter(VehicleProductsPage))
