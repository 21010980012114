import * as React from 'react'

/* Import components here */
import { StyledGrid } from './Grid.styles'

/* Import interfaces here */
import { IGridProps } from './Grid.interfaces'

export const Grid: React.FC<IGridProps> = ({ children, columns = 4, mdColumns, xlColumns, ...props }) => {
  const md = mdColumns || columns
  const xl = xlColumns || mdColumns || columns

  return (
    <StyledGrid columns={columns} mdColumns={md} xlColumns={xl} {...props}>
      {children}
    </StyledGrid>
  )
}

export default Grid
