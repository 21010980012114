import * as React from 'react'
import { useTranslation } from 'react-i18next'

/* Import components here */
import { Heading, Paragraph } from 'Components'

/* Import interfaces here */

/* Import utilities here */

export const DriveInDescription: React.FC = () => {
  const { i18n } = useTranslation()

  return (
    <React.Fragment>
      <Heading level={2} margin={{ top: 'xxlarge' }}>
        {i18n.t('stationsAndTime:additionalInformation.noTimeFit')}
      </Heading>
      <Paragraph margin={{ bottom: 'medium' }}>
        {i18n.t('stationsAndTime:additionalInformation.driveInStations-1')}
        <br />
        {i18n.t('stationsAndTime:additionalInformation.driveInStations-2')}
      </Paragraph>
    </React.Fragment>
  )
}

export default DriveInDescription
