import * as React from 'react'

/* Import components here */
import classNames from 'classnames'
import { StyledButtonGroup } from './ButtonGroup.styles'

/* Import interfaces here */
import { IButtonGroupProps } from './ButtonGroup.interfaces'

export const ButtonGroup: React.FC<IButtonGroupProps> = ({
  direction = 'horizontal',
  verticalSpacing,
  style,
  children,
  ...props
}) => {
  // TODO: Ta bort classNames och skicka in värden som props till `StyledButtonGroup`.
  const classDirection = direction
  const classVerticalSpacing =
    (verticalSpacing === 'wide' && 'verticalSpacingWide') ||
    (verticalSpacing === 'none' && 'verticalSpacingNone') ||
    'verticalSpacingNarrow'

  return (
    <StyledButtonGroup
      className={classNames(classDirection, classVerticalSpacing)}
      role="group"
      style={style}
      {...props}
    >
      {children}
    </StyledButtonGroup>
  )
}

export default ButtonGroup
