import React from 'react'

import { faCheck, faExclamation, faInfo, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

/* Import components here */
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Description, Icon, StyledNotification, Title } from './Notification.styles'

/* Import interfaces here */
import { INotificationProps } from './Notification.interfaces'

/* Import utilities here */

export const Notification: React.FC<INotificationProps> = ({ description, icon, title, type, ...props }) => {
  /** The icon that will be rendered */
  let theIcon = icon
  /** Check if an icon should be rendered or not */
  const useIcon = !!theIcon || theIcon === undefined

  // If no icon is defined we will use the default icons based on notification type
  if (theIcon === undefined) {
    theIcon =
      (type === 'error' && faTimes) ||
      (type === 'info' && faInfo) ||
      (type === 'success' && faCheck) ||
      (type === 'warning' && faExclamation) ||
      faInfo
  }

  return (
    <StyledNotification className={classNames(type)} {...props}>
      {useIcon && (
        <Icon>
          <FontAwesomeIcon icon={theIcon as IconProp} />
        </Icon>
      )}
      <div>
        {title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
      </div>
    </StyledNotification>
  )
}

export default Notification
