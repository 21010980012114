/**
 * Helper functions to simplify API calls
 */

import { apiConfig } from 'Api_DEPRECATED/client'
import { IAviFormData } from 'Components/Opus/AviForm'

export interface IAvailableTimesOverview {
  [date: string]: { available: number; bestPrice: number }
}

const removeSlashPrefix = (s: string): string => (s[0] === '/' ? s.slice(1, s.length) : s)
const removeTrailingSlash = (s: string): string => (s[s.length - 1] === '/' ? s.slice(0, s.length - 1) : s)
const normalizePath = (s: string): string => removeSlashPrefix(removeTrailingSlash(s))

const formatApiUrl = (...endpoint: string[]): string => {
  return [removeTrailingSlash(apiConfig.baseURL), 'api', ...endpoint.map(normalizePath)].join('/')
}

export const getConfirmationPrintUrl = (bookingNumber: string): string => {
  return formatApiUrl('bookings', bookingNumber, 'confirmationPrintOut')
}

export const formatAviFormDataForBackend = (aviData: IAviFormData): string => {
  /**
   * Back-end vill ha det såhär
   * public EAviCancelOption Option { get; set; }
   * public string Name { get; set; }
   * public string Email { get; set; }
   * public string Phone { get; set; }
   * public string Bank { get; set; }
   * public string ClearingNumber { get; set; }
   * public string AccountNumber { get; set; }
   * public string StreetAddress { get; set; }
   * public string ZipCode { get; set; }
   * public string City { get; set; }
   * public string BankGiroNumber { get; set; }
   */

  const {
    accountNumber,
    address,
    bank,
    bgNumber,
    city,
    clearingNumber,
    email,
    name,
    phoneNumber,
    postcode,
    selectedPaymentOption,
  } = aviData

  const backendFormattedAviData = {
    Option: parseInt(selectedPaymentOption),
    Name: name,
    Email: email,
    Phone: phoneNumber,
    Bank: bank,
    ClearingNumber: clearingNumber,
    AccountNumber: accountNumber,
    StreetAddress: address,
    ZipCode: postcode,
    City: city,
    BankGiroNumber: bgNumber,
  }
  return JSON.stringify(backendFormattedAviData)
}
