import * as React from 'react'

import { withTranslation } from 'react-i18next'

/* Import components here */
import { Button, Logo, Modal } from 'Components'
import { StyledOpusHeader } from './OpusHeader.styles'

/* Import interfaces here */
import { IOpusHeaderProps } from './OpusHeader.interfaces'
import { BookingStatus } from 'DataTypes'

/* Import utilities here */
import { API_DEPRECATED } from 'Api_DEPRECATED'
import { useIntelecomPositionUpdate, useStores } from 'Utils'

const IntelecomPositionWrapper = ({ children }): JSX.Element => <div id="intelecom-positioner">{children}</div>

export const OpusHeader: React.FC<IOpusHeaderProps> = ({
  i18n,
  bookingNumber,
  bookingStatus,
  canExitRebooking,
  hideExitButton,
  restartButtonText,
}) => {
  const { uiStore, sessionStore } = useStores()
  const { redirectUrl } = uiStore
  /**
   * Cancel the temporary booking by removing it and redrecting the user to the start page.
   *
   * Note: This only happen on a new booking or rebooking, never on `/summary`.
   *
   */
  const exitBooking = async (): Promise<void> => {
    if (bookingNumber) {
      // Clear any stale rebooking state from sessionStorage in case this is
      // a rebooking after a rebooking, and user cancel the rebooking
      sessionStore.rebookingState.clearRebookingState()

      // Cancel the booking when restarting
      await API_DEPRECATED.Bookings.postExitBooking(bookingNumber)

      const localRedirectUrl = canExitRebooking ? `/summary/${bookingNumber}` : '/'
      const redirectUrl =
        uiStore.parameters && uiStore.parameters.forceCallbackUrlRedirectOnAbort && uiStore.redirectUrl
      window.location.href = redirectUrl || localRedirectUrl // FIXME: Byt till React Router redirect
    }
  }

  /**
   * Redirect the user to the start page without removing the temporary booking.
   *
   * Note: This only happens on `/summary`
   *
   */
  const redirectToStart = (): void => {
    window.location.href = '/'
  }

  const redirectToRedirectUrl = (): void => {
    if (redirectUrl) {
      window.location.href = redirectUrl
    }
  }

  const cancelRebookingModal = (): JSX.Element => (
    <Modal
      header={i18n.t('common:allPages.cancelRebookingHeader')}
      hideDefaultCloseButton={true}
      leftButton={{
        component: <Button title={i18n.t('common:cancel')} />,
        closeOnClick: true,
      }}
      message={i18n.t('common:allPages.confirmCancelRebooking')}
      rightButton={{
        component: <Button title={i18n.t('common:confirm')} />,
        callbackFn: exitBooking,
      }}
      triggerComponent={<Button size="small" title="X" variant="danger" />}
    />
  )

  const cancelBookingModal = (): JSX.Element => (
    <Modal
      header={i18n.t('common:allPages.cancelBookingHeader')}
      hideDefaultCloseButton={true}
      leftButton={{
        component: <Button title={i18n.t('common:noSaveBooking')} />,
        closeOnClick: true,
      }}
      message={
        redirectUrl
          ? i18n.t('common:allPages.confirmCancelBookingToRedirectUrl')
          : i18n.t('common:allPages.confirmCancelBooking')
      }
      rightButton={{
        component: <Button title={redirectUrl ? i18n.t('common:back') : i18n.t('common:yesRestart')} />,
        callbackFn: exitBooking,
      }}
      triggerComponent={<Button size="small" title={redirectUrl ? i18n.t('common:back') : 'X'} variant="danger" />}
    />
  )

  useIntelecomPositionUpdate()

  restartButtonText = restartButtonText || redirectUrl ? i18n.t('common:back') : i18n.t('common:cancel')

  let exitButton: JSX.Element | null = null
  if (bookingStatus === BookingStatus.ConfirmedButNotStarted || bookingStatus === BookingStatus.NotConfirmed) {
    exitButton = (
      <Button
        size="small"
        title={restartButtonText}
        variant="danger"
        onClick={redirectUrl ? redirectToRedirectUrl : redirectToStart}
      />
    )
  } else if (canExitRebooking) {
    exitButton = cancelRebookingModal()
  } else if (bookingNumber) {
    exitButton = cancelBookingModal()
  }

  return (
    <StyledOpusHeader>
      <Logo showText={!exitButton} />
      <IntelecomPositionWrapper>{!hideExitButton && exitButton}</IntelecomPositionWrapper>
    </StyledOpusHeader>
  )
}

export default withTranslation()(OpusHeader)
