import _ from 'lodash'
const isAllZeros = (str: string): boolean =>
  _.reduce(str, (acc: boolean, current: string): boolean => (acc ? current === '0' : false), true)

/**
 * Format a number to fixed decimals. If all decimals are zeroes, the decimals are
 * stripped. For example 2.5 => '2.50' and 2.9999 => '3'
 * @returns a string representing the number
 * @param x - format this number
 * @param decimals - number of decimals. Defaults to 2
 */
export const toFixedWithOptionalDecimals = (x: number, decimals = 2): string => {
  const [n, frac] = x.toFixed(decimals).split('.')
  return isAllZeros(frac) ? n : [n, frac].join('.')
}
