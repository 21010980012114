import React from 'react'

import { Field, FormikErrors, FormikProps, withFormik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'

/* Import components here */
import { Alert, Button, Checkbox, Input } from 'Components'
import { StyledPayAtStation } from './PayAtStation.styles'

/* Import interfaces here */
import { IFormProps, IFormValues, IPayAtStationProps } from './PayAtStation.interfaces'

/* Import utilities here */
import i18n from 'Config/i18n'
import { EnvironmentVariables } from 'Config/environment'
import { isValidEmail, isValidPhoneNumber } from 'Utils'

const { TERMS_PAGE_URL, GDPR_PAGE_URL, COOKIE_POLICY_URL } = EnvironmentVariables

const InnerPayAtStationForm: React.FC<
  FormikProps<IFormValues> & { showContactForm: boolean; showSubmitButton?: boolean }
> = ({ showContactForm, errors, setFieldValue, touched, values, handleSubmit, showSubmitButton = true }) => {
  const { t } = useTranslation()

  /** Message to show user when user has not added email nor phone */
  let mustEnterPhoneOrEmail = ''

  const toggleTerms = (e: React.MouseEvent<HTMLElement>): void => {
    const target = e.target as HTMLElement
    if (target.tagName !== 'A') setFieldValue('terms', !values.terms)
  }

  // If uses has touched the input fields
  if (touched.email && touched.phone) {
    // But not written anything in the fields
    if (values.phone.toString().length === 0 && values.email.length === 0) {
      mustEnterPhoneOrEmail = touched.email && touched.phone && i18n.t('checkout:validation.email-or-phone')
    }
  }

  return (
    <StyledPayAtStation>
      <form onSubmit={handleSubmit}>
        {showContactForm && (
          <>
            <Field
              component={Input}
              id="email"
              label={t('common:email')}
              message={errors.email && touched.email && errors.email}
              name="email"
              placeholder={t('checkout:emailPlaceholder')}
              type="email"
              variant={errors.email && touched.email && 'error'}
            />
            <Field
              component={Input}
              id="phone"
              label={t('common:mobile')}
              message={errors.phone && touched.phone && errors.phone}
              name="phone"
              placeholder={t('checkout:phonePlaceholder')}
              type="tel"
              variant={errors.phone && touched.phone && 'error'}
            />
          </>
        )}
        <Field
          checked={values.terms}
          component={Checkbox}
          label={
            <Trans i18nKey="checkout:acceptTerms">
              I accept Opus Bilprovnings
              <a href={TERMS_PAGE_URL} rel="noopener noreferrer" target="_blank">
                terms
              </a>
              and handling of
              <a href={GDPR_PAGE_URL} rel="noopener noreferrer" target="_blank">
                personal information (GDPR)
              </a>
              and
              <a href={COOKIE_POLICY_URL} rel="noopener noreferrer" target="_blank">
                Cookie policy
              </a>
            </Trans>
          }
          message={errors.terms}
          name="terms"
          variant={errors.terms && 'error'}
          onClick={toggleTerms}
        />
        {!!mustEnterPhoneOrEmail && <Alert bordered={true} message={mustEnterPhoneOrEmail} type="error" />}
        {showSubmitButton ? (
          <Button title={t('checkout:payment.completeBooking')} type="submit" variant="accent" verticalSpacing="wide" />
        ) : null}
      </form>
    </StyledPayAtStation>
  )
}

export const EnhancedPayAtStationForm = withFormik<IFormProps, IFormValues>({
  // Give the form a default value. If not included the form is an uncontrolled form and later Formik starts to control it which throws an error.
  mapPropsToValues: ({ paymentDetails }): IFormValues => ({
    email: paymentDetails.email,
    phone: paymentDetails.phone,
    terms: false,
  }),

  handleSubmit: async (values, formikBag): Promise<void> => {
    formikBag.setSubmitting(true)
    await formikBag.props.onFormSubmit(values)
    formikBag.setSubmitting(false)
  },

  validate: ({ email, phone, terms }, { onFormChange }): void | FormikErrors<IFormValues> => {
    /**
     * Validation rules
     * 1. A valid Swedish mobile phone number AND `>= 5 && <= 16 ` OR
     * 2. A valid e-mail address AND
     * 3. Terms must be checked
     */

    /** Object containing possible errors */
    const errors: FormikErrors<IFormValues> = {}

    // Validate e-mail
    if (email) {
      if (!isValidEmail(email)) {
        errors.email = i18n.t('checkout:validation.email.not-valid')
      }
    }

    // If a phone number is entered, it is ok
    if (!!phone && !isValidPhoneNumber(phone)) {
      errors.phone = i18n.t('checkout:validation.phone.not-swedish')
    }

    // Check if terms are checked
    if (!terms) {
      errors.terms = i18n.t('checkout:validation.terms')
    }

    /** If a value has been entered in either the phone or email fields */
    const hasValueInEmailOrPhone: boolean = !!phone || !!email
    const hasNoErrors: boolean = !errors.email && !errors.phone && !errors.terms

    if (hasValueInEmailOrPhone && hasNoErrors && onFormChange) {
      onFormChange(true, { email, phone })
    } else if (onFormChange) {
      onFormChange(false, { email, phone })
    }

    return errors
  },

  validateOnChange: true,
})(InnerPayAtStationForm)

const PayAtStation: React.FC<IPayAtStationProps> = ({
  showContactForm,
  onFormChange,
  onFormSubmit,
  paymentDetails,
  showSubmitButton = true,
}) => {
  if (showContactForm === undefined) {
    showContactForm = true
  }

  return (
    <EnhancedPayAtStationForm
      paymentDetails={paymentDetails}
      showContactForm={showContactForm}
      showSubmitButton={showSubmitButton}
      onFormChange={onFormChange}
      onFormSubmit={onFormSubmit}
    />
  )
}

export default PayAtStation
