import * as React from 'react'
import { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

/* Import components here */
import { Button, Modal } from 'Components'

/* Import utilities here */
import { config as bookingSessionConfig } from 'Config/booking'
import { notifyError } from 'Utils/Notifications'
import { useInterval, useScheduled, useStores } from 'Utils/hooks'

/**
 * Component which displays a modal dialog with a warning if the booking
 * session is about to expire
 */
const BookingSessionTimer: React.FunctionComponent = () => {
  // console.group('Session timer')

  const [counter, setCounter] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [endIsScheduled, setEndIsScheduled] = useState(false)
  const { t, i18n } = useTranslation()
  const { bookingStore, uiStore } = useStores()

  const warnAt = bookingStore.bookingSessionWarningTime
  const endAt = bookingStore.bookingSessionEndTime

  // utility for Cancelling temporary booking session and reload page
  const cancelBooking = async (): Promise<void> => {
    if (bookingStore.bookingNumber) {
      await bookingStore.ExitBooking(bookingStore.bookingNumber)
    }
    uiStore.restart()
  }

  // Click handler for cancel session button
  const handleCancelSessionClick = (): void => {
    setShowModal(false)
    cancelBooking()
  }

  // Click handler for refresh session button
  const handleRefreshSessionClick = (): void => {
    if (bookingStore.bookingNumber) {
      bookingStore.RefreshBooingSession().catch(notifyError.bind(null, i18n))
    }

    setEndIsScheduled(false)
    setShowModal(false)
  }

  // console.log(`warn at: ${warnAt && warnAt.getTime()}`)
  //console.log(`end at: ${endAt && endAt.getTime()}`)
  //console.log('current counter', counter)

  // Schedule session warning
  useScheduled(
    (): void => {
      const secondsLeft = bookingSessionConfig.endTimeout - bookingSessionConfig.warnTimeout - 1
      // console.log(`%c WARNING, session will end in ${secondsLeft} s`, 'font-weight: bold; color: red')
      setCounter(secondsLeft)
      setEndIsScheduled(true)
      setShowModal(true)
    },
    warnAt,
    'WARN',
  )

  // Schedule Session end
  useScheduled(
    (): void => {
      // console.log('WARNING, session ends NOW')
      cancelBooking()
      setShowModal(false)
    },
    endIsScheduled ? endAt : null,
    'END',
  )

  // Countdown timer
  useInterval(
    (): void => {
      setCounter(counter - 1)
    },
    showModal ? 1000 : null,
  )

  // console.groupEnd()

  // Prepare message shown for user
  const minutesLeft = Math.floor(counter / 60)
  const secondsLeft = counter % 60
  const minutesText = minutesLeft
    ? t('common:bookingSession.minutes', { count: minutesLeft }) + ` ${t('common:and')}`
    : ''
  const secondsText = t('common:bookingSession.seconds', { count: secondsLeft })
  const message = t('common:bookingSession.warningMessage', {
    timeLeft: minutesText + secondsText,
  })

  return (
    <Modal
      header={t('common:bookingSession.warningHeader')}
      hideDefaultCloseButton={true}
      importantIsOpen={showModal}
      leftButton={{
        component: <Button title={t('common:no')} />,
        callbackFn: handleCancelSessionClick,
      }}
      message={message}
      rightButton={{
        component: <Button title={t('common:yes')} />,
        callbackFn: handleRefreshSessionClick,
      }}
    />
  )
}

export default observer(BookingSessionTimer)
