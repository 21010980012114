import { ApisauceConfig, ApisauceInstance, create } from 'apisauce'

import { AccountEndpoints, IAccountEndpoints } from './Account'
import { BookingsEndpoints, IBookingsEndpoints } from './Bookings'
import { IStationsEndpoints, StationsEndpoints } from './Stations'
import { IVehiclesEndpoints, VehiclesEndpoints } from './Vehicles'

// TODO: Om vi inte hämtar från environment direkt kraschar appen i prod-build. Varför?
import { EnvironmentVariables } from 'Config/environment'

interface ISwaggerAPIConfig extends ApisauceConfig {
  baseURL: string
}

export const apiConfig: ISwaggerAPIConfig = {
  baseURL: EnvironmentVariables.API_URL,
  timeout: EnvironmentVariables.API_TIMEOUT * 1000,
}

interface ISwaggerAPI {
  http: ApisauceInstance
  Account: IAccountEndpoints
  Bookings: IBookingsEndpoints
  Stations: IStationsEndpoints
  Vehicles: IVehiclesEndpoints
}

class SwaggerAPI implements ISwaggerAPI {
  public http: ApisauceInstance

  public Account = AccountEndpoints

  public Bookings = BookingsEndpoints

  public Stations = StationsEndpoints

  public Vehicles = VehiclesEndpoints

  public constructor(config: ApisauceConfig) {
    if (!config.baseURL) {
      throw new Error('You must provide a baseURL to the API client')
    }

    this.http = create(config)
  }
}

export const API_DEPRECATED = new SwaggerAPI(apiConfig)
