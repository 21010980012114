import { API_DEPRECATED } from '.'

import {
  ISetContactInformation,
  ISetNoteInformation,
  ISetNotificationInformation,
  IUserVehicleInfo,
  IVehicleBasicInfo,
  IVehicleInfo,
} from './interfaces'

/* INTERFACES */

export interface IVehiclesEndpoints {
  /**
   * Gets a xlsx file of vehicles associated with the user with pagination through a filter
   *
   * GET /api/vehicles/xlsx
   */
  getXlsxWithFilter(queryParams: {
    RegNo?: string
    OrgNo?: string
    IsDrivingBan?: boolean
    IsInTraffic?: boolean
    LastInspectionMonth?: string
    Alias?: string
    Page: number
    PageSize: number
    VehicleType?: string
    token?: string
  }): Promise<string>

  /**
   * Gets a csv file of vehicles associated with the user with pagination through a filter
   *
   * GET /api/vehicles/csv
   */
  getCsvWithFilter(queryParams: {
    RegNo?: string
    OrgNo?: string
    IsDrivingBan?: boolean
    IsInTraffic?: boolean
    LastInspectionMonth?: string
    Alias?: string
    Page: number
    PageSize: number
    VehicleType?: string
    saveAsXls?: boolean
  }): Promise<string>

  /**
   * Gets all the vehicles associated with the user
   *
   * GET /api/vehicles
   */
  get(): Promise<IVehicleBasicInfo[]>

  /**
   * Gets vehicles associated with the given alias on the user
   *
   * GET /api/vehicles/alias
   */
  getFromAlias(queryParams: { alias?: string }): Promise<IVehicleBasicInfo[]>

  /**
   * Validate a Case number from Transportstyrelsen
   *
   * GET /api/vehicles/{caseno}/isValidCaseNumber
   */
  getIsCaseNumberValid(caseno: string): Promise<boolean>

  /**
   * Gets basic information about a vehicle based of the plate number of the vehicle
   *
   * GET /api/vehicles/{regno}
   */
  getVehicle(regno: string): Promise<IVehicleBasicInfo>

  /**
   * Gets additional information about a vehicle that can be manipulated
   *
   * GET /api/vehicles/{regno}/info
   */
  getUserVehicleInfo(regno: string): Promise<IUserVehicleInfo>

  /**
   * This endpoint saves contact information of a vehicle
   *
   * PUT /api/vehicles/{regno}/contactInfo
   */
  putSetContactInfo(regno: string, bodyParams?: ISetContactInformation): Promise<IUserVehicleInfo>

  /**
   * This endpoint saves notification information of a vehicle
   *
   * PUT /api/vehicles/{regno}/notifications
   */
  putSetNotifications(regno: string, bodyParams?: ISetNotificationInformation): Promise<IUserVehicleInfo>

  /**
   * This endpoint saves note information of a vehicle
   *
   * PUT /api/vehicles/{regno}/notes
   */
  putSetNotes(regno: string, bodyParams: ISetNoteInformation): Promise<IUserVehicleInfo>

  /**
   * This endpoint gets the inspection documents for a vehicle
   *
   * GET /api/vehicles/{regno}/inspectionDocuments
   */
  getInspectionDocuments(
    regno: string,
    queryParams: {
      From?: string
      To?: string
    },
  ): Promise<IVehicleInfo>
}

/* IMPLEMENTATION */

export const VehiclesEndpoints: IVehiclesEndpoints = {
  getXlsxWithFilter: async function (queryParams) {
    const response = await API_DEPRECATED.http.get<string>(`/api/vehicles/xlsx`, queryParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getCsvWithFilter: async function (queryParams) {
    const response = await API_DEPRECATED.http.get<string>(`/api/vehicles/csv`, queryParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  get: async function () {
    const response = await API_DEPRECATED.http.get<IVehicleBasicInfo[]>(`/api/vehicles`)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getFromAlias: async function (queryParams) {
    const response = await API_DEPRECATED.http.get<IVehicleBasicInfo[]>(`/api/vehicles/alias`, queryParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getIsCaseNumberValid: async function (caseno) {
    const response = await API_DEPRECATED.http.get<boolean>(`/api/vehicles/${caseno}/isValidCaseNumber`)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getVehicle: async function (regno) {
    const response = await API_DEPRECATED.http.get<IVehicleBasicInfo>(`/api/vehicles/${regno}`)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getUserVehicleInfo: async function (regno) {
    const response = await API_DEPRECATED.http.get<IUserVehicleInfo>(`/api/vehicles/${regno}/info`)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  putSetContactInfo: async function (regno, bodyParams) {
    const response = await API_DEPRECATED.http.put<IUserVehicleInfo>(`/api/vehicles/${regno}/contactInfo`, bodyParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  putSetNotifications: async function (regno, bodyParams) {
    const response = await API_DEPRECATED.http.put<IUserVehicleInfo>(`/api/vehicles/${regno}/notifications`, bodyParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  putSetNotes: async function (regno, bodyParams) {
    const response = await API_DEPRECATED.http.put<IUserVehicleInfo>(`/api/vehicles/${regno}/notes`, bodyParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getInspectionDocuments: async function (regno, queryParams) {
    const response = await API_DEPRECATED.http.get<IVehicleInfo>(
      `/api/vehicles/${regno}/inspectionDocuments`,
      queryParams,
    )

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },
}
