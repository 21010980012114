import { Button } from 'Components'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  target: React.RefObject<HTMLElement>
}

const offset = 8 * 16 // 8 rem
const slack = 50

export function ScrollToCheckoutButton({ target }: IProps) {
  const [showButton, setShowButton] = useState(true)
  const { t } = useTranslation()
  const onClick = useCallback(() => {
    if (!target.current) return

    const bodyRect = document.body.getBoundingClientRect().top
    const elementRect = target.current.getBoundingClientRect().top
    const elementPosition = elementRect - bodyRect
    const offsetPosition = elementPosition - offset

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }, [target])

  const onScroll = useCallback(
    e => {
      if (!target.current) return
      const bounding = target.current.getBoundingClientRect()
      const newShow = bounding.top >= offset + slack && bounding.bottom > window.innerHeight
      setShowButton(newShow)
    },
    [target],
  )

  useEffect(() => {
    const a = Math.random()
    const handler = e => onScroll(e)
    document.addEventListener('scroll', handler)

    return () => {
      document.removeEventListener('scroll', handler)
    }
  }, [onScroll])

  return showButton ? <Button nowrap title={t('checkout:titles.main')} variant="accent" onClick={onClick} /> : null
}
