import * as React from 'react'

/* Import components here */
import { Heading, Paragraph } from 'Components'

/* Import interfaces here */
import { IPageTitleProps } from './PageTitle.interfaces'

/* Import utilities here */

export const PageTitle: React.FC<IPageTitleProps> = ({ description, style, title, message, children }): JSX.Element => {
  return (
    <div style={style}>
      <Heading level={1} margin={{ top: 'large', bottom: 'xxsmall' }} size="large" textAlign="center">
        {title}
      </Heading>
      {message && (
        <Paragraph margin={{ bottom: 'xlarge' }} textAlign="center">
          {message}
        </Paragraph>
      )}
      {description && (
        <Paragraph margin={{ bottom: 'xlarge' }} textAlign="center">
          {description}
        </Paragraph>
      )}
      {children}
    </div>
  )
}

export default PageTitle
