import * as React from 'react'

/* Import components here */
import { Header, Navigation, OpusFooter, OpusHeader } from 'Components'
import { PageContent, StyledPage } from './Page.styles'

/* Import interfaces here */
import { IDumbPageProps } from './Page.interfaces'

export const DumbPage: React.FunctionComponent<IDumbPageProps> = ({
  children,
  footerProps,
  hideHeader,
  hideNavigation,
  hideFooter,
  headerProps,
  ...props
}) => (
  <StyledPage {...props}>
    {!hideHeader && (
      <Header>
        <OpusHeader {...headerProps} />
      </Header>
    )}

    {!hideNavigation && <Navigation currentStep={undefined} />}

    <PageContent>{children}</PageContent>

    {!hideFooter && <OpusFooter {...footerProps} />}
  </StyledPage>
)

export default DumbPage
