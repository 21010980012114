import { styled } from 'Theme'
import { rgba } from 'polished'

export const StyledAside = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 10; /* This must be at a higher index to the rest of your page content */

  transform: translateZ(0);
  background-color: ${({ theme }): string => {
    return rgba(theme.modal.overlayBackground, Number(theme.modal.overlayOpacity))
  }};

  color: ${({ theme }): string => theme.modal.color};

  border: 1px solid transparent;
  border-radius: ${({ theme }): string => theme.modal.borderRadius};
`

// Wrapper i gamla
export const StyledModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 1rem;
  left: 50%;
  top: 50%;
  height: auto;
  transform: translate(-50%, -50%);
  max-width: 30em;
  max-height: calc(100% - 1em);

  border-radius: ${({ theme }): string => theme.modal.borderRadius};

  background-color: #ffffff;

  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: ${({ theme }): string => theme.sizes.mdContainer}) {
    width: 24rem;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  line-height: 1;
  background: #f6f6f7;
  border: 0;
  box-shadow: 0;
  outline-style: none;
  cursor: pointer;
`

export const CloseIcon = styled.svg`
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 2;
`

export const Close = styled.span`
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
`

export const Header = styled.p`
  margin-bottom: 0.5rem;

  font-weight: 600;
  font-size: 1.25rem;
  text-align: center;
`

export const Body = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

  margin-top: 1rem;
`

export const Message = styled.p`
  margin-bottom: 0.5rem;

  font-weight: 500;
  font-size: 1rem;
  text-align: center;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;

  margin-top: 1rem;

  &.leftButton {
    justify-content: flex-start;
  }

  &.rightButton {
    justify-content: flex-end;
  }
`
