import { styled } from 'Theme'
import { Footer, Grid, Module } from 'Components'
import { StyledInput } from 'Components/Generic/Input/Input.styles'
import { StyledButton } from 'Components/Generic/Button/Button/Button.styles'

interface IProps {
  justifyContent?: 'flex-end' | 'flex-start'
}

export const StyledModule = styled(Module)<IProps>`
  align-items: center;
  display: flex;
  justify-content: ${({ justifyContent }): string => justifyContent || 'center'};
`

export const StyledGrid = styled(Grid)`
  margin: 0 auto;

  @media (min-width: ${({ theme }): string => theme.sizes.mdContainer}) {
    width: ${({ theme }): string => theme.sizes.mdContainer};
  }
  @media (min-width: ${({ theme }): string => theme.sizes.xlContainer}) {
    width: ${({ theme }): string => theme.sizes.xlContainer};
  }
`

export const StyledFooter = styled(Footer)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0 1rem;

  ${StyledButton} {
    line-height: 0.9;

    &[disabled] {
      background: ${({ theme }): string => theme.button.variant.primary.disabled.background};
      color: ${({ theme }): string => theme.button.variant.primary.disabled.color};
    }
  }

  ${StyledInput} {
    line-height: 1.4;
  }
`
