import React from 'react'
import styled from 'styled-components/macro'
import { Trans, useTranslation } from 'react-i18next'

import { Alert } from 'Components'

const Paragraph = styled.p`
  margin-bottom: 1rem;
`

interface ICoronaMessageProps {
  variant: 'short' | 'long'
}

export function CoronaMessage({ variant }: ICoronaMessageProps): JSX.Element {
  const { t } = useTranslation('common')
  const longMessage = t('coronaMessage.long').split('\n\n')

  if (variant === 'short') {
    return (
      <Alert
        centered={true}
        message={
          <>
            <Trans i18nKey="coronaMessage.short">
              Aktuell information gällande covid-19. Läs{' '}
              <a
                href="https://opus.se/nyheter/nyheter/2020-03-11-aktuell-information-med-anledning-av-corona-viruset"
                rel="noopener noreferrer"
                target="_blank"
              >
                här
              </a>
              .
            </Trans>
          </>
        }
        type="warning"
      />
    )
  }

  return (
    <Alert
      message={
        <>
          {longMessage.map((p, i) => (
            <Paragraph key={i}>{p}</Paragraph>
          ))}
        </>
      }
      type="warning"
    />
  )
}
