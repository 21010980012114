import { styled } from 'Theme'
import { Card } from 'Components/Generic/Card'

export const CardHeader = styled.div`
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: row;
`

export const StationName = styled.h2`
  order: 0;
  text-align: left;
  margin-bottom: 0;
  flex: 1;
  align-self: center;
  font-size: 1.75rem;
`
export const StationImageLine = styled.div``

export const StationAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
export const AddressLine = styled.span`
  font-size: 1rem;
  margin-top: 0.25rem;
`

export const ButtonLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1rem;
`

export const StationImage = styled.img`
  width: 100%;
  border-radius: 0.25rem;
`

export const CardWithMargin = styled(Card)`
  margin-top: 0.5rem;
`
