import * as React from 'react'

import formatDate from 'date-fns/format'
import { useTranslation } from 'react-i18next'

/* Import components here */
import { Grid, Heading, Module, Paragraph } from 'Components'
import {
  ImageContainer,
  JustifiedRow,
  JustifiedRowWithMargin,
  Line,
  PlateRow,
  StationAndDate,
  StyledSummaryCard,
  StyledText,
} from './SummaryCard.styles'
import { RegNoInput } from '../RegNoInput'

/* Import interfaces here */
import { ISummaryCardProps } from './SummaryCard.interfaces'

/* Import utilities here */
import { capitalize, getLocaleFile, toFixedWithOptionalDecimals, useStores } from 'Utils'
import { colors } from 'Theme/colors'

const PAYMENT_STATUS = {
  PRE_PAYED: 5,
  PENDING: 7,
} as const

export const SummaryCard: React.FC<ISummaryCardProps> = ({ image, title, showStatus, showPrepayedAmount }) => {
  const { i18n, t } = useTranslation()
  const { bookingStore } = useStores()
  const { station, prepayedAmount, driveInMyCar } = bookingStore

  const stationName = station ? station.name : '' // FIXME: Modellen bör nog inte vara types.maybe för station. Då behöver man göra onödiga kontroller
  /** Date for the inspection */

  const date = capitalize(
    formatDate(bookingStore.time, "EEEE d MMMM 'kl' HH:mm", {
      locale: getLocaleFile(),
    }),
  )

  const { priceInfo } = bookingStore
  const paymentStatus =
    bookingStore.paymentStatus == PAYMENT_STATUS.PENDING ? PAYMENT_STATUS.PRE_PAYED : bookingStore.paymentStatus
  const paymentStatusMessage = t(`payment:paymentStatusText.${paymentStatus}`)

  /**
   * Displays products and price for each vehicle
   */
  const renderVehicleWithProducts = (): JSX.Element[] => {
    return bookingStore.vehicles.map(
      (vehicle): JSX.Element => {
        return (
          <React.Fragment key={vehicle.regNo}>
            <Heading key={vehicle.regNo} level={2} margin={{ top: 'large' }} size="small">
              {vehicle.regNo}
            </Heading>
            {priceInfo.perVehicle[vehicle.regNo].products.map(
              (product): JSX.Element => (
                <JustifiedRow key={product.productId}>
                  <StyledText alignSelf="start" weight="normal">
                    {product.productName}
                  </StyledText>
                  <StyledText alignSelf="end" weight="bold">
                    {t('common:priceAndCurrency', { price: product.priceWithoutDiscount })}
                  </StyledText>
                </JustifiedRow>
              ),
            )}
            {priceInfo.perVehicle[vehicle.regNo].discount !== 0 && (
              <JustifiedRow>
                <StyledText alignSelf="start" weight="normal">
                  {t('payment:discount')}
                </StyledText>
                <StyledText alignSelf="end" weight="bold">
                  {t('common:priceAndCurrency', { price: priceInfo.perVehicle[vehicle.regNo].discount })}
                </StyledText>
              </JustifiedRow>
            )}
          </React.Fragment>
        )
      },
    )
  }

  return (
    <StyledSummaryCard>
      <Heading level={2} margin={{ bottom: 'large' }} size="large">
        {title}
      </Heading>
      <Grid columns={6}>
        <Module columns={6} mdColumns={3}>
          <StationAndDate>
            <Paragraph>
              {/*i18n.t('common:opus')*/} {stationName}
            </Paragraph>
            <Paragraph>{date}</Paragraph>
          </StationAndDate>
        </Module>
      </Grid>
      <Line />
      <Grid columns={6}>
        <Module columns={6} mdColumns={3}>
          {renderVehicleWithProducts()}
          {driveInMyCar && (
            <JustifiedRow margin>
              <Heading level={2} margin={{ top: 'none' }} size="small">
                {t('driveInMyCar', { ns: 'vehiclesAndProducts' })}
              </Heading>
              <StyledText alignSelf="end" weight="bold">
                {t('common:yes', { ns: 'common' })}
              </StyledText>
            </JustifiedRow>
          )}

          <Line />
          <JustifiedRow>
            <Heading>{t('common:total')}</Heading>
            <StyledText weight="bold">
              {i18n.t('common:priceAndCurrency', { price: priceInfo.priceWithDiscount })}
            </StyledText>
          </JustifiedRow>
          <JustifiedRow>
            <StyledText color={colors.grayDark} weight="bold">
              {t('payment:vat')}
            </StyledText>
            <StyledText color={colors.grayDark} weight="bold">
              {i18n.t('common:priceAndCurrency', {
                price: toFixedWithOptionalDecimals((bookingStore.amount ?? 0) - bookingStore.amountWithoutVat),
              })}
            </StyledText>
          </JustifiedRow>
          {priceInfo.discount !== 0 && (
            <>
              <JustifiedRow>
                <StyledText color={colors.grayDark} weight="bold">
                  {t('payment:amountWithoutDiscount')}
                </StyledText>
                <StyledText color={colors.grayDark} weight="bold">
                  {i18n.t('common:priceAndCurrency', {
                    price: toFixedWithOptionalDecimals(priceInfo.priceWithoutDiscount),
                  })}
                </StyledText>
              </JustifiedRow>
              <JustifiedRow>
                <StyledText color={colors.grayDark} weight="bold">
                  {t('payment:discount')}
                </StyledText>
                <StyledText color={colors.grayDark} weight="bold">
                  {i18n.t('common:priceAndCurrency', { price: toFixedWithOptionalDecimals(priceInfo.discount) })}
                </StyledText>
              </JustifiedRow>
            </>
          )}
          {showPrepayedAmount && !!prepayedAmount && (
            <JustifiedRow>
              <StyledText color={colors.grayDark} weight="bold">
                {t('payment:prepayed')}
              </StyledText>
              <StyledText color={colors.grayDark} weight="bold">
                {i18n.t('common:priceAndCurrency', { price: prepayedAmount })}
              </StyledText>
            </JustifiedRow>
          )}
          {showStatus && (
            <>
              <JustifiedRowWithMargin>
                <StyledText alignSelf="start" weight="bold">
                  {t('payment:paymentStatus')}: <StyledText>{paymentStatusMessage}</StyledText>
                </StyledText>
              </JustifiedRowWithMargin>
            </>
          )}
        </Module>
        <ImageContainer columns={6} image={image} mdColumns={3}>
          {image}
        </ImageContainer>
      </Grid>
    </StyledSummaryCard>
  )
}

export default SummaryCard
