import React from 'react'
import { ITimeListProps } from './StationsTimePage.interfaces'

import { StyledTimeHeader, StyledTimesSection } from './StationsTimePage.styles'
import { EmptyState, Text, TimePicker } from 'Components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { faCalendarTimes } from '@fortawesome/pro-solid-svg-icons'
import _ from 'lodash'

export const TimeList = (props: ITimeListProps): React.ReactElement => {
  const { onlyBestPrice, bestPrice, selectedTime, onSelectTime, times } = props
  const { t } = useTranslation()

  const timesToShow = onlyBestPrice ? times.filter((t): boolean => t.price === bestPrice) : times
  const highlightBestPrice = _.uniqBy(times, 'price').length > 1

  return times.length ? (
    <>
      <StyledTimeHeader>
        <Text weight="bold">{t('stationsAndTime:time')}</Text>
        <Text weight="bold">{t('stationsAndTime:station')}</Text>
        <Text weight="bold">{t('stationsAndTime:price')}</Text>
      </StyledTimeHeader>

      {timesToShow.map(
        (time): JSX.Element => {
          const selected =
            !!selectedTime && selectedTime.time === time.time && selectedTime.workshiftId === time.workshiftId
          const isBestPrice = time.price === bestPrice

          return (
            <StyledTimesSection key={time.hash}>
              <TimePicker
                discount={time.discount}
                hash={time.hash}
                isBestPrice={highlightBestPrice && isBestPrice}
                price={time.price}
                roundedTime={time.roundedTime}
                selected={selected}
                station={time.stationName}
                stationId={time.stationId}
                time={time.time}
                workshiftId={time.workshiftId}
                onSelectTime={onSelectTime}
              />
            </StyledTimesSection>
          )
        },
      )}
    </>
  ) : (
    <EmptyState
      description={t('stationsAndTime:noAvailableTimes')}
      icon={<FontAwesomeIcon icon={faCalendarTimes} />}
      title={t('stationsAndTime:noTimesFound')}
    />
  )
}
