import { styled } from 'Theme'

export const StyledEmptyState = styled.div`
  margin: ${({ theme }): string => theme.emptyState.margin};
  background: ${({ theme }): string => theme.emptyState.background};
  color: ${({ theme }): string => theme.emptyState.color};
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledIcon = styled.div`
  font-size: ${({ theme }): string => theme.emptyState.icon.fontSize};
  margin-bottom: 1rem;
`

export const StyledTitle = styled.h1`
  margin-bottom: 0.5rem;
  font-size: ${({ theme }): string => theme.emptyState.title.fontSize};
  font-weight: ${({ theme }): string => theme.emptyState.title.fontWeight};
`

export const StyledDescription = styled.p`
  font-size: ${({ theme }): string => theme.emptyState.description.fontSize};
`
