import * as React from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useStores } from 'Utils'
import { Checkbox, List, Modal } from 'Components'
import { faArrowDown, faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { parseTemplateString } from 'Utils'
import { IProduct } from 'Models/Products'

import { ExternalProductLink } from './ProductListLink'
import { IProductListProps } from './ProductList.interfaces'
import { IndentedListItem, ProductInfo, ShowAllProductsButton, StyledProductList } from './ProductList.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getProductsToRender } from './utils'
import { observer } from 'mobx-react'

type ProductItemProps = {
  id: string
  name: string
  description: string
  product?: IProduct
  isSelected: boolean
  onClick: (product?: IProduct) => void
  indented?: boolean
}

type DriveInMyCarWrapperProps = Omit<ProductItemProps, 'indented' | 'product' | 'id'> & {
  enableDriveInMyCar: boolean
  product: IProduct
}

function ProductItem(props: ProductItemProps) {
  const { product, isSelected, onClick, name, description, indented = false } = props
  const { deadline } = product ?? {}
  const { t } = useTranslation()

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation()
      if (event.currentTarget.localName === 'li') onClick(product)
    },
    [product, onClick],
  )

  return (
    <IndentedListItem indented={indented} onClick={handleClick}>
      <List.Item.Left>
        <Checkbox checked={isSelected} />
      </List.Item.Left>
      <List.Item.Title>{name}</List.Item.Title>
      {product?.isDeadlineAfterNow && (
        <List.Item.Description>{t('validThru', { deadline, ns: 'vehiclesAndProducts' })}</List.Item.Description>
      )}
      <List.Item.Right>
        <ProductInfo onClick={handleClick}>
          <Modal header={name} triggerComponent={<FontAwesomeIcon icon={faInfoCircle} />}>
            {parseTemplateString(description, undefined, ExternalProductLink)}
          </Modal>
        </ProductInfo>
      </List.Item.Right>
    </IndentedListItem>
  )
}

const DriveInMyCarWapper = observer(function DriveInMyCarWrapper(props: DriveInMyCarWrapperProps) {
  const { product } = props
  const { t } = useTranslation('vehiclesAndProducts')
  const { productsStore } = useStores()

  const items = [<ProductItem key={product.id} {...props} id={String(product.id)} />]

  const toggleDriveInCar = useCallback(() => {
    productsStore.toggleDriveInMyCar()
  }, [productsStore])

  if (props.isSelected && product.offerDriveInMyCar) {
    const id = `${product.id}-drive-in-my-car`
    items.push(
      <ProductItem
        key={id}
        indented
        description={product.offerDriveInMyCarDescription}
        id={id}
        isSelected={productsStore.driveInMyCar}
        name={t('driveInMyCar')}
        onClick={toggleDriveInCar}
      />,
    )
  }

  return <>{items}</>
})

export const ProductList = observer(function ProductList(props: IProductListProps): JSX.Element {
  const { regNo, showDriveInMyCar } = props
  const { productsStore } = useStores()
  const { t } = useTranslation()

  const products = getProductsToRender(productsStore, regNo)
  const selectedProducts = productsStore.selectedProducts.get(regNo)
  const noSelectedProducts = !!selectedProducts?.length

  const isSelected = useCallback((id: number) => selectedProducts?.includes(id) ?? false, [selectedProducts])

  const handleClickProduct = useCallback(
    (product?: IProduct) => {
      if (!product) return
      const { id } = product
      // If not included, add it to the array
      let newArrayOfSelectedProducts: number[]

      if (selectedProducts && !selectedProducts.includes(id)) {
        /** New array of `selectedProducts` which adds the new product item. */
        newArrayOfSelectedProducts = [...selectedProducts, id]
      } else {
        /** New array of `selectedProducts` without the selected product */
        newArrayOfSelectedProducts = selectedProducts
          ? selectedProducts.filter((product): boolean => product !== id)
          : []
      }

      // Uppdatera selectedProducts med de nya valen. set() returnerar en ny map.
      productsStore.setSelectedProductsOnRegNo(regNo, newArrayOfSelectedProducts)
    },
    [productsStore, regNo, selectedProducts],
  )

  const handleClearAllProductsClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      productsStore.setSelectedProductsOnRegNo(regNo, [])
    },
    [productsStore, regNo],
  )

  if (products.length === 0) {
    return (
      <StyledProductList>
        <List.Item>
          <p>{t('noProducts', { ns: 'vehiclesAndProducts' })}</p>
        </List.Item>
      </StyledProductList>
    )
  }

  return (
    <StyledProductList>
      {products.map(product => (
        <DriveInMyCarWapper
          key={product.id}
          description={product.description}
          enableDriveInMyCar={showDriveInMyCar}
          isSelected={isSelected(product.id)}
          name={product.name}
          product={product}
          onClick={handleClickProduct}
        />
      ))}
      {noSelectedProducts && (
        <List.Item>
          <ShowAllProductsButton
            icon={<FontAwesomeIcon icon={faArrowDown} />}
            iconRight={true}
            title={t('clearSelectedProducts', { ns: 'vehiclesAndProducts' })}
            onClick={handleClearAllProductsClick}
          />
        </List.Item>
      )}
    </StyledProductList>
  )
})

export default ProductList
