import * as React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'

/* Import components here */
import { Title } from './LoadingPage.styles'
import { DumbPage, Grid, Module } from 'Components'

export const LoadingPage: React.FC = () => (
  <DumbPage hideFooter={true} hideHeader={true} hideNavigation={true}>
    <Grid>
      <Module style={{ textAlign: 'center' }}>
        <Title>Opus</Title>
        <FontAwesomeIcon icon={faSpinnerThird} size="4x" spin={true} />
      </Module>
    </Grid>
  </DumbPage>
)

export default LoadingPage
