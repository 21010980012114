export enum VehicleType {
  /** Lätt buss */
  LBUSS = 1,

  /** Lätt husbil */
  LHB = 2,

  /** Lätt husvagn */
  HV = 3,

  /** Lätt lastbil */
  LLB = 4,

  /** MC */
  MC = 5,

  /** Moped */
  MOPED = 6,

  /** Lätt motorredskap */
  LMRED = 7,

  /** Lätt personbil */
  LPB = 8,

  /** Lätt släp */
  LSLÄP = 9,

  /** Lätt TGHJUL (TERRÄNGHJULING) */
  LTGHJUL = 10,

  /** Lätt TGSK (TERRÄNGSKOTER) */
  LTGSK = 11,

  /** Lätt TGSL (TERRÄNGSLÄP) */
  LTGSL = 12,

  /** Lätt TGSNÖ (SNÖSKOTER) */
  TGSNÖ = 13,

  /** Lätt TGV (TERRÄNGVAGN) */
  LTGV = 14,

  /** Lätt traktor */
  LTR = 15,

  /** Tung buss */
  TBUSS = 16,

  /** Tung husbil */
  THB = 17,

  /** Tung husvagn */
  THV = 18,

  /** Tung lastbil */
  TLB = 19,

  /** Tungt motorredskap */
  TMRED = 20,

  /** Tung personbil */
  TPB = 21,

  /** Tungt släp */
  TSLÄP = 22,

  /** Tung TGHJUL */
  TTGHJUL = 23,

  /** Tung TGSK */
  TTGSK = 24,

  /** Tung TGSL */
  TTGSL = 25,

  /** Tung TGV */
  TTGV = 26,

  /** Tung TTR */
  TTR = 27,
}
