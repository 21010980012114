import { darken } from 'polished'

import { styled } from 'Theme'

export const StyledAlert = styled.div<{ centered: boolean }>`
  margin: 0.5rem 0;
  padding: 0.75rem 1rem;

  border: 1px solid transparent;
  border-radius: 0.25rem;

  text-align: ${({ centered }): string => (centered ? 'center' : 'inherit')};

  &.error {
    background-color: ${({ theme }): string => theme.alert.error.background};
    border-color: ${({ theme }): string => darken('0.1', theme.alert.error.background)};

    &.bordered {
      background-color: transparent;
      color: ${({ theme }): string => theme.alert.error.background};
    }
  }
  &.info {
    background-color: ${({ theme }): string => theme.alert.info.background};
    border-color: ${({ theme }): string => darken('0.1', theme.alert.info.background)};

    &.bordered {
      background-color: transparent;
      color: ${({ theme }): string => theme.alert.info.background};
    }
  }
  &.success {
    background-color: ${({ theme }): string => theme.alert.success.background};
    border-color: ${({ theme }): string => darken('0.1', theme.alert.success.background)};

    &.bordered {
      background-color: transparent;
      color: ${({ theme }): string => theme.alert.success.background};
    }
  }
  &.warning {
    background-color: ${({ theme }): string => theme.alert.warning.background};
    border-color: ${({ theme }): string => darken('0.1', theme.alert.warning.background)};

    &.bordered {
      background-color: transparent;
      color: ${({ theme }): string => theme.alert.warning.background};
    }
  }
`
