import { Card } from 'Components'
import { styled } from 'Theme'

export const StyledTimeHeader = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 0.75rem;
`

export const StyledTimesSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

export const SwitchButton = styled.div`
  margin-top: -3rem;
  margin-bottom: 2rem;
  float: right;
  cursor: pointer;
  padding: 5px 8px;
  background: #00968f;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 600;
  transition: background-color 0.2s ease-out;

  &:hover {
    background: #12a49e;
    transition: background-color 0.2s ease-out;
  }
`

export const StyledCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0.5rem 0;
  padding: 0.5rem;
`

export const StyledSelectedTime = styled.span`
  display: flex;
  justify-content: center;
`

export const Hideable = styled.div<{ show: boolean }>`
  visibility: ${({ show }): string => (show ? 'visible' : 'hidden')};
`
