export enum StatusCode {
  /** Okänd */
  Unknown = 0,
  /** I trafik */
  InTraffic = 1,
  /** Avställd */
  OffRoad = 2,
  /** Avregistrerad */
  Deregistered = 3,
}
