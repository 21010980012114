import * as React from 'react'

/* Import components here */
import { StyledText } from './Text.styles'

/* Import interfaces here */
import { ITextProps } from './Text.interfaces'

/* Import utilities here */

export const Text: React.FC<ITextProps> = ({
  alignSelf = 'auto',
  size = 'medium',
  tag = 'span',
  textAlign = 'start',
  weight = 'normal',
  children,
  ...props
}) => {
  return (
    <StyledText alignSelf={alignSelf} fontWeight={weight} size={size} tag={tag} textAlign={textAlign} {...props}>
      {children}
    </StyledText>
  )
}

export default Text
