import * as React from 'react'

import classNames from 'classnames'

/* Import components here */
import { StyledHeader, StyledInnerTab, StyledTabItem } from './TabItem.styles'

/* Import interfaces here */
import { ITabItemProps } from './TabItem.interfaces'

/* Import utilities here */

const TabItem: React.FC<ITabItemProps> = ({ activeTabKey: activeTab, disabled, header, onChangeTab, tabKey: key }) => {
  /** Check if the tab is active */
  const active = activeTab === key

  /** Used to determine if the default tab component should be rendered, or a custom component passed as prop.
   *
   * If the prop is of type `string` the default tab header will be used.
   *
   * If not then the custom componentent will be rendered.
   */
  const isHeaderString = typeof header === 'string'

  /** Manage the tab click. If a `onClick` callback is used on a custom component this is where we will trigger it. */
  const handleClick = (): void => {
    if (!isHeaderString) {
      /** If the header isn't a string it can contain an onClick-function */
      const { onClick } = (header as React.ReactElement).props

      // If it does contain an onClick as a function, run it
      // E.g. header = <Button title="test" onClick={() => { console.log('I am a "side effect"') }}
      if (typeof onClick === 'function') {
        onClick()
      }
    }

    onChangeTab(key)
  }

  return isHeaderString ? (
    <StyledTabItem
      className={classNames(active && 'active', disabled && 'disabled')}
      disabled={disabled}
      role="tab"
      onClick={handleClick}
    >
      <StyledInnerTab>
        <StyledHeader>{header}</StyledHeader>
      </StyledInnerTab>
    </StyledTabItem>
  ) : (
    // If header isn't a string it's a ReactElement. Render it with additional classes and the callback
    React.cloneElement(header as JSX.Element, {
      className: classNames(active && 'active', disabled && 'disabled'),
      onClick: handleClick,
    })
  )
}

export default TabItem
