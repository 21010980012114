import * as React from 'react'

import classNames from 'classnames'

/* Import components here */
import { Price, StyledTimePicker, Time } from './TimePicker.styles'

/* Import interfaces here */
import { ITimeProps } from './TimePicker.interfaces'

export const TimePicker: React.FunctionComponent<ITimeProps> = ({
  discount,
  price,
  roundedTime,
  station,
  time,
  selected,
  workshiftId,
  onSelectTime,
  stationId,
  hash,
  isBestPrice,
  ...props
}) => {
  const onClick = (): void => onSelectTime(time, roundedTime, workshiftId, stationId, hash)

  return (
    <StyledTimePicker className={classNames(selected && 'selected')} onClick={onClick} {...props}>
      <Time>{roundedTime.substr(11, 5)}</Time>
      <span>{station}</span>
      <Price isBestPrice={!!isBestPrice}>
        {price} SEK {discount !== 0 && '*'}
      </Price>
    </StyledTimePicker>
  )
}

export default TimePicker
