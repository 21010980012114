import * as React from 'react'

/* Import components here */
import { Grid, Module } from 'Components'

import { IErrorPageProps } from './ErrorPage.interfaces'

export const ErrorPage: React.FC<IErrorPageProps> = () => {
  // Eftersom denna komponent används i App om det blir fel med rootStore kan vi inte inject:a någonting här
  // Om man vill navigera, använd länkar från React Router, uiStore är smart nog att uppdatera sig själv
  return (
    <Grid columns={8}>
      <Module columns={5} startColumn={3} xlColumns={3}>
        <h1>Error</h1>
      </Module>
    </Grid>
  )
}
