import { API_DEPRECATED } from '.'

import {
  IAvailableProducts,
  IAvailableTimeOverview,
  IBookingDto,
  IBookingTime,
  ICancelInfo,
  ICloneBookingDto,
  IContentResult,
  ICreateBookingDto,
  IFutureBookingInfo,
  IPayBookingDto,
  IPaymentInstructions,
  IPaymentOption,
  ISelectTime,
  ISetCampaignCodeDto,
  ISetProducts,
  IStationDto,
} from './interfaces'

/* INTERFACES */

export interface IBookingsEndpoints {
  /**
   * This endpoint retrieves a booking by booking number. The result can be a completed booking, a confirmed future booking or a ongoing temporary booking.
   *
   * GET /api/bookings/{bookingNumber}
   */
  getBooking(bookingNumber: string): Promise<IBookingDto>

  /**
   * This endpoint cancels a confirmed booking
   *
   * DELETE /api/bookings/{bookingNumber}
   */
  deleteCancelBooking(
    bookingNumber: string,
    queryParams: {
      bankAccountInfo?: string
    },
  ): Promise<true>

  /**
   * This endpoint retrieves a booking by sso key. The result can be a completed booking, a confirmed future booking or a ongoing temporary booking.
   *
   * GET /api/bookings/sso/{key}
   */
  getBookingFromSsoKey(key: string): Promise<IBookingDto>

  /**
   * This endpoint tries to create a new temporary booking, to be filled in with future request.
   * If a temporary booking already exist, that booking will be returned.
   * A temporary booking has a lifespan of about 30 minutes. That counter is reset with every action made to the booking
   *
   * POST /api/bookings
   */
  postCreateBooking(bodyParams: ICreateBookingDto): Promise<IBookingDto>

  /**
   * Gets dates and status of future bookings of the current user
   *
   * GET /api/bookings
   */
  get(): Promise<IFutureBookingInfo[]>

  /**
   * This endpoint retrieves information about the consequences of cancelling the booking
   *
   * GET /api/bookings/{bookingNumber}/cancelInfo
   */
  getCancelBookingInfo(bookingNumber: string): Promise<ICancelInfo>

  /**
   * This endpoint is responsible for retrieving available payment options for the booking with the given booking number
   *
   * GET /api/bookings/{bookingNumber}/payment
   */
  getPaymentOptions(bookingNumber: string): Promise<IPaymentOption[]>

  /**
   * This endpoint is responsible for opening up payment for the booking with the given booking number
   *
   * POST /api/bookings/{bookingNumber}/payment
   */
  postPayBooking(bookingNumber: string, bodyParams: IPayBookingDto): Promise<IPaymentInstructions>

  /**
   * This endpoint is to be called when a payment failure occured on a booking
   *
   * POST /api/bookings/{bookingNumber}/payment/failed
   */
  postPaymentFailed(
    bookingNumber: string,
    queryParams: {
      orderId?: string
    },
  ): Promise<IBookingDto>

  /**
   * This endpoint is responsible for updating the payment for the booking with the given booking number
   *
   * PUT /api/bookings/{bookingNumber}/payment/{orderId}
   */
  putUpdatePayBooking(bookingNumber: string, orderId: string): Promise<string>

  /**
  * This endpoint initializes a rebooking scenario of a already existing ordinary booking.
It make a temporary copy of the ordinary booking that can me manipulated and later merged in to the original.
  *
  * POST /api/bookings/{bookingNumber}/rebook
  */
  postCloneBooking(bookingNumber: string, bodyParams?: ICloneBookingDto): Promise<IBookingDto>

  /**
   * This endpoint deletes a uncompleted booking
   *
   * POST /api/bookings/{bookingNumber}/exit
   */
  postExitBooking(bookingNumber: string): Promise<true>

  /**
   * This endpoint created ans returns a *.ics file with calendar information about the booking
   *
   * GET /api/bookings/{bookingNumber}/iCal
   */
  getICal(bookingNumber: string): Promise<string>

  /**
   * Gets the bookings on a specific vehicle both temporary and ordinary
   *
   * GET /api/bookings/vehicles/{regno}
   */
  getVehicle(
    regno: string,
    queryParams: {
      ignorePassedBookings?: boolean
      includeUncompleted?: boolean
    },
  ): Promise<IBookingDto[]>

  /**
   * The get available products for booking.
   *
   * GET /api/bookings/{bookingNumber}/vehicles/{regno}/availableProducts
   */
  getAvailableProductsForBooking(bookingNumber: string, regno: string): Promise<IAvailableProducts>

  /**
   * This endpoint is used for setting the products for the given booking
   *
   * PUT /api/bookings/{bookingNumber}/products
   */
  putSetProducts(bookingNumber: string, bodyParams: ISetProducts): Promise<IBookingDto>

  /**
   * The get stations for booking.
   *
   * GET /api/bookings/{bookingNumber}/stations
   */
  getSuggestedStationsForBooking(
    bookingNumber: string,
    queryParams: {
      lat?: number
      lon?: number
      radius?: number
    },
  ): Promise<IStationDto[]>

  /**
   * This endpoint retrieves the available times for the booking based on the given search parameters
   *
   * GET /api/bookings/{bookingNumber}/availableTimes
   */
  getAvailableTimesForBooking(
    bookingNumber: string,
    queryParams: {
      stationIds: number[]
      start: string
      end: string
    },
  ): Promise<IBookingTime[]>

  /**
   * This endpoint selects a time that is previously retrieved with the [GET bookingNumber}/availableTimes]
   *
   * PUT /api/bookings/{bookingNumber}/availableTimes
   */
  putSetTimeForBooking(bookingNumber: string, bodyParams: ISelectTime): Promise<IBookingDto>

  /**
   * This endpoint retrieves the available times for the booking based on the given search parameters
   *
   * GET /api/bookings/{bookingNumber}/availableTimesOverview
   */
  getAvailableTimesOverviewForBooking(
    bookingNumber: string,
    queryParams: {
      stationIds: number[]
      start: string
      end: string
    },
  ): Promise<IAvailableTimeOverview[]>

  /**
   * This endpoint keeps the time that is currently on the booking, but recalculates the price
   *
   * PUT /api/bookings/{bookingNumber}/keepTime
   */
  putKeepTimeForBooking(bookingNumber: string): Promise<IBookingDto>

  /**
   * This endpoint is used to get the klarna confirmation page snippet
   *
   * GET /api/bookings/{bookingNumber}/klarnaConfirmation/{orderId}
   */
  getKlarnaConfirmation(orderId: string, bookingNumber: string): Promise<string>

  /**
   * This endpoint checks if a given campaign code is generally valid
   *
   * GET /api/bookings/campaignCode
   */
  getIsCampaignCodeValid(queryParams: { campaignCode: string }): Promise<boolean>

  /**
   * This endpoints attempts to att a campaign code to a booking
   *
   * PUT /api/bookings/{bookingNumber}/campaignCode
   */
  putSetCampaignCode(bookingNumber: string, bodyParams?: ISetCampaignCodeDto): Promise<IBookingDto>

  /**
   * This endpoint sends an extra confirmation email to the provided email when confirming the booking
   *
   * GET /api/bookings/{bookingNumber}/confirmationPrintOut
   */
  getConfirmationPrintOut(bookingNumber: string): Promise<IContentResult>

  /**
   * This endpoint sends an extra confirmation email to the provided email when confirming the booking
   *
   * POST /api/bookings/{bookingNumber}/sendConfirmation
   */
  postSendConfirmation(bookingNumber: string): Promise<string>

  /**
   * This endpoint extends the life time of the temporary booking
   *
   * PUT /api/bookings/{bookingNumber}/updateSession
   */
  putUpdateSession(bookingNumber: string): Promise<string>
}

/* IMPLEMENTATION */

export const BookingsEndpoints: IBookingsEndpoints = {
  getBooking: async function (bookingNumber) {
    const response = await API_DEPRECATED.http.get<IBookingDto>(`/api/bookings/${bookingNumber}`)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  deleteCancelBooking: async function (bookingNumber, queryParams) {
    const response = await API_DEPRECATED.http.delete(`/api/bookings/${bookingNumber}`, queryParams)

    if (response.ok) {
      return response.ok
    } else {
      throw response.originalError
    }
  },

  getBookingFromSsoKey: async function (key) {
    const response = await API_DEPRECATED.http.get<IBookingDto>(`/api/bookings/sso/${key}`)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  postCreateBooking: async function (bodyParams) {
    const response = await API_DEPRECATED.http.post<IBookingDto>(`/api/bookings`, bodyParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  get: async function () {
    const response = await API_DEPRECATED.http.get<IFutureBookingInfo[]>(`/api/bookings`)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getCancelBookingInfo: async function (bookingNumber) {
    const response = await API_DEPRECATED.http.get<ICancelInfo>(`/api/bookings/${bookingNumber}/cancelInfo`)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getPaymentOptions: async function (bookingNumber) {
    const response = await API_DEPRECATED.http.get<IPaymentOption[]>(`/api/bookings/${bookingNumber}/payment`)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  postPayBooking: async function (bookingNumber, bodyParams) {
    const response = await API_DEPRECATED.http.post<IPaymentInstructions>(
      `/api/bookings/${bookingNumber}/payment`,
      bodyParams,
    )

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  postPaymentFailed: async function (bookingNumber, queryParams) {
    const response = await API_DEPRECATED.http.post<IBookingDto>(
      `/api/bookings/${bookingNumber}/payment/failed`,
      queryParams,
    )

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  putUpdatePayBooking: async function (bookingNumber, orderId) {
    const response = await API_DEPRECATED.http.put<string>(`/api/bookings/${bookingNumber}/payment/${orderId}`)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  postCloneBooking: async function (bookingNumber, bodyParams) {
    const response = await API_DEPRECATED.http.post<IBookingDto>(`/api/bookings/${bookingNumber}/rebook`, bodyParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  postExitBooking: async function (bookingNumber) {
    const response = await API_DEPRECATED.http.post(`/api/bookings/${bookingNumber}/exit`)

    if (response.ok) {
      return response.ok
    } else {
      throw response.originalError
    }
  },

  getICal: async function (bookingNumber) {
    const response = await API_DEPRECATED.http.get<string>(`/api/bookings/${bookingNumber}/iCal`)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getVehicle: async function (regno, queryParams) {
    const response = await API_DEPRECATED.http.get<IBookingDto[]>(`/api/bookings/vehicles/${regno}`, queryParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getAvailableProductsForBooking: async function (bookingNumber, regno) {
    const response = await API_DEPRECATED.http.get<IAvailableProducts>(
      `/api/bookings/${bookingNumber}/vehicles/${regno}/availableProducts`,
    )

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  putSetProducts: async function (bookingNumber, bodyParams) {
    const response = await API_DEPRECATED.http.put<IBookingDto>(`/api/bookings/${bookingNumber}/products`, bodyParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getSuggestedStationsForBooking: async function (bookingNumber, queryParams) {
    const response = await API_DEPRECATED.http.get<IStationDto[]>(
      `/api/bookings/${bookingNumber}/stations`,
      queryParams,
    )

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getAvailableTimesForBooking: async function (bookingNumber, queryParams) {
    const response = await API_DEPRECATED.http.get<IBookingTime[]>(
      `/api/bookings/${bookingNumber}/availableTimes`,
      queryParams,
    )

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  putSetTimeForBooking: async function (bookingNumber, bodyParams) {
    const response = await API_DEPRECATED.http.put<IBookingDto>(
      `/api/bookings/${bookingNumber}/availableTimes`,
      bodyParams,
    )

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getAvailableTimesOverviewForBooking: async function (bookingNumber, queryParams) {
    const response = await API_DEPRECATED.http.get<IAvailableTimeOverview[]>(
      `/api/bookings/${bookingNumber}/availableTimesOverview`,
      queryParams,
    )

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  putKeepTimeForBooking: async function (bookingNumber) {
    const response = await API_DEPRECATED.http.put<IBookingDto>(`/api/bookings/${bookingNumber}/keepTime`)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getKlarnaConfirmation: async function (orderId, bookingNumber) {
    const response = await API_DEPRECATED.http.get<string>(
      `/api/bookings/${bookingNumber}/klarnaConfirmation/${orderId}`,
    )

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getIsCampaignCodeValid: async function (queryParams) {
    const response = await API_DEPRECATED.http.get<boolean>(`/api/bookings/campaignCode`, queryParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  putSetCampaignCode: async function (bookingNumber, bodyParams) {
    const response = await API_DEPRECATED.http.put<IBookingDto>(
      `/api/bookings/${bookingNumber}/campaignCode`,
      bodyParams,
    )

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  getConfirmationPrintOut: async function (bookingNumber) {
    const response = await API_DEPRECATED.http.get<IContentResult>(
      `/api/bookings/${bookingNumber}/confirmationPrintOut`,
    )

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  postSendConfirmation: async function (bookingNumber) {
    const response = await API_DEPRECATED.http.post<string>(`/api/bookings/${bookingNumber}/sendConfirmation`)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  putUpdateSession: async function (bookingNumber) {
    const response = await API_DEPRECATED.http.put<string>(`/api/bookings/${bookingNumber}/updateSession`)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },
}
