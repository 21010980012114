import { colors } from './colors'

interface INotificationType {
  /** Background color of the notification */
  background: string
  /** Color of both title and description */
  color: string
}

export interface INotification {
  error: INotificationType
  /** Color of the icon */
  icon: string
  info: INotificationType
  success: INotificationType
  warning: INotificationType
}

export const notification: INotification = {
  error: {
    background: colors.red,
    color: colors.white,
  },
  icon: colors.white,
  info: {
    background: colors.blue,
    color: colors.white,
  },
  success: {
    background: colors.green,
    color: colors.white,
  },
  warning: {
    background: colors.orange,
    color: colors.white,
  },
}
