import { MobXProviderContext } from 'mobx-react'
import { useContext, useEffect, useRef, useState } from 'react'

import { IRootStore } from 'Models/RootStore'

const RETRY_DELAY = 5000

type TFnPromise<T> = () => Promise<T>
type THandlerFn = () => void

/**
 * A hook that simplifies the usage of stores in function components
 * Usage:
 *   const { bookingStore, productStore } = useStore()
 */
export const useStores = (): IRootStore => {
  return useContext(MobXProviderContext)
}

/**
 * A hook to execute a function after a set time
 * @param onTimeout - function to be executed on timeout
 * @param timeout - timeout in milliseconds. If this is null, no timeout will be
 *                  set.
 */
export const useTimeout = (onTimeout: THandlerFn, timeout: number | null, label = ''): void => {
  const callbackFn = useRef<THandlerFn>()

  // Update callback function if it changes
  useEffect((): void => {
    callbackFn.current = onTimeout
  }, [onTimeout])

  // Setup timout
  useEffect((): (() => void) | void => {
    function onTimeout(): void {
      callbackFn.current && callbackFn.current()
    }

    let timerId: number | undefined
    if (timeout !== null) {
      timerId = setTimeout(onTimeout, timeout)
      // console.log(`[${label}] set timeout in ${timeout} ms (id: ${timerId})`)

      return (): void => {
        clearTimeout(timerId)
        // console.log(`[${label}]clear timeout with id: ${timerId}`)
      }
    }
  }, [timeout, label])
}

/**
 * A hook to schedule a function at a specific time.
 * @remarks If scheduled time is in the past, nothing is scheduled
 * @param handler - function to be executed on scheduled time
 * @param atTime - Scheduled time to execute function. (In ms from the unix epoch,
 *             same as getTime returns)
 */
export const useScheduled = (handler: THandlerFn, at: Date | null, label = ''): void => {
  const callbackFn = useRef<THandlerFn>()
  const atTime = at && at.getTime()

  // Update handler function
  useEffect((): void => {
    callbackFn.current = handler
  }, [handler])

  // Initialize timer
  useEffect((): void | (() => void) => {
    const onTimeout = (): void => {
      callbackFn.current && callbackFn.current()
    }
    const now = new Date()

    let timeout = atTime && atTime - now.getTime()
    if (timeout && timeout < 0) {
      timeout = null
      // console.warn(`[${label}] Scheduled time at ${atTime} has already passes, so skipping this`)
    }

    if (timeout) {
      const timerId = setTimeout(onTimeout, timeout)
      // console.log(`[${label}]scheduled timeout in ${timeout} milliseconds at ${atTime} with id ${timerId}`)

      return (): void => {
        clearTimeout(timerId)
        // console.log(`[${label}] cleared scheduled timeout with id ${timerId}`)
      }
    }
  }, [atTime, label])
}

/**
 * A react hook for setting up an interval
 * @param handler - Function to execute on interval
 * @param interval - interval in milliseconds
 */
export const useInterval = (handler: THandlerFn, interval: number | null): void => {
  const callbackFn = useRef<THandlerFn>()

  // Update callback function
  useEffect((): void => {
    callbackFn.current = handler
  }, [handler])

  // Setup interval
  useEffect((): (() => void) | void => {
    const tick = (): void => {
      callbackFn.current && callbackFn.current()
    }

    let timerId: number
    if (interval) {
      timerId = setInterval(tick, interval)
      // console.log(`Start interval with id ${timerId}`)

      return (): void => {
        clearInterval(timerId)
        // console.log(`clear interval with id ${timerId}`)
      }
    }
  }, [interval])
}

/**
 * A hook, executing a callback function. If the function fails, it tries again
 * @param fn - callback function to execute until it succeeds. It should return a promise.
 *             If the promise is rejected, the function will be exucuted again
 * @param attempts - number of attempts
 * @param deps - list of dependencies used in callback. If any of the dependencies are changed
 *               new attempts are made
 */
export const useRetryOnFail = <T extends unknown | void>(
  fn: TFnPromise<T>,
  attemts: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps: any[] = [],
): [boolean, Error | null] => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const callback = useRef<TFnPromise<T>>()
  const retriesLeft = useRef<number>()

  useEffect((): void => {
    callback.current = fn
  }, [fn])

  useEffect((): (() => void) => {
    const timers: number[] = []
    const runCallback = (): void => {
      if (!callback.current) return

      setIsLoading(true)
      callback
        .current()
        .then((): void => setIsLoading(false))
        .catch((e): void => {
          retriesLeft.current = retriesLeft.current === undefined ? attemts - 1 : retriesLeft.current - 1

          if (retriesLeft.current > 0) {
            timers.push(setTimeout(runCallback, RETRY_DELAY))
          } else {
            setError(e)
            setIsLoading(false)
          }
        })
    }
    runCallback()

    return (): void => {
      // Clear any pending timeouts (regardless if they have fired or not)
      timers.map(clearTimeout)
      retriesLeft.current = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading, setError, attemts, ...deps])

  return [isLoading, error]
}

const createIntelecomEvent = (): CustomEvent => {
  const eventName = 'intelecomfixpos'

  try {
    return new CustomEvent(eventName)
  } catch {
    // CustomEvent cannot be used as a constructor in IE11
    const event = document.createEvent('CustomEvent')
    event.initCustomEvent(eventName, false, false, null)
    return event
  }
}

/**
 * A hook, telling intelecom script to update position
 */
export const useIntelecomPositionUpdate = (): void => {
  useEffect((): void => {
    const event = createIntelecomEvent()
    document.dispatchEvent(event)
  })
}
