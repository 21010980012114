import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

/* Import components here */
import {
  AddressLine,
  ButtonLine,
  CardHeader,
  CardWithMargin,
  StationAddress,
  StationImage,
  StationImageLine,
  StationName,
} from './StationCard.styles'
import { Button } from 'Components'

/* Import interfaces here */
import { IStationCardProps } from './StationCard.interfaces'

/* Import utilities here */
import { getDistanceFromLatLonInKm, getPosition } from 'Utils'

export const StationCard: React.FunctionComponent<IStationCardProps> = ({ station }) => {
  const { t } = useTranslation('summary')
  const { city, name, streetAddress, zipCode, imageUrl, stationUrl } = station
  const [distance, setDistance] = React.useState<number>()

  const onClick = (): void => {
    window.open(stationUrl, '_blank')
  }

  const updateDistance = async (): Promise<void> => {
    const pos = await getPosition()

    if (pos) {
      const dist = getDistanceFromLatLonInKm(pos.latitude, pos.longitude, station.latitude, station.longitude)
      setDistance(Math.round(dist))
    }
  }

  useEffect((): void => {
    updateDistance()
  })

  return (
    <CardWithMargin>
      <CardHeader>
        <StationName>{name}</StationName>
        {distance && (
          <AddressLine>
            {t('summary:stationcardDistance', {
              distance: distance,
            })}
          </AddressLine>
        )}
      </CardHeader>
      <StationImageLine>
        <StationImage src={imageUrl} />
      </StationImageLine>
      <StationAddress>
        <AddressLine>{streetAddress}</AddressLine>
        <AddressLine>
          {zipCode} {city}
        </AddressLine>
      </StationAddress>
      <ButtonLine>
        <Button title={t('summary:moreInfo')} variant="secondary" onClick={onClick} />
      </ButtonLine>
    </CardWithMargin>
  )
}

export default StationCard
