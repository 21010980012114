import { colors } from './colors'

export interface ILoadingIndicator {
  background: string
  borderRadius: string
  color: string
  overlayBackground: string
  overlayOpacity: string
  padding: string
}

export const loadingIndicator: ILoadingIndicator = {
  background: colors.primaryLight,
  borderRadius: '0.25rem',
  color: colors.white,
  overlayBackground: colors.primaryDark,
  overlayOpacity: '0.3',
  padding: '1rem',
}
