import * as React from 'react'

import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'

/* Import components here */
import { Button, ButtonGroup, Card, Input } from 'Components'

/* Import interfaces here */
import { IRebookCardProps } from './RebookCard.interfaces'

export const RebookCard: React.FunctionComponent<IRebookCardProps> = ({ onCancel, onSubmit }) => {
  const [input, setInput] = React.useState<string>('')
  const [isValid, setIsValid] = React.useState<boolean>(false)
  const [submitting, setSubmitting] = React.useState(false)

  const { t } = useTranslation('common')

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase()
    if (value.length > 0) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }

    setInput(value)
  }

  const handleSubmit = async (): Promise<void> => {
    try {
      if (isValid) {
        setSubmitting(true)
        await onSubmit(input)
      }
    } finally {
      setSubmitting(false)
    }
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter' && isValid) {
      handleSubmit()
    }
  }

  return (
    <Card>
      <Input label={t('summary:rebookMessage')} value={input} onChange={handleInput} onKeyDown={onKeyDown} />
      <ButtonGroup>
        <Button title={t('cancel')} type="button" variant="secondary" onClick={onCancel} />
        <Button
          disabled={!isValid || submitting}
          icon={submitting && <FontAwesomeIcon icon={faSpinnerThird} spin={true} />}
          title={t('summary:rebook')}
          type="submit"
          variant="accent"
          onClick={handleSubmit}
        />
      </ButtonGroup>
    </Card>
  )
}

export default RebookCard
