import * as React from 'react'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'

import { PossibleRootPathValue } from 'Config'
import { ISsoRedirectPageParams } from './SsoRedirectPage.interfaces'
import { useStores } from 'Utils/hooks'
import { UIJump, UINext } from 'Utils/ui'
import { notifyError } from 'Utils/Notifications'
import { LoadingPage } from 'Pages/LoadingPage'
import { BookingStatus } from 'DataTypes'
import { EBookingStep, ETrackingEvent } from '@cdab/opus-api-client'
import { fbTrackEvent } from 'Utils'

/**
 * Redirect to summary page
 */
const JumpToSummary = ({ bookingNumber }): JSX.Element => (
  <UIJump
    params={bookingNumber}
    state={{ hideFooter: true, hideNavigation: true, shouldOverridePreviousPageComplete: true }}
    to={PossibleRootPathValue.summary}
  />
)

/**
 * Redirect to step 2, stations and products
 */
const JumpToStep2 = (): JSX.Element => (
  <UIJump state={{ shouldOverridePreviousPageComplete: true }} to={PossibleRootPathValue.stationsAndTime} />
)

/**
 * Fetch booking from SSO key, and redirect to correct page
 */
const SsoRedirectPage = observer(
  ({ match }: RouteComponentProps<ISsoRedirectPageParams>): JSX.Element => {
    const [isLoading, setIsLoading] = useState(true)
    const [trackingDone, setTrackingDone] = useState(false)
    const { bookingStore, uiStore } = useStores()
    const { i18n } = useTranslation()

    const { ssoKey } = match.params

    const omitStep1 = uiStore.parameters && uiStore.parameters.omitBookingStep1

    const orderIsDone =
      bookingStore.bookingStatus === BookingStatus.NotConfirmed ||
      bookingStore.bookingStatus === BookingStatus.ConfirmedButNotStarted

    useEffect((): void => {
      async function loadBooking(sso: string) {
        try {
          await bookingStore.LoadBookingWithSso(sso)
        } catch (e) {
          notifyError(e, undefined, 'error')
        } finally {
          setIsLoading(false)
        }
      }

      loadBooking(ssoKey)
    }, [bookingStore, i18n, ssoKey])

    useEffect(() => {
      async function track() {
        if (isLoading) return

        if (bookingStore.bookingNumber && !orderIsDone) {
          await fbTrackEvent({
            EventName: ETrackingEvent.SubmitApplication,
            BookingId: bookingStore.bookingNumber,
            CustomData: {
              booking_step: omitStep1 ? EBookingStep.StationsAndTime : EBookingStep.StationsAndTime,
            },
          })
        }

        setTrackingDone(true)
      }

      track()
    }, [isLoading, bookingStore.bookingNumber, omitStep1, orderIsDone])

    // Use observer to re-render this after LoadBookingWithSso is finnished
    if (isLoading || !trackingDone) {
      return <LoadingPage />
    }

    if (orderIsDone) {
      return <JumpToSummary bookingNumber={bookingStore.bookingNumber} />
    }

    if (omitStep1) {
      return <JumpToStep2 />
    }

    return <UINext />
  },
)

export default SsoRedirectPage
