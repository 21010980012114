import { styled } from 'Theme'

export const StyledKlarna = styled.div`
  background-color: white;
  padding: 1rem;
`

export const ErrorMessage = styled.p`
  color: ${({ theme }): string => theme.colors.black};
  font-size: 1.5rem;
`
