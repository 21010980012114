import { colors } from './colors'

export interface IModal {
  background: string
  borderRadius: string
  color: string
  overlayBackground: string
  overlayOpacity: string
  padding: string
}

export const modal: IModal = {
  background: colors.white,
  borderRadius: '0.25rem',
  color: colors.primaryDark,
  overlayBackground: colors.primaryDark,
  overlayOpacity: '0.3',
  padding: '1rem',
}
