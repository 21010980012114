import { EnvironmentVariables } from 'Config/environment'

const getCurrentPosition = async (geolocation: Geolocation): Promise<Coordinates> =>
  new Promise((resolve, reject): void => {
    geolocation.getCurrentPosition(
      (position): void => {
        resolve(position.coords)
      },
      reject,
      {
        maximumAge: EnvironmentVariables.GEOLOCATION_TIMEOUT * 1000,
        timeout: EnvironmentVariables.GEOLOCATION_MAXIMUM_AGE * 1000,
      },
    )
  })

function deg2rad(deg: number): number {
  return deg * (Math.PI / 180)
}

export const getPosition = async (): Promise<Coordinates | undefined> => {
  try {
    if (navigator.geolocation) {
      return await getCurrentPosition(navigator.geolocation)
    }
  } catch (e) {
    return undefined
  }

  return undefined
}

// Source: https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates
export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2): number => {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1) // deg2rad below
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return d
}
