import * as React from 'react'

import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

/* Import components here */
import { IconAndLabel, IconWrapper, Label, Message, StyledCheckbox } from './Checkbox.styles'

/* Import interfaces here */
import { ICheckboxProps } from './Checkbox.interfaces'

// Component
export const Checkbox: React.FC<ICheckboxProps> = ({ checked, label, message, variant, ...props }): JSX.Element => {
  return (
    <StyledCheckbox className={classNames(variant)} {...props}>
      <IconAndLabel>
        <IconWrapper>{checked && <FontAwesomeIcon icon={faCheck} />}</IconWrapper>
        {label && <Label>{label}</Label>}
      </IconAndLabel>
      {!!message && <Message>{message}</Message>}
    </StyledCheckbox>
  )
}

export default Checkbox
