import { IProductsStore } from 'Models'
import { IProduct } from 'Models/Products'
import { distinctArray } from 'Utils'

// TODO: Use common utility function instead
// In set theory, an intersection is the subset of each collection that is found in both collections.
// If one array is empty the total of the other array is returned (i.e. doesn't effect)
const intesectArraysEmptyArrayDoesntEffect = <T extends unknown>(array1: T[], array2: T[]): T[] => {
  // One array empty? Return the other.
  if (array1.length === 0 && array2.length > 0) {
    return array2
  }

  // One array empty? Return the other.
  if (array1.length > 0 && array2.length === 0) {
    return array1
  }

  // Return the intersection.
  return array1.filter((value): boolean => array2.includes(value))
}
function filterProducts(productsStore: IProductsStore, regNo: string): number[] {
  const { products } = productsStore.productsDataForVehicle.get(regNo) || { products: [] }
  const selectedProducts = productsStore.selectedProducts.get(regNo) || []

  let productsToDisplay: number[] = []

  selectedProducts.forEach((selectedProductID): void => {
    const selectedProduct = products.find((product): boolean => product.id === selectedProductID)

    if (selectedProduct) {
      let allowedProductsForSelectedProduct: number[] = []

      if (selectedProduct.relatedProducts.length === 0) {
        products.forEach((product): void => {
          if (product.id === selectedProductID) return

          if (product.relatedProducts.length === 0 || product.relatedProducts.includes(selectedProductID)) {
            allowedProductsForSelectedProduct.push(product.id)
          }
        })
      } else {
        allowedProductsForSelectedProduct = Array.from(selectedProduct.relatedProducts)
      }

      productsToDisplay = intesectArraysEmptyArrayDoesntEffect(productsToDisplay, allowedProductsForSelectedProduct)
    }
  })

  // Lägg till valda produkter i listan som ska visas
  return distinctArray(productsToDisplay.concat(Array.from(selectedProducts)))
}

export function getProductsToRender(productsStore: IProductsStore, regNo: string): IProduct[] {
  const productsForVehicle = productsStore.productsDataForVehicle.get(regNo)
  const products = productsForVehicle ? productsForVehicle.products : []

  // If there are selected products, filter on related products
  if ((productsStore.selectedProducts.get(regNo) || []).length > 0) {
    const productsToDisplay: number[] = filterProducts(productsStore, regNo)

    return products.filter((product): boolean => productsToDisplay.includes(product.id))
  } else {
    // Show all products
    return products
  }
}
