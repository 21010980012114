import { colors } from './colors'

export interface IParagraph {
  color: string
  margin: {
    xxsmall: string
    xsmall: string
    small: string
    medium: string
    large: string
    xlarge: string
    xxlarge: string
  }
  size: {
    small: string
    medium: string
    large: string
    xlarge: string
    xxlarge: string
  }
}

export const paragraph: IParagraph = {
  color: colors.white,
  margin: {
    xxsmall: '0.25rem',
    xsmall: '0.5rem',
    small: '0.75rem',
    medium: '1rem',
    large: '1.25rem',
    xlarge: '1.5rem',
    xxlarge: '1.75rem',
  },
  size: {
    small: '0.875rem',
    medium: '1rem',
    large: '1.25rem',
    xlarge: '1.5rem',
    xxlarge: '2rem',
  },
}
