import { styled } from 'Theme'

import { Button, Page } from 'Components'
import { StyledButton } from 'Components/Generic/Button/Button/Button.styles'
import { PageContent } from 'Components/Opus/Page/Page.styles'
import { StyledSummaryCard } from 'Components/Opus/SummaryCard/SummaryCard.styles'
import { StyledParagraph } from 'Components/Generic/Paragraph/Paragraph.styles'
import { TabHeader } from 'Components/Generic/Tabs/Tabs.styles'

export const StyledPage = styled(Page)`
  ${PageContent} {
    justify-content: center;
    margin-bottom: 1rem;

    ${StyledSummaryCard} {
      margin-bottom: 3rem;
    }

    ${TabHeader} {
      ${StyledButton} {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        background-color: ${({ theme }): string => theme.colors.primaryLight};
        color: ${({ theme }): string => theme.colors.white};

        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &.active {
          background-color: ${({ theme }): string => theme.colors.white};
          color: ${({ theme }): string => theme.colors.primary};
        }

        &:not(.active) {
          border: 1px solid ${({ theme }): string => theme.colors.white};
          border-bottom: none;
        }
      }
    }
  }
`

export const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 3rem;
`

export const ConfirmationButton = styled(Button)`
  margin-top: 1rem;

  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 1rem;

  &.active {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-bottom: 0;
    padding-bottom: 0.5rem;

    span {
      font-size: 1.5rem;
    }
  }
`

export const StyledConfirmation = styled.div`
  ${StyledParagraph} {
    margin-bottom: 1rem;
  }
`
