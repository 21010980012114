import * as React from 'react'

/* Import components here */
import { GlobalModalStyles } from './Modal.styles'
import { ModalTrigger } from './ModalTrigger'
import { ModalContent } from './ModalContent'

/* Import interfaces here */
import { IModalProps } from './Modal.interfaces'

const Modal: React.FC<IModalProps> = ({
  ariaLabel,
  as,
  buttons,
  children,
  header,
  hideDefaultCloseButton = false,
  importantIsOpen = false,
  leftButton,
  message,
  onAfterOpen,
  onRequestClose,
  rightButton,
  role,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = true,
  triggerComponent,
  triggerText,
}) => {
  // FIXME: Få refs att fungera så man kan klicka utanför och autofokusera
  /** References the modal, not the overlay */
  // const modalRef = React.useRef<HTMLDivElement>(null)
  // const closeButtonRef = React.useRef<HTMLButtonElement>(null)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  /**
   * Disable or enable scroll for all content behind the modal.
   */
  const toggleScrollLock = (action: 'remove' | 'add'): void => {
    if (!document) return

    const elem = document.querySelector('html')

    if (!elem) return

    if (action === 'remove') {
      elem.classList.remove('lock-scroll')
    } else if (action === 'add') {
      elem.classList.add('lock-scroll')
    }
  }

  const onClose = () => {
    setIsOpen(false)

    if (onRequestClose) onRequestClose()

    // FIXME: Få refs att fungera så man kan klicka utanför och autofokusera
    // openButtonNode.focus()
    toggleScrollLock('remove')
  }

  React.useEffect(() => {
    return (): void => {
      toggleScrollLock('remove')
    }
  }, [])

  const onClickAway = (): void => {
    if (!shouldCloseOnOverlayClick) return

    // FIXME: Få refs att fungera så man kan klicka utanför och autofokusera
    // If clicking something else than the modal, like the overlay.
    // if (modalRef && modalRef.current) {
    //   if (!modalRef.current.contains(event.target as Node)) onClose()
    // }
  }

  const onOpen = (): void => {
    setIsOpen(true)

    // FIXME: Få refs att fungera så man kan klicka utanför och autofokusera
    // if (closeButtonRef && closeButtonRef.current) {
    //   closeButtonRef.current.focus()
    // }
    toggleScrollLock('add')

    if (onAfterOpen) onAfterOpen()
  }

  const onKeyDown = (event: KeyboardEvent): void | false => {
    if (!shouldCloseOnEsc) return

    return event.keyCode === 27 && onClose()
  }

  /**
   * Controls the visibility of the modal through a prop instead of only when clicking the button
   */
  React.useEffect((): void => {
    if (importantIsOpen !== undefined) setIsOpen(importantIsOpen)
  }, [importantIsOpen])

  return (
    <>
      <GlobalModalStyles />
      {(triggerText || triggerComponent) && (
        <ModalTrigger as={as} triggerComponent={triggerComponent} triggerText={triggerText} onOpen={onOpen} />
      )}
      {isOpen && (
        <ModalContent
          ariaLabel={ariaLabel}
          // buttonRef={(node): void => (closeButtonRef.current = node)} // FIXME: Refs som ovan
          buttons={buttons}
          content={children}
          header={header}
          hideDefaultCloseButton={hideDefaultCloseButton}
          leftButton={leftButton}
          message={message}
          // modalRef={(node): void => (modalRef.current = node)} // FIXME: Refs som ovan
          rightButton={rightButton}
          role={role}
          onClickAway={onClickAway}
          onClose={onClose}
          onKeyDown={onKeyDown}
        />
      )}
    </>
  )
}

export default Modal
