import { styled } from 'Theme'
import { IButtonStyleProps } from './Button.interfaces'
import { FlattenSimpleInterpolation, css } from 'styled-components'

export const StyledButton = styled.button<IButtonStyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 1rem 1.5rem;
  margin: 0;

  font: inherit;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;

  background: ${({ theme }): string => theme.button.background};
  color: ${({ theme }): string => theme.button.color};

  border: 1px solid transparent;
  border-radius: ${({ theme }): string => theme.borderRadius};
  transition: background-color 0.2s ease-out;

  cursor: pointer;
  outline-style: none;

  &:hover,
  &:focus {
    background: ${({ theme }): string => theme.button.variant.primary.hover};
    transition: background-color 0.2s ease-out;
  }

  & {
    cursor: pointer;
  }

  &:hover {
    background: ${({ theme }): string => theme.button.variant.primary.hover};
    transition: background-color 0.2s ease-out;
  }

  &.selected {
    background-color: ${({ theme }): string => theme.button.selected.background};
    color: ${({ theme }): string => theme.button.selected.color};

    svg {
      color: ${({ theme }): string => theme.button.selected.iconColor};
    }
  }

  ${({ transparent, theme }): string =>
    transparent
      ? `
      background: transparent;
      color: ${theme.colors.primary};
    `
      : ''}

  /* TODO: Add bordered type */

  /* TODO: Add styling for disabled for each variant, e.g. secondary, accent etc. */
  &[disabled] {
    background: ${({ theme }): string => theme.button.disabled.background};
    color: ${({ theme }): string => theme.button.disabled.color};
    cursor: default;
  }

  /** break on space or not */
  ${({ nowrap }): string => (nowrap ? 'white-space: nowrap;' : '')}

  /* Fixed width */
  &.full {
    border-left: none;
    border-right: none;
    border-radius: 0;

    width: 100%;
  }

  &.half {
    width: 50%;
  }

  ${({ block }): FlattenSimpleInterpolation | undefined | false =>
    block &&
    css`
      width: 100%;
    `}

  &.rounded {
    border-radius: ${({ theme }): string => theme.button.rounded};
  }

  /* Sizes */
  &.small {
    padding: ${({ theme }): string => theme.button.size.small};
  }

  &.large {
    padding: ${({ theme }): string => theme.button.size.large};
  }

  /* Variants */
  &.accent {
    background: ${({ theme }): string => theme.button.variant.accent.background};
    color: ${({ theme }): string => theme.button.variant.accent.color};

    :hover {
      background: ${({ theme }): string => theme.button.variant.accent.hover};
    }

    &[disabled] {
      background: ${({ theme }): string => theme.button.variant.accent.disabled.background};
      color: ${({ theme }): string => theme.button.variant.accent.disabled.color};
    }
  }

  &.danger {
    background: ${({ theme }): string => theme.button.variant.danger.background};
    color: ${({ theme }): string => theme.button.variant.danger.color};

    border-color: ${({ theme }): string => theme.button.variant.danger.border};
  }

  &.primary {
    background: ${({ theme }): string => theme.button.variant.primary.background};
    color: ${({ theme }): string => theme.button.variant.primary.color};

    :hover {
      background: ${({ theme }): string => theme.button.variant.primary.hover};
    }

    &[disabled] {
      background: ${({ theme }): string => theme.button.variant.primary.disabled.background};
      color: ${({ theme }): string => theme.button.variant.primary.disabled.color};
    }
  }

  &.secondary {
    background: ${({ theme }): string => theme.button.variant.secondary.background};
    color: ${({ theme }): string => theme.button.variant.secondary.color};

    border-color: ${({ theme }): string => theme.button.variant.secondary.border};

    // TODO: Lägg till styling på :hover.
    :hover {
    }
  }

  /* Spacing */
  &.verticalNarrow {
    margin: ${({ theme }): string => theme.spacing.narrow} 0;
  }
  &.verticalWide {
    margin: ${({ theme }): string => theme.spacing.wide} 0;
  }
  &.horizontalNarrow {
    margin: 0 ${({ theme }): string => theme.spacing.narrow};
  }
  &.horizontalWide {
    margin: 0 ${({ theme }): string => theme.spacing.wide};
  }
`

export const TitleAndIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;

    &:only-child {
      margin: 0;
    }
  }

  &.iconRight {
    flex-direction: row-reverse;

    svg {
      margin-right: 0;
      margin-left: 0.5rem;

      &:only-child {
        margin: 0;
      }
    }
  }
`

export const Title = styled.span`
  font-size: ${({ theme }): string => theme.button.title.size};
`

export const Description = styled.span`
  font-size: ${({ theme }): string => theme.button.description.size};
  font-weight: ${({ theme }): string => theme.button.description.fontWeight};

  margin-top: 0.5rem;

  /* If no title/icon is used */
  &:only-child {
    margin-top: 0;
  }
`
