import { API_DEPRECATED } from '.'

import { ILoginDto, IRestorePasswordModel, IUserClass } from './interfaces'

/* INTERFACES */

export interface IAccountEndpoints {
  /**
   * This endpoint checked if the user has a valid authorization token
   *
   * GET /api/account/isLoggedIn
   */
  getIsLoggedIn(): void

  /**
   * This endpoint renews the session of the user
   *
   * POST /api/account/renewSession
   */
  postRenewSession(): void

  /**
   * Performs a login on the user and returns a JWT token to be used in the coming requests
   *
   * POST /api/account/login
   */
  postLogin(bodyParams?: ILoginDto): Promise<IUserClass>

  /**
   * Requests a new password for the customer
   *
   * POST /api/account/requestNewPassword
   */
  postRequestNewPassword(bodyParams: IRestorePasswordModel): Promise<boolean>
}

/* IMPLEMENTATION */

export const AccountEndpoints: IAccountEndpoints = {
  getIsLoggedIn: async function () {
    const response = await API_DEPRECATED.http.get(`/api/account/isLoggedIn`)

    if (response.ok) {
      return response.ok
    } else {
      throw response.originalError
    }
  },

  postRenewSession: async function () {
    const response = await API_DEPRECATED.http.post(`/api/account/renewSession`)

    if (response.ok) {
      return response.ok
    } else {
      throw response.originalError
    }
  },

  postLogin: async function (bodyParams) {
    const response = await API_DEPRECATED.http.post<IUserClass>(`/api/account/login`, bodyParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },

  postRequestNewPassword: async function (bodyParams) {
    const response = await API_DEPRECATED.http.post<boolean>(`/api/account/requestNewPassword`, bodyParams)

    if (response.ok) {
      if (response.data === undefined) throw new Error('No data from server')
      return response.data
    } else {
      throw response.originalError
    }
  },
}
