export enum ProductCode {
  'A' = 'A',
  'A1' = 'A1',
  'A2' = 'A2',
  'A2-' = 'A2-',
  'A2-E' = 'A2-E',
  'A2A' = 'A2A',
  'A2E' = 'A2E',
  'A5' = 'A5',
  'AE' = 'AE',
  'ÅG' = 'ÅG',
  'ÅH' = 'ÅH',
  'ÅMA' = 'ÅMA',
  'ÅMG' = 'ÅMG',
  'ÅMK' = 'ÅMK',
  'ÅML' = 'ÅML',
  'ANDRING' = 'ANDRING',
  'ÅPG' = 'ANDRING',
  'ÅPM' = 'ÅPM',
  'ÅRT' = 'ÅRT',
  'ÅT' = 'ÅT',
  'AU' = 'AU',
  /** Kontrollbesiktning */
  'B' = 'B',
  'BE' = 'BE',
  'BENK' = 'BENK',
  'BILKON' = 'BILKON',
  'BRÅ' = 'BRÅ',
  'BRE' = 'BRE',
  'BSÖK1' = 'BSÖK1',
  'BSÖK2' = 'BSÖK2',
  'BU' = 'BU',
  'C' = 'C',
  'CARDROP' = 'CARDROP',
  'CARSWIPE' = 'CARSWIPE',
  'CO2E' = 'CO2E',
  'D1' = 'D1',
  'D12' = 'D12',
  'D3' = 'D3',
  'D5' = 'D5',
  'D6ÅL' = 'D6ÅL',
  'D6ÅT' = 'D6ÅT',
  'D6T' = 'D6T',
  'D7' = 'D7',
  'D7D' = 'D7D',
  'D7E' = 'D7E',
  'D7S' = 'D7S',
  'D8' = 'D8',
  'D8-' = 'D8-',
  'D8E' = 'D8E',
  'DEK' = 'DEK',
  'DEKS' = 'DEKS',
  'DEN' = 'DEN',
  'DENS' = 'DENS',
  'DIAGAR' = 'DIAGAR',
  'DIAPB' = 'DIAPB',
  'EBE' = 'EBE',
  'EBK' = 'EBK',
  'eD1P' = 'eD1P',
  'EK' = 'EK',
  'ENS' = 'ENS',
  'FB' = 'FB',
  'FBES' = 'FBES',
  'FLE' = 'FLE',
  'FM' = 'FM',
  'FOSK B' = 'FOSK B',
  'FOSK BE' = 'FOSK BE',
  'FTG' = 'FTG',
  'G' = 'G',
  'GASINTYG' = 'GASINTYG',
  'GSL' = 'GSL',
  'HVT' = 'HVT',
  'I' = 'I',
  'IBL' = 'IBL',
  'IBS' = 'IBS',
  'K' = 'K',
  'KAAG' = 'KAAG',
  'KAAT' = 'KAAT',
  'KAMUX' = 'KAMUX',
  'KSLP' = 'KSLP',
  'KSLX' = 'KSLX',
  'KSPP' = 'KSPP',
  'KSVP' = 'KSVP',
  'KSVT' = 'KSVT',
  'LÅ' = 'LÅ',
  'LAÅ' = 'LAÅ',
  'LAS' = 'LAS',
  'LD' = 'LD',
  'LE' = 'LE',
  'LHBT' = 'LHBT',
  'LM' = 'LM',
  'LMK' = 'LMK',
  'LMP' = 'LMP',
  'LP' = 'LP',
  'LPK' = 'LPK',
  'LPS' = 'LPS',
  'LT' = 'LT',
  'LTK' = 'LTK',
  'LTM' = 'LTM',
  'LTR' = 'LTR',
  'LV' = 'LV',
  'LYP' = 'LYP',
  'LYPE' = 'LYPE',
  'MCT' = 'MCT',
  'MO' = 'MO',
  'MODERNA' = 'MODERNA',
  'MP' = 'MP',
  'MPE' = 'MP',
  'NETBIL1' = 'NETBIL1',
  'NETBIL2' = 'NETBIL2',
  'NETBIL3' = 'NETBIL3',
  'O' = 'O',
  'OBT' = 'OBT',
  'OMT' = 'OMT',
  'PROK' = 'PROK',
  'PROV' = 'PROV',
  'PRVW' = 'PRVW',
  'PTÅ' = 'PTÅ',
  'RB' = 'RB',
  'RESA' = 'RESA',
  'RESKS' = 'RESKS',
  'RESKV' = 'RESKV',
  'RETI' = 'RETI',
  'SANTLEAF' = 'SANTLEAF',
  'SANTTEST' = 'SANTTEST',
  'SKAD' = 'SKAD',
  'SKADO' = 'SKADO',
  'SVWP' = 'SVWP',
  'T' = 'T',
  'TÄT' = 'TÄT',
  'TÄTG' = 'TÄTG',
  'TE' = 'TE',
  'TEST' = 'TEST',
  'THBT' = 'THBT',
  'TS' = 'TS',
  'V' = 'V',
  'VE' = 'VE',
  'VT' = 'VT',
}
