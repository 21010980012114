import { Instance, types } from 'mobx-state-tree'
import { IProductDto } from 'Api_DEPRECATED/interfaces'
import { parseISO } from 'date-fns'

export const Product = types
  .model('Product', {
    controlCode: types.string,
    description: types.string,
    id: types.number,
    name: types.string,
    relatedProducts: types.array(types.number),
    deadline: types.maybeNull(types.Date),
    offerDriveInMyCar: false,
    offerDriveInMyCarDescription: '',
  })
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  .views(self => ({
    get isDeadlineAfterNow(): boolean {
      return !!self.deadline && self.deadline > new Date()
    },
  }))

// FIXME: Justera så vi eventuellt inte behöver inaktivera den här regeln
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProduct extends Instance<typeof Product> {}

export const ProductModelFromProductDto = (product: IProductDto): IProduct => {
  return Product.create({
    controlCode: product.ControlCode || 'UNAVAILABLE',
    description: product.Description || 'UNAVAILABLE',
    id: product.Id || 0,
    name: product.Name || 'UNAVAILABLE',
    relatedProducts: product.RelatedProducts || [],
    deadline: product.Deadline ? parseISO(product.Deadline) : null,
    offerDriveInMyCar: product.OfferDriveInMyCar,
    offerDriveInMyCarDescription: product.OfferDriveInMyCarDescription,
  })
}
