import { styled } from 'Theme'

export const StyledFooter = styled.div`
  width: 100vw;

  height: 4rem;

  /* Keep the Footer at the bottom. */
  position: fixed;
  bottom: 0;

  margin-left: calc(100% - 100vw);

  color: ${({ theme }): string => theme.colors.primary};
  background: ${({ theme }): string => theme.colors.primaryLight};
`
