import * as React from 'react'

import classNames from 'classnames'

/* Import components here */
import { StyledCard } from './Card.styles'

/* Import interfaces here */
import { ICardProps } from './Card.interfaces'

/** A much reusable card for any type of card-based interface */
export const Card: React.FC<ICardProps> = ({ spacing, children, ...props }) => {
  return (
    <StyledCard className={classNames(spacing)} {...props}>
      {children}
    </StyledCard>
  )
}

export default Card
