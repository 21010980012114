import { styled } from 'Theme'

import { StyledChip } from 'Components/Generic/Chip/Chip.styles'
import { InputWrapper, StyledInput } from 'Components/Generic/Input/Input.styles'

export const StyledChipInput = styled.div`
  flex-direction: column;
  justify-content: space-around;

  max-width: 100%;

  ${InputWrapper} {
    display: flex;
  }

  ${StyledInput} {
    padding: 0.75rem;
  }
`

export const ChipWrapper = styled.div`
  position: relative;
  align-self: center;
  display: flex;

  max-width: 100%;

  margin-left: 0.5rem;

  ${StyledChip} {
    margin-right: 0.5rem;
  }
`

export const FixPlaceholder = styled.div`
  ${StyledInput} {
    ::placeholder {
      font-weight: bold;
      color: #003c43;
    }
  }
`
