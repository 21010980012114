import { AccountApi, BookingsApi, ConversionTrackingApi, StationsApi, VehiclesApi } from '@cdab/opus-api-client'
import { apiConfig } from 'Config'

export const accountApi = new AccountApi(apiConfig)

export const bookingsApi = new BookingsApi(apiConfig)

export const stationsApi = new StationsApi(apiConfig)

export const vehiclesApi = new VehiclesApi(apiConfig)

export const trackingApi = new ConversionTrackingApi(apiConfig)
