import * as React from 'react'

/* Import components here */
import { StyledListDescription } from './ListDescription.styles'

/* Import interfaces here */
import { IListDescriptionProps } from './ListDescription.interfaces'

/* Import utilities here */

export const ListDescription: React.FC<IListDescriptionProps> = ({ children, ...props }) => {
  return <StyledListDescription {...props}>{children}</StyledListDescription>
}

export default ListDescription
