import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { Redirect, Route, RouteProps, BrowserRouter as Router, Switch } from 'react-router-dom'

import {
  CheckoutPage,
  ErrorPage,
  KlarnaFailed,
  SsoRedirectPage,
  StationsTimePage,
  SummaryPage,
  VehicleProductsPage,
} from 'Pages'

import { Paths, STARTPAGE } from 'Config/routes'
import { IUIStore } from 'Models'

interface IAppRouterProps extends RouteProps {
  uiStore?: IUIStore
}

@inject('uiStore')
@observer
export class AppRouter extends React.Component<IAppRouterProps> {
  public render(): JSX.Element {
    return (
      <Router>
        <Switch>
          <Route exact={true} path="/" render={this.redirect} />
          <Route component={SsoRedirectPage} exact={true} path={`${Paths.sso}/:ssoKey`} />
          <Route component={KlarnaFailed} exact={true} path={`${Paths.klarnaFailed}/:bookingNumber`} />
          <Route component={KlarnaFailed} exact={true} path={`${Paths.klarnaFailed}/:bookingNumber/:klarnaOrderId`} />
          <Route component={VehicleProductsPage} exact={true} path={Paths.vehiclesAndProducts} />
          <Route component={VehicleProductsPage} exact={true} path={`${Paths.vehiclesAndProducts}/:regNo`} />
          <Route component={StationsTimePage} exact={true} path={Paths.stationsAndTime} />
          <Route component={CheckoutPage} exact={true} path={Paths.checkout} />
          <Route component={SummaryPage} exact={true} path={`${Paths.summary}/:bookingId/:klarnaOrderId`} />
          <Route component={SummaryPage} exact={true} path={`${Paths.summary}/:bookingId`} />
          <Route component={ErrorPage} />
        </Switch>
      </Router>
    )
  }

  private redirect = ({ location }): JSX.Element => <Redirect to={{ pathname: STARTPAGE, search: location.search }} />
}
