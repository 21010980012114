import { styled } from 'Theme'
import { Button } from 'Components'

import { StyledModule } from 'Components/Generic/Module/Module.styles'

export const BorderedBox = styled(StyledModule)`
  border: 3px dotted ${({ theme }): string => theme.colors.white};

  margin-top: 1rem;
  padding-bottom: 1.5rem;

  text-align: center;
`
export const AdditionalVehicleButton = styled(Button)`
  display: inline;
  text-decoration-line: underline;

  background: transparent;
  &:hover {
    background: transparent;
  }
`

export const PolicyLink = styled.a`
  display: block;
  font-size: 0.8rem;
`
export const LinkBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > ${PolicyLink} {
    margin-top: 1rem;
  }
`
