import { styled } from 'Theme'

// TIDI
export const StyledWeekday = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 3rem;
  justify-content: space-evenly;
  width: 3rem;
  cursor: pointer;

  &:first-child {
    border-bottom-left-radius: 0.25rem;
  }

  &:last-child {
    border-bottom-right-radius: 0.25rem;
  }

  &.isPast {
    color: ${({ theme }): string => theme.colors.gray};
    cursor: default;
  }

  &.selected {
    background: ${({ theme }): string => theme.colors.secondary};
    color: white;
  }
`

export const Day = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  padding-top: 0.25rem;
`

export const Date = styled.span`
  font-size: 1rem;
  padding-bottom: 0.25rem;
`
