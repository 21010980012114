import { styled } from 'Theme'

export const StyledCircleButton = styled.button`
  // TODO: Add to theme
  /* Default size */
  width: 3rem;
  height: 3rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* padding: 1rem 1.5rem; */
  margin: 0;

  font: inherit;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;

  background: ${({ theme }): string => theme.button.background};
  color: ${({ theme }): string => theme.button.color};

  border: 1px solid transparent;
  border-radius: 50%;
  transition: background-color 0.2s ease-out;

  cursor: pointer;
  outline-style: none;

  &:hover {
    background: ${({ theme }): string => theme.button.variant.primary.hover};
    transition: background-color 0.2s ease-out;
  }

  & {
    cursor: pointer;
  }

  &:hover {
    background: ${({ theme }): string => theme.button.variant.primary.hover};
    transition: background-color 0.2s ease-out;
  }

  // TODO: Add bordered type

  // TODO: Add styling for disabled for each variant, e.g. secondary, accent etc.
  &[disabled] {
    background: ${({ theme }): string => theme.button.disabled.background};
    color: ${({ theme }): string => theme.button.disabled.color};
    cursor: default;
  }

  /* Sizes */
  &.small {
    height: 2.25rem;
    width: 2.25rem;
  }

  &.large {
    width: 3.5rem;
    height: 3.5rem;
  }

  &.xl {
    width: 4.25rem;
    height: 4.25rem;
  }

  /* Variants */
  &.accent {
    background: ${({ theme }): string => theme.button.variant.accent.background};
    color: ${({ theme }): string => theme.button.variant.accent.color};

    :hover {
      background: ${({ theme }): string => theme.button.variant.accent.hover};
    }
  }

  &.danger {
    background: ${({ theme }): string => theme.button.variant.danger.background};
    color: ${({ theme }): string => theme.button.variant.danger.color};

    border-color: ${({ theme }): string => theme.button.variant.danger.border};
  }

  &.primary {
    background: ${({ theme }): string => theme.button.variant.primary.background};
    color: ${({ theme }): string => theme.button.variant.primary.color};

    :hover {
      background: ${({ theme }): string => theme.button.variant.primary.hover};
    }

    &[disabled] {
      background: ${({ theme }): string => theme.button.variant.primary.disabled.background};
      color: ${({ theme }): string => theme.button.variant.primary.disabled.color};
    }
  }

  &.secondary {
    background: ${({ theme }): string => theme.button.variant.secondary.background};
    color: ${({ theme }): string => theme.button.variant.secondary.color};

    border-color: ${({ theme }): string => theme.button.variant.secondary.border};

    // TODO: Lägg till styling på :hover.
    :hover {
    }
  }

  /* Spacing */
  &.verticalNarrow {
    margin: ${({ theme }): string => theme.spacing.narrow} 0;
  }
  &.verticalWide {
    margin: ${({ theme }): string => theme.spacing.wide} 0;
  }
  &.horizontalNarrow {
    margin: 0 ${({ theme }): string => theme.spacing.narrow};
  }
  &.horizontalWide {
    margin: 0 ${({ theme }): string => theme.spacing.wide};
  }
`

export const TitleAndIcon = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const Title = styled.span`
  font-size: ${({ theme }): string => theme.button.title.size};
`
