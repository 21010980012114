import * as Yup from 'yup'
import i18n from 'Config/i18n'

export const validationSchema = Yup.object().shape({
  regNo: Yup.string()
    .uppercase()
    .min(1, i18n.t('vehiclesAndProducts:vehicle-search-form-validation.regNo.min', { length: 1 }))
    .max(15, i18n.t('vehiclesAndProducts:vehicle-search-form-validation.regNo.max', { length: 15 }))
    .matches(
      /^[a-zA-Z0-9 åäöÅÄÖ-]+$/,
      i18n.t('vehiclesAndProducts:vehicle-search-form-validation:regNo.matchesCharacters'),
    )
    .test(
      'is-ABC123',
      'vehiclesAndProducts:vehicle-search-form-validation.regNo.invalid',
      (value: string) => !!value && value.replaceAll(' ', '').toLowerCase() !== 'abc123',
    )
    .required(i18n.t('vehiclesAndProducts:vehicle-search-form-validation:regNo.required')),
})

// min 1 tecken, max 15 tecken OK
// bokstäver, siffror, mellanslag och bindestreck OK
// KUNDFORDON och BLOCKERA inte godkända OK
// kökoder (4 siffror) inte godkända OK
// Om man slår in ett ärendenummer får man ett meddelande om att kontakta kundtjänst. OK
// Om man slår in ett anonymt nummer får man ett meddelande om att kontakta kundtjänst. OK
// Om regno redan finns uppslaget får man ett meddelande om det. OK
