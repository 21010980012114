import * as React from 'react'

/* Import components here */
import { StyledDescription, StyledEmptyState, StyledIcon, StyledTitle } from './EmptyState.styles'

/* Import interfaces here */
import { IEmptyStateProps } from './EmptyState.interfaces'

/* Import utilities here */

export const EmptyState: React.FC<IEmptyStateProps> = ({ title, description, icon }) => {
  return (
    <StyledEmptyState>
      {!!icon && <StyledIcon>{icon}</StyledIcon>}
      {!!title && <StyledTitle>{title}</StyledTitle>}
      {!!description && <StyledDescription>{description}</StyledDescription>}
    </StyledEmptyState>
  )
}

export default EmptyState
