import { styled } from 'Theme'
import { IStyledNotificationProps } from './Notification.interfaces'

// HACK: We override some default values from parent elements which are defined by Toastify.
import './toastify.css'

export const StyledNotification = styled.div`
  border-radius: 0;
  display: flex;
  height: 100%;
  padding: 0.5rem 1rem;
  opacity: 0.925;

  background-color: ${(props: IStyledNotificationProps): string => {
    const { className, theme } = props
    const { error, info, success, warning } = theme.notification

    return (
      (className === 'error' && error.background) ||
      (className === 'info' && info.background) ||
      (className === 'success' && success.background) ||
      (className === 'warning' && warning.background) ||
      'transparent'
    )
  }};

  @media (min-width: ${({ theme }): string => theme.sizes.mdContainer}) {
    border-radius: 0.25rem;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const Title = styled.span`
  color: ${({ theme }): string => theme.colors.accent};
  display: inline;
  font-family: Brokman;
  font-size: 1.25rem;
  font-weight: 600;

  .error & {
    color: ${({ theme }): string => theme.notification.error.color};
  }
  .info & {
    color: ${({ theme }): string => theme.notification.info.color};
  }
  .success & {
    color: ${({ theme }): string => theme.notification.success.color};
  }
  .warning & {
    color: ${({ theme }): string => theme.notification.warning.color};
  }
`

export const Description = styled.span`
  color: ${({ theme }): string => theme.colors.black};
  font-family: Brokman;
  font-size: 1rem;

  .error & {
    color: ${({ theme }): string => theme.notification.error.color};
  }
  .info & {
    color: ${({ theme }): string => theme.notification.info.color};
  }
  .success & {
    color: ${({ theme }): string => theme.notification.success.color};
  }
  .warning & {
    color: ${({ theme }): string => theme.notification.warning.color};
  }
`

export const Icon = styled.div`
  color: ${({ theme }): string => theme.notification.icon};

  font-size: 2rem;
  margin: 0 1rem 0 0;
`
