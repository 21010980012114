import * as React from 'react'

import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'

/* Import components here */
import { Chip, Input } from 'Components'
import { ChipWrapper, FixPlaceholder, StyledChipInput } from './ChipInput.styles'

/* Import interfaces here */
import { IChipInputAsyncProps } from './ChipInput.interfaces'

export const ChipInputAsync: React.FC<IChipInputAsyncProps> = ({
  maxCharacters,
  minCharacters = 1,
  placeholder,
  onChipChanged,
  disabled,
  chipProps,
  campaignCodeInputRef,
  ...props
}) => {
  const [text, setText] = React.useState<string>('')
  const [chip, setChip] = React.useState<string>((chipProps && chipProps.text) || '')
  const [
    latestBlurText,
    // , setLatestBlurText
  ] = React.useState<string>('')
  const [isAwaitingParent, setIsAwaitingParent] = React.useState<boolean>(false)

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value.trim()

    /** Contains the result of the check if the user can enter more characters or not */
    const updateText: boolean = !chip && (!maxCharacters || text.length < maxCharacters)

    /** Check to only allow numbers and text */
    const regEx = /[^A-Za-z0-9ÅÄÖåäö-]+/
    const isValidCharacter = value.length > 0 && value.match(regEx)

    if (!isValidCharacter && updateText) {
      /** The new value of the text */
      const newText = value.toLowerCase()

      setText(newText.toUpperCase())
    }
  }

  async function removeChip(): Promise<void> {
    if (chip.length > 0) {
      setChip('')

      setIsAwaitingParent(true)
      await onChipChanged('')
      setIsAwaitingParent(false)
    }
  }

  /** Removes text and replaces it with a chip */
  const convertTextToChip = async (): Promise<void> => {
    /** Contains the result of the check if the text should be convertet to a chip or not */
    const convertToChip = !chip && text.length >= minCharacters

    setIsAwaitingParent(true)

    if (convertToChip) {
      setChip(text)
      setText('')

      const response = await onChipChanged(text)

      if (!response) {
        setText(text)
        setChip('')
      }
    }

    setIsAwaitingParent(false)
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    // Convert the text to a chip when the user presses Enter, Tab or Space
    if (['Enter', 'Tab', ' '].includes(event.key)) {
      event.preventDefault()

      // Only do this if the user has enter something. This is where we can do some custom validation before converting the text to a chip.
      convertTextToChip()
    } else if (['Backspace'].includes(event.key)) {
      removeChip()
    }
  }

  function handleBlur(): void {
    if (text.length === 0 || latestBlurText === text) {
      return
    }

    return
  }

  return (
    <StyledChipInput {...props}>
      {chip ? (
        <ChipWrapper>
          <Chip
            disabled={disabled || isAwaitingParent} // TODO: Is this the right way to do this? Should maybe use `shouldWrap` or something? Should we add `style`?
            icon={isAwaitingParent ? faSpinnerThird : (chipProps && chipProps.icon) || undefined}
            maxWidth={'100%'}
            spinIcon={isAwaitingParent || (chipProps && chipProps.spinIcon)}
            text={chip}
            onClick={removeChip}
            {...chipProps}
          />
        </ChipWrapper>
      ) : (
        <FixPlaceholder>
          <Input
            ref={campaignCodeInputRef}
            disabled={disabled || isAwaitingParent}
            placeholder={placeholder}
            value={text}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </FixPlaceholder>
      )}
    </StyledChipInput>
  )
}

export default ChipInputAsync
