import { History } from 'history'
import { IAnyModelType, IMaybe, IReferenceType, Instance, cast, types } from 'mobx-state-tree'
import _ from 'lodash'

import { IRouteData } from 'Config'
import { PossibleRootPathValue, STARTPAGE } from 'Config/routes'
import { BookingActions, RouteData, WebUIParameters } from './UI'
import { IWebUIParametersDto } from 'Api_DEPRECATED'
import { isBitSet } from 'Utils'

export interface ILocationState {
  hideNavigation?: boolean
  hideFooter?: boolean
  shouldOverridePreviousPageComplete?: boolean
  isRebooking?: boolean
  showCoronaMessage?: boolean
}

/**
 * Map to keep track of what page belongs to what page
 */
const steps = {
  step1: [PossibleRootPathValue.vehiclesAndProducts],
  step2: [PossibleRootPathValue.stationsAndTime],
  step3: [PossibleRootPathValue.checkout],
}
export type TStep = keyof typeof steps

/**
 * Invserse of the step, for easy lookup
 */
const stepMap: { [path: string]: string } = {}
for (const key in steps) {
  for (const path of steps[key]) {
    stepMap[path] = key
  }
}

export const UIStore = types
  .model('UIStore', {
    bookingActions: BookingActions,
    currentPage: types.late((): IMaybe<IReferenceType<IAnyModelType>> => types.safeReference(RouteData)),
    pages: types.map(RouteData),
    previousPage: types.maybe(types.late((): IMaybe<IReferenceType<IAnyModelType>> => types.safeReference(RouteData))),
    parameters: types.maybe(WebUIParameters),
    redirectUrl: types.maybe(types.string),
    availableSteps: types.optional(types.array(types.string), ['step1', 'step2', 'step3']),
  })
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  .views(self => ({
    getPage(page: string): IRouteData | undefined {
      return self.pages.get(page.toLowerCase())
    },
  }))
  // FIXME: Justera så vi eventuellt inte behöver inaktivera den här regeln. Försök använda `.actions((self): { [keys: string]: Function } => {` för return type
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  .actions(self => {
    // TODO: A "volatile" history object should be created here that then
    // syncs automatically. If this is done `history` as a parameter can be
    // removed in all functions and it will be generally more stable.
    // Hint: steal from these :)
    // https://github.com/alisd23/mst-react-router/blob/master/src/sync.js
    // https://github.com/alisd23/mst-react-router/blob/master/src/store.js
    // https://github.com/mobxjs/mobx-state-tree#volatile-state

    // FIXME: Remove this
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const restart = (history?: History): void => {
      self.currentPage = self.getPage(STARTPAGE)

      // FIXME: UGLY. We need to actually clear all data. The best thing to do would probably be to re-create all stores from rootStore
      // We can access rootStore via `getParent`, see: https://github.com/mobxjs/mobx-state-tree/blob/master/docs/API/README.md#getparent
      window.location.href = '/'
      // history.push(self.currentPage ? self.currentPage.rootPath : Paths.vehiclesAndProducts)
      // TODO: Clear booking here, maybe through getParent (needs to be imported)
    }

    /**
     * Returns the `rootPath`
     *
     * @param pathname The path to convert. E.g. /summary/B1234567 to /summary
     */
    const getRootPathFromLocationPathname = (pathname: string): string | undefined => {
      // Make sure pathname is in the format '/url/here'
      // If it contains more than one /, use only the first part, e.g. url
      if (new RegExp(/^\/.*/).test(pathname)) {
        if (pathname.substr(1).includes('/')) {
          // Add slash to start of string and return it
          return `/${pathname.split('/')[1]}`
        } else if (pathname[0] === '/' && pathname.split('/').length === 2) {
          // If the string starts with a / and we only have one, then just return it
          // I.e. the string looks like '/example'
          return pathname
        }
      }

      return undefined
    }

    /**
     * Makes sure that `currentPage` is valid
     *
     * @param history
     */
    const validate = (history: History): boolean => {
      if (!self.currentPage) {
        const historyLocationPathname: string =
          getRootPathFromLocationPathname(history.location.pathname) || history.location.pathname

        self.currentPage = self.getPage(historyLocationPathname) || undefined

        // SUGGESTION: maybe throw an error here instead?
        return !!self.currentPage
      }
      return true
    }

    /**
     * This function navigates to `rootPath` and updates state appropiately
     * This function assumes rootPath is correct and will not do any checks
     *
     * @param history The history-object used for navigating
     * @param rootPath The root-path we want to navigate to
     * @param params Additional parameters to add to the url. E.g. /B123456 to /summary
     */
    const navigate = (
      history: History,
      rootPath: PossibleRootPathValue,
      state: ILocationState,
      params?: string,
    ): void => {
      self.previousPage = self.currentPage
      self.currentPage = self.getPage(rootPath)

      if (!self.currentPage) return
      self.currentPage.params = params

      const path = params ? `${self.currentPage.rootPath}/${params}` : self.currentPage.rootPath

      history.push(path, state)
    }

    return {
      hasNextPage: (history: History): boolean | undefined => {
        if (!validate(history) || !self.currentPage) {
          return
        }

        return !!self.currentPage.nextPath
      },
      hasPrevPage: (history: History): boolean | undefined => {
        if (!validate(history) || !self.currentPage) {
          return
        }

        return !!self.currentPage.previousPath
      },
      initPagesFromArray: (arr: IRouteData[]): void => {
        self.pages.clear()
        arr.forEach((pageData: IRouteData): void => {
          self.pages.put(pageData)
        })
      },
      /**
       * Jumps to a specified page defined in `PossibleRootPathValue`
       */
      jump: (
        rootPathToJumpTo: PossibleRootPathValue,
        history: History,
        params?: string,
        state: ILocationState = {},
      ): void => {
        if (!validate(history) || !self.currentPage) {
          return
        }

        const currentPathname = getRootPathFromLocationPathname(history.location.pathname) || history.location.pathname

        if (self.currentPage.rootPath !== currentPathname) {
          self.currentPage = self.getPage(currentPathname)
          if (!self.currentPage) {
            self.previousPage = undefined
            history.push(params ? `${rootPathToJumpTo}/${params}` : rootPathToJumpTo)
            return // SUGGESTION: Instead of trusting the jump function, throw error?
          }
        }
        if (!rootPathToJumpTo) {
          restart(history)
          return
        }

        window.scrollTo(0, 0)
        navigate(history, rootPathToJumpTo, state, params)
      },
      next: (history: History, params?: string, state: ILocationState = {}): void => {
        if (!validate(history) || !self.currentPage) {
          return
        }

        const currentPathname = getRootPathFromLocationPathname(history.location.pathname) || history.location.pathname

        if (self.currentPage.rootPath !== currentPathname) {
          self.currentPage = self.getPage(currentPathname)

          if (!self.currentPage) {
            // TODO: Throw error here, use <ErrorBoundary /> to handle app errors
            return
          }
        }

        if (!self.currentPage.nextPath) {
          restart(history)
          return
        }

        window.scrollTo(0, 0)
        navigate(history, self.currentPage.nextPath as PossibleRootPathValue, state, params)
      },
      previous: (history: History, state: ILocationState = {}): void => {
        if (!validate(history) || !self.currentPage) {
          return
        }

        const currentPathname = getRootPathFromLocationPathname(history.location.pathname) || history.location.pathname

        if (self.currentPage.rootPath !== currentPathname) {
          self.currentPage = self.getPage(currentPathname)

          if (!self.currentPage) {
            return // SUGGESTION: Throw error here
          }
        }

        if (!self.currentPage.previousPath) {
          restart(history)
          return
        }

        window.scrollTo(0, 0)
        navigate(history, self.currentPage.previousPath as PossibleRootPathValue, state)
      },
      restart,
      setCurrentPage: (page: string): void => {
        self.currentPage = self.getPage(page)
      },
      updateBookingActions: (actions: number): void => {
        self.bookingActions = {
          canAddVehicle: isBitSet(actions, 1),
          canRemoveVehicle: isBitSet(actions, 2),
          canAddProduct: isBitSet(actions, 3),
          canRemoveProduct: isBitSet(actions, 4),
          canSelectStation: isBitSet(actions, 5),
          canSelectTime: isBitSet(actions, 6),
          canAddCampaignCode: isBitSet(actions, 7),
          canRemoveCampaignCode: isBitSet(actions, 8),
          canPay: isBitSet(actions, 9),
          canRebook: isBitSet(actions, 10),
          canCancelBooking: isBitSet(actions, 11),
          canCredit: isBitSet(actions, 12),
          canBankCredit: isBitSet(actions, 13),
          canExitRebooking: isBitSet(actions, 14),
          canRebookWithoutAuthentication: isBitSet(actions, 15),
          canSendConfirmation: isBitSet(actions, 16),
        }
      },
      updateCurrentPage: (history: History): void => {
        if (!validate(history) || !self.currentPage) {
          return
        }

        const currentPathname = getRootPathFromLocationPathname(history.location.pathname) || history.location.pathname
        if (self.currentPage.rootPath !== currentPathname) {
          self.currentPage = self.getPage(currentPathname)
        }
      },
      updateWebUIParams: ({ Value, RedirectUrl }: IWebUIParametersDto): void => {
        self.parameters = {
          forceCallbackUrlRedirectOnComplete: isBitSet(Value, 1),
          forceCallbackUrlRedirectOnAbort: isBitSet(Value, 2),
          omitBookingStep1: isBitSet(Value, 3),
          removeContactDetails: isBitSet(Value, 4),
          removeTopMenuReturnButton: isBitSet(Value, 5),
        }

        self.redirectUrl = RedirectUrl || undefined

        if (self.parameters.omitBookingStep1) {
          const page = self.getPage(PossibleRootPathValue.stationsAndTime)
          if (page) {
            page.previousPath = undefined
          }
          self.availableSteps = cast(['step2', 'step3'])
        }
      },
      getRootPathFromLocationPathname,
    }
  })

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  .views(self => {
    return {
      get currentStep(): TStep | undefined {
        const rootPath = _.get(self, 'currentPage.rootPath')
        const step = stepMap[rootPath]
        return step as TStep | undefined
      },

      getBookingAction(action: string): boolean {
        return self.bookingActions[action]
      },
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUIStore extends Instance<typeof UIStore> {}
