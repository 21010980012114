import * as React from 'react'

import { inject } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'

/* Import components here */
import { InfoText, OpenHoursList, Spinner, WebLink } from './StationInfoModal.styles'

/* Import interfaces here */
import { IStationInfoModalProps } from './StationInfoModal.interfaces'
import { IStationOpenHours } from 'Api_DEPRECATED'
import { IStation } from 'Models/Stations'

/* Import utilities here */

export const StationInfoModal: React.FC<IStationInfoModalProps> = ({ date, stationId, stationsStore }) => {
  const [stationOpenHours, setStationOpenHours] = React.useState<IStationOpenHours | null>(null)
  const [stationDto, setStationDto] = React.useState<IStation | null>(null)
  const { t } = useTranslation()

  React.useEffect((): void => {
    if (!stationsStore) return

    const fetchStationInfo = async (): Promise<void> => {
      if (stationsStore && stationId) {
        const station = await stationsStore.GetStationOpenHoursAndInfo(stationId, {
          date,
        })

        setStationOpenHours(station)
      }
    }

    fetchStationInfo()

    const [station] = stationsStore.stations.filter((station): boolean => station.id === stationId)
    setStationDto(station)
  }, [date, stationId, stationsStore])

  const modalToRender =
    stationOpenHours && stationDto ? (
      <>
        <OpenHoursList>
          <span>{t('common:days.monday')}:</span>
          <span>{stationOpenHours.Monday || 'Stängt'}</span>
          <span>{t('common:days.tuesday')}:</span>
          <span>{stationOpenHours.Tuesday || 'Stängt'}</span>
          <span>{t('common:days.wednesday')}:</span>
          <span>{stationOpenHours.Wednesday || 'Stängt'}</span>
          <span>{t('common:days.thursday')}:</span>
          <span>{stationOpenHours.Thursday || 'Stängt'}</span>
          <span>{t('common:days.friday')}:</span>
          <span>{stationOpenHours.Friday || 'Stängt'}</span>
          <span>{t('common:days.saturday')}:</span>
          <span>{stationOpenHours.Saturday || 'Stängt'}</span>
          <span>{t('common:days.sunday')}:</span>
          <span>{stationOpenHours.Sunday || 'Stängt'}</span>
        </OpenHoursList>
        {stationOpenHours.Info && <InfoText>{stationOpenHours.Info}</InfoText>}
        <WebLink>
          <a href={stationDto.stationUrl} rel="noopener noreferrer" target="_blank">
            {t('stationsAndTime:moreInformation')}
          </a>
        </WebLink>
      </>
    ) : (
      <Spinner>
        <FontAwesomeIcon icon={faSpinner} size="lg" spin={true} />
      </Spinner>
    )

  return modalToRender
}

export default inject('stationsStore')(StationInfoModal)
