import { styled } from 'Theme'

export const StyledTabItem = styled.button`
  background: ${({ theme }): string => theme.tabs.background};
  border-color: initial;
  border-image: initial;
  border-style: none;
  border-width: initial;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  margin: 0px;
  outline: none;
  overflow: visible;
  padding: 0px;
  text-align: inherit;
  text-decoration: none;
  text-transform: none;

  &.disabled {
    color: ${({ theme }): string => theme.tabs.tabItem.disabled.color};
    cursor: default;
  }
`

export const StyledInnerTab = styled.div`
  background: ${({ theme }): string => theme.tabs.tabItem.background};
  border-bottom-width: ${({ theme }): string => theme.tabs.tabItem.border.width};
  border-bottom-color: ${({ theme }): string => theme.tabs.tabItem.border.color};
  border-bottom-style: solid;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }): string => theme.tabs.tabItem.marginVertical};
  margin-left: ${({ theme }): string => theme.tabs.tabItem.marginHorizontal};
  margin-right: ${({ theme }): string => theme.tabs.tabItem.marginHorizontal};
  margin-top: ${({ theme }): string => theme.tabs.tabItem.marginVertical};
  max-width: 100%;
  min-height: 0px;
  min-width: 0px;
  outline: none;
  padding-bottom: ${({ theme }): string => theme.tabs.tabItem.paddingBottom};

  ${StyledTabItem}:hover & {
    border-bottom-color: ${({ theme }): string => theme.tabs.tabItem.hover.borderColor};
    color: ${({ theme }): string => theme.tabs.tabItem.hover.color};
  }

  ${StyledTabItem}.disabled & {
    background: ${({ theme }): string => theme.tabs.tabItem.disabled.background};
    border-bottom-color: ${({ theme }): string => theme.tabs.tabItem.disabled.borderColor};
    color: ${({ theme }): string => theme.tabs.tabItem.disabled.color};

    &:hover {
      border-bottom-color: ${({ theme }): string => theme.tabs.tabItem.disabled.hover.borderColor};
      color: ${({ theme }): string => theme.tabs.tabItem.disabled.hover.color};
    }
  }

  ${StyledTabItem}.active & {
    background: ${({ theme }): string => theme.tabs.tabItem.active.background};
    border-bottom-color: ${({ theme }): string => theme.tabs.tabItem.active.borderColor};
    color: ${({ theme }): string => theme.tabs.tabItem.active.color};
  }
`

export const StyledHeader = styled.span`
  font-size: ${({ theme }): string => theme.tabs.tabItem.title.size};
  line-height: ${({ theme }): string => theme.tabs.tabItem.title.lineHeight};
  text-transform: none;
`
