import * as React from 'react'

import { AxiosError } from 'axios'
import { getI18n } from 'react-i18next'
import { toast } from 'react-toastify'

import { Notification } from 'Components'

import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

const errorCodeToErrorMessage = (codeBase10: number): string => {
  const code = codeBase10.toString(16)
  const i18n = getI18n()

  // Eftersom felkoden skickas som ett tal kan längden antingen vara 4 eller 5 (om första siffran är en 0 kommer den försvinna) men de sista 3 finns garanterat
  const split = code.length - 3

  return i18n.t(`errors:${code.substr(0, split).toUpperCase()}.${code.substr(split).toUpperCase()}`)
}

type TError = unknown
type TErrorType = 'error' | 'info' | 'success' | 'warning'
export const notifyError = (error: TError, title: string | undefined = undefined, type: TErrorType = 'error'): void => {
  const i18n = getI18n()

  if (!error || Array.isArray(error)) {
    toast(<Notification title={'Unknown error'} type={type} />) // TODO: Byt ut mot i18n så vi kan översätta felet vi har
  } else if (typeof error === 'string') {
    // A custom error message
    toast(<Notification description={i18n.t('errors:contactSupport')} title={title || error} type={type} />)
  } else if (typeof error === 'number') {
    // An error code is used
    toast(
      <Notification
        description={i18n.t('errors:contactSupport')}
        title={title || errorCodeToErrorMessage(error)}
        type={type}
      />,
    )
  } else {
    const axiosError: AxiosError = error as AxiosError

    // If we have a axios error, use error message
    if (axiosError.response && axiosError.response.data && axiosError.response.data.Code) {
      toast(
        <Notification
          description={i18n.t('errors:contactSupport')}
          title={title || errorCodeToErrorMessage(axiosError.response.data.Code)}
          type={type}
        />,
      )
    } else {
      toast(
        <Notification
          description={i18n.t('errors:contactSupport')}
          title={title || i18n.t('errors:oopsSomethingWrong')}
          type={type}
        />,
      )
    }
  }
}

interface IErrorNotificationProps {
  error: TError
  title?: string
  type?: TErrorType
}

export const ErrorNotification = ({ error, title, type }: IErrorNotificationProps): null => {
  const { i18n } = useTranslation()
  useEffect((): void => {
    notifyError(error, title, type)
  }, [error, i18n, title, type])
  return null
}
