import { colors } from './colors'

export interface IText {
  color: string
  size: {
    small: string
    medium: string
    large: string
    xlarge: string
    xxlarge: string
  }
  weight: {
    normal: string
    bold: string
  }
}

export const text: IText = {
  color: colors.white,
  size: {
    small: '0.875rem',
    medium: '1rem',
    large: '1.25rem',
    xlarge: '1.5rem',
    xxlarge: '2rem',
  },
  weight: {
    normal: 'normal',
    bold: 'bold',
  },
}
