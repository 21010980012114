import { Instance, types } from 'mobx-state-tree'
import { IStationDto } from 'Api_DEPRECATED/interfaces'

export const Station = types.model('Station', {
  city: types.string,
  distance: types.number,
  id: types.number,
  imageUrl: types.string,
  latitude: types.maybeNull(types.number),
  longitude: types.maybeNull(types.number),
  name: types.string,
  stationUrl: types.string,
  streetAddress: types.string,
  zipCode: types.string,
  isDriveInOpen: types.boolean,
  // TODO: For future features
  // Aliases?: NamedResponse[]
})

// FIXME: Justera så vi eventuellt inte behöver inaktivera den här regeln
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IStation extends Instance<typeof Station> {}

export const StationModelFromStationDto = (station: IStationDto): IStation => {
  return Station.create({
    city: station.City || 'UNAVAILABLE',
    distance: station.Distance || 0,
    id: station.Id || -1,
    imageUrl: station.ImageUrl || 'https://via.placeholder.com/160x112.png',
    latitude: station.Latitude,
    longitude: station.Longitude,
    name: station.Name || 'UNAVAILABLE',
    stationUrl: station.StationUrl || 'https://opus.se/stationer/',
    streetAddress: station.StreetAddress || 'UNAVAILABLE',
    zipCode: station.ZipCode || 'UNAVAILABLE',
    isDriveInOpen: station.IsDriveInOpen || false,
    // TODO: For future features
    // Aliases?: NamedResponse[]
  })
}
