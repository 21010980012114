import React, { useCallback, useMemo, useState } from 'react'

import { AviForm, Button, Card, IAviFormData, Paragraph } from 'Components'
import { useTranslation } from 'react-i18next'
import { KlarnaPayment } from './KlarnaPayment'
import { useStores } from 'Utils'
import { FormikActions } from 'formik'
import { formatAviFormDataForBackend } from 'Utils/api'

const STATES = {
  CONFIRM: 'confirm',
  AVI_FORM: 'aviForm',
  KLARNA_PAYMENT: 'klarna',
} as const
type TStates = typeof STATES[keyof typeof STATES]

const PAYMENT_KLARNA = 2

const MARGIN = { vertical: 'medium' }
function AviConfirmAndInfo({ onConfirm }) {
  const { t } = useTranslation('checkout')

  return (
    <Card>
      <Paragraph color="#003C43" margin={MARGIN}>
        {t('aviRebooking.step1.description')}
      </Paragraph>
      <Button block title={t('aviRebooking.step1.buttonText')} variant="accent" onClick={onConfirm} />
    </Card>
  )
}

export function AviRebooking({ bookingNumber }: { bookingNumber: string }): JSX.Element {
  const { t } = useTranslation('checkout')
  const { bookingStore } = useStores()
  const [aviState, setAviState] = useState<TStates>(STATES.CONFIRM)

  const onConfirmClick = useCallback(() => {
    setAviState(STATES.AVI_FORM)
  }, [])

  const onAviConfirmClick = useCallback(
    async (values: IAviFormData, actions: FormikActions<IAviFormData>) => {
      try {
        await bookingStore.PayBooking(bookingNumber, {
          PaymentOption: PAYMENT_KLARNA,
          Data: '',
          BankAccountInfo: formatAviFormDataForBackend(values),
        })
      } finally {
        actions.setSubmitting(false)
      }
      setAviState(STATES.KLARNA_PAYMENT)
    },
    [bookingStore, bookingNumber],
  )

  const AviStateComponents = useMemo(
    () => ({
      [STATES.CONFIRM]: <AviConfirmAndInfo onConfirm={onConfirmClick} />,
      [STATES.AVI_FORM]: (
        <AviForm
          descriptionText={t('aviRebooking.step2.description')}
          submitButtonText={t('aviRebooking.step2.submitButtonText')}
          onSubmit={onAviConfirmClick}
        />
      ),
      [STATES.KLARNA_PAYMENT]: <KlarnaPayment noPayBookingCall bookingNumber={bookingNumber} />,
    }),
    [bookingNumber, onAviConfirmClick, onConfirmClick, t],
  )

  return AviStateComponents[aviState]
}
