import { styled } from 'Theme'
import { css } from 'styled-components'
import { IStyledNavigationIconProps } from './Navigation.interfaces'

type TConditionalStyle<T> = undefined | false | T

export const StyledNavigationDistance = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 0 auto;

  @media (min-width: ${({ theme }): string => theme.sizes.mdContainer}) {
    width: ${({ theme }): string => theme.sizes.mdContainer};
  }
  @media (min-width: ${({ theme }): string => theme.sizes.xlContainer}) {
    width: ${({ theme }): string => theme.sizes.xlContainer};
  }
`

export const StyledNavigation = styled.nav`
  padding: 0.75rem 1rem;

  /* Keep the Navigation under the Header. */
  position: fixed;
  top: 4rem; /* The height of the Header */
  width: 100%;
  z-index: 1;

  background: ${({ theme }): string => theme.colors.primaryLight};
`

export const NavigationDescription = styled.div`
  white-space: nowrap;
`

const activeStyle = css`
  color: ${({ theme }): string => theme.colors.primary};
  background: ${({ theme }): string => theme.colors.white};
  ${NavigationDescription} {
    display: inline;
  }
`

export const StepCounter = styled.span`
  margin-left: 0.5rem;
`

export const StyledNavigationIcon = styled.div<IStyledNavigationIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 2.5rem;
  height: 2.5rem;
  padding: 1rem 0.5rem;
  font-size: 0.9rem;

  background: ${({ theme }): string => theme.colors.primary};

  transition: background-color 0.2s ease-out;

  border: none;
  border-radius: ${({ theme }): string => theme.borderRadius};

  outline-style: none;

  &.active {
    color: ${({ theme }): string => theme.colors.primary};
    background: white;
  }

  ${NavigationDescription} {
    display: none;
  }

  ${({ active }): TConditionalStyle<typeof activeStyle> => active && activeStyle}

  @media (min-width: ${({ theme }): string => theme.sizes.xsContainer}) {
    font-size: inherit;
  }

  @media (min-width: ${({ theme }): string => theme.sizes.mdContainer}) {
    ${NavigationDescription} {
      display: inline;
    }
  }
`
