import ReactGA from 'react-ga'

import { EnvironmentVariables } from 'Config/environment'
import { trackingApi } from 'Api'
import { FacebookEventDto } from '@cdab/opus-api-client'
import { faWindowRestore } from '@fortawesome/pro-solid-svg-icons'

/**
 * Events are actions that happen on your website. Standard events are predefined by Facebook and can be used to log conversions, optimize for conversions and build audiences. See below for a list of the standard events for the Facebook pixel.
 *
 * Source: https://www.facebook.com/business/help/402791146561655
 */
export enum PixelTrackableEvents {
  /** The addition of payment information in the checkout flow (ex: click, landing page on billing info) */
  AddPaymentInfo = 'AddPaymentInfo',

  /** The addition of items to a shopping cart (ex: click, landing page on Add to Cart button) */
  AddToCart = 'AddToCart',

  /** The addition of items to a wishlist (ex: click, landing page on Add to Wishlist button) */
  AddToWishlist = 'AddToWishlist',

  /** The submission of a registration form (ex: complete subscription, sign up for a service) */
  CompleteRegistration = 'CompleteRegistration',

  /** A telephone/SMS, email, chat or other type of contact between a customer and your business */
  Contact = 'Contact',

  /** The customization of products through a configuration tool or other application your business owns */
  CustomizeProduct = 'CustomizeProduct',

  /** The donation of funds to your organisation or cause */
  Donate = 'Donate',

  /** A web or app search for one of your business locations that suggests intention to visit */
  FindLocation = 'FindLocation',

  /** When someone enters the checkout flow (ex: click, landing page on checkout button) */
  InitiateCheckout = 'InitiateCheckout',

  /** When someone expresses interest in your offering (ex: form submission, sign up for trial, landing on pricing page) */
  Lead = 'Lead',

  /** Purchases or checkout flow completions (ex: Landing on "Thank You" or confirmation page) */
  Purchase = 'Purchase',

  /** The booking of an appointment to visit one of your locations */
  Schedule = 'Schedule',

  /** Searches on your website, app or other property (ex: product searches) */
  Search = 'Search',

  /** The start of a free trial of a product or service you offer (ex: trial subscription) */
  StartTrial = 'StartTrial',

  /** A submitted registration form for a product, service or program you offer (ex: credit card, educational program or job) */
  SubmitApplication = 'SubmitApplication',

  /** The start of a paid subscription for a product or service you offer */
  Subscribe = 'Subscribe',

  /** Key page views (ex: product page, landing page, article)  */
  ViewContent = 'ViewContent',
}

/**
 * Make sure the Tracker is initialized correctly before using its methods.
 *
 * If Tracker isn't initialized the method will not run.
 */
const requireInitialization = () => {
  return function (
    target: { initialized: boolean },
    propertyKey: string,
    descriptor: PropertyDescriptor,
  ): PropertyDescriptor {
    /** The original function on Tracker class where the decorator is added to */
    const originalMethod = descriptor.value

    descriptor.value = (...args: unknown[]): PropertyDescriptor | undefined => {
      if (target.initialized && process.env.NODE_ENV === 'production') {
        return originalMethod(...args)
      } else {
        return undefined
      }
    }

    return descriptor
  }
}

/**
 * Utility to track and send analytics to different sources. Tracks events and pageviews.
 *
 * @export
 * @abstract
 * @class Tracker
 */
export abstract class Tracker {
  public static initialized = false

  /**
   * Initialize the Tracker and its sources correctly.
   *
   * In our case it initializes Google Analytics and Facebook Pixel.
   */
  public static init(): void {
    if (this.initialized || process.env.NODE_ENV !== 'development') return // FIXME: Är inte säker på att det här är rätt logik. Den här koden ska bara köras i production och om den inte redan har startats

    ReactGA.initialize(EnvironmentVariables.GA_TRACKING_ID)

    this.initialized = true
  }

  /**
   * Track specific pages and views, not events.
   *
   * @static
   * @param {string} path The path to send to the analytics tool
   * @param {boolean} [usePixel=false] Enable/disable Facebook Pixel for current page track
   * @param {boolean} [useGA=true] Enable/disable Google Analytics for current page track
   * @memberof Tracker
   */
  @requireInitialization()
  public static trackPage(path: string): void {
    ReactGA.pageview(path)
  }

  public static trackExternalId(externalId: string): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-extra-semi
    ;(window as any).dataLayer.push({ externalId })
  }
}

export const fbTrackEvent = (event: FacebookEventDto) =>
  trackingApi.trackEvent({ EventSourceUrl: window.location.href, ...event }).catch(console.error)
