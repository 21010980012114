import React, { useEffect, useState } from 'react'
import { ErrorNotification } from 'Utils/Notifications'
import { useStores } from 'Utils'
import { Centered } from '../CheckoutPage.styles'
import { Card, Klarna } from 'Components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'

const PAYMENT_KLARNA = 2

export const KlarnaPayment = ({
  bookingNumber,
  noPayBookingCall,
  headerContent,
}: {
  bookingNumber: string
  noPayBookingCall?: boolean
  headerContent?: React.ReactNode
}): JSX.Element | null => {
  const { bookingStore } = useStores()
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { checkoutKlarnaData } = bookingStore

  useEffect((): void => {
    setIsLoading(true)
    // Har vi använt klarna tidigare? I så fall, fortsätt med att använda samma snippet
    if (window._klarnaCheckout && bookingStore.checkoutKlarnaData) {
      bookingStore.UpdatePayBooking(bookingNumber, bookingStore.checkoutKlarnaData.orderId).then((): void => {
        setIsLoading(false)
      })
    } else {
      if (noPayBookingCall) {
        setIsLoading(false)
        return
      }
      bookingStore
        .PayBooking(bookingNumber, {
          Data: '',
          PaymentOption: PAYMENT_KLARNA,
        })
        .then((): void => {
          setIsLoading(false)
        })
        .catch(setError)
    }
  }, [bookingStore, bookingNumber, noPayBookingCall])

  const snippet = checkoutKlarnaData && checkoutKlarnaData.snippet

  return isLoading ? (
    <Card>
      <Centered>
        <FontAwesomeIcon icon={faSpinnerThird} spin={true} />
      </Centered>
    </Card>
  ) : error ? (
    <ErrorNotification error={error} />
  ) : (
    <Card>
      {headerContent}
      <Klarna snippet={snippet} />
    </Card>
  )
}
