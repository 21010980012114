import * as React from 'react'

/* Import components here */
import { SectionTitle, StyledSection } from './Section.styles'

/* Import interfaces here */
import { ISectionProps } from './Section.interfaces'

// Component
// eslint-disable-next-line react/display-name
export const Section = React.forwardRef<HTMLElement, React.PropsWithChildren<ISectionProps>>(
  ({ children, title, titleAlign = 'start', ...props }, forwardedRef): JSX.Element => {
    return (
      <StyledSection ref={forwardedRef} {...props}>
        {title && <SectionTitle titleAlign={titleAlign}>{title}</SectionTitle>}
        {children}
      </StyledSection>
    )
  },
)

export default Section
