import { styled } from 'Theme'

export const StyledTimePicker = styled.div`
  background-color: white;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  color: ${({ theme }): string => theme.colors.primary};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 0.5rem 0;
  padding: 0.75rem;

  transition: background-color 0.2s ease-out;
  width: 100%;

  &:hover {
    background: ${({ theme }): string => theme.colors.lightGray};
    transition: background-color 0.2s ease-out;
  }
  &.selected {
    background: ${({ theme }): string => theme.colors.secondary};

    /* Refers to Time and Price */
    span {
      color: white;
    }
  }
  &.selected:hover {
    background: ${({ theme }): string => theme.colors.secondaryLight};
  }
`

export const Time = styled.span`
  font-weight: 600;
`

export const Price = styled.span<{ isBestPrice: boolean }>`
  color: ${({ theme, isBestPrice }): string => (isBestPrice ? theme.colors.accent : theme.colors.secondary)};
  font-weight: 500;
`
