import * as React from 'react'
import * as ReactDOM from 'react-dom'

/** Import components here */
import FocusTrap from 'focus-trap-react'
import classNames from 'classnames'
import {
  Body,
  Close,
  CloseButton,
  CloseIcon,
  Footer,
  Header,
  Message,
  StyledAside,
  StyledModal,
} from './ModalContent.styles'

/** Import interfaces here */
import { IModalContentProps } from './ModalContent.interfaces'

/** Import utilities here */

export const ModalContent: React.FC<IModalContentProps> = ({
  ariaLabel,
  buttonRef,
  buttons,
  hideDefaultCloseButton,
  content,
  leftButton,
  rightButton,
  header,
  message,
  modalRef,
  onClickAway,
  onClose,
  onFocus,
  onKeyDown,
  role = 'dialog',
}) => {
  const closeButton = (
    <CloseButton ref={buttonRef} aria-labelledby="close-modal" className="c-modal__close" onClick={onClose}>
      <Close className="u-hide-visually" id="close-modal">
        Close
      </Close>
      <CloseIcon viewBox="0 0 40 40">
        <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
      </CloseIcon>
    </CloseButton>
  )

  const modal = (
    <FocusTrap aria-label={ariaLabel} aria-modal="true" role={role} tabIndex={-1} onFocus={onFocus}>
      <StyledAside className="ie11-loader" onClick={onClickAway} onKeyDown={onKeyDown}>
        <StyledModal ref={modalRef}>
          {!hideDefaultCloseButton && closeButton}
          {header && <Header>{header}</Header>}
          {(message || content) && (
            <Body>
              {message && <Message>{message}</Message>}
              {content && content}
            </Body>
          )}
          {(leftButton || rightButton) && (
            <Footer
              className={classNames(
                (leftButton && rightButton && 'leftAndRightButton') ||
                  (leftButton && 'leftButton') ||
                  (rightButton && 'rightButton'),
              )}
            >
              {leftButton &&
                React.cloneElement(leftButton.component, {
                  onClick: (): void => {
                    leftButton.callbackFn && leftButton.callbackFn()
                    leftButton.closeOnClick && onClose()
                  },
                })}
              {rightButton &&
                React.cloneElement(rightButton.component, {
                  onClick: (): void => {
                    rightButton.callbackFn && rightButton.callbackFn()
                    rightButton.closeOnClick && onClose()
                  },
                })}
            </Footer>
          )}
          {buttons && (
            <Footer>
              {buttons.map(
                (button): JSX.Element => {
                  return button
                },
              )}
            </Footer>
          )}
        </StyledModal>
      </StyledAside>
    </FocusTrap>
  )

  return ReactDOM.createPortal(modal, document.body)
}
