import * as React from 'react'
import { useCallback } from 'react'

/* Import components here */
import {
  CountDisplay,
  DateDisplay,
  DayWrapper,
  MonthDisplay,
  StyledAvailableTimesCalendar,
  StyledDay,
} from './AvailableTimesCalendar.styles'
import { Calendar } from 'Components'

/* Import interfaces here */
import { IAvailableTimesCalendarProps, IExtraInfo } from './AvailableTimesCalendar.interfaces'
import { IDayProps } from 'Components/Generic/Calendar/Calendar.interfaces'

/* Import utilities here */
import { addDays, isPast, isToday } from 'date-fns'
import { getMonthName, useStores } from 'Utils'
import { getFirstDayOfWeek } from 'Components/Generic/Calendar'
import { useTranslation } from 'react-i18next'
import { useObserver } from 'mobx-react'

/**
 * Day component
 */
const Day: React.FC<IDayProps<IExtraInfo>> = dayProps => {
  const {
    date,
    extraInfo,
    isFirstVisibleDay,
    isSelected,
    isToday: isTodayProp,
    isCurrentMonth,
    isMonth,
    ...props
  } = dayProps
  const freeTimes = extraInfo ? extraInfo.available : 0
  //const bestPrice = extraInfo && extraInfo.bestPrice
  const enabled = freeTimes > 0 && (!isPast(date) || isToday(date))

  const freeTimesInterval = freeTimes < 5 ? '<5 st' : freeTimes < 10 && freeTimes >= 5 ? '<10 st' : '>10 st'

  const monthNameOfLastDayInWeek = (date.getDate() === 1 || isFirstVisibleDay) && getMonthName(date, true)

  return (
    <DayWrapper>
      {!!monthNameOfLastDayInWeek && <MonthDisplay>{monthNameOfLastDayInWeek}</MonthDisplay>}
      <StyledDay
        disabled={!enabled}
        isCurrentMonth={isCurrentMonth}
        isMonth={!!isMonth}
        isSelected={isSelected}
        isToday={isTodayProp}
        {...props}
      >
        <DateDisplay>{date.getDate()}</DateDisplay>
        <CountDisplay showPriceTag={true}>{freeTimes ? freeTimesInterval : '-'}</CountDisplay>
      </StyledDay>
    </DayWrapper>
  )
}

/**
 * Component for showing available times in a calendar view
 */
export const AvailableTimesCalendar: React.FC<IAvailableTimesCalendarProps> = props => {
  const { stationsStore } = useStores()
  const { onSelectDate } = props
  const { t } = useTranslation()

  const handleSelectDate = useCallback((date: Date): void => onSelectDate(date), [onSelectDate])

  return useObserver(
    (): JSX.Element => {
      const {
        firstWeek,
        selectedStations,
        selectedDate,
        error,
        onChangePeriod,
        viewFullMonth,
        isLoading = false,
      } = props
      const firstDay = getFirstDayOfWeek(firstWeek)
      const extraInfo = stationsStore.timesOverview(selectedStations, firstDay, addDays(firstDay, 7 * 5))
      return (
        <StyledAvailableTimesCalendar>
          <Calendar
            dayComponent={Day}
            error={error ? t('errors:somethingWrong') : undefined}
            extraInfo={extraInfo}
            isLoading={isLoading || !!error}
            month={firstWeek}
            value={selectedDate}
            viewFullMonth={viewFullMonth}
            onChangeMonth={onChangePeriod}
            onSelectDate={handleSelectDate}
          />
        </StyledAvailableTimesCalendar>
      )
    },
  )
}

export default AvailableTimesCalendar
