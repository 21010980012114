import { colors } from './colors'

export interface IHeading {
  color: string
  margin: {
    none: string
    xxsmall: string
    xsmall: string
    small: string
    medium: string
    large: string
    xlarge: string
    xxlarge: string
  }
  size: {
    small: string
    medium: string
    large: string
    xlarge: string
    xxlarge: string
  }
}

export const heading: IHeading = {
  color: colors.white,
  margin: {
    none: '0',
    xxsmall: '0.25rem',
    xsmall: '0.5rem',
    small: '0.75rem',
    medium: '1rem',
    large: '1.25rem',
    xlarge: '1.5rem',
    xxlarge: '2rem',
  },
  size: {
    small: '1rem',
    medium: '1.25rem',
    large: '1.75rem',
    xlarge: '2.5rem',
    xxlarge: '3rem',
  },
}
