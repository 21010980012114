import * as React from 'react'

import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addDays, addWeeks, isPast as datefnsIsPast, format, getISOWeek, isToday, startOfWeek } from 'date-fns'
import { withTranslation } from 'react-i18next'

/* Import components here */
import { Weekday } from 'Components/Generic/DatePicker/Weekday'
import { Message, Month, StyledDatePicker, Week, WeekButton, WeekNavigation, Weekdays } from './DatePicker.styles'

/* Import interfaces here */
import { IDatePickerProps, IDatePickerState } from './DatePicker.interfaces'

/* Import utilities here */
import { capitalize, getLocaleFile } from 'Utils'

type firstWeekDay = 0 | 1 | 2 | 3 | 4 | 5 | 6

export class DatePicker extends React.Component<IDatePickerProps, IDatePickerState> {
  public constructor(props: IDatePickerProps) {
    super(props)

    const weekday = this.props.selectThisDate ? this.props.selectThisDate : new Date()

    // Don't call this.setState() here!
    this.state = {
      selectedDate: weekday,
      selectedDay: weekday.getDay(),
      selectedWeekNumber: getISOWeek(weekday),
      ...this.extractCalendarValues(weekday),
    }
  }

  public render(): JSX.Element {
    const { weekdays, weekNo, selectedDate } = this.state
    const { i18n, message, header } = this.props

    const dateDisplay = format(selectedDate, 'd#MMMM#yyyy', { locale: getLocaleFile() })
      .split('#')
      .map(capitalize)
      .join(' ')

    return (
      <StyledDatePicker>
        {header}
        <WeekNavigation>
          <WeekButton disabled={getISOWeek(new Date()) === weekNo} rounded={true} onClick={this.goToPreviousWeek}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </WeekButton>
          <div>
            <Week>
              {i18n.t('vehiclesAndProducts:week')} {weekNo}
            </Week>
            <Month>{dateDisplay}</Month>
            {message && <Message>{message}</Message>}
          </div>
          <WeekButton rounded={true} onClick={this.goToNextWeek}>
            <FontAwesomeIcon icon={faAngleRight} />
          </WeekButton>
        </WeekNavigation>
        <Weekdays>{weekdays.map((day: Date): JSX.Element => this.renderDay(day))}</Weekdays>
      </StyledDatePicker>
    )
  }

  private isPast = (d: Date): boolean => datefnsIsPast(d) && !isToday(d)

  /**
   * Renders a container with first letter of a Weekday and its corresponding date
   */
  private renderDay = (weekday: Date): JSX.Element => {
    const index = weekday.getDay()
    const weekNumber = getISOWeek(weekday)

    return (
      <Weekday
        key={index}
        isPast={this.isPast(weekday)}
        selected={this.state.selectedDay === index && this.state.selectedWeekNumber === weekNumber}
        weekday={weekday}
        onSelectDay={this.selectDay}
      />
    )
  }

  /**
   *
   * Selects chosen day. Also disabled clicking on old dates.
   */
  private selectDay = (weekday: Date): void => {
    if (!this.isPast(weekday)) {
      // Send the new selected date up to the page
      this.props.onSelectedDayChange(weekday)

      this.setState({
        selectedDate: weekday,
        selectedDay: weekday.getDay(),
        selectedWeekNumber: getISOWeek(weekday),
        ...this.extractCalendarValues(weekday),
      })
    }
  }

  /**
   * Navigate to previous week
   */
  private goToPreviousWeek = (): void => {
    // Om måndagen i veckan har passerat välj isåfall dagens datum, gäller alltså för innevarande vecka
    this.selectDay(
      this.isPast(addWeeks(this.state.weekdays[0], -1)) ? new Date() : addWeeks(this.state.weekdays[0], -1),
    )
  }

  /**
   * Navigate to next week
   */
  private goToNextWeek = (): void => {
    this.selectDay(addWeeks(this.state.weekdays[0], 1))
  }

  /**
   * Set up current state based on week dates
   */
  private extractCalendarValues = (
    anyDateInWeek: Date,
    numberOfFirstWeekDay: firstWeekDay = 1,
  ): { month: string; weekNo: number; weekdays: Date[]; year: number } => {
    const firstWeekDay = startOfWeek(anyDateInWeek, { weekStartsOn: numberOfFirstWeekDay })
    const weekdays: Date[] = []

    for (let i = 0; i < 7; i++) {
      weekdays.push(addDays(firstWeekDay, i))
    }

    const weekNo = getISOWeek(firstWeekDay)
    const month = format(firstWeekDay, 'MMMM', { locale: getLocaleFile() })
    const year = firstWeekDay.getFullYear()

    return {
      month,
      weekNo,
      weekdays,
      year,
    }
  }
}

export default withTranslation()(DatePicker)
