/**
 * get the first day of the week containing given day
 *
 * @remarks This function assumes that the forst day of the week always is a monday
 *
 * @param date - Date which specifies the week
 * @returns the first date of the week
 */
export function getFirstDayOfWeek(date: Date): Date {
  const firstWeekday = new Date(date.getTime())
  firstWeekday.setDate(firstWeekday.getDate() - ((firstWeekday.getDay() + 6) % 7))
  return firstWeekday
}

/**
 * Returns true if two dates are the same day
 *
 * @param d1 - first date to compare
 * @param d2 - second date to compare
 * @returns true if d1 and d2 are on the same date
 */
export const isSameDay = (d1: Date, d2: Date): boolean =>
  d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()

/**
 * Returns two if two dates are in the same month
 * @param d1 - first date in month to compare
 * @param d2 - second date in month to compare
 */
export const isSameMonth = (d1: Date, d2: Date): boolean =>
  d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth()

/**
 * Generates all dates within a month, given a date within that month.
 *
 * @remarks
 * First date is the first monday, the first week of that month
 *
 * @param date A date within the month
 * @param weeksToShow Number of weeks to show in calendar.
 */
export function* generateCalendar(date: Date, numberOfWeeks: number): IterableIterator<Date> {
  const nrDays = 7 * numberOfWeeks

  /** Set first day in calendar to monday of current selected week */
  const firstDay = getFirstDayOfWeek(date)

  for (let day = 0; day < nrDays; day++) {
    const d = new Date(firstDay.getTime())
    d.setDate(d.getDate() + day)
    yield d
  }
}
