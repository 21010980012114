import { styled } from 'Theme'

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
`

export const Overlay = styled.div`
  background-color: ${({ theme }): string => theme.loadingIndicator.overlayBackground};
  width: 100%;
  height: 100%;
  opacity: ${({ theme }): string => theme.loadingIndicator.overlayOpacity};
`

export const LoadingIndicator = styled.div`
  position: fixed;

  padding: ${({ theme }): string => theme.loadingIndicator.padding};

  text-align: center;

  background: ${({ theme }): string => theme.loadingIndicator.background};
  color: ${({ theme }): string => theme.loadingIndicator.color};

  border: 1px solid transparent;
  border-radius: ${({ theme }): string => theme.loadingIndicator.borderRadius};

  width: 100%;

  @media (min-width: ${({ theme }): string => theme.sizes.mdContainer}) {
    width: 24rem;
  }
`

export const Message = styled.p`
  margin-bottom: 0.5rem;
`
