import { colors } from './colors'

interface INotificationType {
  /** Background color of the notification */
  background: string
  /** Color of both title and description */
  color: string
  /** Special styling for disabled state */
  bordered: {
    /** Border color */
    border: string
    /** Text color */
    color: string
  }
}

export interface IAlert {
  error: INotificationType
  info: INotificationType
  success: INotificationType
  warning: INotificationType
}

export const alert: IAlert = {
  error: {
    background: colors.red,
    color: colors.white,
    bordered: {
      border: colors.red,
      color: colors.red,
    },
  },
  info: {
    background: colors.blue,
    color: colors.white,
    bordered: {
      border: colors.blue,
      color: colors.white,
    },
  },
  success: {
    background: colors.green,
    color: colors.white,
    bordered: {
      border: colors.green,
      color: colors.white,
    },
  },
  warning: {
    background: colors.orange,
    color: colors.white,
    bordered: {
      border: colors.orange,
      color: colors.white,
    },
  },
}
