import { FormikActions } from 'formik'

/** Back end enum
 * public enum EAviCancelOption
 * {
 *   Bank = 3,
 *   Avi = 2,
 *   BankGiro = 1
 * }
 */
export enum AviRepaymentOption {
  bank = '3',
  avi = '2',
  bg = '1',
}

export interface IAviFormData {
  name: string
  email: string
  phoneNumber: string
  accountNumber?: string
  address?: string
  bank?: string
  bgNumber?: string
  city?: string // postort
  clearingNumber?: string
  postcode?: string
  selectedPaymentOption: AviRepaymentOption
}

export interface IAviFormProps {
  onSubmit: (values: IAviFormData, actions: FormikActions<IAviFormData>) => void
  onCancel?: () => void
  submitButtonText?: string
  descriptionText?: string
}
