import { Instance, types } from 'mobx-state-tree'

export const WebUIParameters = types.model({
  forceCallbackUrlRedirectOnComplete: types.boolean,
  forceCallbackUrlRedirectOnAbort: types.boolean,
  omitBookingStep1: types.boolean,
  removeContactDetails: types.boolean,
  removeTopMenuReturnButton: types.boolean,
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IWebUIParameters extends Instance<typeof WebUIParameters> {}
