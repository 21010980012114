import * as React from 'react'

import { IModalTriggerProps } from './ModalTrigger.interfaces'
import { Button } from 'Components'

export const ModalTrigger: React.FC<IModalTriggerProps> = ({
  as = 'button',
  triggerComponent,
  onOpen,
  triggerText,
}) => {
  const triggerType = !!as && (as === 'text' ? 'text' : as === 'button' && 'button')

  const elem = triggerComponent ? (
    <div onClick={onOpen}>{triggerComponent}</div>
  ) : triggerType === 'text' ? (
    <p onClick={onOpen}>{triggerText}</p>
  ) : (
    triggerType === 'button' && <Button onClick={onOpen}>{triggerText}</Button>
  )

  if (elem) {
    return elem
  }

  throw new Error('You must provide a text, icon or a custom component to act as the trigger')
}
