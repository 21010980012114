import { Instance, flow, types } from 'mobx-state-tree'

import { API_DEPRECATED, IAvailableProducts, IBookingDto, IProductDto, IVehicleProducts } from 'Api_DEPRECATED'
import { IProduct, Product, ProductModelFromProductDto } from './Products'
import { IVehicle, Vehicle, VehicleModelFromVehicleBasicInfo } from './Vehicle'
import { IBookingStore } from './BookingStore'
import { FlowReturn, distinctArray } from 'Utils'

const ProductsDataForVehicle = types
  .model({
    campaignCodeMessage: types.maybeNull(types.string),
    products: types.array(Product),
    vehicle: Vehicle,
  })
  // FIXME: Justera så vi eventuellt inte behöver inaktivera den här regeln
  .views(self => ({
    get hasReinspectionDeadlineInFuture(): boolean {
      return self.products.some(
        (product): boolean =>
          product.controlCode === 'BE' && !!product.deadline && new Date(product.deadline) > new Date(),
      )
    },
    get getReinspectionDeadline(): Date | undefined {
      const product = self.products.find((item): boolean => item.controlCode === 'BE')
      return (product && product.deadline) || undefined
    },
  }))

// FIXME: Justera så vi eventuellt inte behöver inaktivera den här regeln
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProductsPerVehicle extends Instance<typeof ProductsDataForVehicle> {}

export interface IReinspectionMap {
  [regNo: string]: { vehicle: IVehicle; productData: IProductsPerVehicle }
}

// FIXME: Vad är motivationen till den här store:n? Är inte den överflödig? Den används endast på /vehicleAndProducts så varför inte använda component-state?
// Är motivationen att man ska kunna gå tillbaka till /vehicleAndProducts utan att behöva hämta data?
export const ProductsStore = types
  .model('ProductsStore', {
    productsDataForVehicle: types.map(ProductsDataForVehicle),
    selectedProducts: types.map(types.array(types.number)),
    driveInMyCar: false,
  })
  // FIXME: Justera så vi eventuellt inte behöver inaktivera den här regeln. Försök använda `.actions((self): { [keys: string]: Function } => {` för return type
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  .views(self => ({
    get hasSelectedReinspectionMap(): IReinspectionMap {
      const hasSelectedReinspectionMap: IReinspectionMap = {}

      const allRegNos = distinctArray([
        ...Array.from(self.productsDataForVehicle.keys()),
        ...Array.from(self.selectedProducts.keys()),
      ])

      allRegNos.forEach((regNo): void => {
        const productsDataForVehicle: IProductsPerVehicle | undefined = self.productsDataForVehicle.get(regNo)
        if (
          self.selectedProducts.has(regNo) &&
          !!productsDataForVehicle &&
          productsDataForVehicle.hasReinspectionDeadlineInFuture
        ) {
          const reinspectionProduct = productsDataForVehicle.products.find(
            (product): boolean => product.controlCode === 'BE',
          )
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          if (!!reinspectionProduct && self.selectedProducts.get(regNo)!.includes(reinspectionProduct.id)) {
            hasSelectedReinspectionMap[regNo] = {
              productData: productsDataForVehicle,
              vehicle: productsDataForVehicle.vehicle,
            }
          }
        }
      })

      return hasSelectedReinspectionMap
    },

    // FIXME: Fix correct return type for this property
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    get hasCampaignCodeMessageArray() {
      return Array.from(self.productsDataForVehicle).filter(
        (productData): boolean => !!productData[1].campaignCodeMessage,
      )
    },

    /**
     * One way to check if the user has searched for a vehicle.
     *
     * The first vehicle, in a new booking, isn't added to the booking. Therefore we can't use BookingStore to check the number of vehicles.
     *
     * Note: This is a workaround to check if there are any vehicles.
     *
     * @returns True if the user has searched for a vehicle
     */
    get hasAddedVehicle(): boolean {
      return self.productsDataForVehicle.size > 0 ? true : false
    },
  }))
  // FIXME: Justera så vi eventuellt inte behöver inaktivera den här regeln. Försök använda `.actions((self): { [keys: string]: Function } => {` för return type
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  .actions(self => {
    return {
      toggleDriveInMyCar() {
        self.driveInMyCar = !self.driveInMyCar
      },
      setDriveInMyCar(value: boolean) {
        self.driveInMyCar = value
      },
      /**
       * Get the available products for the booking with regno and update productStore with new products
       */
      GetAvailableProductsForBookingWithRegno: flow(function* (
        bookingNumber: string,
        regno: string,
      ): FlowReturn<IAvailableProducts, boolean> {
        const productsData: IAvailableProducts = yield API_DEPRECATED.Bookings.getAvailableProductsForBooking(
          bookingNumber,
          regno,
        )

        if (productsData.IsAllowed === false) {
          return false
        }

        if (productsData.Products) {
          const parsedProducts: IProduct[] = productsData.Products.map(
            (product: IProductDto): IProduct => ProductModelFromProductDto(product),
          )

          self.productsDataForVehicle.set(productsData.Vehicle.RegNo.toUpperCase(), {
            campaignCodeMessage: productsData.CampaignCodeMessage,
            products: parsedProducts,
            vehicle: VehicleModelFromVehicleBasicInfo(productsData.Vehicle),
          })
        }

        // Return true to indicate that the vehicle was allowed to be added to the productStore
        return true
      }),

      DeleteVehicle: flow(function* (
        bookingNumber: string,
        regno: string,
        bookingStore: IBookingStore,
      ): FlowReturn<IBookingDto> {
        const vehicles: IVehicleProducts[] = []
        self.productsDataForVehicle.forEach((ppv: IProductsPerVehicle): void => {
          const ppvRegno = ppv.vehicle.regNo

          vehicles.push({
            Products: ppvRegno === regno ? [] : self.selectedProducts.get(ppvRegno),
            Regno: ppvRegno,
          })
        })

        // FIXME: Är detta rätt? Ska man alltid ta bort fordonet från BE @Niclas?
        const booking: IBookingDto = yield API_DEPRECATED.Bookings.putSetProducts(bookingNumber, {
          Vehicles: vehicles,
        })
        self.productsDataForVehicle.delete(regno)
        bookingStore.updateBookingInBookingStore(booking)
      }),

      SetSelectedProducts: (selectedProducts: Map<string, number[]>, driveInMyCar: boolean): void => {
        self.selectedProducts = self.selectedProducts.replace(selectedProducts)
        self.driveInMyCar = driveInMyCar
      },

      setSelectedProductsOnRegNo: (regNo: string, productIds: number[]): void => {
        self.selectedProducts = self.selectedProducts.set(regNo, productIds)
      },
    }
  })

// FIXME: Justera så vi eventuellt inte behöver inaktivera den här regeln
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProductsStore extends Instance<typeof ProductsStore> {}
