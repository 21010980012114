import { ITheme, styled } from 'Theme'
import { ThemedStyledProps } from 'styled-components'

import { Card, Module, Text } from 'Components/Generic' // ! Måste komma till grunden med det hela. Importerar man endast `from 'Components'` blir det white screen of death. - https://teams.microsoft.com/l/message/19:ccc1fff627184df1a567f8ba776530cf@thread.skype/1570698258187?tenantId=e43d1ffa-e60e-472c-ba30-4fcc10372f3d&groupId=31cba7ba-07fa-42e0-a1f6-08feb832b0a6&parentMessageId=1570698258187&teamName=Alterview.OPUS&channelName=General&createdTime=1570698258187

import { StyledParagraph } from 'Components/Generic/Paragraph/Paragraph.styles'
import { StyledHeading } from 'Components/Generic/Heading/Heading.styles'
import { ITextProps } from 'Components/Generic/Text/Text.interfaces'

import { StyledRegNoInput } from 'Components/Opus/RegNoInput/RegNoInput.styles'
import { IStyledSummaryCardProps } from './SummaryCard.interfaces'

export const StyledSummaryCard = styled(Card)`
  border-radius: ${({ theme }): string => theme.borderRadius};
  padding: 1rem;

  ${StyledParagraph}, ${StyledHeading} {
    color: ${({ theme }): string => theme.colors.primaryDark};
  }

  ${StyledParagraph} {
    font-weight: bold;
  }

  width: 100%;
`

export const Line = styled.hr`
  height: 2px;

  background: ${({ theme }): string => theme.colors.gray};
  border: none;

  margin: ${({ theme }): string => theme.spacing.normal} 0;
`

export const StationAndDate = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

export const PlateRow = styled.div`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;

  margin-top: 0;

  ${StyledRegNoInput} {
    margin-bottom: 1rem;
    max-width: 10rem;

    &:only-child,
    &:last-child {
      margin-bottom: 0;
    }

    input {
      padding-right: 0;
      padding-left: 0;
    }
  }

  @media (min-width: ${({ theme }): string => theme.sizes.smContainer}) {
    flex-direction: row;
    flex-wrap: nowrap;

    margin-top: 0;

    ${StyledRegNoInput} {
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }

  @media (min-width: ${({ theme }): string => theme.sizes.mdContainer}) {
    justify-content: flex-end;
  }
`

export const JustifiedRow = styled.div<{ margin?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ margin }) => margin && `margin-top: 1.25rem`}
`

export const StyledText = styled(Text).attrs((props: ThemedStyledProps<React.PropsWithChildren<ITextProps>, ITheme>): {
  color: string | undefined
} => ({
  color: props.color || props.theme.colors.primaryDark,
}))``

export const ImageContainer = styled(Module)<IStyledSummaryCardProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    align-self: center;
    max-width: 100%; /* We recommend that the long version of the Payment Method Display at your site is between 330-440 px, and 240-312 px for the short version. Note that you should not add px after the size parameter. https://developers.klarna.com/resources/branding-visuals/payment-method-display/#width*/
    height: auto;
  }
`
export const JustifiedRowWithMargin = styled(JustifiedRow)`
  margin-top: 1rem;
`
