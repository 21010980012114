import { styled } from 'Theme'

export const StyledHeader = styled.header`
  /* Keep the Header at the top */
  display: flex;
  z-index: 1;
  justify-content: center;

  position: fixed;
  top: 0px;
  width: 100%;

  & > div {
    margin: 0 calc(100vw - 100% + 1rem);
  }

  background-color: ${({ theme }): string => theme.header.backgroundColor};
`
