import * as React from 'react'

import {
  IconDefinition,
  faArrowRight,
  faBicycle,
  faCar,
  faCarSide,
  faImage,
  faMotorcycle,
  faRv,
  faShuttleVan,
  faSnowmobile,
  faSpinnerThird,
  faTimes,
  faTractor,
  faTruck,
  faTruckMonster,
  faTruckMoving,
  faTruckPickup,
  faTruckPlow,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

/* Import components here */
import { Alert, Button, Modal } from 'Components'
import {
  ButtonWrapper,
  CardHeader,
  LastInspectionDate,
  RegistrationNumber,
  StyledVehicleDataCard,
  TrashButton,
  VehicleInfo,
} from './VehicleDataCard.styles'

/* Import interfaces here */
import { IVehicleDataCardProps } from './VehicleDataCard.interfaces'

/* Import utilities here */
import { VehicleType } from 'DataTypes'
import { formatToLocaleNumericYMD } from 'Utils/Date'

export const VehicleDataCard: React.FunctionComponent<IVehicleDataCardProps> = ({
  isDeletingVehicle,
  deleteVehicle,
  hideTrashcan,
  buttonCallback,
  buttonDisabled,
  // trashHandle,
  showRequireSupportAlert,
  vehicle,
}) => {
  const { color, vehicleDescription, inspectionDeadline, regNo, vehicleTypeId } = vehicle
  const { t } = useTranslation()

  const onClick = async (): Promise<void> => {
    if (deleteVehicle) await deleteVehicle(regNo)
  }

  const vehicleIconName = (vehicleTypeId: number): IconDefinition => {
    switch (vehicleTypeId) {
      case VehicleType.LBUSS:
        return faShuttleVan

      case VehicleType.LHB:
        return faRv

      case VehicleType.HV:
        return faImage

      case VehicleType.LLB:
        return faTruck

      case VehicleType.MC:
        return faMotorcycle

      case VehicleType.MOPED:
        return faBicycle

      case VehicleType.LMRED:
        return faTruckPlow

      case VehicleType.LPB:
        return faCarSide

      case VehicleType.LSLÄP:
        return faImage

      case VehicleType.LTGHJUL:
        return faTruckMonster

      case VehicleType.LTGSK:
        return faSnowmobile

      case VehicleType.LTGSL:
        return faImage

      case VehicleType.TGSNÖ:
        return faSnowmobile

      case VehicleType.LTGV:
        return faImage

      case VehicleType.LTR:
        return faTractor

      case VehicleType.TBUSS:
        return faShuttleVan

      case VehicleType.THB:
        return faRv

      case VehicleType.THV:
        return faImage

      case VehicleType.TLB:
        return faTruckMoving

      case VehicleType.TMRED:
        return faTruckPickup

      case VehicleType.TPB:
        return faCarSide

      case VehicleType.TSLÄP:
        return faImage

      case VehicleType.TTGHJUL:
        return faTruckMonster

      case VehicleType.TTGSK:
        return faSnowmobile

      case VehicleType.TTGSL:
        return faImage

      case VehicleType.TTGV:
        return faImage

      case VehicleType.TTR:
        return faTractor

      default:
        return faCar
    }
  }

  return (
    <StyledVehicleDataCard>
      <ButtonWrapper>
        {!hideTrashcan && (
          <Modal
            header={t('vehiclesAndProducts:deleteVehicle', {
              regNo: regNo,
            })}
            leftButton={{
              component: <Button title={t('common:noSave')} />,
              closeOnClick: true,
            }}
            rightButton={{
              component: <Button title={t('common:yesRemove')} onClick={onClick} />,
              callbackFn: onClick,
              closeOnClick: false,
            }}
            triggerComponent={
              <TrashButton>
                <FontAwesomeIcon icon={faTimes} />
              </TrashButton>
            }
          >
            {isDeletingVehicle && <FontAwesomeIcon icon={faSpinnerThird} spin={true} />}
          </Modal>
        )}
      </ButtonWrapper>
      {showRequireSupportAlert && <Alert key={regNo} message={t('vehiclesAndProducts:a+b-message')} type={'warning'} />}
      {/* buttonCallback is used for design */}
      <CardHeader buttonCallback={buttonCallback}>
        <div>
          <FontAwesomeIcon fixedWidth={true} icon={vehicleIconName(vehicleTypeId)} pull="left" size="lg" />
          <RegistrationNumber>{regNo}</RegistrationNumber>
          <VehicleInfo>
            {color} {vehicleDescription}
          </VehicleInfo>
          {!!inspectionDeadline && (
            <LastInspectionDate>
              {t('vehiclesAndProducts:inspectionDeadline')}
              {inspectionDeadline && formatToLocaleNumericYMD(inspectionDeadline)}
            </LastInspectionDate>
          )}
          {/*<DrivingStatus>{t(parsedStatusCode(statusCode))}</DrivingStatus>*/}
        </div>
        <div>
          {buttonCallback && (
            <Button
              disabled={buttonDisabled}
              icon={<FontAwesomeIcon icon={faArrowRight} />}
              iconRight={true}
              style={{ marginTop: '0.5rem' }}
              title={t('vehiclesAndProducts:chooseStationAndTime')}
              variant="accent"
              onClick={buttonCallback}
            ></Button>
          )}
        </div>
      </CardHeader>
    </StyledVehicleDataCard>
  )
}

export default VehicleDataCard
