import { Configuration, ConfigurationParameters } from '@cdab/opus-api-client'
import { EnvironmentVariables } from 'Config/environment'

const configParams: ConfigurationParameters = {
  basePath: EnvironmentVariables.API_URL,
  baseOptions: {
    timeout: EnvironmentVariables.API_TIMEOUT * 1000,
  },
}
export const apiConfig = new Configuration(configParams)
