import { styled } from 'Theme'

import { StyledInput } from 'Components/Generic/Input/Input.styles'

export const StyledSearchForm = styled.div`
  align-items: center;
  border: 0.1rem solid ${({ theme }): string => theme.colors.lightGray};
  display: flex;
  justify-content: space-between;

  svg {
    &:first-of-type {
      margin-left: 1rem;
    }

    &:last-of-type {
      margin-right: 1rem;
    }
  }

  ${StyledInput} {
    background: transparent;
    box-shadow: none;
  }

  @keyframes pulse {
    0% { box-shadow: 0 0 0 0 #f0f0f0; }
  }

/*
  ${StyledInput}:not(:focus) {
    animation: pulse 1.5s 5s infinite;
    box-shadow: 0 0 0 1.5em rgb(255 255 255 / 0%);;
  }*/

  /* ALTERNATIVE ANIMATION */

  /*
  ${StyledInput}:focus {
    animation: pulse 0.5s 1;
    box-shadow: 0 0 0 1.5em rgb(255 255 255 / 0%);;
  }
  */

  /*
  ${StyledInput} {
    background: transparent;
    /*background-image: linear-gradient(#ffffff);
    background-size: 300% 100%;
    box-shadow: none;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    cursor: pointer !important;*/
  }
  */

  /*
  ${StyledInput}:hover {
    background-image: linear-gradient(to right,#ffffff,#f0f0f0,#ffffff,#ffffff);
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  */
  /*
  ${StyledInput}:focus {
    background-image: linear-gradient(to right,#ffffff,#f0f0f0,#ffffff,#ffffff);
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  */
`
