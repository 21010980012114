import * as React from 'react'

/* Import components here */
import { StyledListExtraRight } from './ListExtraRight.styles'

/* Import interfaces here */
import { IListExtraRightProps } from './ListExtraRight.interfaces'

/* Import utilities here */

export const ListExtraRight: React.FC<IListExtraRightProps> = ({ children, ...props }) => {
  return <StyledListExtraRight {...props}>{children}</StyledListExtraRight>
}

export default ListExtraRight
