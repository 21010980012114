import * as React from 'react'

/* Import components here */
import SVGLogo from './opus-logo.svg'

import { StyledLogo } from './Logo.styles'

/* Import interfaces here */
import { ILogoProps, IStyledLogoProps } from './Logo.interfaces'

/* Import utilities here */

export const Logo: React.FC<ILogoProps> = props => {
  const styleProps = props as IStyledLogoProps
  return (
    <StyledLogo {...styleProps}>
      <a className="brand" href="https://opus.se/" rel="noopener noreferrer" target="_blank">
        <img alt="OPUS logo" src={SVGLogo} />
      </a>
    </StyledLogo>
  )
}

export default Logo
