import * as React from 'react'

/* Import components here */
import { LicensePlateIcon, StyledRegNoInput } from './RegNoInput.styles'

/* Import interfaces here */
import { IRegNoInputProps } from './RegNoInput.interface'
import { Input } from 'Components'

export const RegNoInput: React.FC<IRegNoInputProps> = ({ border, disabled, registrationNumber, ...props }) => {
  return (
    <StyledRegNoInput border={!!border}>
      <LicensePlateIcon>
        <span className="eu-icon" />
        <span>S</span>
      </LicensePlateIcon>
      <Input defaultValue={registrationNumber} disabled={disabled} {...props} />
    </StyledRegNoInput>
  )
}

export default RegNoInput
