import { colors } from './colors'

export interface IButton {
  background: string
  borderRadius: string
  color: string
  description: {
    size: string
    fontWeight: string
  }
  disabled: {
    background: string
    color: string
  }
  rounded: string
  selected: {
    background: string
    color: string
    iconColor: string
  }
  size: {
    large: string
    small: string
  }
  title: {
    size: string
  }
  variant: {
    accent: {
      background: string
      color: string
      hover: string
      disabled: {
        background: string
        color: string
      }
    }
    danger: {
      background: string
      border: string
      color: string
    }
    primary: {
      background: string
      color: string
      disabled: {
        background: string
        color: string
      }
      hover: string
    }
    secondary: {
      background: string
      border: string
      color: string
      disabled: {
        background: string
        color: string
      }
    }
  }
}

export const button: IButton = {
  background: colors.secondary,
  borderRadius: '0.25rem',
  color: colors.white,
  description: {
    fontWeight: 'bold',
    size: '0.875rem',
  },
  disabled: {
    background: colors.primaryDark,
    color: colors.primaryLight,
  },
  rounded: '100vh',
  selected: {
    background: colors.white,
    color: colors.primary,
    iconColor: colors.primary,
  },
  size: {
    large: '1.5rem 1rem',
    small: '0.75rem 1rem',
  },
  title: {
    size: '1rem',
  },
  variant: {
    accent: {
      background: colors.accent,
      color: colors.white,
      hover: colors.accentFocused,
      disabled: {
        background: colors.accentLight,
        color: colors.white,
      },
    },
    danger: {
      background: colors.white,
      border: colors.red,
      color: colors.red,
    },
    primary: {
      background: colors.secondary,
      color: colors.white,
      disabled: {
        background: colors.primaryDark,
        color: colors.primaryLight,
      },
      hover: colors.secondaryLight,
    },
    secondary: {
      background: colors.white,
      border: colors.secondary,
      color: colors.secondary,
      disabled: {
        background: colors.primaryDark,
        color: colors.primaryLight,
      },
    },
  },
}
