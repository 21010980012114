import * as React from 'react'
import _ from 'lodash'
/* Import components here */
import { StyledFieldset } from './RadioGroup.styles'

/* Import interfaces here */
import { IRadioButtonProps, IRadioGroupProps } from './RadioGroup.interfaces'

const RadioButton: React.FC<IRadioButtonProps> = ({ field, label, name, ...props }) => (
  <div>
    <input
      name={name || (field && field.name)}
      type="radio"
      value={props.id} // could be something else for output?
      {...field}
      {..._.omit(props, 'form')}
    />
    <label htmlFor={props.id}>{label}</label>
  </div>
)

/**
 * source: https://codesandbox.io/s/pjqp3xxq7q?from-embed
 */
export class RadioGroup extends React.Component<IRadioGroupProps> {
  public static RadioButton = RadioButton

  public render(): JSX.Element {
    const { children, label } = this.props

    return (
      <StyledFieldset role="radiogroup">
        {label && <legend>{label}</legend>} {children}
      </StyledFieldset>
    )
  }
}

export default RadioGroup
