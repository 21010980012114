import { castToSnapshot } from 'mobx-state-tree'

import { DEFAULT_AVAILABLE_BOOKING_ACTIONS, LogConfig, Routes } from 'Config'
import {
  BookingStore,
  IBookingStore,
  IProductsStore,
  IRootStore,
  ISessionStore,
  IStationsStore,
  IUIStore,
  ProductsStore,
  RootStore,
  SessionStore,
  StationsStore,
  UIStore,
  enableStorePersistance,
  getPersistedSnapshot,
} from 'Models'
import { UNSETTIME } from 'Constants'

/**
 * Setup the root store.
 */
export const setupRootStore = async (): Promise<IRootStore> => {
  const bookingStore: IBookingStore = BookingStore.create({
    time: UNSETTIME, // TODO: Kontrollera om den här används på rätt sätt.
  })
  const productsStore: IProductsStore = ProductsStore.create({})
  const stationsStore: IStationsStore = StationsStore.create({})
  const uiStore: IUIStore = UIStore.create({
    bookingActions: DEFAULT_AVAILABLE_BOOKING_ACTIONS,
    currentPage: undefined,
    pages: {},
  })

  const snapshot = getPersistedSnapshot<ISessionStore>('OPUS-STATE', 'sessionStorage')
  const sessionStore: ISessionStore = snapshot ? SessionStore.create(snapshot) : SessionStore.create()

  const rootStore: IRootStore = RootStore.create({
    bookingStore: castToSnapshot(bookingStore),
    productsStore: castToSnapshot(productsStore),
    stationsStore: castToSnapshot(stationsStore),
    uiStore: castToSnapshot(uiStore),
    sessionStore: castToSnapshot(sessionStore),
  })

  rootStore.uiStore.initPagesFromArray(Routes)
  rootStore.uiStore.setCurrentPage(window.location.pathname)

  enableStorePersistance(sessionStore, 'OPUS-STATE', 'sessionStorage')

  // reactotron logging
  LogConfig.setRootStore(rootStore)

  return rootStore
}
