import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import formatDate from 'date-fns/format'
import svLocale from 'date-fns/locale/sv'
import enLocale from 'date-fns/locale/en-GB'

import { EnvironmentVariables } from 'Config/environment'

// English
import enCheckout from 'I18n/en/checkout.json'
import enCommon from 'I18n/en/common.json'
import enErrors from 'I18n/en/errors.json'
import enPayment from 'I18n/en/payment.json'
import enStationsAndTime from 'I18n/en/stationsAndTime.json'
import enSummary from 'I18n/en/summary.json'
import enValidation from 'I18n/en/validation.json'
import enVehicleAndProducts from 'I18n/en/vehiclesAndProducts.json'

// Swedish
import svValidation from 'I18n/en/validation.json'
import svCheckout from 'I18n/sv/checkout.json'
import svCommon from 'I18n/sv/common.json'
import svErrors from 'I18n/sv/errors.json'
import svPayment from 'I18n/sv/payment.json'
import svStationsAndTime from 'I18n/sv/stationsAndTime.json'
import svSummary from 'I18n/sv/summary.json'
import svVehicleAndProducts from 'I18n/sv/vehiclesAndProducts.json'

const options = {
  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'localStorage', 'htmlTag', 'navigator', 'path', 'subdomain'], // Change place on htmlTag and navigator to get best detextion

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // only detect languages that are in the whitelist
  checkWhitelist: true,
}

const resources = {
  en: {
    checkout: enCheckout,
    common: enCommon,
    errors: enErrors,
    payment: enPayment,
    stationsAndTime: enStationsAndTime,
    summary: enSummary,
    validation: enValidation,
    vehiclesAndProducts: enVehicleAndProducts,
  },
  sv: {
    checkout: svCheckout,
    common: svCommon,
    errors: svErrors,
    payment: svPayment,
    stationsAndTime: svStationsAndTime,
    summary: svSummary,
    validation: svValidation,
    vehiclesAndProducts: svVehicleAndProducts,
  },
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(
    {
      debug: EnvironmentVariables.I18NEXT_DEBUG,
      defaultNS: 'common',
      detection: options,
      fallbackLng: ['sv', 'en'],
      whitelist: ['sv'], // Add `en` to enable english translation. Remember to check `Utils/i18n.ts` for default language
      load: 'languageOnly',
      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
        format(value: Date | string, format, lng): string {
          // TODO: Skriv om till en switch
          if (!(value instanceof Date) && format === 'uppercase') {
            return value.toUpperCase()
          }
          if (!(value instanceof Date) && format === 'capitalize') {
            return `${value[0].toUpperCase() + value.substring(1)}`
          }

          if (value instanceof Date && format === 'dateTimeFormat') {
            const dateFormat = lng === 'en' ? 'MM-dd-yyyy h:mm a' : 'yyyy-MM-dd HH:mm'
            return formatDate(value, dateFormat)
          }
          if (value instanceof Date && format === 'dateFormat') {
            const dateFormat = lng === 'en' ? 'MM-dd-yyyy' : 'yyyy-MM-dd'
            return formatDate(value, dateFormat)
          }
          if (value instanceof Date && format === 'weekday') {
            const locale = lng === 'en' ? enLocale : svLocale
            return formatDate(value, 'EEEE', { locale })
          }
          if (value instanceof Date && format === 'dateAndMonth') {
            const dateFormat =
              lng === 'en' ? { format: 'MMMM d', locale: enLocale } : { format: 'd MMMM', locale: svLocale }
            return formatDate(value, dateFormat.format, { locale: dateFormat.locale })
          }

          return ''
        },
      },
      react: {
        wait: true,
      },
      resources,
    },
    (err): void => {
      if (err) {
        // eslint-disable-next-line no-console
        return console.log('something went wrong loading', err)
      }
    },
  )

export default i18next
