// NOTE: Make sure values are lower case
export enum PossibleRootPathValue {
  checkout = '/checkout',
  stationsAndTime = '/stationsandtime',
  summary = '/summary',
  vehiclesAndProducts = '/vehiclesandproducts',
  sso = '/sso',
  klarnaFailed = '/klarnafailed',
}

interface IPathsStructure {
  checkout: PossibleRootPathValue
  stationsAndTime: PossibleRootPathValue
  summary: PossibleRootPathValue
  vehiclesAndProducts: PossibleRootPathValue
  [key: string]: PossibleRootPathValue
}

export const Paths: IPathsStructure = {
  checkout: PossibleRootPathValue.checkout,
  stationsAndTime: PossibleRootPathValue.stationsAndTime,
  summary: PossibleRootPathValue.summary,
  vehiclesAndProducts: PossibleRootPathValue.vehiclesAndProducts,
  klarnaFailed: PossibleRootPathValue.klarnaFailed,
  sso: PossibleRootPathValue.sso,
}

export const STARTPAGE = Paths.vehiclesAndProducts

export const PathValues = Object.keys(Paths).map((key): string => Paths[key].toString())

export interface IRouteData {
  nextPath: string | undefined
  rootPath: string
  previousPath: string | undefined
}

export const Routes: IRouteData[] = [
  {
    nextPath: PossibleRootPathValue.summary,
    previousPath: undefined,
    rootPath: Paths.klarnaFailed,
  },
  {
    nextPath: PossibleRootPathValue.vehiclesAndProducts,
    previousPath: undefined,
    rootPath: Paths.sso,
  },
  {
    nextPath: PossibleRootPathValue.stationsAndTime,
    previousPath: undefined,
    rootPath: Paths.vehiclesAndProducts,
  },
  {
    nextPath: Paths.checkout,
    previousPath: Paths.vehiclesAndProducts,
    rootPath: Paths.stationsAndTime,
  },
  {
    nextPath: Paths.summary,
    previousPath: Paths.stationsAndTime,
    rootPath: Paths.checkout,
  },
  {
    nextPath: undefined,
    previousPath: undefined,
    rootPath: Paths.summary,
  },
]
