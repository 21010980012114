import * as React from 'react'

import classNames from 'classnames'

/* Import components here */
import { StyledAlert } from './Alert.styles'

/* Import interfaces here */
import { IAlertProps } from './Alert.interfaces'

/* Import utilities here */

export const Alert: React.FC<IAlertProps> = ({ bordered, centered = false, type, message, ...props }) => {
  return (
    <StyledAlert centered={centered} className={classNames(type, bordered && 'bordered')} {...props}>
      {message}
    </StyledAlert>
  )
}

export default Alert
