import * as React from 'react'

/* Import components here */
import { Overlay, StyledHideWhileLoading } from './HideWhileLoading.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'

/* Import interfaces here */
import { IHideWhileLoadingProps } from './HideWhileLoading.interfaces'

export const HideWhileLoading: React.FC<IHideWhileLoadingProps> = ({ children, ...props }) => {
  const { isLoading, error } = props
  return (
    <StyledHideWhileLoading {...props}>
      {children}{' '}
      {(isLoading || error) && (
        <Overlay>{!error ? <FontAwesomeIcon icon={faSpinnerThird} spin={true} /> : <span>{error}</span>}</Overlay>
      )}
    </StyledHideWhileLoading>
  )
}

export default HideWhileLoading
