import * as React from 'react'

import classNames from 'classnames'

/* Import components here */
import { Description, StyledButton, Title, TitleAndIcon } from './Button.styles'

/* Import interfaces here */
import { IButtonProps, IButtonStyleProps } from './Button.interfaces'

export const Button: React.FC<IButtonProps> = ({
  children,
  className,
  description,
  disabled,
  fixedWidth,
  full,
  horizontalSpacing,
  icon,
  iconRight,
  onClick,
  rounded,
  selected,
  size,
  style,
  title,
  type,
  variant,
  verticalSpacing,
  as,
  asProps,
  ...props
}): JSX.Element => {
  const horSpacing =
    (horizontalSpacing === 'narrow' && 'horizontalNarrow') || (horizontalSpacing === 'wide' && 'horizontalWide')
  const verSpacing =
    (verticalSpacing === 'narrow' && 'verticalNarrow') || (verticalSpacing === 'wide' && 'verticalWide')
  const styleProps: IButtonStyleProps = props

  return (
    <StyledButton
      as={as}
      className={classNames(
        className,
        fixedWidth,
        full && 'full',
        rounded && 'rounded',
        selected && 'selected',
        size,
        variant,
        horSpacing,
        verSpacing,
      )}
      disabled={disabled}
      role="button"
      style={style}
      type={type}
      onClick={onClick}
      {...asProps}
      {...styleProps}
    >
      {!!title || !!icon ? (
        <TitleAndIcon className={classNames(iconRight && 'iconRight')}>
          {icon}
          {title && <Title>{title}</Title>}
        </TitleAndIcon>
      ) : null}
      {description && <Description>{description}</Description>}
      {children}
    </StyledButton>
  )
}

export default Button
