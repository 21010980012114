import * as React from 'react'

import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

/* Import components here */
import { Button, CampaignCodeInput } from 'Components'
import { StyledFooter, StyledGrid, StyledModule } from './OpusFooter.styles'

/* Import utilities here */
import { useStores } from 'Utils'

/* Import interfaces here */
import { IFooterProps } from './OpusFooter.interfaces'

export const OpusFooter: React.FunctionComponent<IFooterProps> = ({
  history,
  hideCampaignCodeChip,
  hideNextButton,
  hidePrevButton,
  onNextClick,
  onPreviousClick,
  campaignCodeInputRef,
  onCampaignCodeChanged,
  onSubmitCampaignCode,
  nextButton,
  ...props
}) => {
  const { t } = useTranslation()
  const { uiStore } = useStores()

  function defaultOnNextClick(): void {
    toast.dismiss()
    uiStore.next(history)
  }
  function defaultOnPreviousClick(): void {
    toast.dismiss()
    uiStore.previous(history)
  }

  return (
    <StyledFooter {...props}>
      <StyledGrid columns={5}>
        <StyledModule columns={1} justifyContent="flex-start">
          {/* Never show prevPageButton if it doesn't exist */}
          {uiStore.hasPrevPage(history) && !hidePrevButton && (
            <Button title={t('common:previous')} onClick={onPreviousClick || defaultOnPreviousClick} />
          )}
        </StyledModule>
        <StyledModule columns={3} mdColumns={1} mdStartColumn={3} startColumn={2}>
          {!hideCampaignCodeChip && (
            <CampaignCodeInput
              campaignCodeInputRef={campaignCodeInputRef}
              chipProps={{
                placeholder: t('common:enterCampaignCode'),
              }}
              onChipChanged={onCampaignCodeChanged}
              onSubmit={onSubmitCampaignCode}
            />
          )}
        </StyledModule>
        <StyledModule
          columns={1}
          justifyContent={hideCampaignCodeChip ? 'flex-end' : 'flex-start'}
          mdStartColumn={hideCampaignCodeChip ? 5 : 4}
          startColumn={5}
        >
          {nextButton ??
            (uiStore.hasNextPage(history) && !hideNextButton && (
              <Button
                nowrap
                disabled={uiStore.currentPage && !uiStore.currentPage.isComplete}
                title={t('common:goAhead')}
                variant="accent"
                onClick={onNextClick || defaultOnNextClick}
              />
            ))}
        </StyledModule>
      </StyledGrid>
    </StyledFooter>
  )
}

export default withRouter(OpusFooter)
