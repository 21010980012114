import svLocale from 'date-fns/locale/sv'
import enLocale from 'date-fns/locale/en-GB'

import { i18nConfig } from 'Config'

/**
 * Get current language
 *
 * @returns Language formatted as a string, or empty string if no language is detected.
 */
export const getCurrentLng = (): string => i18nConfig.language || window.localStorage.i18nextLng || ''

/**
 * Returns a locale file from date-fns
 * @param currentLangauge - Language to get a file for
 *
 * @returns Language module form date-fns, defaults to sv
 */
export const getLocaleFile = (currentLanguage?: string): any => {
  const lng = currentLanguage || getCurrentLng()

  switch (lng) {
    case 'en':
      return enLocale

    // When enabling English in Config/i18n.ts, or any other language, this default check should be changed.
    case 'sv':
    default:
      return svLocale
  }
}
