import { styled } from 'Theme'

export const StyledListItem = styled.li`
  display: flex;
  flex: 0 1 auto;
`

export const StyledListContent = styled.div`
  flex: 1 1 auto;
  margin-top: 0.5rem;

  &:first-child {
    margin-left: 1rem;
  }

  &:last-child {
    margin-right: 1rem;
  }
`
