import { Instance, getSnapshot, types } from 'mobx-state-tree'
import { autorun } from 'mobx'
import { storageAvailable } from 'Utils'

const RebookingState = types
  .model('RebookingState', {
    bookingNumber: types.optional(types.string, ''),
    isRebooking: types.optional(types.boolean, false),
    rebookingLessThan24H: types.optional(types.boolean, false),
    lastPrice: types.optional(types.number, 0),
  })
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  .actions(self => ({
    clearRebookingState(): void {
      self.bookingNumber = ''
      self.isRebooking = false
      self.rebookingLessThan24H = false
      self.lastPrice = 0
    },

    setRebookingState({ bookingNumber, isRebooking, rebookingLessThan24H }): void {
      self.bookingNumber = bookingNumber
      self.isRebooking = isRebooking
      self.rebookingLessThan24H = rebookingLessThan24H
    },

    storeLastPrice(price: number): void {
      self.lastPrice = price
    },
  }))
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  .views(self => ({
    isLowerPrice(currentPrice: number): boolean {
      return currentPrice < self.lastPrice
    },
  }))

export const SessionStore = types.model('SessionStore', {
  rebookingState: types.optional(RebookingState, {}),
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISessionStore extends Instance<typeof SessionStore> {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRebookingState extends Instance<typeof RebookingState> {}

/**
 * Get storage
 * @param backendName - sessionStorage or localStorage, etc..
 */
const getStorageBackend = (backendName: string): Storage | undefined => {
  if (!storageAvailable(backendName)) {
    console.warn('Session storage unavailable, unable to persist state')
    return undefined
  }
  return window[backendName]
}

/**
 *  Enable the autorun for persisting a store to sessionStorage
 * @param store - store to persist
 * @param key - key to use in storage
 * @param storageBackend - use as backend. can be sessionStprage, localStorage, etc..
 */
export const enableStorePersistance = (store, key: string, storageBackend: string): void => {
  let firstRun = true

  const persistState = (): void => {
    const storage = getStorageBackend(storageBackend)
    if (!storage) return

    // NOTE: getSnapshot must be called regardless if we persist it or not.
    // otherwise the autorun stops working
    const snapshot = getSnapshot(store)

    // Prevent saving the initial state back to storage, as it was just
    // loaded from there
    if (firstRun) {
      firstRun = false
      return
    }

    storage.setItem(key, JSON.stringify(snapshot))
  }

  // Persist state everytime it is updated, debounce it to 300ms
  autorun(persistState, { delay: 300 })
}

/**
 * Get a persisted storage from sessionStorage or localStorage
 * @param key - key used in storage backend
 * @param storageBackend - sessionStorage, localStorage etc...
 */
export const getPersistedSnapshot = <T extends unknown>(key: string, storageBackend: string): T | undefined => {
  const storage = getStorageBackend(storageBackend)
  if (!storage) return

  const data = storage.getItem(key)
  if (data) {
    return JSON.parse(data)
  }
  return undefined
}
