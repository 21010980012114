import * as React from 'react'

/* Import components here */
import { StyledParagraph } from './Paragraph.styles'

/* Import interfaces here */
import { IParagraphProps } from './Paragraph.interfaces'

/* Import utilities here */

export const Paragraph: React.FC<IParagraphProps> = ({
  alignSelf = 'auto',
  margin,
  size = 'medium',
  textAlign = 'start',
  fontWeight = 'normal',
  fontStyle = 'normal',
  children,
  ...props
}) => {
  return (
    <StyledParagraph
      alignSelf={alignSelf}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      margin={margin}
      size={size}
      textAlign={textAlign}
      {...props}
    >
      {children}
    </StyledParagraph>
  )
}

export default Paragraph
