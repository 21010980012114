import { styled } from 'Theme'

export const StyledCard = styled.div`
  padding: 1rem;

  color: ${({ theme }): string => theme.colors.black};
  background: white;

  border-radius: ${({ theme }): string => theme.borderRadius};

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }): string => theme.colors.primaryDark};
  }

  a {
    color: ${({ theme }): string => theme.colors.secondary};
  }

  & + & {
    margin-top: 1.5rem;
  }

  &.none {
    padding: 0;
  }

  &.small {
    padding: 0.5rem;
  }
`
