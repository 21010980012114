import * as React from 'react'

/* Import components here */
import { StyledFooter } from './Footer.styles'

/* Import interfaces here */

export const Footer: React.FC<unknown> = ({ children, ...props }) => (
  <StyledFooter {...props} role="group">
    {children}
  </StyledFooter>
)

export default Footer
