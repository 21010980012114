import * as React from 'react'

/* Import components here */
import { Message, Overlay, LoadingIndicator as StyledLoadingIndicator, Wrapper } from './LoadingIndicator.styles'

/* Import interfaces here */
import { ILoadingIndicatorProps } from './LoadingIndicator.interfaces'

/* Import utilities here */
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const LoadingIndicator: React.FC<ILoadingIndicatorProps> = ({ message }) => {
  return (
    <Wrapper className="ie11-wrapper">
      <Overlay />
      <StyledLoadingIndicator className="ie11-loader">
        {message && <Message>{message}</Message>}
        <FontAwesomeIcon icon={faSpinnerThird} spin={true} />
      </StyledLoadingIndicator>
    </Wrapper>
  )
}

export default LoadingIndicator
