import * as React from 'react'

/* Import components here */
import { StyledHeading } from './Heading.styles'

/* Import interfaces here */
import { IHeadingProps } from './Heading.interfaces'

/* Import utilities here */
export const Heading: React.FC<IHeadingProps> = ({
  level = '1',
  margin,
  size = 'medium',
  textAlign = 'start',
  children,
  ...props
}) => {
  const asComp = `h${level}` as any // TODO: Fixa korrekt type

  return (
    <StyledHeading as={asComp} margin={margin} size={size} textAlign={textAlign} {...props}>
      {children}
    </StyledHeading>
  )
}

export default Heading
