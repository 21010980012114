import { darken } from 'polished'

// import { styled } from 'Theme'
import styled from 'styled-components'

import { IChipProps } from './Chip.interfaces'

export const Text = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;

  color: ${({ theme }): string => theme.chip.text.color};
  font-size: ${({ theme }): string => theme.chip.text.size};
  line-height: ${({ theme }): string => theme.chip.text.size};
  text-transform: ${({ theme }): string => theme.chip.text.transform};
`

export const StyledChip = styled.div`
  display: flex;
  align-items: center;

  max-width: ${(props: IChipProps): string => props.maxWidth || 'none'};

  height: 2.5rem;
  padding: 0 1rem;

  background-color: ${({ theme }): string => theme.chip.backgroundColor};
  border-radius: ${({ theme }): string => theme.chip.borderRadius};

  transition: background-color 50ms cubic-bezier(0.4, 0, 0.2, 1);

  svg {
    color: ${({ theme }): string => theme.chip.icon.color};

    &.fa-times {
      margin-right: 0.5rem;
    }
  }

  &:hover {
    background-color: ${({ theme }): string => darken('0.1', theme.chip.backgroundColor)};
    cursor: pointer;

    svg {
      color: ${({ theme }): string => darken('0.1', theme.chip.icon.color)};
    }

    ${Text} {
      color: ${({ theme }): string => darken('0.1', theme.chip.text.color)};
    }
  }
`
