import { Instance, flow, getRoot, types } from 'mobx-state-tree'
import { addSeconds, parseISO } from 'date-fns'
import _ from 'lodash'

import {
  API_DEPRECATED,
  IBookingDto,
  IBookingRowDto,
  ICancelInfo,
  ISelectTime,
  IVehicleBasicInfo,
  IVehicleProducts,
  IWebUIParametersDto,
} from 'Api_DEPRECATED'
import { BookingStatus } from 'DataTypes'
import { IAviFormData } from 'Components'

import { BookingRow, BookingRowModelFromBookingRowDto, IBookingRow } from './Booking'
import { Station as StationModel, StationModelFromStationDto } from './Stations'
import { IVehicle, Vehicle, VehicleModelFromVehicleBasicInfo } from './Vehicle'

import { config as bookingSessionConfig } from 'Config/booking'
import { FlowReturn, IGetVehicleParams } from 'Utils'
import { formatAviFormDataForBackend } from 'Utils/api'
import { UNSETTIME } from 'Constants'
import { IRootStore } from './RootStore'
import { bookingsApi } from 'Api'
import { PayBookingDto, PaymentInstructions } from '@cdab/opus-api-client'

const CheckoutKlarnaStore = types.model('CheckoutKlarnaStore', {
  orderId: types.string,
  snippet: types.string,
})

interface IPrice {
  priceWithDiscount: number
  priceWithoutDiscount: number
  discount: number
  priceWithoutVat: number
}

interface IProductPriceInfo extends IPrice {
  productId: number
  productName: string
}

interface IVehiclePriceInfo extends IPrice {
  products: IProductPriceInfo[]
}

// TODO: Behöver vi verkligen ha så många maybe? Kan man inte initiera vår store med faktiska värden? Det förenklar en hel del, för då behöver vi inte kolla om värdet är !== undefined innan vi använder det i koden.
export const BookingStore = types
  .model('BookingStore', {
    amount: types.maybe(types.number),
    prepayedAmount: types.maybe(types.number),
    bookingNumber: types.maybe(types.string),
    bookingNumberHash: types.maybe(types.string),
    bookingRows: types.array(BookingRow),
    bookingStatus: types.maybe(types.number),
    paymentStatus: types.maybe(types.number),
    campaignCode: types.maybe(types.string),
    checkoutKlarnaData: types.maybe(CheckoutKlarnaStore),
    email: types.maybe(types.string),
    phone: types.maybe(types.string),
    station: types.maybe(StationModel),
    time: types.Date,
    timestamp: types.maybe(types.Date),
    vehicles: types.array(Vehicle),
    userMessage: types.maybe(types.string),
    driveInMyCar: false,
  })
  // FIXME: Justera så vi eventuellt inte behöver inaktivera den här regeln. Försök använda `.actions((self): { [keys: string]: Function } => {` för return type
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  .actions(self => {
    const updateBookingActions = (actions: number): void => {
      const root = getRoot<IRootStore>(self)
      root.uiStore.updateBookingActions(actions)
    }

    const updateUIParams = (uiParams: IWebUIParametersDto): void => {
      const root = getRoot<IRootStore>(self)
      root.uiStore.updateWebUIParams(uiParams)
    }

    const updateBookingInBookingStore = (booking: IBookingDto): void => {
      const {
        Amount,
        PrepaidAmount,
        AvailableBookingActions,
        Id,
        BookingNumberHash,
        BookingStatus,
        CampaignCode,
        Email,
        Phone,
        Rows,
        Station,
        Time,
        Timestamp,
        Vehicles,
        UserMessage,
        PaymentStatus,
        IsDriveInMyCar,
      } = booking

      // Clear current booking
      self.vehicles.clear()
      self.bookingRows.clear()

      if (Amount) {
        self.amount = Amount
      }
      if (PrepaidAmount) {
        self.prepayedAmount = PrepaidAmount
      }
      if (Id) {
        self.bookingNumber = Id
      }

      if (BookingNumberHash) {
        self.bookingNumberHash = BookingNumberHash
      }

      if (BookingStatus) {
        self.bookingStatus = BookingStatus
      }
      if (PaymentStatus) {
        self.paymentStatus = PaymentStatus
      }
      self.bookingRows.clear()
      if (Rows) {
        Rows.forEach((bookingRow: IBookingRowDto): void => {
          self.bookingRows.push(BookingRowModelFromBookingRowDto(bookingRow))
        })
      }
      if (CampaignCode) {
        self.campaignCode = CampaignCode
      } else {
        self.campaignCode = ''
      }
      if (Email) {
        self.email = Email
      }
      if (Phone) {
        self.phone = Phone
      }
      if (Station) {
        self.station = StationModelFromStationDto(Station)
      }

      if (Timestamp) {
        self.timestamp = parseISO(Timestamp)
      }

      if (UserMessage) {
        self.userMessage = UserMessage
      }

      self.time = parseISO(Time)

      if (Vehicles) {
        self.vehicles.clear()
        Vehicles.forEach((vehicle: IVehicleBasicInfo): void => {
          self.vehicles.push(VehicleModelFromVehicleBasicInfo(vehicle))
        })
      }

      self.driveInMyCar = IsDriveInMyCar || false

      updateBookingActions(AvailableBookingActions)
    }

    return {
      SetCampaignCode: flow(function* (
        bookingNumber: string,
        campaignCode: string,
      ): FlowReturn<IBookingDto, IBookingDto> {
        const booking: IBookingDto = yield API_DEPRECATED.Bookings.putSetCampaignCode(bookingNumber, {
          CampaignCode: campaignCode,
        })

        // FIXME: success kommer alltid vara true eftersom om `campaignCode` inte är giltig kastas ett 404-fel
        // Använd try-catch i den här funktionen för att lösa problemet
        if (booking) {
          updateBookingInBookingStore(booking)
        }

        return booking
      }),

      GetBooking: flow(function* (bookingNumber: string): FlowReturn<IBookingDto> {
        const booking: IBookingDto = yield API_DEPRECATED.Bookings.getBooking(bookingNumber)

        if (booking) {
          updateBookingInBookingStore(booking)
          updateUIParams(booking.WebUIParameters)
        }
      }),

      GetVehicleBookings: flow(function* (regno: string, queryParams: IGetVehicleParams): FlowReturn<IBookingDto[]> {
        const bookings = yield API_DEPRECATED.Bookings.getVehicle(regno, queryParams)

        // Finns det en skarp bokning, en offert eller en temporär bokning?
        const theBooking = bookings.find(
          (booking: IBookingDto): boolean =>
            booking.BookingStatus === BookingStatus.ConfirmedButNotStarted ||
            booking.BookingStatus === BookingStatus.NotConfirmed ||
            booking.BookingStatus === BookingStatus.Temporary,
        )

        if (theBooking) {
          updateBookingInBookingStore(theBooking)
        }
      }),

      CreateBooking: flow(function* (Regno: string, TestCenterId?: string): FlowReturn<IBookingDto> {
        // Clear current booking if any
        self.vehicles.clear()
        // TODO: Töm bookingNumber också

        const booking = yield API_DEPRECATED.Bookings.postCreateBooking({
          Regno,
          TestCenterId,
        })

        updateBookingInBookingStore(booking)
      }),

      SetProductsOnBooking: flow(function* (
        bookingNumber: string,
        driveInMyCar: boolean,
        vehicles: IVehicleProducts[],
      ): FlowReturn<IBookingDto> {
        const booking = yield API_DEPRECATED.Bookings.putSetProducts(bookingNumber, {
          Vehicles: vehicles,
          DriveInMyCar: driveInMyCar,
        })

        updateBookingInBookingStore(booking)
      }),

      SetTimeOnBooking: flow(function* (bookingNumber: string, selectTime: ISelectTime): FlowReturn<IBookingDto> {
        const booking = yield API_DEPRECATED.Bookings.putSetTimeForBooking(bookingNumber, selectTime)
        updateBookingInBookingStore(booking)
      }),

      PayBooking: flow(function* (bookingNumber: string, paymentData: PayBookingDto) {
        const { data: paymentInstructions }: { data: PaymentInstructions } = yield bookingsApi.payBooking(
          bookingNumber,
          paymentData,
        )

        self.checkoutKlarnaData =
          paymentInstructions && !!paymentInstructions.OrderId && !!paymentInstructions.IframeContent
            ? {
                orderId: paymentInstructions.OrderId,
                snippet: paymentInstructions.IframeContent,
              }
            : undefined
      }),

      GetKlarnaConfirmation: flow(function* (bookingId: string, klarnaOrderId: string): FlowReturn<string, string> {
        const snippet = yield API_DEPRECATED.Bookings.getKlarnaConfirmation(klarnaOrderId, bookingId)
        return snippet
      }),

      CancelBooking: flow(function* (bookingNumber: string, aviData?: IAviFormData): FlowReturn<true> {
        let queryParams: { bankAccountInfo?: string } = {}

        if (aviData) {
          queryParams = {
            bankAccountInfo: formatAviFormDataForBackend(aviData),
          }
        }

        yield API_DEPRECATED.Bookings.deleteCancelBooking(bookingNumber, queryParams)
      }),

      CloneBooking: flow(function* (bookingNumber: string, emailOrMobile: string): FlowReturn<IBookingDto> {
        const booking: IBookingDto = yield API_DEPRECATED.Bookings.postCloneBooking(bookingNumber, {
          Verification: `${emailOrMobile}`,
        })

        updateBookingInBookingStore(booking)
      }),

      Reset: (): void => {
        self = BookingStore.create({
          time: UNSETTIME,
        })
      },

      UpdatePayBooking: flow(function* (bookingNumber: string, klarnaOrderId: string): FlowReturn<string> {
        yield API_DEPRECATED.Bookings.putUpdatePayBooking(bookingNumber, klarnaOrderId)
      }),

      GetCancelBookingInfo: flow(function* (bookingNumber: string): FlowReturn<ICancelInfo, ICancelInfo> {
        const cancelBookingInfo: ICancelInfo = yield API_DEPRECATED.Bookings.getCancelBookingInfo(bookingNumber)
        return cancelBookingInfo
      }),

      PaymentFailed: flow(function* (bookingNumber: string, orderId?: string): FlowReturn<IBookingDto> {
        const bookingData = yield API_DEPRECATED.Bookings.postPaymentFailed(bookingNumber, { orderId })

        updateBookingInBookingStore(bookingData)
      }),

      KeepTime: flow(function* (bookingNumber: string): FlowReturn<IBookingDto> {
        const bookingData = yield API_DEPRECATED.Bookings.putKeepTimeForBooking(bookingNumber)

        updateBookingInBookingStore(bookingData)
      }),

      ExitBooking: flow(function* (bookingNumber: string): FlowReturn<true> {
        yield API_DEPRECATED.Bookings.postExitBooking(bookingNumber)

        self = BookingStore.create({
          time: UNSETTIME,
        })
      }),

      /**
       * Refresh the temporary booking session and updates the timestamp
       */
      RefreshBooingSession: flow(function* (): FlowReturn<string> {
        if (!self.bookingNumber) return

        const newTimestamp = yield API_DEPRECATED.Bookings.putUpdateSession(self.bookingNumber)
        if (newTimestamp) {
          self.timestamp = new Date(newTimestamp)
        }
      }),

      /**
       * Load a booking with a SSO Key
       */
      LoadBookingWithSso: flow(function* (ssoKey: string): FlowReturn<IBookingDto> {
        const maybeUndefinedBooking = yield API_DEPRECATED.Bookings.getBookingFromSsoKey(ssoKey)

        if (maybeUndefinedBooking === undefined) return // Shoud never happend though
        const bookingData = maybeUndefinedBooking as IBookingDto

        updateBookingInBookingStore(bookingData)
        updateUIParams(bookingData.WebUIParameters)
      }),

      updateBookingInBookingStore,
    }
  })
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  .views(self => ({
    get bookingSessionWarningTime(): Date | null {
      if (self.bookingStatus !== -1) return null

      return self.timestamp ? addSeconds(self.timestamp, bookingSessionConfig.warnTimeout) : null
    },
    get bookingSessionEndTime(): Date | null {
      if (self.bookingStatus !== -1) return null

      return self.timestamp ? addSeconds(self.timestamp, bookingSessionConfig.endTimeout) : null
    },
    get numberOfVehicles(): number {
      return Number(self.vehicles)
    },
    /**
     * Get the products in the current booking for a vehicle
     *
     * @param regNo - The registration number to get selected products for
     * @returns Array of BookingRows
     */
    productsForVehicle(regNo: string): IBookingRow[] {
      return self.bookingRows.filter(
        (bookingRow: IBookingRow): boolean => bookingRow.regNo.toUpperCase() === regNo.toUpperCase(),
      )
    },
    /**
     * Get vehicle information for a vehicle in the current booking
     *
     * @param regNo - The registration number to get information for
     * @returns IVehicle
     */
    getVehicleInBooking(regNo: string): IVehicle | undefined {
      return self.vehicles.find((vehicle: IVehicle): boolean => vehicle.regNo === regNo)
    },

    get amountWithoutVat(): number {
      return _.sum(self.bookingRows.map(row => row.priceWithoutVat))
    },
    /**
     * Get price information about current booking
     * @returns an object such as {
     *      perVehicle: { regno: <price info per vehicle > },
     *      priceWithDiscount: <total price with discount excluded>,
     *      priceWithoutDiscount: <total price with discount not included>,
     *      vatAmount: <amount of vat in priceWithDiscount>,
     *    }
     */
    get priceInfo(): { perVehicle: { [regNo: string]: IVehiclePriceInfo } } & IPrice {
      const [totalDiscount, pricePerVehicle] = self.bookingRows.reduce(
        (
          accumulatedValue: [number, { [regNo: string]: IVehiclePriceInfo }], // accumulator
          bookingRow, // Current value
        ): [number, { [regNo: string]: IVehiclePriceInfo }] => {
          const [discountAccumulator, vehicleAccumulator] = accumulatedValue
          const { regNo, discount, price, productId, productName } = bookingRow
          // If the discount is negative, it's not a real discount. It's an
          // additional cost on the booking
          const realDiscount = discount < 0 ? 0 : discount

          // Get so far accumulated price for vehicle in current booking row
          const accumulatedVehiclePrice = _.get(vehicleAccumulator, `[${regNo}]`, {
            discount: 0,
            priceWithDiscount: 0,
            priceWithoutDiscount: 0,
            priceWithoutVat: 0,
            products: [],
          } as IVehiclePriceInfo)

          // NOTE: The discount is already reducted from the price in the
          // booking row, so to get the price without discount, we must add the
          // discount to price
          const priceForVehicle: IVehiclePriceInfo = {
            discount: accumulatedVehiclePrice.discount + realDiscount, // Total discount for vehicle
            priceWithDiscount: accumulatedVehiclePrice.priceWithDiscount + price, // Total price customer should pay for this vehicle
            priceWithoutDiscount: accumulatedVehiclePrice.priceWithoutDiscount + price + realDiscount,
            priceWithoutVat: accumulatedVehiclePrice.priceWithoutVat + bookingRow.priceWithoutVat,
            products: [
              // Append current product to vehicle products
              ...accumulatedVehiclePrice.products,
              {
                discount: realDiscount,
                priceWithDiscount: price,
                priceWithoutDiscount: price + realDiscount,
                priceWithoutVat: bookingRow.priceWithoutVat,
                productId,
                productName,
              },
            ],
          }

          const totalDiscount = discountAccumulator + realDiscount
          return [
            totalDiscount,
            {
              ...vehicleAccumulator,
              // Replace prices for vehicle with new calculated prices
              [regNo]: priceForVehicle,
            },
          ]
        },
        [0, {}],
      )
      const amount = self.amount || 0
      return {
        perVehicle: pricePerVehicle,
        priceWithDiscount: amount,
        discount: totalDiscount,
        priceWithoutDiscount: amount + totalDiscount,
        priceWithoutVat: this.amountWithoutVat,
      }
    },
  }))

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBookingStore extends Instance<typeof BookingStore> {}
