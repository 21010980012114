import { Instance, types } from 'mobx-state-tree'

export const BookingActions = types.model({
  canAddVehicle: types.boolean,
  canRemoveVehicle: types.boolean,
  canAddProduct: types.boolean,
  canRemoveProduct: types.boolean,
  canSelectStation: types.boolean,
  canSelectTime: types.boolean,
  canAddCampaignCode: types.boolean,
  canRemoveCampaignCode: types.boolean,
  canPay: types.boolean,
  canRebook: types.boolean,
  canCancelBooking: types.boolean,
  canCredit: types.boolean,
  canBankCredit: types.boolean,
  canExitRebooking: types.boolean,
  canRebookWithoutAuthentication: types.boolean,
  canSendConfirmation: types.boolean,
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBookingActions extends Instance<typeof BookingActions> {}
