import { colors } from './colors'

export interface ITabs {
  background: string
  tabItem: {
    background: string
    marginHorizontal: string
    marginVertical: string
    paddingBottom: string
    hover: {
      background: string
      borderColor: string
      color: string
    }
    border: {
      color: string
      width: string
    }
    active: {
      background: string
      borderColor: string
      color: string
      hover: {
        background: string
        borderColor: string
        color: string
      }
    }
    disabled: {
      background: string
      borderColor: string
      color: string
      hover: {
        background: string
        borderColor: string
        color: string
      }
    }
    title: {
      size: string
      lineHeight: string
    }
  }
}

export const tabs: ITabs = {
  background: 'transparent',
  tabItem: {
    active: {
      background: 'transparent',
      borderColor: colors.accent,
      color: colors.accent,
      hover: {
        background: 'inherit',
        borderColor: 'inherit',
        color: 'inherit',
      },
    },
    background: 'transparent',
    border: {
      color: colors.black,
      width: '2px',
    },
    disabled: {
      background: 'transparent',
      borderColor: colors.gray,
      color: colors.gray,
      hover: {
        background: 'transparent',
        borderColor: colors.gray,
        color: colors.gray,
      },
    },
    hover: {
      background: 'transparent',
      borderColor: colors.accent,
      color: colors.accent,
    },
    marginHorizontal: '0.75rem',
    marginVertical: '0.2rem',
    paddingBottom: '0.4rem',
    title: {
      lineHeight: '1.5rem',
      size: '1rem',
    },
  },
}
