import * as React from 'react'

/* Import components here */
import { StyledListContent, StyledListItem } from './ListItem.styles'
import { ListDescription, ListExtraLeft, ListExtraRight, ListTitle } from '../'

/* Import interfaces here */
import { IListItemProps } from './ListItem.interfaces'

/* Import utilities here */

export class ListItem extends React.Component<IListItemProps & React.HTMLAttributes<Element>> {
  public static Description = ListDescription
  public static Title = ListTitle

  public static Left = ListExtraLeft
  public static Right = ListExtraRight

  public render(): JSX.Element {
    const { children, ...props } = this.props

    const { left, right, rest, title, description } = this.sortChildren(
      React.Children.map(children || [], (child): JSX.Element => child as React.ReactElement),
    )

    return (
      <StyledListItem {...props}>
        {!!left && left}
        <StyledListContent>
          {!!title && title}
          {!!description && description}
          {!!rest && rest}
        </StyledListContent>
        {!!right && right}
      </StyledListItem>
    )
  }

  private sortChildren = (
    children: React.ReactElement[],
  ): {
    rest: React.ReactElement[] | []
    description: React.ReactElement | undefined
    left: React.ReactElement | undefined
    right: React.ReactElement | undefined
    title: React.ReactElement | undefined
  } => {
    const rest: React.ReactElement[] = []

    let description: React.ReactElement | undefined
    let left: React.ReactElement | undefined
    let right: React.ReactElement | undefined
    let title: React.ReactElement | undefined

    children.forEach((child): void => {
      if (child.type) {
        const name = child.type

        switch (name) {
          case ListItem.Description:
            description = child
            break
          case ListItem.Title:
            title = child
            break
          case ListItem.Left:
            left = child
            break
          case ListItem.Right:
            right = child
            break
          default:
            rest.push(child)
        }
      } else {
        rest.push(child)
      }
    })

    return {
      description,
      left,
      rest,
      right,
      title,
    }
  }
}

export default ListItem
