import { styled } from 'Theme'

export const OpenHoursList = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  justify-content: start;

  > span {
    &:nth-of-type(odd) {
      grid-column-start: 1;
    }
    &:nth-of-type(even) {
      grid-column-start: 2;
    }
  }
`

export const InfoText = styled.p`
  margin-top: 1rem;
`

export const Spinner = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    align-self: center;
  }
`
export const WebLink = styled.div`
  margin-top: 1rem;
`
