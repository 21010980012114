import { Instance, types } from 'mobx-state-tree'
import { BookingTime } from '@cdab/opus-api-client'
import { parseISO } from 'date-fns'

export const Time = types
  .model('Time', {
    discount: types.number,
    price: types.number,
    roundedTime: types.string,
    stationName: types.string,
    stationId: types.number,
    time: types.string,
    workshiftId: types.number,
    acpmRule: types.maybe(types.string),
  })
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  .views(self => {
    return {
      get date(): Date {
        return parseISO(self.time)
      },
      get hash(): string {
        return `${self.stationId}:${self.roundedTime}`
      },
    }
  })

// FIXME: Justera så vi eventuellt inte behöver inaktivera den här regeln
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITime extends Instance<typeof Time> {}

export const TimeModelFromTimeDto = (time: BookingTime): ITime => {
  return Time.create({
    discount: time.Discount || 0,
    price: time.Price || 0,
    roundedTime: time.RoundedTime || 'UNAVAILABLE',
    stationName: time.Station || 'UNAVAILABLE',
    time: time.Time || 'UNAVAILABLE',
    workshiftId: time.WorkshiftId || 0,
    stationId: time.StationId || 0,
    acpmRule: time.ACPMPriceRuleId?.toString(),
  })
}
