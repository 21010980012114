import * as React from 'react'

import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { ChipInputAsync } from 'Components'
import { notifyError, useStores } from 'Utils'

import { ICampaignCodeInputProps } from './CampaignCodeInput.interfaces'

export const CampaignCodeInput = observer(
  ({ campaignCodeInputRef, chipProps, onChipChanged, onSubmit }: ICampaignCodeInputProps): JSX.Element => {
    const { bookingStore } = useStores()
    const { t } = useTranslation('common')

    async function chipChanged(campaignCode: string): Promise<boolean> {
      if (!bookingStore || !bookingStore.bookingNumber) return false

      let success = false

      const isCampaignCodeEmpty =
        campaignCode.length === 0 && bookingStore.campaignCode && bookingStore.campaignCode.length > 0

      try {
        if (onSubmit) {
          await onSubmit(campaignCode)
        }

        // There is no campaign code, delete the one we have, otherwise update
        const res = await bookingStore.SetCampaignCode(
          bookingStore.bookingNumber,
          isCampaignCodeEmpty ? '' : campaignCode,
        )
        success = !!res.CampaignCode

        if (success) {
          if (onChipChanged) {
            onChipChanged(isCampaignCodeEmpty ? '' : campaignCode)
          }
        } else {
          notifyError(t('campaignCodeDoesntExist'), undefined, 'info')
        }
      } catch (e) {
        if (e && e.response && e.response.status === 404) {
          notifyError(t('campaignCodeDoesntExist'), undefined, 'info')
        } else {
          notifyError(e)
        }
      }

      return success
    }

    const internalProps = {
      text: bookingStore?.campaignCode,
    }
    const chipInternalProps = internalProps

    return (
      <ChipInputAsync
        campaignCodeInputRef={campaignCodeInputRef}
        chipProps={chipInternalProps}
        onChipChanged={chipChanged}
        {...chipProps}
      />
    )
  },
)

export default CampaignCodeInput
