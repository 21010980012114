import { styled } from 'Theme'

export const StyledFieldset = styled.fieldset`
  border: none;
  margin: 1rem 0;

  > div {
    display: inline;
    margin-right: 1rem;

    > label {
      margin-left: 0.25rem;
    }
  }
`
