import { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useStores } from './hooks'
import { ILocationState } from 'Models/UIStore'
import { PossibleRootPathValue } from 'Config'

interface IUIProps extends RouteComponentProps {
  params?: string
  state?: ILocationState
}

interface IUIJumpProps extends IUIProps {
  to: PossibleRootPathValue
}

/**
 * Utility component to declaratively jump to another page using uiStore.jump
 */
export const UIJump = withRouter(({ history, to, params, state }: IUIJumpProps): null => {
  const { uiStore } = useStores()

  useEffect((): void => {
    uiStore.jump(to, history, params, state)
  })

  return null
})

/**
 * Utility component to declaratively jump to another page using uiStore.next
 */
export const UINext = withRouter(({ history, params, state }: IUIProps): null => {
  const { uiStore } = useStores()

  useEffect((): void => {
    uiStore.next(history, params, state)
  })
  return null
})
