export enum BookingStatus {
  /** Avbokad */
  Cancelled = 8,
  /** Avbokad - Ägarbyte */
  CancelledDownerChange = 9,
  /** Bekräftad - ej påbörjad */
  ConfirmedButNotStarted = 5,
  /** Slutförd, genomförd */
  Finished = 7,
  /** Dök aldrig upp */
  NoShow = 6,
  /** Offert */
  NotConfirmed = 1,
  /** Förfallen offert */
  OverdueOffer = 12,
  /** Pausad */
  Paused = 10,
  /** Trolig No Show */
  ProbableNoShow = 11,
  /** Temporär bokning */
  Temporary = -1,
}
