import * as React from 'react'

/* Import components here */
import { StyledListTitle } from './ListTitle.styles'

/* Import interfaces here */
import { IListTitleProps } from './ListTitle.interfaces'

/* Import utilities here */

export const Title: React.FC<IListTitleProps & React.HtmlHTMLAttributes<Element>> = ({ children, ...props }) => {
  return <StyledListTitle {...props}>{children}</StyledListTitle>
}

export default Title
