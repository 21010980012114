export interface IColors {
  accent: string
  accentFocused: string
  accentLight: string
  accentThin: string
  black: string
  blue: string
  gray: string
  grayDark: string
  green: string
  licensePlateBlue: string
  lightGray: string
  lightGreen: string
  orange: string
  pink: string
  pinkLight: string
  primary: string
  primaryDark: string
  primaryLight: string
  primarySemiLight: string
  red: string
  secondary: string
  secondaryLight: string
  secondaryThin: string
  secondaryThinLight: string
  semiLightGray: string
  white: string
}

export const colors: IColors = {
  accent: '#FF8200',
  accentFocused: '#e2790b',
  accentLight: '#FFBD79',
  accentThin: 'black',
  black: '#333333',
  blue: '#4A90E2',
  gray: '#c0c0c0',
  grayDark: '#8c8c8c',
  green: '#4C9C2E',
  licensePlateBlue: '#003399',
  lightGray: '#f5f5f5',
  lightGreen: '#EAF4F4',
  orange: '#FF8200',
  pink: '#f5d2d7',
  pinkLight: '#f9dee4',
  primary: '#004851',
  primaryDark: '#003C43',
  primaryLight: '#527A7F',
  primarySemiLight: '#006571',
  red: '#D0021B',
  secondary: '#00968F',
  secondaryLight: '#12A49E',
  secondaryThin: '#00968F08',
  secondaryThinLight: '#D9F0EE',
  semiLightGray: '#ededed',
  white: '#ffffff',
}
