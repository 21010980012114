import React, { useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'

/* Import components here */
import { ErrorMessage, StyledKlarna } from './Klarna.styles'

/* Import interfaces here */
import { IKlarnaProps } from './Klarna.interfaces'

export const Klarna: React.FC<IKlarnaProps> = ({ snippet, ...props }) => {
  const klarnaRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  useEffect((): void => {
    if (!snippet) return

    // https://developers.klarna.com/documentation/klarna-checkout/integration-guide/render-the-checkout
    if (klarnaRef.current !== null) {
      klarnaRef.current.innerHTML = snippet
      const scriptList = Array.prototype.slice.call(klarnaRef.current.getElementsByTagName('script'))

      // This is necessary otherwise the scripts tags are not going to be evaluated
      for (const tag of scriptList) {
        const { parentNode } = tag
        const newScriptTag = document.createElement('script')
        newScriptTag.type = 'text/javascript'
        newScriptTag.text = tag.text
        if (parentNode !== null) {
          parentNode.removeChild(tag)
          parentNode.appendChild(newScriptTag)
        }
      }
    }
  }, [snippet])

  if (!snippet) {
    return (
      <StyledKlarna>
        <ErrorMessage>{t('errors:klarnaComponentError')}</ErrorMessage>
      </StyledKlarna>
    )
  }

  return <StyledKlarna {...props} ref={klarnaRef} />
}

export default Klarna
