import { styled } from 'Theme'

/* Import interfaces here */
import { IStyledGridProps } from './Grid.interfaces'
import { css } from 'styled-components'

const calculateGridColumns = (columns: number, mdColumns: number, xlColumns: number): any => {
  return css`
    grid-template-columns: repeat(${columns}, 1fr);

    /* Medium, and above */
    @media (min-width: ${({ theme }): string => theme.sizes.mdContainer}) {
      grid-template-columns: repeat(${mdColumns}, 1fr);
    }

    /* XL, and above */
    @media (min-width: ${({ theme }): string => theme.sizes.xlContainer}) {
      grid-template-columns: repeat(${xlColumns}, 1fr);
    }
  `
}

export const StyledGrid = styled.div<IStyledGridProps>`
  --grid-columns: ${({ columns }): string => String(columns)};
  --md-grid-columns: ${({ mdColumns }): string => String(mdColumns)};
  --xl-grid-columns: ${({ xlColumns }): string => String(xlColumns)};

  display: grid;
  grid-auto-flow: dense;
  gap: 2rem 1rem;

  ${({ columns, mdColumns, xlColumns }): string => calculateGridColumns(columns, mdColumns, xlColumns)};
`
