import { ScrollToCheckoutButton } from 'Pages/CheckoutPage/ScrollToCheckoutButton'
import * as React from 'react'

import _ from 'lodash'
import { inject } from 'mobx-react'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router'
import { Trans, withTranslation } from 'react-i18next'

/* Import components here */
import {
  BookingSessionTimer,
  Button,
  Card,
  CoronaMessage,
  Grid,
  Heading,
  LoadingIndicator,
  Module,
  PageTitle,
  Paragraph,
  PayAtStation,
  Section,
  SummaryCard,
  Tabs,
} from 'Components'

import { ConfirmationButton, StyledConfirmation, StyledPage } from './CheckoutPage.styles'
import { AviRebooking } from './components/AviRebooking'
import { KlarnaPayment } from './components/KlarnaPayment'

/* Import interfaces here */
import { CancelInfo, ETrackingEvent, PaymentOption } from '@cdab/opus-api-client'
import { ILocationState } from 'Models'
import { ICheckoutPageProps, ICheckoutPageState, ICheckoutPageStores, IPaymentDetails } from './CheckoutPage.interfaces'
import { IParentOpusHeaderProps } from 'Components/Opus/OpusHeader/OpusHeader.interfaces'

/* Import utilities here */
import { bookingsApi } from 'Api'
import { fbTrackEvent, notifyError } from 'Utils'
import { colors } from 'Theme/colors'
import { EnvironmentVariables } from 'Config/environment'

const PAYMENT_NO_OPTION = -1
const PAYMENT_STATION = 1
const PAYMENT_KLARNA = 2
const PAYMENT_CONFIRM_REBOOKING = 3 // option will not be used in the future
const PAYMENT_CONFIRM_SSO_BOOKING = 4

@inject('uiStore', 'bookingStore', 'sessionStore')
class CheckoutPage extends React.Component<ICheckoutPageProps & Partial<ICheckoutPageStores>, ICheckoutPageState> {
  public state: ICheckoutPageState = {
    needKlarnaConfirm: false,
    klarnaIsConfirmed: false,
    currentTabKey: undefined,
    leaving: false,
    loadingPaymentOptions: false,
    payAtStationFormValid: false,
    paymentDetails: {
      email: '',
      phone: '',
    },
    counter: '',
    intervalId: 0,
  }

  private isRebooking = false

  /** List of available payment options. */
  private paymentOptions: PaymentOption[] = []

  private cancelInfo: CancelInfo | undefined = undefined

  private paymentOptionsRef: React.RefObject<HTMLDivElement>

  constructor(props) {
    super(props)
    this.paymentOptionsRef = React.createRef()
  }

  startCounter() {
    const { bookingStore } = this.props as ICheckoutPageStores

    if (this.state.intervalId) {
      clearInterval(this.state.intervalId)
      this.setState({ intervalId: 0 })
    }

    const intervalId = setInterval(() => {
      const end = bookingStore.bookingSessionEndTime as Date
      const timeNow = new Date()
      const diffInMilliseconds = end.getTime() - timeNow.getTime() // Difference in milliseconds

      // Calculate minutes and seconds
      const minutes = Math.floor(diffInMilliseconds / (1000 * 60))
      const seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000)

      // Format minutes and seconds as "mm:ss"
      const formattedTime = `${minutes.toString()}:${seconds.toString().padStart(2, '0')}`

      this.setState(() => {
        return {
          counter: formattedTime,
        }
      })
    }, 1000)

    this.setState({
      intervalId: intervalId,
    })
  }

  stopCounter() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId)
      this.setState({ intervalId: 0, counter: '' })
    }
  }
  public async componentDidMount(): Promise<void> {
    const { bookingStore } = this.props as ICheckoutPageStores

    if (bookingStore.bookingNumber) {
      this.setState({ loadingPaymentOptions: true })

      this.startCounter()

      try {
        this.paymentOptions = (await bookingsApi.getPaymentOptions(bookingStore.bookingNumber)).data

        // Only set cancelInfo if it is a rebooking. This shouldn't be done on new bookings
        // For a more detailed explanation see https://dev.azure.com/cdab/Alterview.OPUS/_workitems/edit/5708
        const { bookingActions } = (this.props as ICheckoutPageStores).uiStore
        if (bookingActions.canExitRebooking) {
          this.isRebooking = true
          this.cancelInfo = (await bookingsApi.getCancelBookingInfo(bookingStore.bookingNumber)).data
        }

        // Sort the list of payment options by the `order` property
        if (this.paymentOptions) {
          this.paymentOptions.sort((a: PaymentOption, b: PaymentOption): number => (b.Id || 0) - (a.Id || 0))

          if (this.paymentOptions.length > 0) {
            this.setState({
              currentTabKey: this.paymentOptions[0].Id ? this.paymentOptions[0].Id : undefined,
            })
          } else {
            this.setState({ currentTabKey: PAYMENT_NO_OPTION })
          }
        }

        // Är Klarna ett alternativ? Hämta isåfall Klarna checkout snippet
        if (!!this.paymentOptions && this.paymentOptions.some((option): boolean => option.Id === PAYMENT_KLARNA)) {
          this.prepareKlarnaPayment()
        }
      } finally {
        this.setState({
          loadingPaymentOptions: false,
          paymentDetails: {
            email: bookingStore.email || '',
            phone: bookingStore.phone || '',
          },
        })
      }

      await fbTrackEvent({
        EventName: ETrackingEvent.InitiateCheckout,
        BookingId: bookingStore.bookingNumber,
        CustomData: {
          value: bookingStore.amountWithoutVat,
          currency: 'SEK',
        },
      })
    }

    this.saveRebookingState()
  }

  componentWillUnmount() {
    this.stopCounter()
  }

  private prepareKlarnaPayment = (): void => {
    const { uiStore } = this.props as ICheckoutPageStores
    const { canExitRebooking } = uiStore.bookingActions

    if (canExitRebooking) {
      // This is a rebooking, where klarna is used
      this.setState({ needKlarnaConfirm: true })
    }
  }

  public IsPageComplete = (): boolean => {
    return this.state.payAtStationFormValid
  }

  public render(): JSX.Element {
    const { i18n } = this.props
    const { bookingActions } = (this.props as ICheckoutPageStores).uiStore
    const { klarnaIsConfirmed, leaving, loadingPaymentOptions } = this.state
    const locationState = this.props.history.location.state as ILocationState

    const description = this.getDescription()

    return (
      <StyledPage
        className="checkout" /* used for styling in IE */
        footerProps={{
          hideCampaignCodeChip: true,
          nextButton: <ScrollToCheckoutButton target={this.paymentOptionsRef} />,
          hidePrevButton: bookingActions.canExitRebooking && klarnaIsConfirmed,
        }}
        headerProps={this.headerProps()}
        hideFooter={locationState && (locationState as ILocationState).hideFooter}
        hideNavigation={true}
        isPageCompleteCallback={this.IsPageComplete}
        {...this.props}
      >
        {leaving && <LoadingIndicator message={i18n.t('common:leaving')} />}

        {loadingPaymentOptions ? (
          <LoadingIndicator message={i18n.t('checkout:loadingPaymentOptions')} />
        ) : (
          <>
            <PageTitle
              description={description}
              style={{ marginBottom: description ? '0rem' : '1rem' }}
              title={i18n.t('checkout:titles.main')}
            />
            {EnvironmentVariables.FEATURE_SHOW_COVID19_INFO && (
              <Grid columns={6} mdColumns={7}>
                <Module columns={6} mdColumns={3} mdStartColumn={3}>
                  <CoronaMessage variant="short" />
                </Module>
              </Grid>
            )}
            <Grid columns={9}>
              <Module mdColumns={7} mdStartColumn={2} xlColumns={5} xlStartColumn={3}>
                <Section>
                  <SummaryCard showPrepayedAmount={true} showStatus={false} title={i18n.t('payment:yourBooking')} />
                </Section>
                <div ref={this.paymentOptionsRef}>
                  {!!this.paymentOptions && this.paymentOptions.length > 0 ? (
                    <Section>{this.renderPaymentOptions()}</Section>
                  ) : (
                    this.renderNoOptions()
                  )}
                </div>
              </Module>
            </Grid>
          </>
        )}
      </StyledPage>
    )
  }

  private headerProps = (): IParentOpusHeaderProps => {
    const { bookingActions } = (this.props as ICheckoutPageStores).uiStore
    const { bookingNumber, bookingStatus } = (this.props as ICheckoutPageStores).bookingStore
    const { klarnaIsConfirmed } = this.state

    const canExitRebooking = bookingActions.canExitRebooking && !klarnaIsConfirmed
    const hideExitButton = bookingActions.canExitRebooking && klarnaIsConfirmed

    return {
      bookingNumber,
      bookingStatus,
      canExitRebooking,
      hideExitButton,
    }
  }

  /**
   * Text that describes the available payment methods.
   *
   * @returns A string if both Klarna and Pay at station are the only available methods, otherwise undefined
   */
  private getDescription = (): string | undefined => {
    if (!this.paymentOptions) return undefined

    const { counter } = this.state
    /** List of the ids from available payment options */
    const paymentIds: number[] = this.paymentOptions.map((payment: PaymentOption): number => payment.Id)
    const idsToCheck = [PAYMENT_STATION, PAYMENT_KLARNA]
    const shouldShowDescription = _.intersection(idsToCheck, paymentIds).length === 2

    return shouldShowDescription
      ? this.props.i18n.t('checkout:titles.subtitle', {
          timeRemaining: counter !== '' ? counter : '0:00',
        })
      : undefined
  }

  private onNextClick = async (): Promise<void> => {
    const {
      paymentDetails: { email, phone },
    } = this.state

    await this.completeBooking(
      JSON.stringify({
        Email: email,
        Phone: phone,
      }),
      PAYMENT_STATION,
    )
  }

  /**
   * Complete the booking by calling backend and redirect to next page
   * @param paymentData - Payment data to send to backend
   * @param paymentOption - Payment method used, to send to backend
   */
  private completeBooking = async (paymentData: string, paymentOption: number): Promise<void> => {
    const { bookingStore } = this.props as ICheckoutPageStores
    const { bookingNumber } = bookingStore
    if (bookingNumber === undefined) throw new Error('missing bookingNumber')

    this.setState({ leaving: true })

    try {
      await bookingStore.PayBooking(bookingNumber, {
        Data: paymentData,
        PaymentOption: paymentOption,
      })
      this.gotoNextPage(bookingNumber)
    } catch (e) {
      notifyError(e)
      this.setState({ leaving: false })
    }
  }

  /**
   * Save rebooking state, used in summary page
   */
  private saveRebookingState = (): void => {
    const { bookingStore, sessionStore } = this.props as ICheckoutPageStores
    const { rebookingState } = sessionStore

    rebookingState.setRebookingState({
      bookingNumber: bookingStore.bookingNumber || '',
      isRebooking: this.isRebooking,
      rebookingLessThan24H: !!this.cancelInfo && this.cancelInfo.LessThan24Hours,
    })
  }

  /**
   * Go to next page or redirect to redirectUrl if requested by webuiparameters
   * @param bookingNumber - Bookingnumber used
   */
  private gotoNextPage(bookingNumber: string): void {
    if (!this.props.uiStore) throw new Error('no store on component')
    const { uiStore, history } = this.props
    const shouldRedirect = uiStore.parameters && uiStore.parameters.forceCallbackUrlRedirectOnComplete

    if (shouldRedirect && uiStore.redirectUrl) {
      window.location.href = uiStore.redirectUrl
    } else {
      toast.dismiss()
      uiStore.next(history, bookingNumber, { isRebooking: this.isRebooking })
    }
  }

  private onTabChange = (currentTabKey: string): void => {
    this.setState({ currentTabKey: Number(currentTabKey) })
  }

  private onFormChange = (payAtStationFormValid: boolean, paymentDetails: IPaymentDetails): void => {
    this.setState({ paymentDetails, payAtStationFormValid })
  }

  private onClickChangeToStationFromKlarna = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    this.setState({ currentTabKey: PAYMENT_STATION })
  }
  /**
   * Checks which options are available and renders corresponding tab
   */
  private renderPaymentOptions = (): JSX.Element => {
    const { bookingStore } = this.props as ICheckoutPageStores

    const confirmationOptions = this.paymentOptions.filter(
      (option: PaymentOption): boolean =>
        option.Id === PAYMENT_CONFIRM_REBOOKING || option.Id === PAYMENT_CONFIRM_SSO_BOOKING,
    )

    // Confirm
    if (confirmationOptions.length > 0) {
      return this.renderConfirmation(confirmationOptions[0].Id)
    }

    if (
      this.cancelInfo &&
      this.cancelInfo.PaidWithAvi &&
      this.isRebooking &&
      this.paymentOptions[0].Id === PAYMENT_KLARNA
    ) {
      return <AviRebooking bookingNumber={bookingStore.bookingNumber || ''} />
    }

    // Fler än 1 alternativ, då ska det renderas som Tabs
    const availableOptions = this.paymentOptions.map(
      (option): JSX.Element => {
        // NOTE: Ska vi verkligen använda `switch`? Finns det något bättre/snyggare alternativ?
        // Om detta ändras måste hanteringen av this.state.currentTabKey ändras

        switch (option.Id) {
          case PAYMENT_STATION:
            return this.renderTabPayAtStation()

          case PAYMENT_KLARNA:
            return this.renderTabKlarna()

          default:
            return <></>
        }
      },
    )

    return (
      <Tabs currentTab={this.state.currentTabKey?.toString()} tabHeaderAlign="center" onChangeTab={this.onTabChange}>
        {availableOptions}
      </Tabs>
    )
  }

  private renderNoOptions = (): JSX.Element => {
    const { i18n } = this.props

    return (
      <StyledConfirmation>
        <Heading>{i18n.t('checkout:payment.noOptions.methodTitle')}</Heading>
        <Paragraph>{i18n.t('checkout:payment.noOptions.description')}</Paragraph>
      </StyledConfirmation>
    )
  }

  private renderConfirmation = (paymentOption: number): JSX.Element => {
    const { bookingStore, i18n } = this.props
    const { paymentDetails, payAtStationFormValid } = this.state

    if (!bookingStore) return <></>

    /**
     * A message containing information regarding rules for the confirmation.
     *
     * **SSO**
     * If performing a booking through SSO no message will be displayed
     *
     * **Less than 24 hours**
     * If it is less than 24 hours until original the user will be informed that no credit is possible
     *
     * Otherwise show default description
     *
     */
    let confirmationMessage: string | undefined = undefined
    const isSsoBooking = paymentOption === PAYMENT_CONFIRM_SSO_BOOKING

    if (!isSsoBooking && this.cancelInfo) {
      confirmationMessage = i18n.t('checkout:payment.confirm.description')
    }

    const { amount, prepayedAmount } = bookingStore

    /** Compares current price with previous. This is used to control which button text should be used. */
    const isNewPriceHigherThanPrevious: boolean = !!amount && !!prepayedAmount && amount > prepayedAmount
    const confirmationText = isSsoBooking
      ? i18n.t('checkout:payment.confirm.methodTitle')
      : isNewPriceHigherThanPrevious
      ? i18n.t('checkout:payment.confirm.confirmAndPay')
      : i18n.t('checkout:payment.confirm.confirmRebooking')

    const showContactForm =
      (paymentOption === PAYMENT_CONFIRM_REBOOKING && !bookingStore.email && !bookingStore.phone) || // Can be deleted when PAYMENT_CONFIRM_REBOOKING is removed
      this.cancelInfo?.PaidWithAvi

    return (
      <StyledConfirmation>
        {confirmationMessage && <Paragraph>{confirmationMessage}</Paragraph>}
        <Paragraph margin={{ bottom: '1rem' }}>{i18n.t('checkout:payment.confirm.bookingConfirmation')}</Paragraph>
        {showContactForm && (
          <Card>
            <PayAtStation
              paymentDetails={paymentDetails}
              showContactForm={true}
              showSubmitButton={isSsoBooking}
              onFormChange={this.onFormChange}
              onFormSubmit={this.onNextClick}
            />
          </Card>
        )}
        <ConfirmationButton
          block={true}
          disabled={showContactForm ? !payAtStationFormValid : false}
          title={confirmationText}
          onClick={this.completeBooking.bind(
            this,
            showContactForm ? JSON.stringify({ Email: paymentDetails.email, Phone: paymentDetails.phone }) : '',
            paymentOption,
          )}
        />
      </StyledConfirmation>
    )
  }

  private handleConfirmKlarnaClick = (): void => {
    this.setState({ klarnaIsConfirmed: true })
  }

  private renderTabKlarna = (): JSX.Element => {
    const { bookingStore } = this.props as ICheckoutPageStores
    const { i18n } = this.props
    const { needKlarnaConfirm, klarnaIsConfirmed } = this.state
    const haveStationPaymentOption = -1 !== this.paymentOptions.findIndex(po => po.Id === PAYMENT_STATION)

    return (
      <Tabs.Tab
        key="klarna"
        header={<Button title={this.props.i18n.t('checkout:payWithKlarna')} />}
        tabKey={PAYMENT_KLARNA.toString()}
      >
        {needKlarnaConfirm && !klarnaIsConfirmed ? (
          <Card>
            <Paragraph color="#003C43" margin={{ vertical: 'medium' }}>
              {bookingStore.prepayedAmount ?? 0 > 0
                ? i18n.t('checkout:payment.confirm.klarnaRebookConfirm')
                : i18n.t('checkout:payment.confirm.klarnaRebookConfirmNoPrepay')}
            </Paragraph>
            <Button
              block
              title={i18n.t('checkout:payment.confirm.confirmRebooking')}
              variant="accent"
              onClick={this.handleConfirmKlarnaClick}
            />
          </Card>
        ) : (
          <KlarnaPayment
            bookingNumber={bookingStore.bookingNumber || ''}
            headerContent={
              haveStationPaymentOption && (
                <>
                  <Paragraph color="black" fontWeight="bold">
                    <Trans i18nKey="checkout:changeToStation">
                      Do you want to pay on station
                      <a href="." onClick={this.onClickChangeToStationFromKlarna}>
                        Click here
                      </a>
                      .
                    </Trans>
                  </Paragraph>
                </>
              )
            }
          />
        )}
      </Tabs.Tab>
    )
  }

  private renderTabPayAtStation = (): JSX.Element => {
    const { i18n } = this.props
    const { paymentDetails } = this.state
    const { uiStore } = this.props as ICheckoutPageStores
    const showContactForm = uiStore.parameters ? !uiStore.parameters.removeContactDetails : true

    return (
      <Tabs.Tab
        key="station"
        header={<Button title={i18n.t('checkout:payment.station.methodTitle')} />}
        tabKey={PAYMENT_STATION.toString()}
      >
        <Card>
          <Heading color={colors.primaryDark} margin={{ top: 'medium', bottom: 'large' }} textAlign="center">
            {i18n.t('checkout:payment.station.card-title')}
          </Heading>
          <Paragraph color={colors.black}>{i18n.t('checkout:payment.station.description')}</Paragraph>
          <Paragraph color={colors.black}>{i18n.t('checkout:payment.station.bookingConfirmation')}</Paragraph>
          <PayAtStation
            paymentDetails={paymentDetails}
            showContactForm={showContactForm}
            onFormChange={this.onFormChange}
            onFormSubmit={this.onNextClick}
          />
        </Card>
      </Tabs.Tab>
    )
  }
}

export default withTranslation()(withRouter(CheckoutPage))
