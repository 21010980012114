import * as React from 'react'

import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field, withFormik } from 'formik'
import { withTranslation } from 'react-i18next'

/* Import components here */
import { RegNoInput } from 'Components'
import { SearchButton, StyledForm } from './VehicleSearchForm.styles'

/* Import interfaces here */
import { IFormValues, IInnerFormProps, IVehicleSearchFormProps } from './VehicleSearchForm.interfaces'

/* Import utilities here */
import { validationSchema } from './validationSchema'

const InnerForm = ({ buttonText, dirty, errors, isSubmitting }: IInnerFormProps): JSX.Element => {
  return (
    <StyledForm>
      <Field component={RegNoInput} disabled={isSubmitting} name="regNo" placeholder="ABC 123" />
      <SearchButton
        disabled={!dirty || !!Object.keys(errors).length || isSubmitting}
        icon={isSubmitting && <FontAwesomeIcon icon={faSpinnerThird} spin={true} />}
        title={!isSubmitting ? buttonText : ''} // FIXME: Det här har slutat fungera. Vi behöver tester för att sånt här inte ska smita igenom
        type="submit"
        variant="accent"
      />
    </StyledForm>
  )
}

export const VehicleSearchForm = withTranslation()(
  withFormik<IVehicleSearchFormProps, IFormValues>({
    // Custom sync validation
    validationSchema,

    // Give the form a default value. If not included the form is an uncontrolled form and later Formik starts to control it which throws an error.
    mapPropsToValues: (): IFormValues => ({
      regNo: '',
    }),

    handleSubmit: async (values, { setSubmitting, props, resetForm }): Promise<void> => {
      resetForm() // Töm formuläret innan vi går vidare, vi kanske dismountar
      await props.onSubmit(values.regNo)
      setSubmitting(false)
    },
  })(InnerForm),
)

export default VehicleSearchForm
