import { styled } from 'Theme'

export const StyledOpusHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: ${({ theme }): string => theme.sizes.container};
  height: 4rem;

  margin: 0 1rem;

  color: ${({ theme }): string => theme.colors.primary};

  @media (min-width: ${({ theme }): string => theme.sizes.mdContainer}) {
    margin: 0;
    width: ${({ theme }): string => theme.sizes.mdContainer};
  }
  @media (min-width: ${({ theme }): string => theme.sizes.xlContainer}) {
    width: ${({ theme }): string => theme.sizes.xlContainer};
  }
`
