import React, { useMemo } from 'react'
import { Button, Modal } from 'Components'
import { useTranslation } from 'react-i18next'

type Props = {
  show: boolean
  message: string
  onClose: () => void
}

export function AcpmMessageModal(props: Props): JSX.Element {
  const { t } = useTranslation()

  const okButton = useMemo(
    () => ({
      component: <Button title={t('common:ok')} />,
      callbackFn: props.onClose,
    }),
    [props.onClose, t],
  )

  return (
    <Modal hideDefaultCloseButton={true} importantIsOpen={props.show} message={props.message} rightButton={okButton} />
  )
}
