import { styled } from 'Theme'
import { IStyledSectionTitleProps } from './Section.interfaces'

export const StyledSection = styled.section`
  margin: 1.5rem 0 0.5rem;

  &:first-of-type {
    margin-top: 0;
  }
`

export const SectionTitle = styled.h2<IStyledSectionTitleProps>`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  align-self: ${({ titleAlign }): string => titleAlign};
`
