import * as React from 'react'

/* Import components here */
import { Tab } from './Tab'
import { TabItem } from './TabItem'
import { StyledTabs, TabContent, TabHeader } from './Tabs.styles'

/* Import interfaces here */
import { ITabsProps } from './Tabs.interfaces'

import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'

export const Tabs = function Tabs(props: ITabsProps): JSX.Element {
  const { children, tabHeaderAlign, vertical, initialTab, onChangeTab, currentTab: currentTabProp } = props
  const [activeTabKey, setActiveTabKey] = useState(
    initialTab ? children[initialTab - 1].props.tabKey : children[0].props.tabKey,
  )

  useEffect(() => {
    if (currentTabProp && currentTabProp !== activeTabKey) setActiveTabKey(currentTabProp)
  }, [activeTabKey, currentTabProp])

  const onClickTabItem = useCallback(
    key => {
      setActiveTabKey(key)

      // Trigger callback function
      if (onChangeTab) onChangeTab(key)
    },
    [onChangeTab],
  )

  const currentTab: React.ReactNode = _.find(
    React.Children.toArray(children),
    (tab): boolean => _.get(tab, 'props.tabKey') === activeTabKey,
  )

  const tabContent: React.ReactNode = _.get(currentTab, 'props.children')
  return (
    <StyledTabs vertical={vertical}>
      {children.length > 1 && (
        <TabHeader tabHeaderAlign={tabHeaderAlign}>
          {children.map(
            ({ props: { disabled, header, tabKey } }): JSX.Element => (
              <TabItem
                key={tabKey}
                activeTabKey={activeTabKey}
                disabled={disabled}
                header={header}
                tabKey={tabKey}
                onChangeTab={onClickTabItem}
              />
            ),
          )}
        </TabHeader>
      )}
      <TabContent>{tabContent}</TabContent>
    </StyledTabs>
  )
}

Tabs.Tab = Tab

export default Tabs
