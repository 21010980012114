import { colors } from './colors'

export interface IEmptyState {
  background: string
  color: string
  margin: string
  icon: {
    fontSize: string
  }
  title: {
    fontSize: string
    fontWeight: string
  }
  description: {
    fontSize: string
  }
}

export const emptyState: IEmptyState = {
  background: 'transparent',
  color: colors.white,
  margin: '2rem  0',
  icon: {
    fontSize: '5rem',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'normal',
  },
  description: {
    fontSize: '1.1rem',
  },
}
