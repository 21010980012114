import * as React from 'react'

import classNames from 'classnames'

/* Import components here */
import { InputWrapper, Label, Message, StyledInput } from './Input.styles'

/* Import interfaces here */
import { IInputProps } from './Input.interfaces'

// eslint-disable-next-line react/display-name
export const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ children, id, label, message, variant, style, form, field, ...props }, forwardRef) => {
    const [myID] = React.useState(id || Math.random().toString())

    return (
      <InputWrapper className={classNames(variant)} style={style}>
        {!!label && <Label htmlFor={myID}>{label}</Label>}
        <StyledInput ref={forwardRef} id={myID} {...field} {...props} />
        {!!message && <Message>{message}</Message>}
        {children}
      </InputWrapper>
    )
  },
)

export default Input
