import * as React from 'react'

import { faCalendarTimes, faCarBuilding } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addDays, endOfDay, parseISO, startOfDay, startOfWeek } from 'date-fns'
import { get, reduce, uniqBy } from 'lodash'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { toast } from 'react-toastify'

/* Import components here */
import {
  Alert,
  AvailableTimesCalendar,
  Button,
  CoronaMessage,
  DriveInDescription,
  EmptyState,
  Grid,
  LoadingIndicator,
  Module,
  Page,
  PageTitle,
  Paragraph,
  Section,
  StationList,
  Visibility,
  getFirstDayOfWeek,
} from 'Components'

import { Hideable, SwitchButton } from './StationsTimePage.styles'
import { TimeList } from './TimeList'

/* Import interfaces here */
import { ISelectTime } from 'Api_DEPRECATED/interfaces'
import { IParentOpusHeaderProps } from 'Components/Opus/OpusHeader/OpusHeader.interfaces'
import { IStationsTimePageProps, IStationsTimePageState, IStationsTimePageStores } from './StationsTimePage.interfaces'

/* Import utilities here */
import { ETrackingEvent } from '@cdab/opus-api-client'
import { AcpmMessageModal } from 'Components/Opus/AcpmMessageModal'
import { Paths } from 'Config'
import { EnvironmentVariables } from 'Config/environment'
import { isUnsetTime } from 'Constants'
import { ProductCode } from 'DataTypes'
import { IProductsPerVehicle, IReinspectionMap } from 'Models'
import { theme } from 'Theme/theme'
import { fbTrackEvent, getPosition, notifyError, timedPromise } from 'Utils'
import { scrollTo } from 'Utils/scrollTo'

const CANCEL = 'cancel' // Used for cancelling a promise

const LOAD_DAYS = 7 * 6

@inject('bookingStore', 'productsStore', 'stationsStore', 'uiStore')
@observer
class StationsTimePage extends React.Component<
  IStationsTimePageProps & Partial<IStationsTimePageStores>,
  IStationsTimePageState
> {
  private isUnmounted = false
  private calendarRef: React.RefObject<HTMLElement>
  private timeListRef: React.RefObject<HTMLDivElement>

  public constructor(props: IStationsTimePageProps) {
    super(props)

    const { bookingStore, stationsStore } = this.props

    let selectedStations: number[] = []

    if (bookingStore && stationsStore) {
      // selectedStations
      if (bookingStore.station?.id) {
        selectedStations = [bookingStore.station.id]
      } else if (stationsStore.stations.length > 0) {
        selectedStations = [stationsStore.stations[0].id]
      }
    }

    this.state = {
      coords: undefined,
      hasClickedOnCalendar: false,
      hasClickedOnStation: false,
      leaving: false,
      loadingStations: true,
      loadingTimes: true,
      selectedDate: undefined,
      selectedFirstWeek: new Date(),
      selectedMonth: new Date(),
      selectedStations,
      selectedTime: undefined,
      showOnlyBestPrices: false,
      canShowAcpmModal: true,
      showAcpmModal: false,
      acpmMessage: '',
      viewFullMonth: false,
    }

    this.calendarRef = React.createRef()
    this.timeListRef = React.createRef()
  }

  private isMobile(): boolean {
    return matchMedia(`(max-width: ${theme.sizes.mdContainer})`).matches
  }

  public async componentDidMount(): Promise<void> {
    const { stationsStore } = this.props as IStationsTimePageStores

    stationsStore.clearTimes()
    try {
      const positionsPromise = getPosition()
        .then((coords: Coordinates | undefined): boolean => {
          if (this.isUnmounted) throw CANCEL
          if (coords) this.initStationsAndDate(coords)
          return !!coords
        })
        .catch((e): true => {
          if (e !== CANCEL) throw e
          return true
        })

      // Wait for location or 3s
      const timeout = timedPromise(3000, false)
      const didInitialize = await Promise.race([positionsPromise, timeout])

      if (!didInitialize) {
        return this.initStationsAndDate()
      }
    } catch (e) {
      notifyError(e)
    }
  }

  public componentWillUnmount(): void {
    this.isUnmounted = true
  }

  private async initStationsAndDate(coords?: Coordinates): Promise<void> {
    const { stationsStore, bookingStore } = this.props as IStationsTimePageStores

    if (coords !== undefined) {
      await this.getStations(coords.latitude, coords.longitude)
    } else {
      await this.getStations()
    }

    const stationToSelect = this.getStationToSelect()

    const timeInBooking: Date | undefined = get(this.props, 'bookingStore.time')
    const dateInBooking = timeInBooking && !isUnsetTime(timeInBooking) ? timeInBooking : null
    const firstDay = getFirstDayOfWeek(dateInBooking || new Date())

    try {
      await fbTrackEvent({
        EventName: ETrackingEvent.FindLocation,
        BookingId: bookingStore.bookingNumber,
        OptionalUserData: {
          zp: stationsStore.stationById(stationToSelect[0])?.zipCode,
        },
      })
      // eslint-disable-next-line no-empty
    } catch {}

    await this.getAvailableTimes(stationToSelect, firstDay)

    // selectedDate prio
    // 1. Datum som redan finns på bokningen
    // 2. Dagens datum om den dagen har lediga tider
    // 3. Första dagen med lediga tider
    // 4. Dagens datum
    // Finns det en datum på bokningen?
    let setToDate: Date | null = dateInBooking
    if (setToDate === null) {
      const firstAvailiableDate = stationsStore.firstAvailableTime(
        stationToSelect,
        new Date(),
        addDays(new Date(), LOAD_DAYS),
      )
      const today = new Date()
      today.setDate(today.getDate() - ((today.getDay() - 1 + 7) % 7))
      setToDate = firstAvailiableDate ? new Date(firstAvailiableDate.date.getTime()) : today

      this.setState({
        selectedFirstWeek: this.state.selectedFirstWeek
          ? firstAvailiableDate?.date || today
          : this.state.selectedFirstWeek,
      })
    } else {
      this.setState({
        selectedFirstWeek: firstDay,
      })
    }

    this.setState({
      coords,
      selectedStations: stationToSelect,
      selectedDate: setToDate,
    })
  }

  public IsPageComplete = (): boolean => {
    const { selectedTime } = this.state

    let ok = true

    if (!selectedTime) {
      ok = false
    }

    return ok
  }

  public async SetTimeOnBooking(): Promise<void> {
    const { bookingStore } = this.props as IStationsTimePageStores
    const { selectedTime } = this.state

    if (bookingStore.bookingNumber && selectedTime) {
      const selectTime: ISelectTime = {
        Time: selectedTime.time,
        WorkshiftId: selectedTime.workshiftId,
      }

      await bookingStore.SetTimeOnBooking(bookingStore.bookingNumber, selectTime)
    }
  }

  public render(): JSX.Element {
    const {
      bookingStore,
      stationsStore,
      uiStore: {
        bookingActions: { canExitRebooking },
      },
      productsStore: { hasSelectedReinspectionMap },
    } = this.props as IStationsTimePageStores

    const {
      i18n,
      history: {
        location: { state: locationState },
      },
    } = this.props
    const {
      coords,
      leaving,
      loadingTimes,
      loadingStations,
      showOnlyBestPrices,
      selectedDate,
      selectedStations,
      selectedTime,
      viewFullMonth,
      selectedFirstWeek,
      selectedMonth,
    } = this.state
    const driveInDescription = <DriveInDescription />

    // TODO: remember to change this in case calendar changes to real months
    const isWithinCurrentBounds = selectedDate
      ? viewFullMonth
        ? selectedDate >= selectedFirstWeek && selectedDate <= addDays(selectedFirstWeek, 7 * 5)
        : selectedDate >= selectedFirstWeek && selectedDate <= addDays(selectedFirstWeek, 7)
      : false

    const allTimes =
      selectedDate && isWithinCurrentBounds
        ? stationsStore.timesAtStations(selectedStations, startOfDay(selectedDate), endOfDay(selectedDate))
        : []
    const isThereAnyDiscounts = selectedDate && stationsStore.hasDiscounts(selectedStations, startOfDay(selectedDate))
    const bestPrice = selectedDate
      ? stationsStore.bestPrice(selectedStations, startOfDay(selectedDate))
      : Number.MAX_VALUE
    const hasManyDifferentPrices =
      selectedDate && stationsStore.hasManyDifferentPrices(selectedStations, startOfDay(selectedDate))

    return (
      <Page
        className="stations-time" /* used for styling in IE */
        footerProps={{
          onNextClick: this.onNextClick,
          hideCampaignCodeChip: true,
        }}
        headerProps={this.headerProps()}
        hideFooter={locationState && locationState.hideFooter}
        hideNavigation={true}
        isPageCompleteCallback={this.IsPageComplete}
        {...this.props}
      >
        {/* Loading indicators */}
        {leaving && <LoadingIndicator message={i18n.t('leaving')} />}
        {loadingStations && <LoadingIndicator message={i18n.t('stationsAndTime:loadingStations')} />}
        <Hideable show={true}>
          <PageTitle style={{ marginBottom: '1.5rem' }} title={i18n.t('stationsAndTime:titles.main')} />
          {EnvironmentVariables.FEATURE_SHOW_COVID19_INFO && (
            <Grid columns={6} mdColumns={7}>
              <Module columns={6} mdColumns={3} mdStartColumn={3}>
                <CoronaMessage variant="short" />
              </Module>
            </Grid>
          )}
          <Grid columns={9}>
            <Module mdColumns={4} xlColumns={3} xlStartColumn={2}>
              {/* Station selector */}
              <Section>
                {stationsStore.stations.length ? (
                  <>
                    <StationList
                      coords={coords}
                      date={selectedDate}
                      selectedStations={selectedStations}
                      stations={stationsStore.stations}
                      onSelectStation={this.onSelectStation}
                      onSetNewCoords={this.onSetNewCoords}
                    />
                    {<Visibility minWidth="mdContainer">{driveInDescription}</Visibility>}
                  </>
                ) : (
                  <EmptyState
                    icon={<FontAwesomeIcon icon={faCarBuilding} />}
                    title={i18n.t('stationsAndTime:noStationsToShow')}
                  />
                )}
              </Section>
            </Module>
            <Module mdColumns={4} mdStartColumn={6} xlColumns={3} xlStartColumn={6}>
              <Section ref={this.calendarRef} title={i18n.t('stationsAndTime:selectDateAndTime')}>
                {
                  <SwitchButton
                    onClick={() => {
                      this.setState({ viewFullMonth: !viewFullMonth })
                    }}
                  >
                    {viewFullMonth ? i18n.t('stationsAndTime:weekView') : i18n.t('stationsAndTime:calendarView')}
                  </SwitchButton>
                }
                <AvailableTimesCalendar
                  firstWeek={this.state.selectedFirstWeek}
                  isLoading={this.state.loadingTimes}
                  selectedDate={selectedDate}
                  selectedStations={selectedStations}
                  viewFullMonth={viewFullMonth}
                  onChangePeriod={this.onChangePeriod}
                  onSelectDate={this.onSelectedDayChange}
                />

                {hasManyDifferentPrices && !EnvironmentVariables.FEATURE_HIDE_BEST_PRICE_BUTTON && (
                  <Button
                    title={
                      showOnlyBestPrices
                        ? i18n.t('stationsAndTime:showBestPriceAlt')
                        : i18n.t('stationsAndTime:showBestPrice')
                    }
                    onClick={this.onClickShowBestTimes}
                  />
                )}
                {this.renderInspectionMessages()}
                {this.renderReinspectionMessageWarnings(hasSelectedReinspectionMap)}

                <div ref={this.timeListRef} />

                {!selectedDate && !loadingTimes && (
                  <EmptyState
                    description={i18n.t('selectDateToShowTime', { ns: 'stationsAndTime' })}
                    icon={<FontAwesomeIcon icon={faCalendarTimes} />}
                    title={i18n.t('noDateSelected', { ns: 'stationsAndTime' })}
                  />
                )}
                {selectedDate && !loadingTimes && (
                  <TimeList
                    bestPrice={bestPrice}
                    onlyBestPrice={showOnlyBestPrices}
                    selectedTime={selectedTime}
                    times={allTimes}
                    onSelectTime={this.onSelectedTimeChange}
                  />
                )}
                <Visibility maxWidth="mdContainer" minWidth="zero">
                  {driveInDescription}
                </Visibility>
                {isThereAnyDiscounts && <Paragraph>{i18n.t('stationsAndTime:discountFootnote')}</Paragraph>}
              </Section>
            </Module>
          </Grid>
        </Hideable>
        <AcpmMessageModal
          message={this.state.acpmMessage}
          show={this.state.showAcpmModal}
          onClose={() => this.setState({ showAcpmModal: false })}
        />
      </Page>
    )
  }

  private headerProps = (): IParentOpusHeaderProps => {
    const { canExitRebooking } = (this.props as IStationsTimePageStores).uiStore.bookingActions
    const { bookingStore } = this.props as IStationsTimePageStores

    const { bookingNumber, bookingStatus } = bookingStore

    return {
      bookingNumber,
      bookingStatus,
      canExitRebooking,
    }
  }

  private onNextClick = async (): Promise<boolean> => {
    const { stationsStore, bookingStore } = this.props as IStationsTimePageStores
    const { uiStore } = this.props as IStationsTimePageStores

    if (this.state.selectedTime === undefined) return false

    this.setState({ leaving: true })
    const station = stationsStore.stationById(this.state.selectedTime.stationId)
    try {
      await Promise.all([
        this.SetTimeOnBooking(),
        fbTrackEvent({
          EventName: ETrackingEvent.Schedule,
          BookingId: bookingStore.bookingNumber,
          CustomData: {
            booking_station_id: station?.id.toString() || 'unknown',
            booking_station_name: station?.name || 'unknown',
            booking_time: this.state.selectedTime.roundedTime,
          },
        }),
      ])

      toast.dismiss()
      uiStore.next(this.props.history)
    } catch (e) {
      if (e.response && e.response.data && e.response.data.Code === 8457) {
        // We know that this is a "custom" error
        // 8457: Invalid booking time
        notifyError(this.props.i18n.t('stationsAndTime:timeTaken'), '', 'info')

        const { hash, stationId } = this.state.selectedTime
        stationsStore.RemoveTime(stationId, hash)

        this.setState({ selectedTime: undefined })
      } else {
        notifyError(e)
      }
      this.setState({ leaving: false })
      return false
    }
    return true
  }

  private onSetNewCoords = (coords: Coordinates): void => {
    this.setState({
      coords,
    })
  }

  private onClickShowBestTimes = (): void => {
    this.setState({
      showOnlyBestPrices: !this.state.showOnlyBestPrices,
    })
  }

  private getStations = async (
    lat: number | undefined = undefined,
    lon: number | undefined = undefined,
  ): Promise<void> => {
    const {
      bookingStore: { bookingNumber },
      stationsStore,
    } = this.props as IStationsTimePageStores

    if (bookingNumber) {
      this.setState({
        loadingStations: true,
        selectedStations: [],
      })

      try {
        await stationsStore.GetStations(bookingNumber, lat, lon)
      } catch (e) {
        notifyError(e)
      } finally {
        // Pre select first station in list
        this.setState({
          loadingStations: false,
        })
      }
    }
  }

  private getStationToSelect = (): number[] => {
    const {
      bookingStore: { bookingNumber, station },
      stationsStore: { stations },
    } = this.props as IStationsTimePageStores
    let stationToSelect: number[] = []

    if (bookingNumber) {
      if (station && station.id !== -1) {
        stationToSelect = [station.id]
      } else if (stations.length && !this.state.selectedStations.length) {
        // Preselect the first station in the list if we have no one from the booking
        stationToSelect = this.isMobile() ? [] : [stations[0].id]
      }
    }

    return stationToSelect
  }

  private handleKeepTime = async (): Promise<void> => {
    try {
      const { history } = this.props
      const { uiStore, bookingStore } = this.props as IStationsTimePageStores
      if (!bookingStore.bookingNumber) return

      this.setState({ leaving: true })

      await bookingStore.KeepTime(bookingStore.bookingNumber)

      // Sätt att sidan är complete, annars går det inte att hoppa till nästa steg
      const thisPage = uiStore.pages.get(history.location.pathname)
      if (thisPage) {
        thisPage.setIsComplete(true)
      }

      // Hoppa till nästa sida
      uiStore.jump(Paths.checkout, history)
    } catch (e) {
      // TODO: Verifiera att detta fungerar. Lyckas inte få ett fel på `keepTime`
      if (e.response && e.response.data && e.response.data.Code === 8457) {
        // Vi vet att detta är ett "custom" fel
        // 8457: Ogiltig bokningstid
        notifyError(this.props.i18n.t('stationsAndTime:timeTaken'), '', 'info')
      } else {
        notifyError(e)
      }

      this.setState({ leaving: false })
    }
  }

  private onSelectedTimeChange = (
    time: string,
    roundedTime: string,
    workshiftId: number,
    stationId: number,
    hash: string,
  ): void => {
    this.setState({
      selectedTime: {
        hash,
        stationId,
        time,
        roundedTime,
        workshiftId,
      },
    })
  }

  private onSelectedDayChange = (date: Date): void => {
    this.setState({ selectedDate: date })
    const { hasClickedOnCalendar } = this.state

    if (this.timeListRef.current !== null && (!hasClickedOnCalendar || this.isMobile())) {
      this.setState({ hasClickedOnCalendar: true })
      scrollTo(this.timeListRef.current, { yOffset: -200 })
    }
  }

  private onChangePeriod = async (date: Date): Promise<void> => {
    const { stationsStore } = this.props as IStationsTimePageStores

    const firstDayOfWeek = getFirstDayOfWeek(date || new Date())
    this.setState({ selectedFirstWeek: firstDayOfWeek })

    await this.getAvailableTimes(this.state.selectedStations, firstDayOfWeek)
    // Select first day with available times
    const firstTime = stationsStore.firstAvailableTime(
      this.state.selectedStations,
      firstDayOfWeek,
      addDays(firstDayOfWeek, LOAD_DAYS),
    )

    const selectedDate = firstTime?.date ? firstTime.date : firstDayOfWeek

    if (this.state.selectedDate) this.setState({ selectedDate: date >= selectedDate ? date : selectedDate })
  }

  /** Update state when toggling a product */
  private onSelectStation = async (id: number): Promise<void> => {
    const { stationsStore } = this.props as IStationsTimePageStores
    const { selectedFirstWeek, hasClickedOnStation, selectedStations } = this.state
    let { selectedDate } = this.state
    const { selectedTime } = this.state
    let stationWasAdded = false

    try {
      // If not included, add it to the array
      let newArrayOfSelectedStations: number[]
      if (!selectedStations.includes(id as never)) {
        /** New array of `selectedProducts` which adds the new product item. */
        newArrayOfSelectedStations = [...selectedStations, id]
      } else {
        /** New array of `selectedProducts` without the selected product */
        newArrayOfSelectedStations = selectedStations.filter((product): boolean => product !== id)
      }
      stationWasAdded = newArrayOfSelectedStations.length - this.state.selectedStations.length > 0
      this.setState({ loadingTimes: true })

      // Fix the occation when a time is selected but the station is removed
      if (id && !stationWasAdded && !newArrayOfSelectedStations.includes(id) && selectedTime?.stationId === id) {
        this.setState({ selectedTime: undefined })
      }

      // Get available times
      const firstAvailiableDayInPeriod = startOfWeek(new Date(), { weekStartsOn: 1 })

      await this.getAvailableTimes(newArrayOfSelectedStations, firstAvailiableDayInPeriod)

      // Select first day with available times
      const firstTime = stationsStore.firstAvailableTime(
        newArrayOfSelectedStations,
        firstAvailiableDayInPeriod,
        addDays(selectedFirstWeek, LOAD_DAYS),
      )
      if (this.isMobile()) {
        selectedDate = undefined
      } else {
        selectedDate = firstTime ? firstTime.date : selectedDate
      }

      const firstDayOfWeek = startOfWeek(selectedDate || new Date(), { weekStartsOn: 1 })
      this.setState({ selectedFirstWeek: firstTime?.date ? firstTime.date : firstDayOfWeek })

      // this will always select the first possible date! Is that really what you'd want?
      this.setState({ selectedStations: newArrayOfSelectedStations, selectedDate })
    } catch (e) {
      notifyError(e)
    }

    if (this.calendarRef.current !== null && stationWasAdded && (!hasClickedOnStation || this.isMobile())) {
      this.setState({ hasClickedOnStation: true })
      scrollTo(this.calendarRef.current, { yOffset: -200 })
    }
  }

  private getAvailableTimes = async (selectedStations: number[], start: Date): Promise<void> => {
    const {
      bookingStore: { bookingNumber },
      stationsStore,
    } = this.props as IStationsTimePageStores

    if (!bookingNumber || !selectedStations) {
      // Make sure we don't show the loading
      this.setState({ loadingTimes: false })
      return
    }

    this.setState({ loadingTimes: true })

    const end = addDays(start, LOAD_DAYS)
    try {
      await stationsStore.UpdateAvailableTimes(bookingNumber, {
        stationIds: selectedStations,
        start,
        end,
      })
    } finally {
      this.setState({ loadingTimes: false })
    }

    // Only one selected station
    this.acpmPopup(selectedStations)
  }

  private acpmPopup(selectedStations: number[]) {
    const messages = this.props.stationsStore?.acpmMessages(selectedStations) ?? []
    if (this.state.canShowAcpmModal && messages.length > 0) {
      this.setState({ showAcpmModal: true, acpmMessage: messages.join(' '), canShowAcpmModal: false })
    }
  }

  /**
   * If we have selected controlCode B
   * Render an alert with a customized message depending on selected products and date.
   *
   */
  private renderInspectionMessages = (): JSX.Element | undefined => {
    const { i18n } = this.props
    const {
      productsStore: { selectedProducts, productsDataForVehicle },
    } = this.props as IStationsTimePageStores

    if (!this.state.selectedDate) return

    const selectedDate = new Date(this.state.selectedDate)

    const selectedProductIds = Array.from(selectedProducts).reduce(
      (acc: { regNo: string; productId: number }[], [regNo, selectedProducts]) =>
        acc.concat(selectedProducts.map(productId => ({ regNo, productId }))),
      [] as { regNo: string; productId: number }[],
    )

    const vehicleInspectionData = reduce(
      selectedProductIds,
      (acc, cur) => {
        const { regNo, productId: selectedProductId } = cur
        const productDataForVehicle: IProductsPerVehicle | undefined = productsDataForVehicle.get(regNo)

        if (!productDataForVehicle) return acc

        const productData = productDataForVehicle.products.find((product): boolean => product.id === selectedProductId)

        if (!productData) return acc

        if (
          productData.controlCode.toUpperCase() === ProductCode.B &&
          !productDataForVehicle.vehicle.inspectionDeadline
        )
          return acc

        // 2 fordon, släp och personbil. nÄr ska respektive visas?

        const { inspectionDeadline, regNo: regNoInProductData } = productDataForVehicle.vehicle
        /** Check if selected date is after inspection deadline. That will result in driving ban for the vehicle. */
        const inspectionDeadlineDate = parseISO(inspectionDeadline || '')
        const willGetDrivingBan = selectedDate >= inspectionDeadlineDate
        const hasDrivingBan = new Date() >= inspectionDeadlineDate

        if (!hasDrivingBan && !willGetDrivingBan) return acc

        if (hasDrivingBan && productDataForVehicle.hasReinspectionDeadlineInFuture) return acc

        return [
          ...acc,
          {
            regNo: regNoInProductData,
            inspectionDeadline: inspectionDeadlineDate,
            willGetDrivingBan,
            hasDrivingBan,
          },
        ]

        // PBI4946 Visa bara meddelande om körförbud kommer att inträda
      },
      [] as { regNo: string; inspectionDeadline: Date; willGetDrivingBan: boolean; hasDrivingBan: boolean }[],
    )

    const uniqData = uniqBy(vehicleInspectionData, 'regNo')
    return (
      <>
        {uniqData.map(
          (inspectionData): JSX.Element => {
            const { inspectionDeadline, regNo, hasDrivingBan } = inspectionData
            const message = hasDrivingBan
              ? i18n.t('stationsAndTime:drivingBan', { regNo })
              : i18n.t('stationsAndTime:lastInspectionDateForVehicle', {
                  inspectionDeadline,
                  regNo,
                })

            return <Alert key={regNo} message={message} type="error" />
          },
        )}
      </>
    )
  }

  private renderReinspectionMessageWarnings = (hasSelectedReinspectionMap: IReinspectionMap): JSX.Element[] => {
    const { i18n } = this.props

    return reduce(
      Object.keys(hasSelectedReinspectionMap),
      (acc, regNo) => {
        const deadline = hasSelectedReinspectionMap[regNo].productData.getReinspectionDeadline
        if (!deadline) return acc

        return [
          ...acc,
          <Alert
            key={regNo}
            message={i18n.t('stationsAndTime:reinspectionDeadlineMessage', { regNo, deadline })}
            type="info"
          />,
        ]
      },
      [] as JSX.Element[],
    )
  }
}

export default withTranslation()(withRouter(StationsTimePage))
