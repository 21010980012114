import React, { ReactNode } from 'react'

import { inject } from 'mobx-react'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'
import {
  faCalendarEdit,
  faCreditCard,
  faPaperPlane,
  faPrint,
  faSpinnerThird,
  faStickyNote,
  faWindowClose,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormikActions } from 'formik'
import _ from 'lodash'

/* Import components here */
import {
  Alert,
  AviForm,
  Button,
  ButtonGroup,
  Card,
  Grid,
  Input,
  Klarna,
  LoadingIndicator,
  Modal,
  Module,
  Notification,
  PageTitle,
  Paragraph,
  RebookCard,
  Section,
  StationCard,
  SummaryCard,
} from 'Components'
import { StyledPage } from './SummaryPage.styles'
import logo from './jordklot.svg'

/* Import interfaces here */
import { ISummaryPageProps, ISummaryPageState, ISummaryPageStores } from './SummaryPage.interfaces'
import { IAviFormData } from 'Components/Opus/AviForm'

/* Import utilities here */
import { API_DEPRECATED } from 'Api_DEPRECATED'
import { Paths, PossibleRootPathValue } from 'Config'
import { BookingStatus } from 'DataTypes'
import { notifyError } from 'Utils'
import { getConfirmationPrintUrl } from 'Utils/api'
import { IParentOpusHeaderProps } from 'Components/Opus/OpusHeader/OpusHeader.interfaces'
import { EnvironmentVariables } from 'Config/environment'

class SummaryPage extends React.Component<ISummaryPageProps & Partial<ISummaryPageStores>, ISummaryPageState> {
  public constructor(props: ISummaryPageProps) {
    super(props)

    this.state = {
      addingCampaignCode: false,
      campaignCodeValue: '',
      canCancelBooking: false,
      cancelBookingInfo: undefined,
      cancelling: false,
      canRebook: false,
      loading: true,
      sendingEmail: false,
      showAviForm: false,
      showCampaignCodeForm: false,
      showCancelCompleteDialog: false,
      showRebookForm: false,
      hideKlarnaConfirmation: false,
      isRebooking: false,
      isRebookingLessThan24H: false,
      isRebookingLowerPrice: false,
      showCampaignCodePriceLower: false,
    }
  }

  public IsPageComplete = (): boolean => true

  // TODO: Bryt ner till mindre funktioner som anropas från componentDidMount()
  public async componentDidMount(): Promise<void> {
    const {
      match: { params },
    } = this.props
    const { bookingStore, uiStore } = this.props as ISummaryPageStores

    try {
      /*
       * Betallänk - om urlen innehåller '/payment' ska vi direkt till checkout för betalning.
       * TODO: Flytta det här till en egen route i AppRouter för att flytta hela den här logiken från den här komponenten.
       */
      if (this.props.match.url.includes('payment')) {
        if (params.bookingId) {
          await bookingStore.GetBooking(params.bookingId)
          this.navigateToCheckout()
          return
        }
      }

      // GetKlarnaConfirmation måste göras före GetCancelBookingInfo, eftersom GetKlarnaCConfirmation uppdaterar den skarpa bokningen och tar bort den temporära efter betalning.
      if (!!params.bookingId && !!params.klarnaOrderId) {
        // TODO: Om ett fel uppstår här kraschar sidan. Det borde fångas och rendera summary-sidan ändå fast med rätt error-alert
        // Ett fel kan triggas genom att skicka med ett felaktigt/gammalt klarna-id
        const klarnaConfirmationSnippet = await bookingStore.GetKlarnaConfirmation(
          params.bookingId,
          params.klarnaOrderId,
        )

        this.setState({
          klarnaConfirmationSnippet,
        })
      }

      if (
        params.bookingId &&
        (!uiStore.previousPage || uiStore.previousPage.rootPath !== PossibleRootPathValue.vehiclesAndProducts)
      ) {
        // Hämta endast bokningen om senaste sidan INTE var vehiclesAndProducts
        await bookingStore.GetBooking(params.bookingId)
      }

      if (uiStore.parameters?.forceCallbackUrlRedirectOnComplete && uiStore.redirectUrl) {
        window.location.href = uiStore.redirectUrl
      }

      if (bookingStore.bookingNumber) {
        if (
          bookingStore.bookingStatus !== BookingStatus.NotConfirmed &&
          bookingStore.bookingStatus !== BookingStatus.ConfirmedButNotStarted
        ) {
          // Bokningen är INTE bekräftad

          if (uiStore.bookingActions.canPay === true) {
            // Betalningen avbröts eller ett fel inträffade.
            // Anropa back-end för att bekräfta bokningen och visa summary
            await bookingStore.PaymentFailed(bookingStore.bookingNumber, params.klarnaOrderId)
          } else {
            // Unauthorized, skicka användaren till startsidan
            // Eventuellt skicka med en flagga som visar att man ska "hoppa framåt" beroende på vilken data som finns i bookingStore
            uiStore.jump(PossibleRootPathValue.vehiclesAndProducts, this.props.history)
            return
          }
        }

        const cancelBookingInfo = await bookingStore.GetCancelBookingInfo(bookingStore.bookingNumber)
        this.setState({ cancelBookingInfo })
      } else {
        throw new Error('FIXME: Kunde inte hitta bokningen!') // TODO: Visa 404-sida?
      }
    } catch (e) {
      const { bookingStore, uiStore } = this.props as ISummaryPageStores

      // TODO: Kanske inte tillräckligt "allvarligt"? Kanske borde navigera till en error-sida istället?
      notifyError(e)

      bookingStore.Reset()
      uiStore.restart(this.props.history)

      return
    }

    this.restoreRebookingStatus()

    this.pollForBookingActions()
    this.setState({ loading: false })
  }

  private restoreRebookingStatus = (): void => {
    const { bookingStore, sessionStore } = this.props as ISummaryPageStores
    const { rebookingState } = sessionStore

    if (rebookingState.bookingNumber !== bookingStore.bookingNumber) {
      return // Saved state is for another booking
    }

    this.setState({
      isRebookingLowerPrice: rebookingState.isLowerPrice(
        bookingStore.amount !== undefined ? bookingStore.amount : Number.MAX_VALUE,
      ),
      isRebooking: rebookingState.isRebooking,
      isRebookingLessThan24H: rebookingState.rebookingLessThan24H,
    })
  }

  private pollForBookingActions = (): void => {
    const delay = (timeout: number): Promise<void> =>
      new Promise((resolve): void => {
        setTimeout(resolve, timeout)
      })

    const poll = async (): Promise<void> => {
      const {
        bookingStore: { bookingNumber, GetBooking },
        uiStore: {
          bookingActions: { canCancelBooking, canRebook },
        },
      } = this.props as ISummaryPageStores

      this.setState({
        canRebook,
        canCancelBooking,
      })

      if (!bookingNumber) throw new Error('No bookingnumber in store')

      if (!canRebook || !canCancelBooking) {
        await delay(5000)
        await GetBooking(bookingNumber)
        poll()
      }
    }

    poll()
  }

  public render(): JSX.Element {
    const { i18n } = this.props
    const {
      uiStore: { bookingActions },
      bookingStore,
    } = this.props as ISummaryPageStores
    const {
      addingCampaignCode,
      cancelBookingInfo,
      cancelling,
      klarnaConfirmationSnippet,
      hideKlarnaConfirmation,
      loading,
      sendingEmail,
      showCampaignCodeForm,
      showCancelCompleteDialog,
      isRebooking,
      showCampaignCodePriceLower,
    } = this.state

    // FIXME: Hur gör vi med 404? Visa tom data eller navigera till en error-sida?

    const message = cancelling ? i18n.t('common:cancelling') : i18n.t('common:loading')

    const { title, message: payMessages, summaryTitle } = this.getTitle()
    const subtitle = i18n.t('summary:titles.subtitle', { date: bookingStore.time })

    const isAllowedToAddCampaignCode =
      bookingActions.canAddCampaignCode && bookingActions.canRebook && bookingActions.canCancelBooking

    const rebookingMessage = i18n.t('summary:rebookSuccessMessage')

    return (
      <StyledPage
        className="summary" /* used for styling in IE */
        isPageCompleteCallback={this.IsPageComplete}
        {...this.props}
        headerProps={this.headerProps()}
        hideFooter={true}
        hideNavigation={true}
      >
        {(cancelling || loading || addingCampaignCode) && <LoadingIndicator message={message} />}

        {!loading &&
          (this.state.showAviForm ? (
            <Grid columns={9}>
              <Module mdColumns={5} mdStartColumn={3} xlColumns={5} xlStartColumn={3}>
                <Section>
                  <AviForm onCancel={this.onAviFormCancel} onSubmit={this.onAviFormSubmit} />
                </Section>
              </Module>
            </Grid>
          ) : this.state.showRebookForm && bookingActions.canRebook ? (
            <Grid columns={9}>
              <Module mdColumns={5} mdStartColumn={3} xlColumns={3} xlStartColumn={4}>
                <Section title={i18n.t('summary:rebook')}>
                  <RebookCard onCancel={this.onRebookCancel} onSubmit={this.onRebookSubmit} />
                </Section>
              </Module>
            </Grid>
          ) : (
            <>
              <Grid columns={6}>
                <Module columns={6} mdColumns={6} mdStartColumn={0} xlColumns={4} xlStartColumn={2}>
                  <PageTitle title={title}>
                    {payMessages}
                    <Paragraph margin={{ bottom: 'xlarge' }} textAlign="center">
                      {subtitle}
                    </Paragraph>
                  </PageTitle>
                  {isRebooking && <Alert message={rebookingMessage} type="info" />}
                  {showCampaignCodePriceLower && (
                    <Alert message={i18n.t('summary:campaignCodeAddedWithLowerPrice')} type="success" />
                  )}
                  <SummaryCard image={<img alt="Opus logo" src={logo} />} showStatus={true} title={summaryTitle} />
                </Module>
                <Module columns={6} mdColumns={3} xlColumns={2} xlStartColumn={2}>
                  {this.renderPaymentMessage()}
                  {klarnaConfirmationSnippet && !hideKlarnaConfirmation && (
                    <Klarna snippet={klarnaConfirmationSnippet} />
                  )}
                  {bookingStore.station && <StationCard station={bookingStore.station} />}
                </Module>
                <Module columns={6} mdColumns={3} xlColumns={2}>
                  <ButtonGroup direction="vertical" verticalSpacing="none">
                    {bookingActions.canPay && (
                      <Button
                        icon={<FontAwesomeIcon icon={faCreditCard} />}
                        title={i18n.t('common:pay')}
                        variant="accent"
                        verticalSpacing="narrow"
                        onClick={this.navigateToCheckout}
                      />
                    )}
                    {bookingActions.canSendConfirmation && (
                      <Button
                        disabled={sendingEmail}
                        icon={
                          <FontAwesomeIcon icon={sendingEmail ? faSpinnerThird : faPaperPlane} spin={sendingEmail} />
                        }
                        title={i18n.t('summary:sendConfirmationAgain')}
                        verticalSpacing="narrow"
                        onClick={this.sendEmailConfirmation}
                      />
                    )}
                    <Button
                      disabled={!this.state.canRebook}
                      icon={<FontAwesomeIcon icon={faCalendarEdit} />}
                      title={i18n.t('summary:rebook')}
                      verticalSpacing="narrow"
                      onClick={this.handleClickRebook}
                    />
                    {!EnvironmentVariables.FEATURE_HIDE_CAMPAIGN_CODE_BUTTON_ON_SUMMARY &&
                      showCampaignCodeForm &&
                      isAllowedToAddCampaignCode &&
                      this.renderCampaignCodeInput()}
                    {!EnvironmentVariables.FEATURE_HIDE_CAMPAIGN_CODE_BUTTON_ON_SUMMARY &&
                      !showCampaignCodeForm &&
                      isAllowedToAddCampaignCode && (
                        <Button
                          icon={<FontAwesomeIcon icon={faStickyNote} />}
                          title={i18n.t('common:addCampaignCode')}
                          verticalSpacing="narrow"
                          onClick={this.handleClickToggleAddCampaignCode}
                        />
                      )}
                    {bookingStore.bookingNumber &&
                      (bookingActions.canSendConfirmation ||
                        bookingStore.bookingStatus === BookingStatus.ConfirmedButNotStarted) && (
                        <Button
                          as="a"
                          asProps={{
                            href: getConfirmationPrintUrl(bookingStore.bookingNumber),
                            target: '_blank',
                          }}
                          icon={<FontAwesomeIcon icon={faPrint} />}
                          title={i18n.t('summary:printConfirmation')}
                          verticalSpacing="narrow"
                        />
                      )}
                    <Modal
                      header={i18n.t('summary:modalBookingCancelled')}
                      hideDefaultCloseButton={true}
                      importantIsOpen={showCancelCompleteDialog}
                      message={i18n.t('summary:modalWelcomeBack')}
                      rightButton={{
                        component: <Button title={i18n.t('common:ok')} />,
                        callbackFn: this.redirectToStart,
                      }}
                    />
                    {
                      // TODO: REFAKTORER DEN HÄR LOGIKEN
                      cancelBookingInfo &&
                        (cancelBookingInfo.LessThan24Hours
                          ? this.renderModalLessThan24Hours()
                          : cancelBookingInfo.PaidWithAvi
                          ? this.renderModalPaidWithAvi()
                          : cancelBookingInfo.PaidWithKlarna
                          ? this.renderModalPaidWithKlarna()
                          : this.renderModalDefaultCancel())
                    }
                  </ButtonGroup>
                </Module>
              </Grid>
            </>
          ))}
      </StyledPage>
    )
  }

  private headerProps = (): IParentOpusHeaderProps => {
    const {
      bookingStore: { bookingNumber, bookingStatus },
      uiStore: {
        bookingActions: { canExitRebooking },
        redirectUrl,
      },
    } = this.props as ISummaryPageStores
    const { i18n } = this.props

    return {
      bookingNumber,
      bookingStatus,
      restartButtonText: redirectUrl ? i18n.t('common:back') : i18n.t('common:restart'),
      canExitRebooking,
    }
  }

  private renderModalLessThan24Hours = (): JSX.Element => {
    const { i18n } = this.props
    const { canCancelBooking } = this.state

    const leftButton = {
      closeOnClick: true,
      component: <Button title={i18n.t('summary:rebook')} type="button" variant="primary" />,
      callbackFn: this.handleClickRebook,
    }
    const rightButton = {
      closeOnClick: true,
      component: <Button title={i18n.t('common:cancelBooking')} variant="accent" />,
      callbackFn: this.cancelBooking,
    }

    if (canCancelBooking) {
      return (
        <Modal
          header={i18n.t('summary:modalWantToCancel')}
          leftButton={leftButton}
          message={i18n.t('summary:modalMessageLess24')}
          rightButton={rightButton}
          triggerComponent={
            <Button
              block={true}
              icon={<FontAwesomeIcon icon={faWindowClose} />}
              title={i18n.t('common:cancelBooking')}
              variant="danger"
              verticalSpacing="narrow"
            />
          }
        />
      )
    }

    return <></>
  }

  private renderModalPaidWithAvi = (): JSX.Element => {
    const { i18n } = this.props
    const { canCancelBooking } = this.state

    const leftButton = {
      closeOnClick: true,
      component: <Button title={i18n.t('common:cancel')} variant="secondary" />,
    }

    const rightButton = {
      component: <Button title={i18n.t('common:cancelBooking')} variant="accent" />,
      callbackFn: this.cancelBookingPaidWithAvi,
    }

    if (canCancelBooking) {
      return (
        <Modal
          header={i18n.t('summary:repayment')}
          leftButton={leftButton}
          message={i18n.t('summary:modalMessageRepayment')}
          rightButton={rightButton}
          triggerComponent={
            <Button
              block={true}
              icon={<FontAwesomeIcon icon={faWindowClose} />}
              title={i18n.t('common:cancelBooking')}
              variant="danger"
              verticalSpacing="narrow"
            />
          }
        />
      )
    }

    return <></>
  }

  private renderModalPaidWithKlarna = (): JSX.Element => {
    const { i18n } = this.props
    const { canCancelBooking, canRebook } = this.state

    const cancelButton = {
      component: <Button title={i18n.t('common:cancel')} variant="secondary" />,
      closeOnClick: true,
    }

    const cancelBookingButton = {
      closeOnClick: true,
      component: <Button title={i18n.t('common:cancelBooking')} variant="accent" />,
      callbackFn: this.cancelBooking,
    }

    const rebookButton = {
      closeOnClick: true,
      component: <Button title={i18n.t('summary:rebook')} type="button" variant="primary" />,
      callbackFn: this.handleClickRebook,
    }

    if (!canRebook) {
      return (
        <Modal
          header={i18n.t('summary:modalWantToCancel')}
          leftButton={cancelButton}
          message={i18n.t('summary:modalMessageMore24NoRebook')}
          rightButton={cancelBookingButton}
          triggerComponent={
            <Button
              block={true}
              icon={<FontAwesomeIcon icon={faWindowClose} />}
              title={i18n.t('common:cancelBooking')}
              variant="danger"
              verticalSpacing="narrow"
            />
          }
        />
      )
    }

    if (canCancelBooking) {
      return (
        <Modal
          header={i18n.t('summary:modalWantToCancel')}
          leftButton={cancelBookingButton}
          message={i18n.t('summary:modalMessageMore24')}
          rightButton={rebookButton}
          triggerComponent={
            <Button
              block={true}
              icon={<FontAwesomeIcon icon={faWindowClose} />}
              title={i18n.t('common:cancelBooking')}
              variant="danger"
              verticalSpacing="narrow"
            />
          }
        />
      )
    }

    return <></>
  }

  private renderModalDefaultCancel = (): JSX.Element => {
    const { i18n } = this.props
    const { canCancelBooking } = this.state

    const leftButton = {
      component: <Button title={i18n.t('common:cancel')} variant="secondary" />,
      closeOnClick: true,
    }
    const rightButton = {
      component: <Button title={i18n.t('common:cancelBooking')} variant="accent" />,
      callbackFn: this.cancelBooking,
      closeOnClick: true,
    }

    if (canCancelBooking) {
      return (
        <Modal
          header={i18n.t('summary:modalWantToCancel')}
          hideDefaultCloseButton={true}
          leftButton={leftButton}
          rightButton={rightButton}
          triggerComponent={
            <Button
              block={true}
              icon={<FontAwesomeIcon icon={faWindowClose} />}
              title={i18n.t('common:cancelBooking')}
              variant="danger"
              verticalSpacing="narrow"
            />
          }
        />
      )
    }

    return <></>
  }

  private cancelBooking = (): void => {
    const {
      bookingStore: { CancelBooking, bookingNumber },
    } = this.props as ISummaryPageStores
    const { i18n } = this.props

    // TODO: Förbättra det här, bookingNumber kan inte vara undefined på summarypage
    if (!bookingNumber) return

    this.setState({ cancelling: true })

    CancelBooking(bookingNumber)
      .then(
        _.bind(this.setState, this, {
          showCancelCompleteDialog: true,
          cancelling: false,
        }),
      )
      .catch((e): void => {
        this.setState({ cancelling: false })
        toast(<Notification title={i18n.t('errors:somethingWrong')} type="error" />)
      })
  }

  private cancelBookingPaidWithAvi = async (): Promise<void> => {
    const {
      uiStore: { bookingActions },
    } = this.props as ISummaryPageStores
    const { cancelBookingInfo } = this.state
    this.setState({ cancelling: true })

    // If there are more than 24 hours left then the AVI form is used.
    if (bookingActions.canBankCredit && cancelBookingInfo && !cancelBookingInfo.LessThan24Hours) {
      this.setState({ showAviForm: true, cancelling: false })
    } else {
      // If less than 24 hours a regular cancellation is performed.
      this.cancelBooking()
    }
  }

  private onAviFormSubmit = async (values: IAviFormData, actions: FormikActions<IAviFormData>): Promise<void> => {
    const { bookingStore } = this.props
    if (!bookingStore) return

    this.setState({ cancelling: true })

    try {
      if (bookingStore.bookingNumber) {
        await bookingStore.CancelBooking(bookingStore.bookingNumber, values)

        this.setState({ showAviForm: false, cancelling: false, showCancelCompleteDialog: true })
      }
    } catch {
      this.setState({ showAviForm: false, cancelling: false })
    } finally {
      actions.setSubmitting(false)
    }
  }

  private onAviFormCancel = (): void => {
    this.setState({ showAviForm: false })
  }

  private onRebookCancel = (): void => {
    this.setState({ showRebookForm: false })
  }

  private handleClickRebook = (): void => {
    const { canRebookWithoutAuthentication } = (this.props as ISummaryPageStores).uiStore.bookingActions
    if (canRebookWithoutAuthentication) {
      this.onRebookSubmit('')
    } else {
      this.setState({ showRebookForm: true })
    }
  }

  private handleClickToggleAddCampaignCode = (): void => {
    this.setState({ showCampaignCodeForm: !this.state.showCampaignCodeForm })
  }

  // TODO: Uppdatera så att man kommer till stationsAndTime när man lägger till en giltig kampanjkod.
  private handleClickAddCampaignCode = async (): Promise<void> => {
    const { i18n } = this.props
    const {
      bookingStore,
      uiStore,
      sessionStore: { rebookingState },
    } = this.props as ISummaryPageStores

    const { bookingNumber, SetCampaignCode } = bookingStore

    try {
      this.setState({
        addingCampaignCode: true,
      })

      if (bookingNumber) {
        const priceBeforeCampaignCode = bookingStore.amount || 0

        const booking = await SetCampaignCode(bookingNumber, this.state.campaignCodeValue)

        if (booking) {
          try {
            if (bookingStore.bookingNumber) {
              rebookingState.storeLastPrice(priceBeforeCampaignCode)
              uiStore.jump(Paths.stationsAndTime, this.props.history, undefined, {
                shouldOverridePreviousPageComplete: true,
              })

              // return
            }
          } catch (e) {
            throw new Error(e)
          }
        }
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        notifyError(i18n.t('common:campaignCodeDoesntExist'), undefined, 'info')
      } else {
        notifyError(e)
      }
    } finally {
      this.setState({
        addingCampaignCode: false,
      })
    }
  }

  private handleCampaignCodeInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ campaignCodeValue: event.target.value.toUpperCase() })
  }

  private navigateToCheckout = (): void => {
    const { uiStore } = this.props
    if (!uiStore) return

    uiStore.jump(PossibleRootPathValue.checkout, this.props.history, undefined, {
      hideFooter: true,
      hideNavigation: true,
      shouldOverridePreviousPageComplete: true,
    })
  }

  private renderCampaignCodeInput = (): JSX.Element => {
    const { i18n } = this.props
    const { campaignCodeValue } = this.state

    return (
      <Card>
        <Section title={i18n.t('common:campaignCode')}>
          <Input
            placeholder={i18n.t('common:enterCampaignCode')}
            style={{ marginTop: '0.5rem' }}
            value={campaignCodeValue}
            onChange={this.handleCampaignCodeInputChange}
          />
          <ButtonGroup verticalSpacing="narrow">
            <Button title={i18n.t('common:cancel')} onClick={this.handleClickToggleAddCampaignCode} />
            <Button title={i18n.t('common:add')} onClick={this.handleClickAddCampaignCode} />
          </ButtonGroup>
        </Section>
      </Card>
    )
  }

  private sendEmailConfirmation = async (): Promise<void> => {
    const { bookingStore } = this.props
    if (!bookingStore || !bookingStore.bookingNumber) return

    this.setState({ sendingEmail: true })

    const { i18n } = this.props
    try {
      await API_DEPRECATED.Bookings.postSendConfirmation(bookingStore.bookingNumber)
      toast(<Notification title={i18n.t('summary:confirmationOnItsWay')} type="success" />)
    } catch (e) {
      notifyError(e)
    }
    this.setState({ sendingEmail: false })
  }

  private onRebookSubmit = async (value: string): Promise<void> => {
    const { history, i18n } = this.props
    const {
      bookingStore,
      uiStore,
      sessionStore: { rebookingState },
    } = this.props as ISummaryPageStores
    if (!uiStore || !bookingStore) return

    try {
      if (bookingStore.bookingNumber) {
        rebookingState.storeLastPrice(bookingStore.amount || 0)
        await bookingStore.CloneBooking(bookingStore.bookingNumber, value)
        uiStore.jump(Paths.vehiclesAndProducts, history)

        return
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.status === 400) {
        toast(
          <Notification
            description={i18n.t('errors:contactSupport')}
            title={i18n.t('summary:rebookWrongCredentials')}
            type="error"
          />,
        )
      } else {
        toast(<Notification title={i18n.t('errors:somethingWrong')} type="error" />)
      }
    }
  }

  private redirectToStart = (): void => {
    const redirectTo = this.props.uiStore?.redirectUrl || '/'

    window.location.href = redirectTo
  }

  private getTitle = (): { title: string; message?: ReactNode; summaryTitle: string } => {
    const { i18n } = this.props
    const { bookingStore, uiStore } = this.props as ISummaryPageStores

    const { bookingStatus } = bookingStore
    const { canPay } = uiStore.bookingActions

    let message: ReactNode | undefined,
      summaryTitle = i18n.t('summary:bookingConfirmation'),
      title = i18n.t('summary:titles.main')

    if (bookingStatus === BookingStatus.NotConfirmed) {
      title = i18n.t('summary:titles.offer')
      message = (
        <Paragraph margin={{ bottom: 'medium' }} textAlign="center">
          {i18n.t('summary:notConfirmedMessage')}
        </Paragraph>
      )
      summaryTitle = i18n.t('summary:titles.proposedTime')
    } else if (canPay) {
      message = (
        <Paragraph margin={{ bottom: 'medium' }} textAlign="center">
          {i18n.t('summary:bookingNotPayed')}
        </Paragraph>
      )
    }

    return { title, message, summaryTitle }
  }

  /**
   * Om bokningen INTE är bekräftad och bookingActions.CanPay == true ska ett felmeddelande visas.
   * Användaren uppmuntras att betala igen med Klarna.
   */
  private renderPaymentMessage = (): JSX.Element | null => {
    const { i18n } = this.props
    const { uiStore, bookingStore } = this.props as ISummaryPageStores

    const { bookingStatus } = bookingStore
    const { canPay } = uiStore.bookingActions

    return bookingStatus === BookingStatus.Temporary && canPay ? (
      <Alert message={i18n.t('summary:errorWithKlarna')} type="error" />
    ) : null
  }
}
export default inject('bookingStore', 'uiStore', 'sessionStore')(withTranslation()(withRouter(SummaryPage)))
