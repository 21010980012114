import * as React from 'react'

import { useTranslation } from 'react-i18next'
import { Field, Form, Formik } from 'formik'

import { Button, ButtonGroup, Card, Input, Paragraph, RadioGroup } from 'Components'

/* Import interfaces here */
import { AviRepaymentOption, IAviFormData, IAviFormProps } from './AviForm.interfaces'

/* Import utils here */
import { isValidEmail, isValidPhoneNumber } from 'Utils'
import { colors } from 'Theme/colors'

export const AviForm: React.FC<IAviFormProps> = ({ onCancel, onSubmit, descriptionText, submitButtonText }) => {
  const initialValues: IAviFormData = {
    name: '',
    email: '',
    phoneNumber: '',
    accountNumber: '',
    address: '',
    bank: '',
    bgNumber: '',
    city: '',
    clearingNumber: '',
    postcode: '',
    selectedPaymentOption: AviRepaymentOption.bank,
  }

  const { t } = useTranslation()

  const isLikeInitialValues = (values: IAviFormData): boolean => {
    let ok =
      values.name === initialValues.name &&
      values.email === initialValues.email &&
      values.phoneNumber === initialValues.phoneNumber

    switch (values.selectedPaymentOption) {
      case AviRepaymentOption.avi:
        ok =
          ok &&
          values.address === initialValues.address &&
          values.city === initialValues.city &&
          values.postcode === initialValues.postcode

        break
      case AviRepaymentOption.bank:
        ok =
          ok &&
          values.bank === initialValues.bank &&
          values.clearingNumber === initialValues.clearingNumber &&
          values.accountNumber === initialValues.accountNumber
        break
      case AviRepaymentOption.bg:
        ok = ok && values.bgNumber === initialValues.bgNumber
        break
      default:
        break
    }

    return ok
  }

  const validate = (values: IAviFormData): Partial<IAviFormData> | undefined => {
    const errors: Partial<IAviFormData> = {}

    if (isLikeInitialValues(values)) {
      return
    }

    if (!values.name) errors.name = t('common:form.required')

    if (!values.email || !isValidEmail(values.email)) errors.email = t('common:form.email')

    if (!values.phoneNumber || !isValidPhoneNumber(values.phoneNumber)) errors.phoneNumber = t('common:form.phone')

    switch (values.selectedPaymentOption) {
      case AviRepaymentOption.avi:
        if (!values.address) errors.address = t('common:form.required')

        if (!values.city) errors.city = t('common:form.required')

        if (!values.postcode) errors.postcode = t('common:form.required')

        break
      case AviRepaymentOption.bank:
        if (!values.bank) errors.bank = t('common:form.required')

        if (!values.clearingNumber) errors.clearingNumber = t('common:form.required')

        if (!values.accountNumber) errors.accountNumber = t('common:form.required')

        break
      case AviRepaymentOption.bg:
        if (!values.bgNumber) errors.bgNumber = t('common:form.required')

        break
      default:
        break
    }

    return errors
  }

  return (
    <Formik
      initialValues={initialValues}
      render={({ isSubmitting, values, errors, touched }): React.ReactNode | undefined => (
        <Card>
          <p>{descriptionText || t('summary:aviDescription')}</p>
          <Form style={{ display: 'flex', flexFlow: 'column' }}>
            <RadioGroup>
              <Field
                checked={values.selectedPaymentOption === AviRepaymentOption.bank}
                component={RadioGroup.RadioButton}
                disabled={isSubmitting}
                id="selectedPaymentOptionBank"
                label="Bank"
                name="selectedPaymentOption"
                value={AviRepaymentOption.bank}
              />
              <Field
                checked={values.selectedPaymentOption === AviRepaymentOption.avi}
                component={RadioGroup.RadioButton}
                disabled={isSubmitting}
                id="selectedPaymentOptionAvi"
                label="AVI"
                name="selectedPaymentOption"
                value={AviRepaymentOption.avi}
              />
              <Field
                checked={values.selectedPaymentOption === AviRepaymentOption.bg}
                component={RadioGroup.RadioButton}
                disabled={isSubmitting}
                id="selectedPaymentOptionBG"
                label="BG"
                name="selectedPaymentOption"
                value={AviRepaymentOption.bg}
              />
            </RadioGroup>
            <Field
              component={Input}
              disabled={isSubmitting}
              id="name"
              label={t('summary:firstAndLastName')}
              message={touched.name && errors.name}
              name="name"
              type="text"
              variant={touched.name && errors.name && 'error'}
            />
            <Field
              component={Input}
              disabled={isSubmitting}
              id="email"
              label={t('common:email')}
              message={touched.email && errors.email}
              name="email"
              type="text"
              variant={touched.email && errors.email && 'error'}
            />
            <Field
              component={Input}
              disabled={isSubmitting}
              id="phoneNumber"
              label={t('common:phoneNumber')}
              message={touched.phoneNumber && errors.phoneNumber}
              name="phoneNumber"
              type="text"
              variant={touched.phoneNumber && errors.phoneNumber && 'error'}
            />
            {values.selectedPaymentOption === AviRepaymentOption.bank && (
              <>
                <Field
                  component={Input}
                  disabled={isSubmitting}
                  id="bank"
                  label={t('summary:bank')}
                  message={touched.bank && errors.bank}
                  name="bank"
                  type="text"
                  variant={touched.bank && errors.bank && 'error'}
                />
                <Field
                  component={Input}
                  disabled={isSubmitting}
                  id="clearingNumber"
                  label={t('summary:clearingNumber')}
                  maxLength="5"
                  message={touched.clearingNumber && errors.clearingNumber}
                  name="clearingNumber"
                  type="text"
                  variant={touched.clearingNumber && errors.clearingNumber && 'error'}
                />
                <Paragraph color={colors.black} margin={{ bottom: 'large' }}>
                  {t('summary:clearingNumberDescription')}
                </Paragraph>
                <Field
                  component={Input}
                  disabled={isSubmitting}
                  id="accountNumber"
                  label={t('summary:accountNumber')}
                  message={touched.accountNumber && errors.accountNumber}
                  name="accountNumber"
                  type="text"
                  variant={touched.accountNumber && errors.accountNumber && 'error'}
                />
              </>
            )}
            {values.selectedPaymentOption === AviRepaymentOption.avi && (
              <>
                <Field
                  component={Input}
                  disabled={isSubmitting}
                  id="address"
                  label={t('common:address')}
                  message={touched.address && errors.address}
                  name="address"
                  type="text"
                  variant={touched.address && errors.address && 'error'}
                />
                <Field
                  component={Input}
                  disabled={isSubmitting}
                  id="postcode"
                  label={t('common:zipCode')}
                  message={touched.postcode && errors.postcode}
                  name="postcode"
                  type="text"
                  variant={touched.postcode && errors.postcode && 'error'}
                />
                <Field
                  component={Input}
                  disabled={isSubmitting}
                  id="city"
                  label={t('common:city')}
                  message={touched.city && errors.city}
                  name="city"
                  type="text"
                  variant={touched.city && errors.city && 'error'}
                />
              </>
            )}
            {values.selectedPaymentOption === AviRepaymentOption.bg && (
              <>
                <Field
                  component={Input}
                  disabled={isSubmitting}
                  id="bgNumber"
                  label={t('summary:bankgiroNumber')}
                  message={touched.bgNumber && errors.bgNumber}
                  name="bgNumber"
                  type="text"
                  variant={touched.bgNumber && errors.bgNumber && 'error'}
                />
              </>
            )}
            <ButtonGroup verticalSpacing="wide">
              {onCancel && (
                <Button
                  disabled={isSubmitting}
                  title={t('common:cancel')}
                  type="button"
                  variant="secondary"
                  onClick={onCancel}
                />
              )}
              <Button disabled={isSubmitting} title={t('common:reset')} type="reset" variant="danger" />
              <Button
                disabled={isSubmitting}
                title={submitButtonText || t('cancelBooking')}
                type="submit"
                variant="accent"
              />
            </ButtonGroup>
          </Form>
        </Card>
      )}
      validate={validate}
      onSubmit={onSubmit}
    />
  )
}

export default AviForm
