import { css } from 'styled-components'
import { styled } from 'Theme'

import { StyledInput } from 'Components/Generic/Input/Input.styles'

interface IWrapperProps {
  border: boolean
}

export const LicensePlateIcon = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;

  padding: 0.5rem 0.3rem;

  color: white;
  background: ${(props): string => props.theme.colors.licensePlateBlue};

  border-radius: 0.25rem 0 0 0.25rem;

  .eu-icon {
    display: inline-block;

    width: 1rem;
    height: 1rem;
    margin-top: 0.4rem;
    border-radius: 0.5rem;
    border: 2px dotted #ffcc00; /* Pantone Yellow motsvarar RGB: 255/204/0 (hexadecimal: FFCC00) (http://publications.europa.eu/code/sv/sv-5000100.htm) */
  }
`

export const StyledRegNoInput = styled.div<IWrapperProps>`
  display: flex;

  border: 10px;

  ${StyledInput} {
    background: ${({ theme }): string => theme.colors.white};
    flex: 1;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    text-transform: uppercase;

    border-radius: 0 0.25rem 0.25rem 0;

    ${({ border }): any =>
      border &&
      css`
        border-width: 2px;
        border-color: ${({ theme }): string => theme.colors.gray};
      `};

    ::placeholder {
      opacity: 0.1;
    }
  }
`
