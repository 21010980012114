import { styled } from 'Theme'

import { IStyledLogoProps } from './Logo.interfaces'

export const StyledLogo = styled.div<IStyledLogoProps>`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: ${({ showText }): string => (showText ? 'auto' : '50px')};

  a {
    border: none;
  }

  img {
    height: 3rem;
    width: ${({ showText }): string => (showText ? '175px' : '283px')};

    @media (min-width: ${({ theme }): string => theme.sizes.xsContainer}) {
      width: 175px;
    }

    @media (min-width: ${({ theme }): string => theme.sizes.smContainer}) {
      width: 283px; /* Default size */
    }
  }

  @media (min-width: ${({ theme }): string => theme.sizes.xsContainer}) {
    width: auto;
  }
`
