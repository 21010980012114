import { Instance, types } from 'mobx-state-tree'

import { BookingStore, ProductsStore, SessionStore, StationsStore, UIStore } from './'

/**
 * A RootStore model.
 */
export const RootStore = types.model({
  bookingStore: BookingStore,
  productsStore: ProductsStore,
  stationsStore: StationsStore,
  uiStore: UIStore,
  sessionStore: SessionStore,
})

/**
 * The RootStore instance.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRootStore extends Instance<typeof RootStore> {}
