import { Button, Card } from 'Components'
import { styled } from 'Theme'

import { IVehicleDataCardProps } from './VehicleDataCard.interfaces'

export const StyledVehicleDataCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;

  line-height: 1;
  padding-bottom: 1rem;

  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-color: #d4d4d4;
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: ${(props: Partial<IVehicleDataCardProps>): string => (props.buttonCallback ? 'column' : 'row')};
  align-items: center;

  > div:first-of-type {
    flex-grow: 1;
  }

  > svg {
    color: black;
    flex-grow: 0;
    margin-top: 3px;
    margin-right: 0.75rem;
  }

  @media (min-width: ${({ theme }): string => theme.sizes.smContainer}) {
    flex-direction: row;
  }
`

export const VehicleInfo = styled.p`
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 1.1rem;

  margin: 0.25rem 0 0.25rem 0;
`

export const RegistrationNumber = styled.h2`
  flex-grow: 2;
  margin-bottom: 0.5rem;
`

export const LastInspectionDate = styled.p`
  order: 2;
  font-weight: 500;
  margin: 0.25rem 0 0.25rem 0;
`

export const DrivingStatus = styled.p`
  order: 2;
  font-weight: 500;
  margin: 0;
`

export const ButtonWrapper = styled.div`
  width: 2rem;

  position: absolute;
  margin: -26px 24px;
  align-self: flex-end;
`

export const TrashButton = styled(Button)`
  height: 2rem;
  width: 2rem;
  padding: 0;

  background-color: ${({ theme }): string => theme.colors.semiLightGray};

  transition: background-color 0.2s ease-out;

  &:hover,
  &:focus {
    background-color: ${({ theme }): string => theme.colors.lightGray};
    transition: background-color 0.2s ease-out;
  }

  svg {
    color: ${({ theme }): string => theme.colors.grayDark};
    margin-right: 0;
  }
`
