import { Instance, types } from 'mobx-state-tree'

import { PathValues } from 'Config/routes'

export const RouteData = types
  .model({
    isComplete: false,
    nextPath: types.maybe(types.enumeration(PathValues)),
    params: types.maybe(types.string),
    previousPath: types.maybe(types.enumeration(PathValues)),
    rootPath: types.identifier,
  })
  // TODO: Definiera korrekt return type
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  .actions(self => ({
    setIsComplete: (complete: boolean): void => {
      self.isComplete = complete
    },
  }))

// TODO: Justera så vi eventuellt inte behöver inaktivera den här regeln
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRouteData extends Instance<typeof RouteData> {}
