import { ITheme, styled } from 'Theme'
import { FlattenSimpleInterpolation, css } from 'styled-components'

import { IStyledTextProps, TFontWeight } from './Text.interfaces'

type TConditionalStyle<T> = FlattenSimpleInterpolation | string | undefined | false | T

const truncateStyle = (): FlattenSimpleInterpolation => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const calculateFontWeight = (weight: TFontWeight, theme: ITheme): string | number => {
  if (typeof weight === 'number') {
    return weight
  } else if (weight) {
    return theme.text.weight[weight]
  }

  return theme.text.weight.normal
}

export const StyledText = styled.span<IStyledTextProps>`
  color: ${({ color, theme }): string => color || theme.text.color};

  font-size: ${({ size, theme }): string => theme.text.size[size]};

  font-weight: ${({ fontWeight, theme }): string | number => calculateFontWeight(fontWeight, theme)};

  text-align: ${({ textAlign }): string => textAlign};

  /** Truncation */
  ${({ truncate }): TConditionalStyle<typeof truncateStyle> => truncate && truncateStyle()}
`
