import { colors } from './colors'

export interface IInput {
  backgroundColor: {
    default: string
    disabled: string
  }
  borderColor: string
  borderRadius: string
  borderStyle: string
  borderWidth: string
  fontWeight: string
  padding: string
  label: {
    color: string
    fontSize: string
    fontWeight: string
    marginBottom: string
  }
  message: {
    marginTop: string
    error: {
      color: string
    }
  }
  variant: {
    error: {
      background: string
      borderWidth: string
      borderStyle: string
      borderColor: string
    }
  }
}

export const input: IInput = {
  backgroundColor: {
    default: colors.semiLightGray,
    disabled: colors.gray,
  },
  padding: '1rem',
  borderColor: 'transparent',
  borderRadius: '0.25rem',
  borderStyle: 'solid',
  borderWidth: '1px',
  fontWeight: '500',
  label: {
    color: colors.primaryDark,
    fontWeight: 'bold',
    fontSize: '1rem',
    marginBottom: '0.5rem',
  },
  message: {
    marginTop: '0.5rem',
    error: {
      color: colors.red,
    },
  },
  variant: {
    error: {
      background: 'rgb(255, 179, 179)',
      borderColor: colors.red,
      borderStyle: 'solid',
      borderWidth: '2px',
    },
  },
}
