import { styled } from 'Theme'

export const StyledPage = styled.div`
  min-height: 100vh; /* Keep the Header, Navigation and Footer at top and bottom. */
  position: relative; /* Keep the Header, Navigation and Footer at top and bottom. */
`

export const PageContent = styled.div`
  width: 100vw;

  padding: 9rem 1rem 4rem; /* 9 rem is needed for the Header/Navigation and 4rem is needed at the bottom for the footer. */

  margin: 0 auto;

  @media (min-width: ${({ theme }): string => theme.sizes.mdContainer}) {
    width: ${({ theme }): string => theme.sizes.mdContainer};
  }
  @media (min-width: ${({ theme }): string => theme.sizes.xlContainer}) {
    width: ${({ theme }): string => theme.sizes.xlContainer};
  }
`
