import 'core-js/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import * as Sentry from '@sentry/react'
import './ie11.css'

import { App } from 'App'
import { LogConfig, sentryInitSettings } from 'Config'
import { EnvironmentVariables } from 'Config/environment'

import * as serviceWorker from 'serviceWorker'

// Initiate configuration of services and libraries
if (EnvironmentVariables.SENTRY_ENABLED) {
  // sentryConfig()
  Sentry.init(sentryInitSettings)
}

// Activate Reactotron locally if it is enabled in your `.env`-file.
if (process.env.NODE_ENV === 'development' && EnvironmentVariables.USE_REACTOTRON) {
  LogConfig.configure(true)
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
