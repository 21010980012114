import { Instance, types } from 'mobx-state-tree'
import { IBookingRowDto } from 'Api_DEPRECATED/interfaces'

export const BookingRow = types.model('BookingRow', {
  discount: types.number,
  price: types.number,
  priceWithoutVat: types.number,
  productId: types.number,
  productName: types.string,
  regNo: types.string,
  vehicleDescription: types.string,
})

// FIXME: Justera så vi eventuellt inte behöver inaktivera den här regeln
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBookingRow extends Instance<typeof BookingRow> {}

export const BookingRowModelFromBookingRowDto = (bookingRow: IBookingRowDto): IBookingRow => {
  return BookingRow.create({
    discount: bookingRow.Discount || 0,
    price: bookingRow.Price || 0,
    priceWithoutVat: bookingRow.PriceWithoutVat,
    productId: bookingRow.ProductId || 0,
    productName: bookingRow.ProductName || 'UNAVAILABLE',
    regNo: bookingRow.Vehicle.RegNo || 'UNAVAILABLE',
    vehicleDescription: bookingRow.Vehicle.VehicleDescription || 'UNAVAILABLE',
  })
}
