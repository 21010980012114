import * as React from 'react'
import { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons'

/* Import components here */
import {
  CalendarGrid,
  DayName,
  Month,
  MonthButton,
  Navigation,
  StyledCalendar,
  StyledDay,
  Year,
} from './Calendar.styles'
import { HideWhileLoading } from 'Components'

/* Import interfaces here */
import { ICalendarProps, IDayProps } from './Calendar.interfaces'

/* Import utilities here */
import { generateCalendar, isSameDay, isSameMonth } from './Calendar.utilities'
import { addWeeks, format, startOfWeek } from 'date-fns'
import { getMonthName, ifDefined } from 'Utils'

/**
 * Returns if given date is today
 * @param date - date to compare
 * @returns True if this is today
 */
const isToday = (date: Date): boolean => isSameDay(date, new Date())

/**
 * Generate a string to be used as key in extraInfo
 * @param d - date to generate key for
 * @returns the key to be used
 */
const getExtraInfoKey = (d: Date): string => format(d, 'yyyy-MM-dd')

/**
 * prepare props for the Day component
 * @remarks This is a curried function
 * @param value - Current selected value in calendar
 * @param month - Current selected month in the calendar
 * @param extraInfo - Extra info dictionary to fetch extra info from
 * @param date - generate props for this day
 *
 * @returns an object that can be used as props to the Day component
 */
const prepareItem = <T extends unknown>(value: Date | undefined, month: Date, extraInfo: T, isMonth: boolean) => (
  date: Date,
) => {
  const key = getExtraInfoKey(date)
  const info = extraInfo ? extraInfo[key] : undefined

  return {
    date,
    isToday: isToday(date),
    isSelected: ifDefined(value, v => isSameDay(v, date), false),
    isCurrentMonth: isSameMonth(month, date),
    extraInfo: info,
    isMonth: isMonth,
  }
}

const DefaultDayComponent = <T extends unknown>(dayProps: IDayProps<T>): JSX.Element => {
  const { date, extraInfo, ...props } = dayProps
  return (
    <StyledDay {...props}>
      {date.getDate()}
      &nbsp;:&nbsp;
      {extraInfo || '0'} st
    </StyledDay>
  )
}

function CalendarTitle<T>({ days, isFullMonth }: { days: IDayProps<T>[]; isFullMonth: boolean }) {
  const firstVisibleDay = days[0].date
  const lastVisibleDay = days[days.length - 1].date

  const startDate = firstVisibleDay.getDate()
  const endDate = lastVisibleDay.getDate()
  const startMonth = getMonthName(firstVisibleDay, true)
  const endMonth = getMonthName(lastVisibleDay, true)
  const year = firstVisibleDay.getFullYear()

  const currentMonth = getMonthName(days.find(day => day.date.getDate() === 1)?.date || firstVisibleDay, false)

  const startEndDate = isFullMonth ? currentMonth : `${startDate} ${startMonth} - ${endDate} ${endMonth}`

  return (
    <div>
      <Month>{startEndDate}</Month>
      <Year>{year}</Year>
    </div>
  )
}
/**
 *
 * @param date Current month to calculate weeks needed to display
 * @returns number of weeks needed to be displayed or loaded
 */
export function getWeeksInMonth(date: Date): number {
  const year = date.getFullYear()
  const month = date.getMonth()
  const firstDayOfMonth = new Date(year, month, 1)
  const lastDayOfMonth = new Date(year, month + 1, 0)
  const daysFromPrevMonth = (firstDayOfMonth.getDay() + 6) % 7
  const daysFromNextMonth = (6 - lastDayOfMonth.getDay()) % 7
  const totalDays = daysFromPrevMonth + lastDayOfMonth.getDate() + daysFromNextMonth
  return Math.ceil(totalDays / 7)
}
export function Calendar<T>(props: ICalendarProps<T>): JSX.Element {
  const {
    value,
    month: firstDayToShow,
    onChangeMonth,
    onSelectDate,
    dayComponent,
    isLoading,
    viewFullMonth = false,
    error,
    ...styleProps
  } = props
  const { t } = useTranslation('common')
  let { extraInfo } = props

  const numberOfWeeks = viewFullMonth ? 5 : 1

  const dayNames = [
    t('calendar.mondayShort'),
    t('calendar.tuesdayShort'),
    t('calendar.wednesdayShort'),
    t('calendar.thursdayShort'),
    t('calendar.fridayShort'),
    t('calendar.saturdayShort'),
    t('calendar.sundayShort'),
  ]

  // Make sure it's not undefined
  extraInfo = extraInfo ?? {}

  const calendarDays: IDayProps<T>[] = Array.from(generateCalendar(firstDayToShow, numberOfWeeks)).map(
    prepareItem(value, firstDayToShow, extraInfo, viewFullMonth),
  )

  const onNextMonthClick = useCallback(() => {
    const nextDate = startOfWeek(addWeeks(firstDayToShow, numberOfWeeks), { weekStartsOn: 1 })
    onChangeMonth(nextDate)
  }, [firstDayToShow, numberOfWeeks, onChangeMonth])

  const onPrevMonthClick = useCallback(() => {
    let nextDate = addWeeks(firstDayToShow, -numberOfWeeks)
    if (nextDate.getTime() < Date.now()) {
      nextDate = startOfWeek(Date.now(), { weekStartsOn: 1 })
    }

    onChangeMonth(nextDate)
  }, [firstDayToShow, numberOfWeeks, onChangeMonth])

  const DayComponent: React.FC<IDayProps<T>> = dayComponent ?? DefaultDayComponent

  const canNavigatePreviousPeriod =
    startOfWeek(firstDayToShow, { weekStartsOn: 1 }) > startOfWeek(new Date(), { weekStartsOn: 1 })

  return (
    <StyledCalendar {...styleProps}>
      <Navigation>
        <MonthButton
          disabled={isLoading ?? !canNavigatePreviousPeriod}
          icon={<FontAwesomeIcon icon={faAngleLeft} />}
          size="small"
          onClick={onPrevMonthClick}
        />
        <CalendarTitle days={calendarDays} isFullMonth={viewFullMonth} />
        <MonthButton
          disabled={isLoading}
          icon={<FontAwesomeIcon icon={faAngleRight} />}
          size="small"
          onClick={onNextMonthClick}
        />
      </Navigation>
      <HideWhileLoading error={error} isLoading={isLoading}>
        <CalendarGrid columns={7}>
          {dayNames.map(
            (dayName, index): JSX.Element => (
              <DayName key={`dayname${index}`}>{dayName}</DayName>
            ),
          )}
          {calendarDays.map(
            (dateProps: IDayProps<T>, index): JSX.Element => (
              <DayComponent
                key={getExtraInfoKey(dateProps.date)}
                {...dateProps}
                isFirstVisibleDay={index === 0}
                onClick={() => onSelectDate(dateProps.date)}
              />
            ),
          )}
        </CalendarGrid>
      </HideWhileLoading>
    </StyledCalendar>
  )
}

export default Calendar
