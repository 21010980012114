import * as React from 'react'

/* Import components here */
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StyledChip, Text } from './Chip.styles'

/* Import interfaces here */
import { IChipProps } from './Chip.interfaces'

/* Import utilities here */

export const Chip: React.FC<IChipProps> = props => (
  // FIXME: kolla i Storybook på Chip. Det finns inte en bra default height
  <StyledChip maxWidth={props.maxWidth} {...props}>
    {/* FIXME: Bör icon fallback:a på `faTimes`? */}
    <FontAwesomeIcon icon={props.icon || faTimes} spin={props.spinIcon} />
    <Text>{props.text}</Text>
  </StyledChip>
)

export default Chip
