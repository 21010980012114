import * as React from 'react'

/* Import components here */
import { StyledHeader } from './Header.styles'

export const Header: React.FC = ({ children, ...props }) => {
  return (
    <StyledHeader {...props} role="banner">
      {children}
    </StyledHeader>
  )
}

export default Header
