import { styled } from 'Theme'

import { StyledButton } from 'Components/Generic/Button/Button/Button.styles'

export const StyledButtonGroup = styled.div`
  display: flex;

  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  &.verticalSpacingNone {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.verticalSpacingWide {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &.horizontal {
    align-items: flex-start;
    flex-direction: row;

    ${StyledButton} {
      flex-grow: 1;

      &:not(:first-of-type) {
        margin-left: 1rem;
      }
    }
  }

  &.vertical {
    flex-direction: column;
  }
`
