import { colors } from './colors'

export interface IChip {
  backgroundColor: string
  borderRadius: string
  text: {
    size: string
    color: string
    transform: string
  }
  icon: {
    color: string
  }
}

export const chip: IChip = {
  backgroundColor: '#41bbc9',
  borderRadius: '0.25rem',
  icon: {
    color: '#004851',
  },
  text: {
    color: colors.white,
    size: '1rem',
    transform: 'uppercase',
  },
}
