import { styled } from 'Theme'
import { FlattenSimpleInterpolation, css } from 'styled-components'

interface IStyledTabs {
  vertical?: boolean
}

interface IStyledTabHeader {
  tabHeaderAlign?: string
}

const justifyContent = (tabHeaderAlign): FlattenSimpleInterpolation => {
  const justify: string =
    (tabHeaderAlign === 'left' && 'flex-start') ||
    (tabHeaderAlign === 'center' && 'space-evenly') ||
    (tabHeaderAlign === 'spaced' && 'space-between') ||
    'flex-start'

  return css`
    justify-content: ${justify};
  `
}

export const TabHeader = styled.div<IStyledTabHeader>`
  display: flex;

  ${({ tabHeaderAlign }): FlattenSimpleInterpolation => justifyContent(tabHeaderAlign)};
`

export const StyledTabs = styled.div`
  display: flex;

  flex-direction: ${({ vertical }: IStyledTabs): string => (vertical ? 'row' : 'column')};

  ${TabHeader} {
    flex-direction: ${({ vertical }: IStyledTabs): string => (vertical ? 'column' : 'row')};
  }
`

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
`
