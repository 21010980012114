import * as React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrosshairs, faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { withTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'

/* Import components here */
import { Button, Checkbox, Input, List, Modal, Paragraph, StationInfoModal, Text } from 'Components'
import { StyledSearchForm } from './SearchForm/SearchForm.styles'
import { Distance, StationInfo, StyledStation } from './StationList.styles'

/* Import interfaces here */
import { IStation } from 'Models/Stations'
import { IStationProps, IStationState } from './StationList.interfaces'

/* Utils */
import { getDistanceFromLatLonInKm, getPosition } from 'Utils'
import { ICoordinateDto } from 'Api_DEPRECATED'
import { theme } from 'Theme'
import { colors } from 'Theme/colors'
import { EnvironmentVariables } from 'Config/environment'

const paragraphMargin = { left: 'medium', top: 'small', bottom: 'none', right: 'medium' }

@inject('stationsStore')
@observer
export class StationList extends React.Component<IStationProps, IStationState> {
  public state: Readonly<IStationState> = {
    inputValue: '',
    isSortedByDistance: false,
    hasDoneASearch: false,
  }

  public getDefaultStationsToShow = (): IStation[] => {
    const { selectedStations, stations, coords } = this.props
    const { hasDoneASearch, isSortedByDistance } = this.state
    const stationsToShow = EnvironmentVariables.NUMBER_OF_STATIONS_TO_SHOW

    return this.distinctArray(
      ((hasDoneASearch || isSortedByDistance) && coords
        ? stations.sort(
            (a, b): number =>
              getDistanceFromLatLonInKm(coords.latitude, coords.longitude, a.latitude, a.longitude) -
              getDistanceFromLatLonInKm(coords.latitude, coords.longitude, b.latitude, b.longitude),
          )
        : stations
      )
        // Top `stationsToShow` or all if fewer than stationsToShow
        .slice(0, stations.length > stationsToShow ? stationsToShow : stations.length)
        // All selected
        .concat(stations.filter((station): boolean => selectedStations.includes(station.id))),
    )
  }

  // TODO: Use common utility function instead of duplicating code
  public distinctArray = <T extends unknown>(arr: T[]): T[] =>
    arr.filter((value, index, self): boolean => self.indexOf(value) === index)

  public render(): JSX.Element {
    const { i18n, stations } = this.props
    const { hasDoneASearch, inputValue } = this.state

    return (
      <StyledStation>
        <StyledSearchForm className="focus-anim">
          <Input
            className="fullWidth focus-anim"
            placeholder={i18n.t('stationsAndTime:cityZipNameAddress')}
            value={inputValue}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
          />
          <Button
            disabled={!hasDoneASearch && inputValue.length < 3}
            size={'small'}
            title={hasDoneASearch ? 'Rensa' : 'Sök'}
            onClick={this.handleSearchLocation}
          ></Button>
          <div onClick={this.handleCrossHair}>
            <FontAwesomeIcon icon={faCrosshairs} />
          </div>
        </StyledSearchForm>
        <Paragraph color={theme.colors.black} margin={paragraphMargin}>
          {i18n.t('stationsAndTime:description')}
        </Paragraph>
        {stations.length ? this.renderStationsList() : i18n.t('stationsAndTime:noStationsToShow')}
      </StyledStation>
    )
  }

  private handleSearchLocation = async (): Promise<void> => {
    const { stationsStore } = this.props
    const { hasDoneASearch, inputValue } = this.state

    // Om vi redan har gjort en sökning och klickar på knappen igen ska sökningen rensas
    if (hasDoneASearch) {
      // Återställ koordinaterna till mina egna
      const coords = await getPosition()
      if (coords) {
        this.props.onSetNewCoords(coords)
      }
      this.setState({
        hasDoneASearch: false,
        inputValue: '',
      })
      return
    }

    if (stationsStore && inputValue.length >= 3) {
      const coords: ICoordinateDto = await stationsStore.GetSearchLocation(inputValue)

      if (coords.Latitude && coords.Longitude) {
        const coordinates: Coordinates = {
          latitude: coords.Latitude,
          longitude: coords.Longitude,
          accuracy: 1,
          altitude: 1,
          altitudeAccuracy: 1,
          heading: 1,
          speed: 1,
        }

        this.props.onSetNewCoords(coordinates)

        // Sort by coords
        this.setState({
          hasDoneASearch: true,
        })
      }
    }
  }

  private renderStationsList = (): JSX.Element => {
    const { hasDoneASearch, inputValue } = this.state
    let stationsToShow

    if (hasDoneASearch || inputValue.length === 0) {
      stationsToShow = this.getDefaultStationsToShow()
    } else {
      stationsToShow = this.props.stations.filter(
        (station): boolean =>
          station.streetAddress.toLowerCase().search(inputValue) !== -1 ||
          station.city.toLowerCase().search(inputValue) !== -1 ||
          station.zipCode.toLowerCase().search(inputValue) !== -1 ||
          station.name.toLowerCase().search(inputValue) !== -1 ||
          this.props.selectedStations.includes(station.id),
      )
    }
    return <List>{stationsToShow.map(this.renderStation)}</List>
  }

  private handleCrossHair = async (): Promise<void> => {
    const coords = await getPosition()

    if (coords) {
      this.props.onSetNewCoords(coords)

      // Toggle sorted
      this.setState({
        isSortedByDistance: !this.state.isSortedByDistance,
        // Återställ ev. sökning också
        hasDoneASearch: false,
        inputValue: '',
      })
    }
  }

  // Search input for stations
  private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ inputValue: event.target.value.toLowerCase() })
  }
  private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      this.handleSearchLocation()
    }
  }

  private handleClick = (event: React.MouseEvent<HTMLElement>, id: number): void => {
    event.stopPropagation()

    // This is to be able to click on the info icon without selecting the station
    if (event.currentTarget.localName === 'li') {
      this.props.onSelectStation(id)
    }
  }

  private renderStation = (station: IStation): JSX.Element => {
    const { id, latitude, longitude, name, isDriveInOpen } = station

    const { coords, i18n, date = new Date() } = this.props

    const checked = this.props.selectedStations.includes(id as never)

    const onClick = (event: React.MouseEvent<HTMLElement>): void => this.handleClick(event, id)

    return (
      <List.Item key={id} data-id={id} onClick={onClick}>
        <List.Item.Left>
          <Checkbox checked={checked} />
        </List.Item.Left>
        <List.Item.Title>
          <Paragraph color={colors.primary}>{name}</Paragraph>
        </List.Item.Title>
        <List.Item.Right>
          {!!coords && (
            <Distance>
              {`(${Math.round(getDistanceFromLatLonInKm(coords.latitude, coords.longitude, latitude, longitude))} km)`}
            </Distance>
          )}
          <StationInfo onClick={onClick}>
            <Modal
              header={name ? name : i18n.t('common:information')}
              triggerComponent={<FontAwesomeIcon icon={faInfoCircle} />}
            >
              <StationInfoModal date={date} stationId={id} />
            </Modal>
          </StationInfo>
        </List.Item.Right>
      </List.Item>
    )
  }
}

export default withTranslation()(StationList)
